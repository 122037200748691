import React from 'react'

import './main.css'
const CircularProgress: React.FC<React.PropsWithChildren<Props>> = (props) => {
  const { size = 60, color = '#3f51b5' } = props

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100vw',
        height: '100vh',
      }}
    >
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <svg
          className="spinner"
          width={`${size}px`}
          height={`${size}px`}
          viewBox="0 0 66 66"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle
            className="path"
            fill="none"
            stroke={color}
            strokeWidth="6"
            strokeLinecap="round"
            cx="33"
            cy="33"
            r="30"
          ></circle>
        </svg>
      </div>
    </div>
  )
}
interface Props {
  size?: number
  color?: string
}
export default CircularProgress
