import { defaultActivityColors } from '@top/shared/src/Activity/types'
import {
  backgroundStyleSchema,
  buttonStyleSchema,
  dateInputFormatsSchema,
  numericScaleIconSchema,
  numericScaleSelectionStylesSchema,
  progressBarStyleSchema,
  sceneButtonTypeSchema,
  selectionBlockDisplayStyleTypeSchema,
  selectionBlockShapeSchema,
  spacingIncrementSchema,
  textStyleSchema,
} from '@top/shared/src/style/types'
import { parseData } from '@top/shared/src/zod/helpers/parseData'
import { defaultPromptTextStyles } from '@top/ui/src/SceneModules/components/PromptText/defaultStyles'
import { defaultDateDisplayStyle } from '@top/ui/src/SceneModules/DateEntry'
import { defaultResultTextStyles } from '@top/ui/src/SceneModules/NumericScale/components/ResultText'

import { z } from 'zod'

const themeTypes = ['THEME_DEFAULT', 'THEME_USER_CREATED'] as const
const themeTypeSchema = z.enum(themeTypes)

/**
 * Note that although this schema is not used outside of dashboard, it is used in the activitySchema
 * so we can't move it out of the shared package.
 */
export const customThemeSchema = z.object({
  id: z.string().optional(),
  org_id: z.string().optional(),
  activity_id: z.string().optional(),
  description: z.string().optional(),
  type: themeTypeSchema.optional(),
  name: z.string(),
  outer_background_styles: z.object({
    style: backgroundStyleSchema,
  }),
  inner_background_styles: z.object({
    style: backgroundStyleSchema.pick({ backgroundColor: true }),
  }),
  text_box_styles: z.object({ style: textStyleSchema }),
  title_text_styles: z.object({ style: textStyleSchema }),
  subtitle_text_styles: z.object({ style: textStyleSchema }),
  choice_question_text_styles: z.object({
    style: textStyleSchema.omit({ backgroundColor: true }),
  }),
  prompt_text_styles: z.object({
    style: textStyleSchema.optional().default(defaultPromptTextStyles),
  }),
  logo_styles: z.object({ style: z.object({ backgroundImage: z.string() }) }),
  numeric_scale_styles: z.object({
    resultTextStyle: textStyleSchema
      .omit({ textAlign: true })
      .optional()
      .default(defaultResultTextStyles),
    selections: z.object({
      style: numericScaleSelectionStylesSchema
        .omit({ shape: true })
        .extend({ shape: numericScaleIconSchema.exclude(['NUMERIC_FACE']) }),
    }),
  }),
  reaction_scale_styles: z.object({
    selections: z.object({
      style: numericScaleSelectionStylesSchema.omit({ shape: true }).extend({
        shape: numericScaleIconSchema.extract(['NUMERIC_FACE', 'NUMERIC_FACE_COLOR_GRADIENT']),
      }),
    }),
  }),
  date_entry_styles: z.object({
    dateDisplayStyle: textStyleSchema.partial().transform((styles) => ({
      ...defaultDateDisplayStyle,
      ...styles,
    })),
    dateInputStyle: textStyleSchema.omit({
      textAlign: true,
      fontSize: true,
      backgroundColor: true,
    }),
    dateFormat: dateInputFormatsSchema.optional().default('DATE_FORMAT_MONTH_DAY_YEAR'),
  }),
  button_styles: z.object({
    button_type: sceneButtonTypeSchema
      .or(z.null())
      .optional()
      .transform((type) => {
        if (!type) {
          return 'BUTTON_CONTAINED'
        }
        return type
      }),
    style: buttonStyleSchema.omit({ maxHeight: true }),
  }),
  progress_bar_styles: z.object({ style: progressBarStyleSchema }),
  created_at: z.string().optional(),
  updated_at: z.string().optional(),
  color_styles: z.object({ colors: z.array(z.string()).optional().default(defaultActivityColors) }),
  choice_question_display_styles: z.object({
    style: z.object({
      backgroundColor: z.string(),
      choicePadding: spacingIncrementSchema,
      gapSpacing: spacingIncrementSchema,
      choiceDisplayType: selectionBlockDisplayStyleTypeSchema,
      radioButtonShape: selectionBlockShapeSchema.optional(),
    }),
  }),
})

export type CustomTheme = z.infer<typeof customThemeSchema>

/**
 * @description Parses a theme's data using a zod schema. If `schema.parse` doesn't error out, type
 *              is narrowed to `CustomTheme` and a new theme with its default values is returned. In
 *              case the data cannot be parsed with the schema, an error is thrown.
 */
export const parseCustomTheme = (maybeTheme: object) =>
  parseData(maybeTheme, customThemeSchema, `Couldn't parse Custom Theme!`)
