import { useCallback, useEffect, useState } from 'react'

import fetchHelper from '@top/shared_deprecated/src/utils/api'
import useSentry from '@top/shared_deprecated/src/utils/useSentry'

import { useDistribution } from 'contexts/Distribution'
import { VCTraceID } from 'contexts/helpers'
import { v4 as uuid } from 'uuid'

const nullReq = {
  url: undefined,
  body: undefined,
  headers: undefined,
  onSuccess: undefined,
  onError: undefined,
  consumeData: undefined,
  onFinally: undefined,
  sentryMessage: undefined,
  sentryExtras: undefined,
}

/**
 * @deprecated
 */
function useAPI_DEPRECATED<Response = any>() {
  const [data, setData] = useState<any>()
  const [req, setReq] = useState<IParams>(nullReq)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [isError, setIsError] = useState<boolean>(false)
  const [isSuccess, setIsSuccess] = useState<boolean>(false)
  const [statusCode, setStatusCode] = useState<number | undefined>(undefined)
  const [error, setError] = useState<any>(undefined)
  const { hash, sessionId, source } = useDistribution()
  const report = useSentry()

  // setting state as Promise prevents batching
  const setReqWithPromise = useCallback((newState: IParams) => {
    Promise.resolve().then(() => setReq(newState))
  }, [])

  useEffect(() => {
    const traceId = uuid()
    let isMounted = true
    const fetchData = async () => {
      const {
        url,
        body,
        headers,
        onSuccess,
        onError,
        consumeData,
        onFinally,
        sentryMessage,
        sentryExtras,
      } = req
      if (!url) {
        return
      }
      setIsError(false)
      setIsLoading(true)
      try {
        const response = await fetchHelper({
          body,
          headers,
          url,
          traceId,
          withRetry: true,
        })
        if (!isMounted) return

        setStatusCode(response.statusCode)

        if (response.data) {
          setData(response.data)
          setIsSuccess(true)
          onSuccess && onSuccess(response.data)
          consumeData && consumeData(response.data)
        } else if (response.error) {
          setError(response.error)
          if (response.error.shouldReport) {
            report({
              severity: 'error',
              mainError: response.error,
              message: sentryMessage
                ? sentryMessage
                : `Something went wrong on ${url} from distributor`,
              tags: {
                API_Error: true,
                End_Point: url,
              },
              extras: {
                session_id: sessionId,
                HTTPStatusCode: response.statusCode,
                hash,
                source,
                [VCTraceID.sentryKey]: traceId,
                ...sentryExtras,
              },
            })
          }
          setIsError(true)
        }
      } catch (error) {
        // these are the errors that happen while we tried to hit the BE and before
        // we get to BE we got error. For example network issues on client side
        report({
          severity: 'error',
          mainError: error,
          message: sentryMessage
            ? sentryMessage
            : `Something went wrong on ${url} from distributor`,
          tags: {
            API_Error: true,
            End_Point: url,
          },
          extras: {
            session_id: sessionId,
            hash,
            source,
            traceId,
            ...sentryExtras,
            StatusCode: undefined,
          },
        })
        setIsError(true)
        onError && onError()
      } finally {
        if (onFinally) {
          onFinally()
        }
      }
      setIsLoading(false)
      setReq(nullReq)
    }
    fetchData()

    return () => {
      isMounted = false
    }
  }, [req, hash, sessionId, report, source])

  const APIProvider: [IResponseParams<Response>, ISetReq] = [
    { data, isLoading, isError, isSuccess, statusCode, error },
    setReqWithPromise,
  ]
  return APIProvider
}

interface IResponseParams<Response> {
  data: Response
  isLoading: boolean
  isError: boolean
  isSuccess: boolean
  statusCode: number | undefined
  error: any
}
interface IParams {
  url: string | undefined
  body: any
  headers?: { [headerKey: string]: string }
  onSuccess?: Function
  onError?: (error?: APIError) => void
  onFinally?: Function
  consumeData?: Function
  sentryMessage?: string
  sentryExtras?: { [key: string]: any }
}

interface APIError {
  code: string
  meta?: {
    argument: string
    custom_msg: string
    ui_code?: string
  }
  msg: string
}

type ISetReq = (params: IParams) => void

export default useAPI_DEPRECATED
