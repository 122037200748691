import { useCallback } from 'react'

import fetchHelper from '@top/shared_deprecated/src/utils/api'
import { VCTraceID } from '@top/shared_deprecated/src/utils/helpers'
import useSentry from '@top/shared_deprecated/src/utils/useSentry'

import { v4 as uuid } from 'uuid'

type Options = { headers?: Object; isForm?: boolean; sentryMessage?: string }

function useFetch() {
  const report = useSentry()

  return useCallback(
    async (url: string, body?: Object, options?: Options) => {
      const traceId = uuid()
      try {
        const response = await fetchHelper({
          body,
          headers: { ...options?.headers },
          url,
          traceId,
          withRetry: true,
        })
        if (response.error.shouldReport) {
          report({
            message: options?.sentryMessage || 'API Error',
            severity: 'error',
            tags: {
              [VCTraceID.sentryKey]: traceId,
            },
            extras: {
              apiError: response.error.data,
            },
          })
        }
        if (response.statusCode >= 400) {
          throw response.error.data
        }
        return response
      } catch (error) {
        // These are the errors that happen while we tried to hit the BE and before
        // we get to BE we got error. For example the 404 we didn't find it or network issue
        report({
          mainError: error,
          message: options?.sentryMessage || 'API Error',
          severity: 'error',
          tags: {
            [VCTraceID.sentryKey]: traceId,
          },
        })
        // Re-throw error so consumers can handle it
        throw error
      }
    },
    [report]
  )
}

export type CustomFetch = ReturnType<typeof useFetch>

export default useFetch
