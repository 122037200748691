import { useRef } from 'react'

import Box from '@mui/material/Box'

import { CloudinaryImage } from '@top/shared/src/components/CloudinaryImage'
import { useDimensions } from '@top/shared/src/hooks/useDimensions'
import { BACKGROUND_IMAGE_ASPECT_RATIO } from '@top/shared/src/style/constants'
import { type BackgroundStyle } from '@top/shared/src/style/types'

const MIN_WIDTH_FOR_DISABLING_IMAGE_TRANSFORMATIONS = 750

type Props = { backgroundStyles: BackgroundStyle; parentTestId?: string }

export function BackgroundImage(props: Props) {
  const { backgroundStyles, parentTestId } = props

  const containerRef = useRef<HTMLDivElement | null>(null)
  const dimensions = useDimensions(containerRef)

  const width = dimensions?.width ?? 0

  const disableTransformations = width > MIN_WIDTH_FOR_DISABLING_IMAGE_TRANSFORMATIONS

  const { backgroundImage, ...restStyles } = backgroundStyles

  // We want to keep the aspect ratio of the background image constant
  const imageStyles = { ...restStyles, aspectRatio: BACKGROUND_IMAGE_ASPECT_RATIO }

  const testId = parentTestId ? `${parentTestId}-background` : undefined

  return (
    <Box
      height="100%"
      width="100%"
      zIndex={-1}
      position="absolute"
      top="0px"
      left="0px"
      ref={containerRef}
    >
      <CloudinaryImage
        imgAlt="Scene Background"
        publicId={backgroundImage}
        testId={testId}
        imageStyles={imageStyles}
        disableTransformations={disableTransformations}
      />
    </Box>
  )
}
