import { type CSSProperties } from 'react'

import TurnedInIcon from '@mui/icons-material/TurnedIn'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'

import COLORS from '@top/shared/src/style/colors'
import Pin from '@top/ui/src/Icons/Pin'

type Props = {
  isPinned: boolean
  isExclusive: boolean
  label: string
}

const containerStyle = {
  position: 'absolute',
  left: '-11px',
  top: 'calc(50% - 0.5rem)',
  display: 'inline-block',
  color: 'white',
  textAlign: 'center',
  zIndex: 20,
  lineHeight: 'normal',
  backgroundColor: COLORS.BLUE,
  fontSize: '0.8rem',
  fontWeight: 500,
  padding: '2px 5px',
} satisfies CSSProperties

const iconStyle = {
  fontSize: '0.75rem',
  marginRight: '5px',
} satisfies CSSProperties

export function PinnedOrExclusiveSelectionLabel(props: Props) {
  const { isPinned, isExclusive, label } = props

  return (
    <Box sx={containerStyle}>
      <Grid container justifyContent="center" alignItems="center">
        {isExclusive && <TurnedInIcon sx={iconStyle} />}
        {isPinned && <Pin sx={iconStyle} />}
        <Typography sx={{ lineHeight: '12px', fontSize: '12px', color: COLORS.WHITE }}>
          {label}
        </Typography>
      </Grid>
    </Box>
  )
}
