import { useRef } from 'react'
import ContentEditable, { type ContentEditableEvent } from 'react-contenteditable'

import { Button } from '@mui/material'

import { selectElementTextContent } from '@top/shared/src/helpers/selectElementTextContent'
import COLORS from '@top/shared/src/style/colors'
import { convertFontSizeToNewFormat, convertFormatsToCSS } from '@top/shared/src/style/helpers'
import { type SkipButtonStyle, type TextAlign } from '@top/shared/src/style/types'
import { FocusedBorder } from '@top/ui/src/SceneModules/components/FocusedBorder'

import DOMPurify from 'dompurify'

export const defaultSkipButtonStyle = {
  fontSize: 12,
  fontFamily: "'SolaimanLipi Normal', 'Roboto'",
  color: COLORS.TEXT_LIGHT,
  italic: false,
  textAlign: 'center',
  underline: false,
  bold: true,
} as const satisfies SkipButtonStyle

export type SkipButtonProps = {
  style: SkipButtonStyle
  text: string
  isBuilder?: boolean
  widgetWidth?: number
  disabled?: boolean
  parentTestId?: string
  onClick?: () => void
  onChange?: (text: string) => void
  onFocus?: () => void
  onBlur?: () => void
  focused?: boolean
  showError?: boolean
}

const SkipButton = (props: SkipButtonProps) => {
  const {
    text,
    onClick,
    style,
    onChange,
    widgetWidth,
    isBuilder = false,
    parentTestId,
    onFocus,
    onBlur,
    disabled = false,
    focused = false,
    showError = false,
  } = props

  const contentEditableRef = useRef<HTMLElement>(null)

  const { maxHeight, fontFamily, color, textAlign, italic, bold, underline } = style

  const testId = parentTestId ? `${parentTestId}-skip-button` : undefined

  const { textDecoration, fontStyle, fontWeight } = convertFormatsToCSS({
    italic,
    bold,
    underline,
  })

  const handleChange = (event: ContentEditableEvent) => {
    const sanitized = DOMPurify.sanitize(event.target.value)
    if (onChange) {
      onChange(sanitized)
    }
  }

  const fontSize = convertFontSizeToNewFormat(style.fontSize, widgetWidth)

  /**
   * The text align property is not working for the ContentEditable component, so we need to use the
   * justify-content property to align the text if in builder mode.
   */
  const getJustifyContentRule = (textAlign: TextAlign) => {
    switch (textAlign) {
      case 'left':
        return 'flex-start'
      case 'right':
        return 'flex-end'
      case 'center':
        return 'center'
    }
  }

  const handleKeyDown = (e: React.KeyboardEvent<HTMLElement>) => {
    if (e.key === 'Escape') {
      if (contentEditableRef.current) {
        contentEditableRef.current.blur()
      }

      if (onBlur) {
        onBlur()
      }
    }
  }

  const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation()

    if (onClick) {
      onClick()
    }
  }

  const handleFocus = (e: React.FocusEvent<HTMLDivElement, Element>) => {
    selectElementTextContent(e.target)

    if (onFocus) {
      onFocus()
    }
  }

  return (
    <FocusedBorder
      isFocused={isBuilder && focused}
      hover={isBuilder && !focused}
      hasError={showError}
      fullWidth
    >
      <Button
        data-testid={testId}
        sx={{
          fontSize,
          fontFamily,
          color,
          textAlign,
          textDecoration,
          fontStyle,
          fontWeight,
          maxHeight,
          width: '100%',
          wordWrap: 'break-word',
          '&:hover': {
            backgroundColor: 'transparent',
          },
          ...(isBuilder && {
            display: 'flex',
            justifyContent: getJustifyContentRule(textAlign),
          }),
        }}
        disableRipple={isBuilder}
        disabled={disabled}
        onClick={handleClick}
      >
        {isBuilder ? (
          <ContentEditable
            html={text}
            tagName="span"
            onChange={handleChange}
            onFocus={handleFocus}
            disabled={disabled}
            style={{
              whiteSpace: 'pre-wrap',
              outline: 'none',
              minHeight: '100%',
              minWidth: '5px',
              cursor: focused ? 'text' : 'pointer',
            }}
            innerRef={contentEditableRef}
            onKeyDown={handleKeyDown}
          />
        ) : (
          <span style={{ outline: 'none', whiteSpace: 'pre-wrap', width: '100%' }}>{text}</span>
        )}
      </Button>
    </FocusedBorder>
  )
}

export default SkipButton
