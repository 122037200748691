import React from 'react'
import { Route, RouteProps } from 'react-router-dom'

import { isDevEnvironment } from 'components/helpers'

const OnlyDevRoute: React.FC<React.PropsWithChildren<Props & RouteProps>> = (props) => {
  const { children, ...rest } = props
  if (isDevEnvironment()) {
    return <Route {...rest}>{children}</Route>
  } else {
    return <p>Not available in prod</p>
  }
}

interface Props {
  children: React.ReactElement
}
export default OnlyDevRoute
