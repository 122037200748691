import React, { createRef } from 'react'
import ContentEditable, { type ContentEditableEvent } from 'react-contenteditable'

import { Box, Checkbox, Typography } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'

import CheckboxIcon from '@top/shared/src/components/Icons/Checkbox'
import COLORS from '@top/shared/src/style/colors'
import { getTextStyle } from '@top/shared/src/style/helpers'
import { Fonts, type TextStyle } from '@top/shared/src/style/types'
import { markdownToHtmlURL } from '@top/ui/src/SceneModules/EmailEntry/helpers/markdownToHtmlURL'
import { type CommonSceneModuleProps } from '@top/ui/src/SceneModules/types'

import DOMPurify from 'dompurify'

export const defaultTermsStyle = {
  color: COLORS.BLACK,
  fontFamily: Fonts.Roboto,
  fontSize: 8,
  bold: false,
  italic: false,
  underline: false,
  backgroundColor: 'transparent',
  textAlign: 'left',
} as const satisfies Readonly<TextStyle>

const useStyles = makeStyles({
  labelText: {
    marginLeft: '0.5rem',
    width: '100%',
    maxWidth: '83%',
    wordBreak: 'break-word',
    cursor: 'inherit',
    outline: 0,
    '&>a': {
      textDecoration: 'underline',
      color: COLORS.LINK,
    },
  },
  checkbox: {
    '& .MuiSvgIcon-root': {
      fontSize: '20px',
      padding: 0,
    },
  },
})

const getFillColor = (showError: boolean, disabled: boolean) => {
  if (showError) {
    return COLORS.ERROR_BACKGROUND
  }
  if (disabled) {
    return COLORS.DISABLED_BACKGROUND
  }
  return COLORS.WHITE
}

const getCursorType = (isBuilder: boolean, isFocused: boolean, disabled: boolean) => {
  if (isBuilder) {
    if (disabled) {
      return 'default'
    }

    return isFocused ? 'text' : 'pointer'
  }

  if (!disabled) {
    return 'pointer'
  }

  return 'default'
}

export const termsRef = createRef<HTMLElement>()

export type TermsProps = {
  /** Function triggered when admin edits the terms text, only called when isBuilder is true. */
  onTermsTextChange?: (text: string) => void
  /** @default false */
  isChecked?: boolean
  /**
   * The text to be displayed in the terms input label. This text will be parsed as html.
   *
   * @default ''
   */
  termsText?: string
  showTermsError?: boolean
  termsStyle: TextStyle
}

type Props = TermsProps &
  Omit<CommonSceneModuleProps<string>, 'testId' | 'showError'> & {
    onChange?: (value: boolean) => void
    /** Function triggered when the terms text is focused, only called when isBuilder is true. */
    onFocus?: () => void
    /** Function triggered when the terms text is blurred, only called when isBuilder is true. */
    onBlur?: (
      value: string,
      options?: {
        clearSelectedElement: boolean
      }
    ) => void
    parentTestId?: string
    widgetWidth?: number
    isParentFocused?: boolean
    isFocused?: boolean
  }

const Terms = (props: Props) => {
  const {
    termsStyle,
    termsText = '',
    isChecked = false,
    onChange,
    showTermsError: showError = false,
    disabled = false,
    isBuilder = false,
    onTermsTextChange,
    error = '',
    onBlur,
    onFocus,
    parentTestId,
    widgetWidth,
    isParentFocused = false,
    isFocused = false,
  } = props

  const classes = useStyles()

  const testId = parentTestId ? `${parentTestId}-terms` : undefined

  const HTMLWithLinks = DOMPurify.sanitize(markdownToHtmlURL(termsText, isBuilder), {
    ADD_ATTR: ['target'],
  })

  const html = isFocused ? termsText : HTMLWithLinks

  const handleLabelTextChange = (event: ContentEditableEvent) => {
    if (isBuilder) {
      const sanitizedText = DOMPurify.sanitize(event.target.value)

      if (onTermsTextChange) {
        onTermsTextChange(sanitizedText)
      }
    }
  }

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (onChange) {
      onChange(event.target.checked)
    }
  }

  /**
   * Since ContentEditable does not have an htmlFor prop, we need to programmatically trigger the
   * checkbox when clicking on the label.
   */
  const handleContentEditableClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    if (disabled) return

    if (isBuilder) {
      e.stopPropagation()
      if (onFocus) {
        onFocus()
      }
    } else {
      const checkbox = document.getElementById('email-entry-terms')
      if (checkbox) {
        checkbox.click()
      }
    }
  }

  const handleKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.key === 'Escape') {
      if (termsRef) {
        termsRef.current?.blur()
      }

      if (onBlur && isFocused) {
        const sanitized = DOMPurify.sanitize(termsRef.current?.textContent ?? '')

        onBlur(sanitized, {
          clearSelectedElement: true,
        })
      }
    }
  }

  // If the parent is not focused, it should not be possible to focus child.
  const handleOnFocus = () => {
    if (!isParentFocused && termsRef.current) {
      termsRef.current.blur()
    }
  }

  const checkboxColor = showError && !isBuilder ? COLORS.ERROR : COLORS.DISABLED_DARK

  const fillColor = getFillColor(showError, disabled)

  const cursor = getCursorType(isBuilder, isFocused, disabled)

  return (
    <Box width="100%">
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        sx={getTextStyle(termsStyle, widgetWidth)}
        width="100%"
        boxSizing="border-box"
      >
        <Checkbox
          id="email-entry-terms"
          data-testid={testId}
          checked={isChecked}
          onChange={handleCheckboxChange}
          disabled={disabled || isBuilder}
          className={classes.checkbox}
          disableRipple
          icon={
            !isChecked ? (
              <CheckboxIcon fillColor={fillColor} borderColor={checkboxColor} />
            ) : undefined
          }
          sx={{
            padding: isChecked ? '2px' : '2px 4px 2px',
            '&.Mui-checked': {
              color: COLORS.LINK,
            },
            '&:hover': {
              backgroundColor: 'transparent',
            },
          }}
        />
        <ContentEditable
          id="TermsTextLabel"
          tagName="label"
          html={html}
          onKeyDown={handleKeyDown}
          onChange={handleLabelTextChange}
          onFocus={handleOnFocus}
          className={classes.labelText}
          disabled={disabled || !isBuilder}
          style={{
            width: '100%',
            maxWidth: '83%',
            margin: 0,
            paddingLeft: '8px',
            cursor,
          }}
          onClick={handleContentEditableClick}
          innerRef={termsRef}
        />
      </Box>
      {!isBuilder && showError && error && (
        <Box minHeight="0.75rem" display="block" paddingTop="8px" textAlign="center">
          <Typography
            variant="h3"
            color={COLORS.ERROR}
            fontWeight="normal"
            margin={0}
            fontSize="0.875rem"
            fontFamily={Fonts.Roboto}
            textAlign="center"
          >
            {error}
          </Typography>
        </Box>
      )}
    </Box>
  )
}

export default Terms
