import { IScene, SceneType } from '@top/shared_deprecated/src/types/Scene'

import { v4 as uuid } from 'uuid'

export const shouldDisableScroll: (query: string | undefined) => boolean = (query) => {
  return query === 'off'
}

type PreviewArgs = {
  uid?: string | undefined
  isPreview?: boolean
}
type NonePreviewArgs = {
  hash?: string | undefined
  external_id?: string
  collection_id?: string
  source?: string
  isPreview?: boolean
  pid?: string
  preview?: string
  local_storage_id?: any
  app_id?: string
}
type PreviewReturn = {
  uid: string | undefined
  isPreview?: boolean
}

type NonePreviewReturn = {
  hash: string | undefined
  source?: string
  collection_id?: string
  external_id?: string
  app_id?: string
}

export const isBlankFlexScene = (scene: IScene | undefined): boolean => {
  if (scene && scene.type === SceneType.SceneBuilder) {
    return !scene.elements.selectionBlock?.position
  }
  return false
}

export const provideServeReqBody: (
  args: PreviewArgs & NonePreviewArgs
) => PreviewReturn | NonePreviewReturn = (args) => {
  const { isPreview } = args
  if (isPreview) {
    const { uid } = args
    return { uid }
  } else {
    const { hash, source, collection_id, external_id, pid, preview, local_storage_id, app_id } =
      args
    return {
      hash,
      ...(source && { source }),
      ...(collection_id && { collection_id }),
      ...(external_id && { external_id }),
      ...(local_storage_id && { local_storage_id }),
      ...(pid && { pid }),
      ...(preview && { preview }),
      ...(app_id && { app_id }),
    }
  }
}

export const VCTraceID: {
  header: string
  sentryKey: string
} = {
  header: 'X-VCTraceId',
  sentryKey: 'vctraceid',
}

export const LOCAL_STORAGE_ID_KEY = '__MAG__LOCAL__STORAGE__ID__'

export function getUserIDFromLocalStorage(): string | undefined {
  try {
    const currentID = localStorage.getItem(LOCAL_STORAGE_ID_KEY)
    if (currentID && currentID.length > 0) {
      return currentID
    } else {
      const newID = uuid()
      localStorage.setItem(LOCAL_STORAGE_ID_KEY, newID)
      return newID
    }
  } catch (error) {
    return undefined
  }
}
