import { Grid } from '@mui/material'

import { type ImageOrientation } from '@top/shared/src/style/types'

type Props = {
  imageSelections: React.ReactNode[]
  imageOrientation: ImageOrientation
  aspectRatio: number
}

export const SelectionsLayoutManager = (props: Props) => {
  const { imageSelections, imageOrientation, aspectRatio } = props

  if (imageSelections.length === 0) throw new Error('No image selections provided')

  return (
    <Grid container spacing={2}>
      {imageSelections.map((selection, index) => {
        return (
          <Grid
            item
            key={index}
            flexGrow={1}
            sx={{ aspectRatio }}
            xs={imageOrientation === 'IMAGE_LANDSCAPE' ? 12 : 6}
          >
            {selection}
          </Grid>
        )
      })}
    </Grid>
  )
}
