import { useRef } from 'react'
import ContentEditable, { type ContentEditableEvent } from 'react-contenteditable'

import Typography from '@mui/material/Typography'

import { selectElementTextContent } from '@top/shared/src/helpers/selectElementTextContent'
import { getTextStyle } from '@top/shared/src/style/helpers'
import { type TextStyle } from '@top/shared/src/style/types'
import { FocusedBorder } from '@top/ui/src/SceneModules/components/FocusedBorder'

import DOMPurify from 'dompurify'

type PromptTextProps = {
  text: string
  styles: TextStyle
  /** If provided, will be `${parentTestId}-prompt-text`. */
  parentTestId?: string
  isBuilder: boolean
  disabled: boolean
  onChange: (newPromptText: string) => void
  onBlur?: (newPromptText: string, options?: { clearSelectedElement: boolean }) => void
  widgetWidth?: number
  onFocus?: () => void
  /** @default false */
  isFocused?: boolean
  /** @default false */
  showHover?: boolean
  fullWidth?: boolean
}

export function PromptText(props: PromptTextProps) {
  const {
    text,
    styles,
    parentTestId,
    isBuilder,
    disabled,
    onChange,
    widgetWidth,
    onFocus,
    onBlur,
    isFocused = false,
    showHover = false,
    fullWidth = false,
  } = props

  const inputRef = useRef<HTMLDivElement>(null)

  const promptTextStyles = getTextStyle(styles, widgetWidth)
  const {
    fontSize,
    color,
    fontWeight,
    fontStyle,
    fontFamily,
    textDecoration,
    textAlign,
    backgroundColor,
  } = promptTextStyles

  const testId = parentTestId ? `${parentTestId}-prompt-text` : null

  const handleOnChange = (event: ContentEditableEvent) => {
    const value = event.target.value
    const sanitized = DOMPurify.sanitize(value)

    onChange(sanitized)
  }

  /** The escape key will blur the input. This is not the default behavior for ContentEditable. */
  const handleKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.key === 'Escape') {
      if (inputRef.current) {
        inputRef.current.blur()
      }

      const sanitized = DOMPurify.sanitize(inputRef.current?.textContent ?? '')

      // If the text has changed, call the onBlur event.
      if (onBlur) {
        onBlur(sanitized, {
          clearSelectedElement: true,
        })
      }
    }
  }

  const handleOnFocus = (e: React.FocusEvent<HTMLDivElement, Element>) => {
    selectElementTextContent(e.target)
    if (onFocus) {
      onFocus()
    }
  }

  const handleOnClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.stopPropagation()
    if (onFocus) {
      onFocus()
    }
  }

  if (isBuilder) {
    return (
      <FocusedBorder isFocused={isFocused} hover={showHover} fullWidth={fullWidth}>
        <ContentEditable
          data-testid={testId}
          innerRef={inputRef}
          html={text}
          onClick={handleOnClick}
          onFocus={handleOnFocus}
          tagName="h3"
          onChange={handleOnChange}
          onKeyDown={handleKeyDown}
          style={{
            minWidth: '80%',
            overflowX: 'hidden',
            transition: 'all 0.2s ease',
            backgroundColor,
            textAlign,
            fontSize,
            color,
            fontWeight,
            fontStyle,
            fontFamily,
            textDecoration,
            margin: 0,
            padding: 0,
          }}
          disabled={disabled}
        />
      </FocusedBorder>
    )
  }

  return (
    <FocusedBorder isFocused={isFocused} hover={showHover}>
      <Typography
        sx={{ ...promptTextStyles, padding: '0px' }}
        data-testid={testId}
        contentEditable={isBuilder && !disabled}
        onChange={handleOnChange}
        spellCheck="false"
      >
        {text}
      </Typography>
    </FocusedBorder>
  )
}
