type Props = {
  diameter: number
  isSelected: boolean
  value: number
  secondaryColor: string
  fill: string
  transition?: string
}

const Circle = (props: Props) => {
  const { diameter, isSelected, value, secondaryColor, transition, fill } = props

  const radius = diameter / 2

  return (
    <svg
      width={`${diameter}`}
      height={`${diameter}`}
      viewBox={`0 0 ${diameter} ${diameter}`}
      fill="none"
    >
      {isSelected && (
        <circle
          r={`${radius - 0.5}`}
          transform={`matrix(1 0 0 -1 ${radius} ${radius})`}
          stroke={fill}
          strokeDasharray="6 6"
        />
      )}
      <circle
        r={`${radius * 0.88}`}
        transform={`matrix(1 0 0 -1 ${radius} ${radius})`}
        fill={secondaryColor}
      />
      <circle
        cx="50%"
        cy="50%"
        r={`${radius * 0.78}`}
        style={{
          fill,
          transition,
        }}
      />
      <text
        x="50%"
        y="50%"
        dominantBaseline="central"
        textAnchor="middle"
        fill={secondaryColor}
        style={{ fontWeight: 500 }}
      >
        {value}
      </text>
    </svg>
  )
}

export default Circle
