import ReactDOM from 'react-dom'

import 'whatwg-fetch'

import Router from './router'
import * as serviceWorker from './serviceWorker'

import './index.css'

import { DevSettingsProvider } from 'contexts/DevSettings'

ReactDOM.render(
  <DevSettingsProvider>
    <Router />
  </DevSettingsProvider>,
  document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
