import { createContext, ReactNode, useContext, useState } from 'react'

import { Api } from '@top/shared/src/api/client'

import { DistributorApiClient, distributorApiConfig } from './schema'

import { QueryClientProvider } from '@tanstack/react-query'
import useFetch, { CustomFetch } from 'hooks/useFetch'

type Props = { apiClient: DistributorApiClient }
const ApiContext = createContext<Props | undefined>(undefined)

export const useApi = () => {
  const context = useContext(ApiContext)
  if (context === undefined) {
    throw new Error('useApi must be used within ApiProvider')
  }
  return context
}

let clientSingleton: DistributorApiClient

const getClientInstance = (
  customFetch: CustomFetch,
  createNewClient: boolean,
  setCreateNewClient: (value: boolean) => void
) => {
  const contract = distributorApiConfig(customFetch)

  if (clientSingleton && !createNewClient) {
    return clientSingleton
  }
  clientSingleton = new Api(contract)
  setCreateNewClient(false)

  return clientSingleton
}

export const ApiProvider = (props: { children: ReactNode }) => {
  const customFetch = useFetch()
  const [createNewClient, setCreateNewClient] = useState(false)

  const client = getClientInstance(customFetch, createNewClient, setCreateNewClient)

  return (
    <QueryClientProvider client={client.queryClient}>
      <ApiContext.Provider value={{ apiClient: client }}>{props.children}</ApiContext.Provider>
    </QueryClientProvider>
  )
}
