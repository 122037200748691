import AssistantPrompt from '@top/shared/src/components/AssistantPrompt'
import { Locale } from '@top/shared/src/Languages/types'
import { parseSceneModule, type SceneModule } from '@top/shared/src/SceneModules/types'
import { Choice } from '@top/ui/src/SceneModules/Choice'
import { DateEntry } from '@top/ui/src/SceneModules/DateEntry'
import { EmailEntry } from '@top/ui/src/SceneModules/EmailEntry'
import { ImagePreset } from '@top/ui/src/SceneModules/ImagePreset'
import { Logo } from '@top/ui/src/SceneModules/Logo'
import { NumericScale } from '@top/ui/src/SceneModules/NumericScale'
import { Textbox } from '@top/ui/src/SceneModules/Textbox'
import { TextEntry } from '@top/ui/src/SceneModules/TextEntry'

export type SelectedModuleChild =
  | {
      type: 'selection'
      uuid: string
    }
  | {
      type: 'promptText' | 'helperText' | 'toc' | 'dateDisplay' | 'dateInput' | 'resultText'
    }

/** @description Object outlining the currently selected scene module or a scene module's child. */
export type SelectedModuleOrChild = {
  parentUuid: string
  child?: SelectedModuleChild
}

type Props = {
  sceneModule: SceneModule
  /** @default false */
  isDisabled?: boolean
  onClick?: (selectedModule: SelectedModuleOrChild) => void
  onBlur?: (value: string, selectedModule: SelectedModuleOrChild) => void
  onChange?: (value: string | string[], selectedModule: SelectedModuleOrChild) => void
  /** @description Whether the parent scene has branches. */
  hasBranches?: boolean
  isAiAssistantEnabled?: boolean
  containerWidth: number
  responseLocale: Locale
}

export function SceneModule(props: Props) {
  const {
    isDisabled,
    onClick,
    onBlur,
    onChange,
    hasBranches,
    isAiAssistantEnabled = false,
    sceneModule,
    containerWidth,
    responseLocale,
  } = props

  const parsedSceneModule = parseSceneModule(sceneModule)

  const testId = `scene-module-${sceneModule.uuid}`
  switch (parsedSceneModule.type) {
    case 'MODULE_TEXTBOX': {
      const handleOnChange = (newValue: string) => {
        if (onChange) {
          onChange(newValue, { parentUuid: parsedSceneModule.uuid })
        }
      }
      const handleOnBlur = (newValue: string) => {
        if (onBlur) {
          onBlur(newValue, { parentUuid: parsedSceneModule.uuid })
        }
      }
      const handleOnClick = () => {
        if (onClick) {
          onClick({ parentUuid: parsedSceneModule.uuid })
        }
      }
      return (
        <Textbox
          testId={testId}
          text={parsedSceneModule.etc.translations[responseLocale] ?? parsedSceneModule.etc.text}
          style={parsedSceneModule.style}
          widgetWidth={containerWidth}
          disabled={isDisabled}
          onFocus={handleOnClick}
          onBlur={handleOnBlur}
          onChange={handleOnChange}
        />
      )
    }
    case 'MODULE_TEXT_ENTRY_QUESTION': {
      const handleOnFocus = () => {
        if (onClick) {
          onClick({ parentUuid: parsedSceneModule.uuid, child: { type: 'helperText' } })
        }
      }
      const handleOnBlur = (newHelperText: string) => {
        if (onBlur) {
          onBlur(newHelperText, { parentUuid: parsedSceneModule.uuid })
        }
      }
      return (
        <TextEntry
          value={parsedSceneModule.etc.translations[responseLocale] ?? parsedSceneModule.etc.text}
          placeholder={
            parsedSceneModule.etc.translations[responseLocale] ?? parsedSceneModule.etc.text
          }
          testId={testId}
          disabled={isDisabled}
          onFocus={handleOnFocus}
          onChange={handleOnBlur}
          onBlur={handleOnBlur}
          promptContent={isAiAssistantEnabled ? <AssistantPrompt /> : undefined}
        />
      )
    }
    case 'MODULE_LOGO': {
      const handleOnFocus = () => {
        if (onClick) {
          onClick({ parentUuid: parsedSceneModule.uuid })
        }
      }

      return (
        <Logo
          testId={`logo-${parsedSceneModule.uuid}`}
          publicId={parsedSceneModule.style.backgroundImage}
          onFocus={handleOnFocus}
          disabled={isDisabled}
        />
      )
    }
    case 'MODULE_DATE_QUESTION': {
      const handleOnFocusDateDisplay = () => {
        if (onClick) {
          onClick({ parentUuid: parsedSceneModule.uuid, child: { type: 'dateDisplay' } })
        }
      }
      const handleOnFocusDateInput = () => {
        if (onClick) {
          onClick({ parentUuid: parsedSceneModule.uuid, child: { type: 'dateInput' } })
        }
      }

      return (
        <DateEntry
          dateInputFormat={parsedSceneModule.etc.date_format}
          dateInputStyle={parsedSceneModule.style.dateInputStyle}
          dateDisplayStyle={parsedSceneModule.style.dateDisplayStyle}
          isDateDisplayShown
          widgetWidth={containerWidth}
          testId={testId}
          disabled={isDisabled}
          onClickDateDisplay={handleOnFocusDateDisplay}
          onClickDateInput={handleOnFocusDateInput}
        />
      )
    }
    case 'MODULE_CHOICE_QUESTION': {
      /**
       * We will always show the selection label if the scene module has branches, or if the scene
       * module has exclusive or pinned selections.
       */
      const isSelectionLabelAlwaysShown =
        hasBranches ||
        !!parsedSceneModule.etc.pinned_selections ||
        !!parsedSceneModule.etc.exclusive_selections

      const handleOnSelectionClick = (value: string[], selectionUuid: string) => {
        if (onChange && !isDisabled) {
          console.log('handleOnSelectionClick', { value, selectionUuid })
          onChange(value, {
            parentUuid: parsedSceneModule.uuid,
            child: { type: 'selection', uuid: selectionUuid },
          })
        }
      }

      const handleOnPromptTextChange = (newPromptText: string) => {
        if (onBlur) {
          onBlur(newPromptText, {
            parentUuid: parsedSceneModule.uuid,
            child: { type: 'promptText' },
          })
        }
      }

      const handleOnFocusPromptText = () => {
        if (onClick) {
          onClick({
            parentUuid: parsedSceneModule.uuid,
            child: { type: 'promptText' },
          })
        }
      }

      const handleOnSelectionTextChange = (selectionUuid: string, newText: string) => {
        if (onBlur) {
          onBlur(newText, {
            parentUuid: parsedSceneModule.uuid,
            child: { type: 'selection', uuid: selectionUuid },
          })
        }
      }

      return (
        <Choice
          widgetWidth={containerWidth}
          value={[]}
          selections={parsedSceneModule.selections}
          disabled={isDisabled}
          testId={testId}
          promptText={
            parsedSceneModule.etc.translations
              ? parsedSceneModule.etc.translations[responseLocale] ?? parsedSceneModule.etc.text
              : parsedSceneModule.etc.text
          }
          showPromptText={parsedSceneModule.etc.show_prompt_text}
          display_options={parsedSceneModule.style.display_options}
          selection_style={parsedSceneModule.style.selection_style}
          prompt_text_style={parsedSceneModule.style.prompt_text_style}
          onPromptTextChange={handleOnPromptTextChange}
          onFocusPromptText={handleOnFocusPromptText}
          onSelectionTextChange={handleOnSelectionTextChange}
          isMultiChoice={parsedSceneModule.etc.show_multi_choice}
          pinnedSelections={parsedSceneModule.etc.pinned_selections}
          exclusiveSelections={parsedSceneModule.etc.exclusive_selections}
          isSelectionLabelAlwaysShown={isSelectionLabelAlwaysShown}
          onChange={handleOnSelectionClick}
          locale={responseLocale}
        />
      )
    }
    case 'MODULE_NUMERIC_NPS_QUESTION':
    case 'MODULE_NUMERIC_SCALE_QUESTION': {
      const handleOnFocusPromptText = () => {
        if (onClick) {
          onClick({
            parentUuid: parsedSceneModule.uuid,
            child: { type: 'promptText' },
          })
        }
      }
      const handleOnFocusResultText = () => {
        if (onClick) {
          onClick({
            parentUuid: parsedSceneModule.uuid,
            child: { type: 'resultText' },
          })
        }
      }
      const handleOnPromptTextBlur = (newPromptText: string) => {
        if (onBlur) {
          onBlur(newPromptText, {
            parentUuid: parsedSceneModule.uuid,
            child: { type: 'promptText' },
          })
        }
      }
      const handleFocus = () => {
        if (onClick) {
          onClick({
            parentUuid: parsedSceneModule.uuid,
          })
        }
      }

      return (
        <NumericScale
          widgetWidth={containerWidth}
          scaleRange={parsedSceneModule.etc.scale_range}
          value=""
          selections={parsedSceneModule.selections}
          resultTextStyle={parsedSceneModule.style.result_text_style}
          promptTextStyle={parsedSceneModule.style.text_style}
          selectionStyles={parsedSceneModule.style.selection_style}
          text={parsedSceneModule.etc.translations[responseLocale] ?? parsedSceneModule.etc.text}
          onFocusResultText={handleOnFocusResultText}
          onFocusPromptText={handleOnFocusPromptText}
          onPromptTextBlur={handleOnPromptTextBlur}
          onPromptTextChange={handleOnPromptTextBlur}
          onFocus={handleFocus}
          disabled={isDisabled}
        />
      )
    }
    case 'MODULE_EMAIL_CTA_QUESTION': {
      const handleOnFocusHelperText = () => {
        if (onClick) {
          onClick({
            parentUuid: parsedSceneModule.uuid,
            child: { type: 'helperText' },
          })
        }
      }
      const handleOnFocusTerms = () => {
        if (onClick) {
          onClick({
            parentUuid: parsedSceneModule.uuid,
            child: { type: 'toc' },
          })
        }
      }

      const handleOnBlurTerms = (newValue: string) => {
        if (onBlur) {
          onBlur(newValue, { parentUuid: parsedSceneModule.uuid, child: { type: 'toc' } })
        }
      }

      const handleBlur = (newValue: string) => {
        if (onBlur) {
          onBlur(newValue, { parentUuid: parsedSceneModule.uuid, child: { type: 'helperText' } })
        }
      }

      return (
        <EmailEntry
          value={parsedSceneModule.etc.text}
          termsStyle={parsedSceneModule.style.toc_style}
          placeholder={
            parsedSceneModule.etc.translations[responseLocale] ?? parsedSceneModule.etc.text
          }
          disabled={isDisabled}
          widgetWidth={containerWidth}
          termsText={
            parsedSceneModule.etc.toc.translations[responseLocale] ?? parsedSceneModule.etc.toc.text
          }
          onFocus={handleOnFocusHelperText}
          onBlur={handleBlur}
          onFocusTerms={handleOnFocusTerms}
          onBlurTerms={handleOnBlurTerms}
          onEmailInputChange={handleBlur}
          onTermsTextChange={handleOnBlurTerms}
        />
      )
    }
    case 'MODULE_PRESET_IMAGE_QUESTION': {
      /**
       * We will always show the selection label if the scene module has branches, or if the scene
       * module has exclusive or pinned selections.
       */
      const isSelectionLabelAlwaysShown =
        hasBranches ||
        !!parsedSceneModule.etc.pinned_selections ||
        !!parsedSceneModule.etc.exclusive_selections

      const handleFocus = () => {
        if (onClick) {
          onClick({
            parentUuid: parsedSceneModule.uuid,
          })
        }
      }

      const handleOnFocusPromptText = () => {
        if (onClick) {
          onClick({
            parentUuid: parsedSceneModule.uuid,
            child: { type: 'promptText' },
          })
        }
      }
      const handleOnFocusSelection = (selectionUuid: string) => {
        if (onClick) {
          onClick({
            parentUuid: parsedSceneModule.uuid,
            child: { type: 'selection', uuid: selectionUuid },
          })
        }
      }

      return (
        <ImagePreset
          value={[]}
          selections={parsedSceneModule.selections}
          imageOrientation={parsedSceneModule.etc.image_orientation}
          widgetWidth={containerWidth}
          promptText={
            parsedSceneModule.etc.translations
              ? parsedSceneModule.etc.translations[responseLocale] ?? parsedSceneModule.etc.text
              : parsedSceneModule.etc.text
          }
          showPromptText={parsedSceneModule.etc.show_prompt_text}
          promptTextStyle={parsedSceneModule.style.prompt_text_style}
          onFocusPromptText={handleOnFocusPromptText}
          pinnedSelections={parsedSceneModule.etc.pinned_selections}
          exclusiveSelections={parsedSceneModule.etc.exclusive_selections}
          isSelectionLabelAlwaysShown={isSelectionLabelAlwaysShown}
          onFocusSelection={handleOnFocusSelection}
          onFocus={handleFocus}
        />
      )
    }

    // These are all deprecated scene modules!
    case 'XXX_MODULE_CTA_QUESTION': {
      throw new Error(`${parsedSceneModule.type} is deprecated!`)
    }

    // Note that we don't need a `default` in this switch since we are enforcing exhaustive switch cases
  }
}
