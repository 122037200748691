/**
 * @description Converts an index (starting at 0) to a string of letters. This uses a conversion
 *              from base 10 to base 26.
 * @example 0 -> 'A', 3 -> 'D', 26 -> 'AA', 100 -> 'CW', 701 -> 'ZZ', 702 -> 'AAA'
 */
export function convertIndexToLetterString(number: number) {
  const isNonNegativeInteger = Number.isInteger(number) && number >= 0

  if (!isNonNegativeInteger) {
    throw new Error('Input must be a non-negative integer.')
  }

  // Add 1 to the number to make it 1-indexed.
  let adjustedNumber = number + 1

  let result = ''
  while (adjustedNumber > 0) {
    const remainder = (adjustedNumber - 1) % 26
    result = String.fromCharCode(remainder + 'A'.charCodeAt(0)) + result
    adjustedNumber = Math.floor((adjustedNumber - 1) / 26)
  }

  return result
}
