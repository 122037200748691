import { useLocation, useParams } from 'react-router-dom'

import queryString from 'query-string'
import { getUserIdFromLocalStorage } from 'utils/getUserIdFromLocalStorage'

type DistSource = 'top_sdk' | 'public' | 'mobile_sdk'

export const useServeParams = () => {
  const location = useLocation()
  const { search } = location

  const { hash } = useParams<{
    hash: string
  }>()

  const {
    source = 'public',
    external_id,
    collection_id,
    sdk_local_storage_id,
    preview,
    app_id,
  }: {
    source?: DistSource
    external_id?: string
    collection_id?: string
    sdk_local_storage_id?: string
    pid?: string
    preview?: string
    app_id?: string
  } = queryString.parse(search)

  const local_storage_id = sdk_local_storage_id || getUserIdFromLocalStorage()

  return {
    hash,
    source,
    external_id,
    collection_id,
    local_storage_id,
    preview,
    app_id,
  }
}
