import { useLayoutEffect } from 'react'

import { getFontUrl } from '@top/shared/src/configs'
import {
  selfHostedDefaultFonts,
  type SelfHostedDefaultFontValue,
} from '@top/shared/src/Fonts/types'

import { preloadFonts } from '../helpers/preloadFonts'

export function useSelfHostedFonts() {
  useLayoutEffect(() => {
    const fontUrl = getFontUrl()

    function loadFont(font: SelfHostedDefaultFontValue) {
      const fontCss = `${font}.css`
      const fontHref = new URL(fontCss, fontUrl).href
      const link = document.createElement('link')

      link.href = fontHref
      link.rel = 'stylesheet'

      document.head.appendChild(link)
    }

    void preloadFonts(Object.keys(selfHostedDefaultFonts))

    Object.values(selfHostedDefaultFonts).forEach(loadFont)
  }, [])
}
