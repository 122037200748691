import { z } from 'zod'

/**
 * @description A helper to create a union of literal values (number or string). The reason this
 *              exists is because `zod.enum` doesn't support numeric values out of the box.
 */
export function unionOfLiterals<T extends string | number>(constants: readonly T[]) {
  /**
   * We have to resort to this type casting because `.map` obfuscates the number of items in an
   * array while `zod.union` expects at least 2 items (`[ z.ZodLiteral<T>, z.ZodLiteral<T>,
   * ...z.ZodLiteral<T>[] ]`).
   */
  const literals = constants.map((x) => z.literal(x)) as unknown as readonly [
    z.ZodLiteral<T>,
    z.ZodLiteral<T>,
    ...z.ZodLiteral<T>[]
  ]
  return z.union(literals)
}
