import React from 'react'

import { convertIndexToLetterString } from '@top/shared/src/helpers/convertIndexToLetterString'
import { type ImageStyle, type SelectionStyles } from '@top/shared/src/style/types'

import { PinnedOrExclusiveSelectionLabel } from '../../components/PinnedOrExclusiveSelectionLabel'

import { BlockSelection } from './SelectionDisplayTypes/BlockSelection'
import { CondensedSelection } from './SelectionDisplayTypes/CondensedSelection'
import { RadioSelection } from './SelectionDisplayTypes/RadioSelection'
import { SelectionContent } from './SelectionDisplayTypes/SelectionContent'

type TextSelectionProps = Omit<SelectionStyles, 'prompt_text_style'> & {
  /** If provided, will be `${parentTestId}-prompt-text`. */
  parentTestId?: string
  /** @default false */
  isBuilder?: boolean
  /** @default false */
  disabled?: boolean
  selected: boolean
  onSelect?: () => void
  isMultiChoice: boolean
  onTextChange: (
    newText: string,
    options?: {
      clearSelectedElement: boolean
    }
  ) => void
  widgetWidth?: number
  imageStyles?: ImageStyle
  text?: string
  image_alt_text?: string
  image_url?: string
  onFocus: () => void
  position: number
  isPinned: boolean
  isExclusive: boolean
  isSelectionLabelAlwaysShown: boolean
  onOpenModal: () => void
  onRemoveImage: () => void
  onOpenEditorModal: () => void
}

export function Selection(props: TextSelectionProps) {
  const {
    text,
    parentTestId,
    isBuilder = false,
    disabled = false,
    onSelect,
    selected,
    isMultiChoice,
    onTextChange,
    image_url,
    image_alt_text,
    widgetWidth,
    display_options,
    selection_style,
    imageStyles,
    onFocus,
    position,
    isPinned,
    isExclusive,
    isSelectionLabelAlwaysShown,
    onOpenModal,
    onRemoveImage,
    onOpenEditorModal,
  } = props

  const testId = parentTestId ? `${parentTestId}-text-selection-${text}` : undefined

  const isLabelShown = isBuilder && (isPinned || isExclusive || isSelectionLabelAlwaysShown)

  const handleSelect = () => {
    if (onSelect) {
      onSelect()
    }
  }

  const displayType = display_options?.choice_display_type
  const isSelected = selected && !isBuilder

  const selectionLabel = (
    <>
      {isLabelShown && (
        <PinnedOrExclusiveSelectionLabel
          label={convertIndexToLetterString(position)}
          isPinned={isPinned}
          isExclusive={isExclusive}
        />
      )}
      <SelectionContent
        text={text}
        style={selection_style}
        onChange={onTextChange}
        isBuilder={isBuilder}
        disabled={disabled}
        imageUrl={image_url}
        imageAlt={image_alt_text}
        widgetWidth={widgetWidth}
        imageStyles={imageStyles}
        onOpenModal={onOpenModal}
        onRemoveImage={onRemoveImage}
        onOpenEditorModal={onOpenEditorModal}
      />
    </>
  )

  // TODO: Should pass both selection_style and display_options down to the components (or at least parts of it)
  switch (displayType) {
    case 'block': {
      return (
        <BlockSelection
          isBuilder={isBuilder}
          choicePadding={display_options?.choice_padding ?? 1}
          backgroundColor={selection_style.backgroundColor}
          testId={testId}
          disabled={disabled}
          onClick={handleSelect}
          onFocus={onFocus}
          selected={isSelected}
        >
          {selectionLabel}
        </BlockSelection>
      )
    }

    case 'condensed': {
      return (
        <CondensedSelection
          disabled={disabled}
          isBuilder={isBuilder}
          onClick={handleSelect}
          choicePadding={display_options?.choice_padding ?? 1}
          selected={isSelected}
          textColor={selection_style.color}
          backgroundColor={selection_style.backgroundColor}
          testId={testId}
          onFocus={onFocus}
        >
          {selectionLabel}
        </CondensedSelection>
      )
    }

    case 'radio': {
      if (!display_options) {
        throw new Error('Missing display options')
      }
      if (!display_options.radio_button_shape) {
        throw new Error('Missing shape for radio selection')
      }

      return (
        <RadioSelection
          disabled={disabled}
          isBuilder={isBuilder}
          onClick={handleSelect}
          choicePadding={display_options.choice_padding}
          selected={isSelected}
          backgroundColor={selection_style.backgroundColor}
          testId={testId}
          shape={display_options.radio_button_shape}
          isMultiChoice={isMultiChoice}
          onFocus={onFocus}
        >
          {selectionLabel}
        </RadioSelection>
      )
    }

    default: {
      throw new Error(`Invalid choice display type "${displayType}"`)
    }
  }
}
