import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import makeStyles from '@mui/styles/makeStyles'

import MessageQuestion from '@top/shared/src/components/Icons/MessageQuestion'
import COLORS, { AI_ASSISTANT as AI_ASSISTANT_COLORS } from '@top/shared/src/style/colors'

import PromptLoader from './PromptLoader'

const DEFAULT_PROMPT = 'Additional prompts will appear here...'

const useStyles = makeStyles({
  container: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    backgroundColor: AI_ASSISTANT_COLORS.PROMPT_BACKGROUND,
    gap: '15px',
    padding: '8px 16px',
    color: COLORS.DISABLED_DARK,
    borderBottomLeftRadius: '4px',
    borderBottomRightRadius: '4px',
    border: `1px solid ${COLORS.BORDER_GRAY}`,
    borderTop: 'none',
    minHeight: '40px',
    cursor: 'default',
  },
  prompt: {
    fontSize: '14px',
    fontWeight: 400,
    color: COLORS.DISABLED_DARK,
    lineHeight: '1.2',
  },
})

type Props = {
  prompt?: string
  isLoading?: boolean
  hasError?: boolean
}

const AssistantPrompt = (props: Props) => {
  const { prompt = DEFAULT_PROMPT, isLoading = false, hasError = false } = props

  const classes = useStyles()

  return (
    <Box
      className={classes.container}
      sx={{
        ...(hasError && { borderColor: COLORS.ERROR, borderTop: 'none' }),
      }}
    >
      <Box display="flex" justifyContent="center" alignItems="center" minHeight="30px">
        <MessageQuestion />
      </Box>
      <Box display="flex" alignItems="center" minHeight="30px">
        {isLoading ? (
          <PromptLoader />
        ) : (
          <Typography className={classes.prompt}>{prompt}</Typography>
        )}
      </Box>
    </Box>
  )
}

export default AssistantPrompt
