import React from 'react'

import Chip from '@mui/material/Chip'

import { getOverlayColor } from '@top/shared/src/style/helpers'
import { type SpacingIncrement } from '@top/shared/src/style/types'

import Color from 'color'

type CondensedSelectionProps = {
  children: React.ReactNode
  choicePadding: SpacingIncrement
  backgroundColor: string
  textColor: string
  testId?: string
  disabled: boolean
  isBuilder: boolean
  onClick: () => void
  onFocus: () => void
  selected: boolean
}

export function CondensedSelection(props: CondensedSelectionProps) {
  const {
    children,
    disabled,
    isBuilder,
    onClick,
    choicePadding,
    backgroundColor,
    textColor,
    testId,
    selected,
    onFocus,
  } = props

  const padding = `calc(0.25rem * ${choicePadding} + 0.25rem) calc(1rem * ${choicePadding} + 0.25rem)`
  const borderRadius = `calc(1.5lh * max(${choicePadding}, 1))`
  const overlayColor = getOverlayColor(Color(textColor), Color(backgroundColor), 'condensed', false)
  const selectionBackground = selected ? overlayColor : backgroundColor
  const sx = {
    ...(!disabled && { cursor: 'pointer' }),
    height: 'auto',
    textAlign: 'center',
    backgroundColor: selectionBackground,
    padding,
    borderRadius,
    transition: '0.2s all ease',

    '& .MuiChip-label': {
      overflow: 'visible',
      wordBreak: 'break-word',
      whiteSpace: 'normal',
      textOverflow: 'clip',
      padding: 0,
    },

    '&:hover': {
      backgroundColor: selectionBackground,
    },
    position: 'relative',
  }

  const handleOnClick = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation()

    onFocus()
    if (!isBuilder) {
      onClick()
    }
  }

  return (
    <Chip
      data-testid={testId}
      disabled={disabled}
      label={children}
      clickable={!disabled}
      onClick={handleOnClick}
      sx={sx}
    />
  )
}
