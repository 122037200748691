import { Box } from '@mui/material'

import COLORS from '@top/shared/src/style/colors'

import Color from 'color'

export type ProgressBarProps = {
  barColor: string
  percentComplete: number
  parentTestId?: string
}

export const defaultProgressBarColor = COLORS.TOUCHPOINT_NAVY

export function ProgressBar(props: ProgressBarProps) {
  const { barColor, percentComplete, parentTestId } = props

  const testId = parentTestId ? `${parentTestId}-progress-bar` : undefined

  const barColorRGB = Color(barColor).rgb()
  const barColorAlpha = barColorRGB.rgb().alpha()
  const backgroundColor = barColorRGB.alpha(barColorAlpha * 0.3).toString()

  return (
    <Box
      display="flex"
      width="100%"
      padding="8px 0 16px 0"
      boxSizing="border-box"
      data-testid={testId}
    >
      <Box
        height="4px"
        width="100%"
        sx={{ transition: 'background-color 0.5s ease-in-out', backgroundColor }}
      >
        <Box
          height="100%"
          width={`${percentComplete}%`}
          sx={{
            transition:
              'width 0.5s ease-in-out, margin-left 0.5s ease-in-out, background-color 0.5s ease-in-out',
            backgroundColor: barColor,
          }}
        ></Box>
      </Box>
    </Box>
  )
}
