import TouchpointErrorPage from '@top/shared_deprecated/src/components/TouchpointErrorPage'
import getSystemMessage from '@top/shared_deprecated/src/utils/systemMessages/getSystemMessage'

const NotFoundPage = () => {
  return (
    <TouchpointErrorPage
      title={getSystemMessage('error_pages.not_found.title')}
      description={getSystemMessage('error_pages.not_found.description')}
      statusCode={404}
    />
  )
}

export default NotFoundPage
