import { type SceneModulesScene } from '@top/shared/src/Activity/types'
import {
  type ProtobufModuleClassType,
  type ProtobufModuleType,
} from '@top/shared/src/api/dashboard.swagger.gen'
import { translationsSchema } from '@top/shared/src/Languages/types'
import {
  dateInputFormatsSchema,
  dateInputStyleSchema,
  imageOrientationSchema,
  imageStyleSchema,
  numericScaleStyleSchema,
  scaleRangeSchema,
  selectionStyleSchema,
  textStyleSchema,
} from '@top/shared/src/style/types'
import { parseData } from '@top/shared/src/zod/helpers/parseData'
import { defaultDateDisplayStyle, defaultDateInputStyle } from '@top/ui/src/SceneModules/DateEntry'

import { z } from 'zod'

export const sceneModuleQuestionTypes = [
  'MODULE_CHOICE_QUESTION',
  'MODULE_DATE_QUESTION',
  'MODULE_EMAIL_CTA_QUESTION',
  'MODULE_NUMERIC_SCALE_QUESTION',
  'MODULE_NUMERIC_NPS_QUESTION',
  'MODULE_PRESET_IMAGE_QUESTION',
  'MODULE_TEXT_ENTRY_QUESTION',
] as const satisfies Readonly<ProtobufModuleType[]>
export const sceneModuleQuestionTypesSchema = z.enum(sceneModuleQuestionTypes)

export const sceneModuleStaticTypes = ['MODULE_LOGO', 'MODULE_TEXTBOX'] as const satisfies Readonly<
  ProtobufModuleType[]
>

export const sceneModuleTypes = [
  'INVALID_MODULE_TYPE',
  ...sceneModuleStaticTypes,
  ...sceneModuleQuestionTypes,
] as const satisfies Readonly<ProtobufModuleType[]>
export const sceneModuleTypesSchema = z.enum(sceneModuleTypes)

export const sceneModuleClasses = [
  'INVALID_MODULE_CLASS_TYPE',
  'MODULE_CLASS_STATIC',
  'MODULE_CLASS_QUESTION',
] as const satisfies Readonly<ProtobufModuleClassType[]>

/** @description A schema shared by all scene module schemas. */
const commonSceneModuleSchema = z.object({
  uuid: z.string().uuid(),
  type: z.enum(sceneModuleTypes),
  class_type: z.enum(sceneModuleClasses),
  position: z.number().gte(0),
  is_optional: z.boolean(),
})

const commonSceneModuleEtcSchema = z.object({
  show_multi_choice: z.boolean(),
  show_prompt_text: z.boolean(),
  randomize_selections: z.boolean().optional().default(false),
})

const logoSchema = commonSceneModuleSchema.extend({
  type: z.literal('MODULE_LOGO' satisfies ProtobufModuleType),
  class_type: z.literal('MODULE_CLASS_STATIC' satisfies ProtobufModuleClassType),
  style: z
    .object({
      backgroundImage: z.string(),
    })
    .strict(),
})
export type LogoSceneModule = z.infer<typeof logoSchema>

const textBoxSchema = commonSceneModuleSchema.extend({
  type: z.literal('MODULE_TEXTBOX' satisfies ProtobufModuleType),
  class_type: z.literal('MODULE_CLASS_STATIC' satisfies ProtobufModuleClassType),
  etc: commonSceneModuleEtcSchema
    .extend({
      text: z.string(),
      translations: translationsSchema,
    })
    .strict(),
  style: textStyleSchema,
})
export type TextBoxSceneModule = z.infer<typeof textBoxSchema>

const textEntrySchema = commonSceneModuleSchema.extend({
  type: z.literal('MODULE_TEXT_ENTRY_QUESTION' satisfies ProtobufModuleType),
  class_type: z.literal('MODULE_CLASS_QUESTION' satisfies ProtobufModuleClassType),
  etc: commonSceneModuleEtcSchema
    .extend({
      text: z.string(),
      translations: translationsSchema,
      name: z.string(),
    })
    .strict(),
  style: z.object({}),
})
export type TextEntrySceneModule = z.infer<typeof textEntrySchema>

const dateSchema = commonSceneModuleSchema.extend({
  type: z.literal('MODULE_DATE_QUESTION' satisfies ProtobufModuleType),
  class_type: z.literal('MODULE_CLASS_QUESTION' satisfies ProtobufModuleClassType),
  etc: commonSceneModuleEtcSchema
    .extend({
      date_format: dateInputFormatsSchema.optional().default('DATE_FORMAT_DAY_MONTH_YEAR'),
      name: z.string(),
    })
    .strict(),
  // Note: There are a number of activities with date scenes that don't have a style object, or have properties that are not in the schema.
  style: z.object({
    dateDisplayStyle: textStyleSchema.optional().default(defaultDateDisplayStyle),
    dateInputStyle: dateInputStyleSchema.optional().default(defaultDateInputStyle),
  }),
})
export type DateSceneModule = z.infer<typeof dateSchema>

const textSelectionEtcSchema = z.object({
  text: z.string(),
  /** @format int32 */
  position: z.number(),
  translations: translationsSchema,
})
const imageSelectionEtcSchema = z.object({
  text: z.string().optional(),
  /** @format int32 */
  position: z.number(),
  image_alt_text: z.string().optional(),
  image_url: z.string(),
  translations: translationsSchema.optional(),
})
const choiceSelectionEtcSchema = z.union([textSelectionEtcSchema, imageSelectionEtcSchema])

export type ImageSelectionEtc = z.infer<typeof imageSelectionEtcSchema>
export type ChoiceSelectionEtc = z.infer<typeof choiceSelectionEtcSchema>

const textSelectionSchema = z
  .object({
    uuid: z.string().uuid(),
    id: z.string().optional(),
    etc: textSelectionEtcSchema.strict(),
    style: z.object({}),
  })
  .strict()
const imageSelectionSchema = z
  .object({
    uuid: z.string().uuid(),
    id: z.string().optional(),
    etc: imageSelectionEtcSchema.strict(),
    style: imageStyleSchema,
  })
  .strict()
export const selectionSchema = z.union([textSelectionSchema, imageSelectionSchema])
export type ImageSelection = z.infer<typeof imageSelectionSchema>
export type TextSelection = z.infer<typeof textSelectionSchema>
export type Selection = z.infer<typeof selectionSchema>

const choiceSchema = commonSceneModuleSchema.extend({
  type: z.literal('MODULE_CHOICE_QUESTION' satisfies ProtobufModuleType),
  class_type: z.literal('MODULE_CLASS_QUESTION' satisfies ProtobufModuleClassType),
  etc: commonSceneModuleEtcSchema.extend({
    /** @description This is the choice question prompt text. */
    text: z.string().optional(),
    /** @description These are the choice question prompt text translations. */
    translations: translationsSchema.optional(),
    minimum_selection: z.number().optional(),
    maximum_selection: z.number().optional(),
    pinned_selections: z.array(z.string().uuid()).optional(),
    exclusive_selections: z.array(z.string().uuid()).optional(),
    name: z.string(),
  }),
  style: selectionStyleSchema,
  selections: z.array(selectionSchema),
})
export type ChoiceSceneModule = z.infer<typeof choiceSchema>

export const isChoiceSceneModule = (sceneModule: SceneModule): sceneModule is ChoiceSceneModule => {
  return sceneModule.type === 'MODULE_CHOICE_QUESTION'
}

const emailCtaSchema = commonSceneModuleSchema.extend({
  type: z.literal('MODULE_EMAIL_CTA_QUESTION' satisfies ProtobufModuleType),
  class_type: z.literal('MODULE_CLASS_QUESTION' satisfies ProtobufModuleClassType),
  etc: commonSceneModuleEtcSchema
    .extend({
      text: z.string(),
      translations: translationsSchema,
      toc: z.object({
        text: z.string(),
        translations: translationsSchema,
      }),
      name: z.string(),
    })
    .strict(),
  style: z
    .object({
      text_style: z.object({}),
      toc_style: textStyleSchema,
    })
    .strict(),
})
export type EmailCtaSceneModule = z.infer<typeof emailCtaSchema>

const numericScaleSelectionEtcSchema = z.object({
  position: z.number().gte(0),
  value: z.number().gte(0),
})
export type NumericScaleSelectionEtc = z.infer<typeof numericScaleSelectionEtcSchema>
const numericScaleSelectionSchema = z.object({
  uuid: z.string().uuid(),
  etc: numericScaleSelectionEtcSchema,
  style: z.object({}),
})
export type NumericScaleSelection = z.infer<typeof numericScaleSelectionSchema>

const numericScaleSchema = commonSceneModuleSchema.extend({
  type: z.literal('MODULE_NUMERIC_SCALE_QUESTION' satisfies ProtobufModuleType),
  class_type: z.literal('MODULE_CLASS_QUESTION' satisfies ProtobufModuleClassType),
  etc: commonSceneModuleEtcSchema.extend({
    text: z.string(),
    translations: translationsSchema,
    scale_range: scaleRangeSchema,
    name: z.string(),
  }),
  style: numericScaleStyleSchema,
  selections: z.array(numericScaleSelectionSchema),
})
export type NumericScaleSceneModule = z.infer<typeof numericScaleSchema>

export const isNumericScaleSceneModule = (
  sceneModule: SceneModule
): sceneModule is NumericScaleSceneModule => {
  return sceneModule.type === 'MODULE_NUMERIC_SCALE_QUESTION'
}

/**
 * @description The schema is pretty much the same as `MODULE_NUMERIC_SCALE_QUESTION`, but users
 *              can't change certain properties of the NPS scale.
 */
const numericNpsSchema = commonSceneModuleSchema.extend({
  type: z.literal('MODULE_NUMERIC_NPS_QUESTION' satisfies ProtobufModuleType),
  class_type: z.literal('MODULE_CLASS_QUESTION' satisfies ProtobufModuleClassType),
  etc: commonSceneModuleEtcSchema.extend({
    text: z.string(),
    translations: translationsSchema,
    scale_range: scaleRangeSchema,
    name: z.string(),
  }),
  style: numericScaleStyleSchema,
  selections: z.array(numericScaleSelectionSchema),
})
export type NumericNpsSceneModule = z.infer<typeof numericNpsSchema>

export const isNumericNpsSceneModule = (
  sceneModule: SceneModule
): sceneModule is NumericNpsSceneModule => {
  return sceneModule.type === 'MODULE_NUMERIC_NPS_QUESTION'
}

/** @description Checks if a scene has an NPS numeric scene module. */
export const isNpsScene = (scene: SceneModulesScene) => {
  return scene.scene_modules.some((sceneModule) => isNumericNpsSceneModule(sceneModule))
}

const presetImageSelectionEtcSchema = z.object({
  position: z.number().gte(0),
  image_alt_text: z.string().optional(),
  image_url: z.string(),
})
export type PresetImageSelectionEtc = z.infer<typeof presetImageSelectionEtcSchema>
const presetImageSelectionSchema = z.object({
  uuid: z.string().uuid(),
  etc: presetImageSelectionEtcSchema,
  style: imageStyleSchema,
})
export type PresetImageSelection = z.infer<typeof presetImageSelectionSchema>

const presetImageSchema = commonSceneModuleSchema.extend({
  type: z.literal('MODULE_PRESET_IMAGE_QUESTION' satisfies ProtobufModuleType),
  class_type: z.literal('MODULE_CLASS_QUESTION' satisfies ProtobufModuleClassType),
  etc: commonSceneModuleEtcSchema.extend({
    /** @description This is the image preset prompt text. */
    text: z.string().optional(),
    /** @description These are the image preset prompt text translations. */
    translations: translationsSchema.optional(),
    show_multi_choice: z.boolean(),
    show_prompt_text: z.boolean(),
    minimum_selection: z.number().optional(),
    maximum_selection: z.number().optional(),
    randomize_selections: z.boolean(),
    pinned_selections: z.array(z.string().uuid()).optional(),
    exclusive_selections: z.array(z.string().uuid()).optional(),
    image_orientation: imageOrientationSchema,
    name: z.string(),
  }),
  style: z.object({
    prompt_text_style: textStyleSchema.optional().nullable(),
  }),
  selections: z.array(presetImageSelectionSchema).max(4),
})
export type PresetImageSceneModule = z.infer<typeof presetImageSchema>

export const isPresetImageSceneModule = (
  sceneModule: SceneModule
): sceneModule is PresetImageSceneModule => {
  return sceneModule.type === 'MODULE_PRESET_IMAGE_QUESTION'
}

/** @deprecated */
const xxxCTASchema = commonSceneModuleSchema.extend({
  type: z.literal('XXX_MODULE_CTA_QUESTION' satisfies ProtobufModuleType),
  class_type: z.literal('MODULE_CLASS_QUESTION' satisfies ProtobufModuleClassType),
  etc: commonSceneModuleEtcSchema.extend({}),
})
export type XxxCTASceneModule = z.infer<typeof xxxCTASchema>

export const questionSceneModules = z.union([
  choiceSchema,
  dateSchema,
  emailCtaSchema,
  numericNpsSchema,
  numericScaleSchema,
  presetImageSchema,
  textEntrySchema,
])
export type QuestionSceneModule = z.infer<typeof questionSceneModules>

export const anySceneModuleSchema = z
  .discriminatedUnion('type', [
    logoSchema,
    textBoxSchema,
    textEntrySchema,
    dateSchema,
    choiceSchema,
    emailCtaSchema,
    numericNpsSchema,
    presetImageSchema,
    numericScaleSchema,
    xxxCTASchema,
  ])
  .transform((val) => {
    if (val.type === 'MODULE_PRESET_IMAGE_QUESTION') {
      // If the image orientation is not 'IMAGE_GRID' and there are more than 2 selections, we
      // need to remove the extra selections so the activity is in a good state.
      if (val.etc.image_orientation !== 'IMAGE_GRID' && val.selections.length > 2) {
        const updatedSelections = val.selections.slice(0, 2)

        return {
          ...val,
          selections: updatedSelections,
        }
      }

      return val
    }

    // For all other scene modules, we don't need to do anything.
    return val
  })

export type SceneModule = z.infer<typeof anySceneModuleSchema>

export type SceneModuleType = SceneModule['type']
export type SceneModuleQuestionType = Extract<
  SceneModuleType,
  | 'XXX_MODULE_CTA_QUESTION'
  | 'MODULE_NUMERIC_SCALE_QUESTION'
  | 'MODULE_PRESET_IMAGE_QUESTION'
  | 'MODULE_EMAIL_CTA_QUESTION'
  | 'MODULE_CHOICE_QUESTION'
  | 'MODULE_DATE_QUESTION'
  | 'MODULE_TEXT_ENTRY_QUESTION'
  | 'MODULE_NUMERIC_NPS_QUESTION'
>

export const isSceneModuleQuestionType = (
  sceneModuleType: SceneModuleType
): sceneModuleType is SceneModuleQuestionType => {
  return [
    'XXX_MODULE_CTA_QUESTION',
    'MODULE_NUMERIC_SCALE_QUESTION',
    'MODULE_PRESET_IMAGE_QUESTION',
    'MODULE_EMAIL_CTA_QUESTION',
    'MODULE_CHOICE_QUESTION',
    'MODULE_DATE_QUESTION',
    'MODULE_TEXT_ENTRY_QUESTION',
    'MODULE_NUMERIC_NPS_QUESTION',
  ].includes(sceneModuleType)
}

export const isQuestionSceneModule = (
  sceneModule: SceneModule
): sceneModule is QuestionSceneModule => {
  return isSceneModuleQuestionType(sceneModule.type)
}

/** @description A scene module whose existence within a scene allows screen branching. */
export type BranchableSceneModule =
  | ChoiceSceneModule
  | PresetImageSceneModule
  | NumericScaleSceneModule
  | NumericNpsSceneModule

export const isBranchableSceneModule = (
  sceneModule: SceneModule
): sceneModule is BranchableSceneModule => {
  return (
    isChoiceSceneModule(sceneModule) ||
    isPresetImageSceneModule(sceneModule) ||
    isNumericScaleSceneModule(sceneModule) ||
    isNumericNpsSceneModule(sceneModule)
  )
}

/**
 * @description Parses a scene module's data using a zod schema. If `schema.parse` doesn't error
 *              out, type is narrowed to `SceneModule`. In case the data cannot be parsed with the
 *              schema, an error is thrown.
 */
export function isSceneModule(maybeSceneModule: object): maybeSceneModule is SceneModule {
  try {
    anySceneModuleSchema.parse(maybeSceneModule)
    return true
  } catch (e) {
    throw e
  }
}

/**
 * @description Parses a scene module's data using a zod schema. If `schema.parse` doesn't error
 *              out, the type of `maybeSceneModule` is narrowed to `SceneModule` and a new scene
 *              module with its default values is returned. In case the data cannot be parsed with
 *              the schema, an error is thrown.
 */
export const parseSceneModule = (maybeSceneModule: object) =>
  parseData(maybeSceneModule, anySceneModuleSchema, `Couldn't parse Scene Module!`)
