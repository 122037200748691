import Box from '@mui/material/Box'
import { createStyles, makeStyles } from '@mui/styles'

import { AI_ASSISTANT as AI_ASSISTANT_COLORS } from '@top/shared/src/style/colors'

const useStyles = makeStyles(() =>
  createStyles({
    dotsContainer: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: AI_ASSISTANT_COLORS.LOADER_BACKGROUND,
      borderRadius: '16px',
      padding: '8px',
    },
    dot: {
      width: '6px',
      height: '6px',
      margin: '0 2px',
      borderRadius: '50%',
      animationDuration: '1.4s',
      animationTimingFunction: 'linear',
      animationIterationCount: 'infinite',
      backgroundColor: AI_ASSISTANT_COLORS.LOADER_DOTS,
    },
    left: {
      animationName: '$leftAnimation',
    },
    center: {
      animationName: '$centerAnimation',
    },
    right: {
      animationName: '$rightAnimation',
    },
    '@keyframes leftAnimation': {
      '0%': {
        opacity: 0.4,
      },
      '50%': {
        opacity: 0.4,
      },
      '100%': {
        opacity: 1,
      },
    },
    '@keyframes centerAnimation': {
      '0%': {
        opacity: 1,
      },
      '50%': {
        opacity: 0.4,
      },
      '100%': {
        opacity: 0.4,
      },
    },
    '@keyframes rightAnimation': {
      '0%': {
        opacity: 0.4,
      },
      '50%': {
        opacity: 1,
      },
      '100%': {
        opacity: 0.4,
      },
    },
  })
)

const PromptLoader = () => {
  const classes = useStyles()

  return (
    <Box className={classes.dotsContainer}>
      <Box className={`${classes.dot} ${classes.left}`} />
      <Box className={`${classes.dot} ${classes.center}`} />
      <Box className={`${classes.dot} ${classes.right}`} />
    </Box>
  )
}

export default PromptLoader
