import { Box } from '@mui/material'

import COLORS from '@top/shared/src/style/colors'
import { Fonts } from '@top/shared/src/style/types'
import { FocusedBorder } from '@top/ui/src/SceneModules/components/FocusedBorder'
import EmailInput from '@top/ui/src/SceneModules/EmailEntry/components/EmailInput'
import { type EmailInputProps } from '@top/ui/src/SceneModules/EmailEntry/components/EmailInput'
import Terms, { type TermsProps } from '@top/ui/src/SceneModules/EmailEntry/components/Terms'
import { type CommonSceneModuleProps } from '@top/ui/src/SceneModules/types'

export const defaultTOCStyle = {
  color: COLORS.BLACK,
  fontFamily: Fonts.Roboto,
  fontSize: 8,
  bold: false,
  italic: false,
  underline: false,
  backgroundColor: 'transparent',
  textAlign: 'left',
} as const

type Props = CommonSceneModuleProps<{ email: string; terms: string }> &
  TermsProps &
  EmailInputProps & {
    /**
     * The text in the email input. If isBuilder is true, the value will be styled to imitate the
     * placeholder.
     */
    value: string
    /** Handles the email text. If isBuilder, this handles changes in the placeholder text. */
    onEmailInputChange?: (value: string) => void
    /** Handles the terms and conditions checkbox boolean. */
    onTermsChange?: (value: boolean) => void
    /**
     * Whether or not we want to show the terms and conditions. This prop only applies when
     * isBuilder is true.
     *
     * @default true
     */
    widgetWidth?: number
    onFocusTerms?: () => void
    onBlurTerms?: (newValue: string, options?: { clearSelectedElement: boolean }) => void
    focusedChild?: 'toc' | 'helperText'
  }

export function EmailEntry(props: Props) {
  const {
    value,
    onEmailInputChange,
    onTermsChange,
    onTermsTextChange,
    termsText = '',
    isChecked = false,
    showEmailError = false,
    showTermsError = false,
    error = { email: '', terms: '' },
    termsStyle,
    isValid = false,
    readOnly,
    placeholder,
    onBlur,
    disabled = false,
    testId,
    isBuilder = false,
    widgetWidth,
    onFocus,
    onFocusTerms,
    onBlurTerms,
    focusedChild,
    isFocused = false,
  } = props

  const hasTermsText = !!termsText

  /**
   * If in the builder, we render the terms and conditions if the module is focused or if the terms
   * text is not empty.
   *
   * If not in the builder, we render the terms and conditions if the terms text is not empty.
   */
  const shouldRenderTerms =
    (isBuilder && (isFocused || hasTermsText || showTermsError)) || (!isBuilder && hasTermsText)

  /**
   * This will handle the event when the user clicks between the email input and the terms, while in
   * the builder. This will focus the scene module if its not already focused, otherwise it will do
   * nothing.
   */
  const handleOnClick = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation()
    if (isBuilder && !isFocused && onFocus) {
      onFocus()
    }
  }

  return (
    <Box
      data-testid={testId}
      width="100%"
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      maxWidth="420px"
      onClick={handleOnClick}
      rowGap={isBuilder ? '1rem' : '0.25rem'}
    >
      <EmailInput
        value={value}
        showEmailError={showEmailError}
        error={error?.email}
        disabled={disabled}
        readOnly={readOnly}
        placeholder={placeholder}
        onFocus={onFocus}
        onBlur={onBlur}
        onChange={onEmailInputChange}
        isValid={isValid}
        isBuilder={isBuilder}
        isParentFocused={isBuilder && isFocused}
        isFocused={isBuilder && focusedChild === 'helperText'}
        parentTestId={testId}
      />
      {shouldRenderTerms && (
        <FocusedBorder
          isFocused={isBuilder && focusedChild === 'toc'}
          hover={isBuilder && isFocused}
          hasError={isBuilder && showTermsError}
          fullWidth
          disabled={!isBuilder || (isBuilder && disabled)}
        >
          <Terms
            onFocus={onFocusTerms}
            isChecked={isChecked}
            showTermsError={showTermsError}
            error={error?.terms}
            disabled={disabled}
            termsText={termsText}
            onBlur={onBlurTerms}
            onTermsTextChange={onTermsTextChange}
            onChange={onTermsChange}
            termsStyle={termsStyle}
            isBuilder={isBuilder}
            parentTestId={testId}
            widgetWidth={widgetWidth}
            isParentFocused={isBuilder && isFocused}
            isFocused={isBuilder && focusedChild === 'toc'}
          />
        </FocusedBorder>
      )}
    </Box>
  )
}
