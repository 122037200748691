export const getTransformationsForCircularLayout = (
  numberOfItems: number,
  index: number,
  itemWidth: number,
  containerWidth: number
) => {
  // The circular span is the number of degrees that the circular scale will span
  const CIRCULAR_SPAN_DEG = 298

  // The number of degrees that each item will span
  const CIRCULAR_ITEM_DEG = CIRCULAR_SPAN_DEG / numberOfItems

  // The starting angle of the circular scale
  const CIRCULAR_STARTING_ANGLE_DEG = 135

  const ITEM_ROTATION = CIRCULAR_STARTING_ANGLE_DEG + CIRCULAR_ITEM_DEG * index

  const ITEM_TRANSLATION = containerWidth / 2 - 0.25 * itemWidth

  return { ITEM_ROTATION, ITEM_TRANSLATION }
}
