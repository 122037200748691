export const getTabTestId = (
  testId: 'OVERALL' | 'RESPONSE' | 'DISTRIBUTIONS' | 'BUILD' | 'DISTRIBUTE' | 'REPORT'
): string => `tab-${testId}`

export const getGenericTestId = (testId: string): string => `test__${testId}`

export const getDateNumberInputTestId = (testId: any): string => `test__date-number-input_${testId}`

export const getTextAreaTestId = (testId: string): string => `TextEntry-TextArea-${testId}`

export const STATUS_BUTTON_TEST_ID = 'status-button'
export const ACTIVITIES_TABLE_TEST_ID = 'activities-table'

export const DISTRIBUTE_DELETE_TEST_ID = 'DeleteOutlineIcon'
