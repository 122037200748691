import COLORS from '@top/shared/src/style/colors'

type Props = {
  fill?: string
  className?: string
}

const ArrowDropUp = (props: Props) => {
  const { className, fill = COLORS.BLACK } = props
  return (
    <svg
      width="64"
      height="24"
      viewBox="0 0 64 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.169098 23.7795L31.656 0.453125L63.1429 23.7795H0.169098Z"
        fill={fill}
      />
    </svg>
  )
}
export default ArrowDropUp
