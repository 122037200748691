import * as React from 'react'

import { TextareaAutosize } from '@mui/base/TextareaAutosize'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import makeStyles from '@mui/styles/makeStyles'

import { selectElementTextContent } from '@top/shared/src/helpers/selectElementTextContent'
import COLORS from '@top/shared/src/style/colors'
import { Fonts } from '@top/shared/src/style/types'
import { type QuestionSceneModuleProps } from '@top/ui/src/SceneModules/types'

import DOMPurify from 'dompurify'

const useStyles = makeStyles({
  textArea: {
    padding: '1.12rem 1rem',
    borderRadius: '0.25rem',
    border: `1px solid ${COLORS.BORDER_GRAY}`,
    resize: 'none',
    fontFamily: Fonts.Roboto,
    fontSize: '1rem',
    lineHeight: '20px',
    letterSpacing: '0.25px',
  },
  hasPromptContent: {
    borderBottomLeftRadius: '0',
    borderBottomRightRadius: '0',
    borderBottom: 'none',
  },
  hasError: {
    borderColor: COLORS.ERROR,
  },
  helperTextHover: {
    '&:hover': {
      border: `1px solid black`,
    },
  },
})

const MAX_LENGTH = 280

type Props = {
  /** Will be used to set the textarea's placeholder text. */
  placeholder: string
  /** Content to be shown between the text area and the word count. */
  promptContent?: JSX.Element
  onBlur?: (newHelperText: string, options?: { clearSelectedElement: boolean }) => void
  isFocused?: boolean
  isHelperTextFocused?: boolean
} & Omit<QuestionSceneModuleProps<string, string>, 'onBlur'>

/**
 * When `isBuilder` is `true`, the word count will always be 0 and the textarea's value font color
 * will imitate that of the placeholder.
 */
export function TextEntry(props: Props) {
  const {
    placeholder,
    testId,
    showError = false,
    error,
    onBlur,
    onChange,
    onFocus,
    promptContent,
    value = '',
    disabled = false,
    isBuilder = false,
    isFocused = false,
    isHelperTextFocused = false,
  } = props

  const classes = useStyles()
  const textAreaClasses = `${classes.textArea} ${!!promptContent ? classes.hasPromptContent : ''} ${
    showError ? classes.hasError : ''
  } ${isBuilder && isFocused && !isHelperTextFocused ? classes.helperTextHover : ''} `

  const builderStyles = {
    color: isBuilder ? COLORS.DISABLED_DARK : undefined,
    cursor: !isFocused && isBuilder && !disabled ? 'pointer' : 'auto',
  }

  const handleChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    const sanitized = DOMPurify.sanitize(event.target.value)

    if (onChange) {
      onChange(sanitized)
    }
  }

  const handleOnClick = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation()

    if (onFocus) {
      onFocus()
    }
  }

  /** The escape key will blur the input. This is not the default behavior for TextareaAutosize. */
  const handleKeyDown = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (e.key === 'Escape') {
      e.currentTarget.blur()

      if (onBlur) {
        onBlur(e.currentTarget.value, {
          clearSelectedElement: true,
        })
      }
    }
  }

  const handleOnFocus = (e: React.FocusEvent<HTMLTextAreaElement>) => {
    // While in the builder, if the parent is not focused, we don't want to allow editing the helper text.
    if (isBuilder) {
      if (isFocused) {
        selectElementTextContent(e.target)
      } else {
        e.currentTarget.blur()
      }
    }
  }

  return (
    <Grid
      display="flex"
      flexDirection="column"
      width="100%"
      maxWidth="610px"
      data-testid={testId}
      onClick={handleOnClick}
    >
      <TextareaAutosize
        aria-label="Response"
        minRows={4}
        value={value}
        placeholder={placeholder}
        className={textAreaClasses}
        maxLength={MAX_LENGTH}
        disabled={disabled}
        style={builderStyles}
        onChange={handleChange}
        onKeyDown={handleKeyDown}
        onFocus={handleOnFocus}
      />
      {promptContent}
      <Grid display="flex" justifyContent="space-between">
        {showError && (
          <Box
            data-testid={`TextEntry-Error-${testId}`}
            color={COLORS.ERROR}
            fontFamily={Fonts.Roboto}
            fontSize="12px"
            lineHeight="16px"
            letterSpacing="0.4px"
            marginTop="3px"
            width="100%"
            display="block"
          >
            {error}
          </Box>
        )}
        <Box
          textAlign="right"
          color={showError ? COLORS.ERROR : COLORS.DISABLED_DARK}
          fontFamily={Fonts.Roboto}
          fontSize="12px"
          lineHeight="16px"
          letterSpacing="0.4px"
          marginTop="3px"
          width="100%"
          display="block"
        >{`${isBuilder ? 0 : value.length}/${MAX_LENGTH}`}</Box>
      </Grid>
    </Grid>
  )
}
