import Box from '@mui/material/Box'

import { CloudinaryImage } from '@top/shared/src/components/CloudinaryImage'
import COLORS from '@top/shared/src/style/colors'
import { type CommonSceneModuleProps } from '@top/ui/src/SceneModules/types'

import { EditImageMenu } from './components/EditImageMenu'
import { ImagePlaceholder } from './components/ImagePlaceholder'

export type Props = Pick<CommonSceneModuleProps<string>, 'testId'> & {
  /** The public id of the image to display. This is the id of the image in the cloudinary media. */
  publicId: string
  onOpenModal?: () => void
  onRemoveImage?: () => void
  /** @default false */
  isBuilder?: boolean
  onFocus?: () => void
  disabled?: boolean
}

export function Logo(props: Props) {
  const {
    publicId,
    testId,
    onOpenModal,
    onRemoveImage,
    isBuilder = false,
    onFocus,
    disabled = false,
  } = props

  const handleOnClick = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation()

    if (onFocus) {
      onFocus()
    }
  }

  const handleOpenModal = () => {
    if (onOpenModal) {
      onOpenModal()
    }
  }

  const handleRemoveImage = () => {
    if (onRemoveImage) {
      onRemoveImage()
    }
  }

  return (
    <Box
      maxWidth="100%"
      maxHeight="100px"
      display="flex"
      alignItems="center"
      justifyContent="center"
      paddingTop="1rem"
      paddingBottom="1rem"
      onClick={handleOnClick}
    >
      {publicId ? (
        <Box
          display="flex"
          alignItems="center"
          border="1px solid"
          borderColor={COLORS.BORDER_GRAY}
          flexDirection="column"
          overflow="hidden"
          position="relative"
          maxWidth="100px"
          maxHeight="100px"
        >
          <CloudinaryImage publicId={publicId} testId={`logo-${testId}`} />
          {isBuilder && !disabled && (
            <Box>
              <EditImageMenu onReplace={handleOpenModal} onRemove={handleRemoveImage} />
            </Box>
          )}
        </Box>
      ) : (
        <ImagePlaceholder onClick={handleOpenModal} />
      )}
    </Box>
  )
}
