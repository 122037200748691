import { Box, Button } from '@mui/material'
import { makeStyles } from '@mui/styles'

import COLORS from '@top/shared/src/style/colors'
import ArrowDropUp from '@top/ui/src/Icons/ArrowDropUp'

const slideUpKeyframes = {
  '0%': {
    transform: 'translateY(0px)',
    backgroundColor: 'rgba(255, 255, 255, 0.2)',
  },
  '70%, 100%': {
    transform: 'translateY(-2.5rem)',
    backgroundColor: 'rgba(255, 255, 255, 1)',
  },
}

const useStyles = makeStyles({
  '@keyframes slideUp': slideUpKeyframes,
  animation: {
    backgroundColor: COLORS.WHITE,
    transform: 'translateY(-1rem)',
    animationName: '$slideUp',
    animationDuration: '3s',
    animationIterationCount: 'infinite',
    animationTimingFunction: 'ease-in-out',
    cursor: 'pointer',
    '&:hover': {
      animationPlayState: 'paused',
    },
  },
})

type Props = {
  onClick?: () => void
  parentTestId?: string
}

export function AnimatedTransitionButton(props: Props) {
  const { onClick, parentTestId } = props

  const classes = useStyles()

  const testId = parentTestId ? `${parentTestId}-animated-transition` : undefined

  return (
    <Button
      data-testid={testId}
      onClick={onClick}
      aria-label="Next scene"
      sx={{
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        border: 'none',
        background: 'none',
        maxHeight: '60px',
        lineHeight: 1.3,
        '&:hover': {
          background: 'none',
        },
      }}
      disableRipple
    >
      <Box
        width="2rem"
        height="2rem"
        borderRadius="100%"
        display="flex"
        justifyContent="center"
        marginTop="2rem"
        className={classes.animation}
      >
        <ArrowDropUp
          fontSize="2rem"
          style={{ paddingBottom: '16px', fill: COLORS.TOUCHPOINT_NAVY }}
        />
      </Box>
    </Button>
  )
}
