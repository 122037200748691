import React, { type CSSProperties } from 'react'

import { Box } from '@mui/material'

type Props = {
  style?: CSSProperties
  fontSize?: number | string
}

const ArrowDropUp = (props: Props) => {
  const { fontSize = 'default', style } = props

  return (
    <Box
      sx={{
        fontSize,
        fill: 'currentcolor',
        width: '1em',
        height: '1em',
        display: 'inline-block',
        transition: 'fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
        flexShrink: 0,
      }}
    >
      <svg style={style} focusable="false" viewBox="0 0 24 24" aria-hidden="true">
        <path d="M7 14l5-5 5 5z"></path>
      </svg>
    </Box>
  )
}
export default ArrowDropUp
