import { sceneSchemaBase } from '@top/shared/src/Activity/types'
import { localeSchema } from '@top/shared/src/Languages/types'
import { parseData } from '@top/shared/src/zod/helpers/parseData'

import { z } from 'zod'

export const distributorSceneSchema = sceneSchemaBase.omit({ type: true }).extend({
  id: z.string(),
})

/**
 * @todo `randomize_selections` and `pinned_selections` have been removed from a selection's etc object.
 *       Confirm with BE that this is corect and if this will be the case going forward in the builder as well.
 *
 * @todo Another issue appear to be image selection styles.
 *
 * @todo Selection `uuid` is replaced by just `id`.
 */
export type DistributorScene = z.infer<typeof distributorSceneSchema>

/**
 * @description Parses a scene's data using a zod schema. If `schema.parse` doesn't error out, type
 *              is narrowed to `SceneModulesScene` and a new scene with its default values is
 *              returned. In case the data cannot be parsed with the schema, an error is thrown.
 */
export function parseScene(maybeScene: object) {
  return parseData(maybeScene, distributorSceneSchema, `Couldn't parse scene!`)
}

const privacyPolicySchema = z.object({ title: z.string().optional(), link: z.string().optional() })
export type PrivacyPolicy = z.infer<typeof privacyPolicySchema>

export const serveResponseSchema = z.object({
  hash: z.string(),
  session_id: z.string(),
  scenes: z.array(distributorSceneSchema),
  scene_results: z
    .record(
      z.string(),
      z.object({
        scene_total: z.string(),
        selection_results: z.record(
          z.string(),
          z.object({ count: z.string(), percent: z.number() })
        ),
      })
    )
    .optional(),
  providers_share_url: z.record(z.string(), z.string()).optional(),
  privacy_policies: z.array(privacyPolicySchema).optional(),
  completed: z.boolean(),
  self_scene_results: z.record(z.string(), z.string()).optional(),
  footer_links: z.array(z.object({ title: z.string(), link: z.string() })).optional(),
  styles: z
    .object({
      colors: z.array(z.string()),
      progressBarStyles: z.object({ color: z.string() }).optional(),
    })
    .optional(),
  etc: z
    .object({ show_progress_bar: z.boolean().optional().default(false) })
    .optional()
    .default({
      show_progress_bar: false,
    }),
  locales: z.array(localeSchema),
  default_locale: localeSchema,
  tm_app_id: z.string(),
  allow_locale_selection: z.boolean(),
  activity_uid: z.string(),
})

export type ServeResponse = z.infer<typeof serveResponseSchema>
