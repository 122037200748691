import ArrowDropDown from '@mui/icons-material/ArrowDropDown'
import ArrowDropUp from '@mui/icons-material/ArrowDropUp'
import { IconButton } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'

import COLORS from '@top/shared/src/style/colors'
import { type IncrementType } from '@top/ui/src/SceneModules/DateEntry/types'

const useStyles = makeStyles({
  button: {
    width: '100%',
    padding: 0,
    height: '35px',
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  icon: {
    fontSize: '1.5rem',
    color: COLORS.TEXT_DARK,
  },
})

type Props = {
  onClick: () => void
  disabled: boolean
  isHidden: boolean
  type: IncrementType
}

const PinwheelButton = (props: Props) => {
  const { onClick, disabled, isHidden, type } = props

  const classes = useStyles()

  const isIncrement = type === 'INCREMENT'

  return (
    <IconButton
      aria-label={`${type.toLocaleLowerCase()}-button`}
      disableRipple
      className={classes.button}
      disabled={disabled}
      sx={{
        visibility: isHidden ? 'hidden' : 'visible',
        opacity: disabled ? 0.3 : 1,
        cursor: disabled ? 'default' : 'pointer',
      }}
      onClick={(event) => {
        event.stopPropagation()
        onClick()
      }}
    >
      {isIncrement ? (
        <ArrowDropUp className={classes.icon} />
      ) : (
        <ArrowDropDown className={classes.icon} />
      )}
    </IconButton>
  )
}

export default PinwheelButton
