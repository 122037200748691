import { z, type ZodSchema, type ZodTypeDef } from 'zod'

/**
 * @description Parses data using a zod schema. If `schema.parse` doesn't error out, type is
 *              narrowed and the data is returned. Note that if there are default values in the
 *              schema, they will be applied to the data.
 *
 * @param data Data to be parsed.
 * @param schema Zod schema to parse the data with.
 * @param error An optional error message to be logged in case the data cannot be parsed.
 */
export function parseData<T>(
  data: unknown,
  schema: ZodSchema<T, ZodTypeDef, unknown>,
  error?: string
) {
  try {
    const parsedData = schema.parse(data)

    return parsedData
  } catch (e) {
    if (e instanceof z.ZodError) {
      console.groupCollapsed(error ?? `Couldn't parse data!`)
      console.table(data)
      console.log(e)
      console.groupEnd()
    }
    throw e
  }
}
