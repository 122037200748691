import COLORS from '@top/shared/src/style/colors'

type Props = {
  fillColor?: string
  borderColor?: string
}

const CheckboxIcon = (props: Props) => {
  const { fillColor = COLORS.WHITE, borderColor = COLORS.DISABLED_DARK } = props

  return (
    <svg width={16} height={16} viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
      <rect
        x="1"
        y="1"
        width={14}
        height={14}
        rx="1"
        fill={fillColor}
        stroke={borderColor}
        strokeWidth="1.5"
      />
    </svg>
  )
}

export default CheckboxIcon
