import { useEffect, useState } from 'react'

const ITEM_WIDTH_DIVISOR = 4.7
const CIRCULAR_LAYOUT_BREAKPOINT = 1000

/** This function calculates the width of the numeric scale based on the width of the container. */
const getNumericScaleWidth = (containerWidth: number) => {
  const DEFAULT_WIDTH_RATIO = 0.7
  const WIDTH_RATIO_MEDIUM_SCREENS = 0.6

  let width = containerWidth

  // We limit the width of the numeric scale on larger screens to avoid complexity of the circular layout.
  if (width > 400 && width <= 550) {
    width = 400
  }
  if (width > 550) {
    width = 650
  }

  const WIDTH_RATIO = width >= 550 && width < 750 ? WIDTH_RATIO_MEDIUM_SCREENS : DEFAULT_WIDTH_RATIO

  return width * WIDTH_RATIO
}

export const useNumericScaleLayout = (widgetWidth: number, isShortScale: boolean) => {
  const [isCircularLayout, setIsCircularLayout] = useState(false)

  useEffect(() => {
    const onResize = () => {
      const isLandscape = widgetWidth > CIRCULAR_LAYOUT_BREAKPOINT

      setIsCircularLayout(!isShortScale && !isLandscape)
    }
    onResize()
    window.addEventListener('resize', onResize)
    return () => window.removeEventListener('resize', onResize)
  }, [isShortScale, widgetWidth])

  const NUMERIC_SCALE_WIDTH = getNumericScaleWidth(widgetWidth)

  const ITEM_WIDTH = NUMERIC_SCALE_WIDTH / ITEM_WIDTH_DIVISOR
  const MAX_PROMPT_TEXT_HEIGHT = NUMERIC_SCALE_WIDTH - 3 * ITEM_WIDTH
  const PROMP_TEXT_WIDTH = NUMERIC_SCALE_WIDTH - ITEM_WIDTH * 2

  return {
    isCircularLayout,
    NUMERIC_SCALE_WIDTH,
    MAX_PROMPT_TEXT_HEIGHT,
    ITEM_WIDTH,
    PROMP_TEXT_WIDTH,
  }
}
