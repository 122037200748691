/**
 * This function is used to set the selected values for a multichoice or single choice selection.
 *
 * @param selectionId The id of the selection that was clicked.
 * @param selectedIds The ids of the selections that are currently selected.
 * @param isMultiChoice Whether or not the selection is a multichoice selection.
 * @param setSelections The function that will set the selected values.
 */
export const getSelectedValues = (
  selectionId: string,
  selectedIds: string[],
  isMultiChoice: boolean,
  maxSelections?: number
) => {
  const selectedIdsCopy = [...selectedIds]
  const selectionIdIndex = selectedIdsCopy.indexOf(selectionId)
  const isAlreadySelected = selectionIdIndex > -1

  /** If the choice is not multichoice, then we want to toggle the selection. */
  if (!isMultiChoice) {
    if (isAlreadySelected) {
      return []
    } else {
      return [selectionId]
    }
  }

  if (isMultiChoice) {
    const hasMaximumNumberOfSelections = maxSelections && selectedIdsCopy.length >= maxSelections

    if (hasMaximumNumberOfSelections) {
      if (!isAlreadySelected) {
        return selectedIdsCopy
      }
      selectedIdsCopy.splice(selectionIdIndex, 1)
    } else {
      if (isAlreadySelected) {
        selectedIdsCopy.splice(selectionIdIndex, 1)
      } else {
        selectedIdsCopy.push(selectionId)
      }
    }
  }
  return selectedIdsCopy
}
