/**
 * List of supported locales in alphabetical order of their associated language name.
 *
 * Make sure the alphabetical order is maintained when adding new languages!
 *
 * @deprecated Use `@top/shared/src/Languages/types` instead
 */
export const SUPPORTED_LOCALES = [
  'AR',
  'HY',
  'BG',
  'ZH',
  'DA',
  'NL',
  'EN',
  'ET',
  'FR',
  'KA',
  'DE',
  'ID',
  'IT',
  'JA',
  'KO',
  'PL',
  'PT',
  'RO',
  'RU',
  'ES',
  'TH',
  'TR',
  'VI',
] as const
/**
 * @deprecated Use `@top/shared/src/Languages/types` instead
 */
export type Locale = (typeof SUPPORTED_LOCALES)[number]

/**
 * @deprecated Use `@top/shared/src/Languages/types` instead
 */
export const FALLBACK_DEFAULT_LOCALE = 'EN'

/**
 * @deprecated Use `@top/shared/src/Languages/types` instead
 */
export type ExhaustiveTranslations = {
  [key in Locale]: string
}

/**
 * @deprecated Use `@top/shared/src/Languages/types` instead
 */
export const localeToLanguageName = {
  AR: 'Arabic',
  HY: 'Armenian',
  BG: 'Bulgarian',
  ZH: 'Chinese',
  DA: 'Danish',
  NL: 'Dutch',
  EN: 'English',
  ET: 'Estonian',
  FR: 'French',
  KA: 'Georgian',
  DE: 'German',
  ID: 'Indonesian',
  IT: 'Italian',
  JA: 'Japanese',
  KO: 'Korean',
  PL: 'Polish',
  PT: 'Portuguese',
  RO: 'Romanian',
  RU: 'Russian',
  ES: 'Spanish',
  TH: 'Thai',
  TR: 'Turkish',
  VI: 'Vietnamese',
} as const satisfies ExhaustiveTranslations
/**
 * @deprecated Use `@top/shared/src/Languages/types` instead
 */
export type LanguageNames = (typeof localeToLanguageName)[Locale][]

/**
 * @deprecated Use `@top/shared/src/Languages/types` instead
 */
export const localeToTranslatedLanguageName = {
  AR: 'العربية',
  HY: 'հայերեն',
  BG: 'български',
  ZH: '中文',
  DA: 'Nederlands',
  EN: 'English',
  ET: 'Eesti keel',
  NL: 'Nederlands',
  FR: 'Français',
  KA: 'ქართული',
  DE: 'Deutsch',
  ID: 'Bahasa Indonesia',
  IT: 'Italiano',
  JA: '日本語',
  KO: '한국어',
  PL: 'Polski',
  PT: 'Português',
  RO: 'Română',
  RU: 'Русский',
  ES: 'Español',
  TH: 'ไทย',
  TR: 'Türkçe',
  VI: 'Tiếng Việt',
} as const satisfies ExhaustiveTranslations

/* If we want to programmatically get language name from locale code if exists

export function getLanguageName(locale: Locale) {
  let languageNames = new Intl.DisplayNames(['en'], { type: 'language' })
  return languageNames.of(locale)
}
*/

/**
 * At least the default locale will always be present in this object.
 *
 * @todo Type this so the above is being reflected in the type.
 * isTranslations type guard will be used for now
 * @deprecated Use `@top/shared/src/Languages/types` instead
 */
export type Translations = Partial<ExhaustiveTranslations>

/**
 * @deprecated Use `@top/shared/src/Languages/types` instead
 */
export type LanguageConfigs<TLocale extends Locale> = {
  localeCode: TLocale
  languageName: (typeof localeToLanguageName)[TLocale]
}

/**
 * @deprecated Use `@top/shared/src/Languages/types` instead
 */
export type TranslationsErrors = Partial<{
  [key in Locale]: boolean
}>

/**
 * @deprecated Use `@top/shared/src/Languages/types` instead
 */
export type RedirectLinks = Partial<{
  [key in Locale]: string
}>

/**
 * @deprecated Use `@top/shared/src/Languages/types` instead
 */
export const isSupportedLocale = (locale: any): locale is Locale => {
  return SUPPORTED_LOCALES.includes(locale as Locale)
}

/**
 * Takes a uncleaned locale code and try to convert it to type Locale.
 * If it does not result into a Locale, return undefined.
 *
 * @deprecated Use `@top/shared/src/Languages/types` instead
 */
export const convertToLocale = (locale: string) => {
  const cleanedLocale = locale.split('-')[0]?.toUpperCase()

  if (isSupportedLocale(cleanedLocale)) return cleanedLocale
  return undefined
}

/**
 * Translations must contain the default locale.
 *
 * @deprecated Use `@top/shared/src/Languages/types` instead
 */
export const hasDefaultLocaleTranslation = <DefaultLocale extends Locale>(
  translations: Translations,
  defaultLocale: DefaultLocale
): translations is Translations & { [K in DefaultLocale]: string } => {
  if (!translations) return false
  return Object.hasOwn(translations, defaultLocale)
}
