import { type CSSProperties } from 'react'

import {
  type ProtobufButtonType,
  type ProtobufImageOrientation,
  type ProtobufScaleRange,
} from '@top/shared/src/api/dashboard.swagger.gen'
import COLORS from '@top/shared/src/style/colors'
import { BACKGROUND_IMAGE_ASPECT_RATIO } from '@top/shared/src/style/constants'
import { unionOfLiterals } from '@top/shared/src/zod/helpers/unionOfLiterals'
import { unionOfNumericLiterals } from '@top/shared/src/zod/helpers/unionOfStrongLiterals'

import { z } from 'zod'

export enum StyleAttributes {
  FontSize = 'FontSizes',
  bold = 'bold',
  color = 'color',
  fontFamily = 'fontFamily',
  italic = 'italic',
  underline = 'underline',
  backgroundColor = 'backgroundColor',
  fontSize = 'fontSize',
  textAlign = 'textAlign',
}

export enum Fonts {
  AbrilFatface = 'Abril Fatface',
  Comfortaa = 'Comfortaa',
  CourierPrime = 'Courier Prime',
  Dosis = 'Dosis',
  Lato = 'Lato',
  Roboto = 'Roboto',
  Sacramento = 'Sacramento',
  TimesNewRoman = 'Times New Roman',
}

export const textAligns = ['left', 'right', 'center'] as const
export const textAlignSchema = z.enum(textAligns).optional().default('center')
export type TextAlign = z.infer<typeof textAlignSchema>

export const alignmentsMap = {
  'flex-start': 'left',
  'flex-end': 'right',
  center: 'center',
} as const
type alignmentsMapKeys = keyof typeof alignmentsMap
export const alignmentSchema = unionOfLiterals(Object.keys(alignmentsMap) as alignmentsMapKeys[])
export type Alignment = z.infer<typeof alignmentSchema>

export const isAlignment = (value: string): value is Alignment => {
  return value in alignmentsMap
}

export const TextAlignToAlignmentMap = {
  left: 'flex-start',
  right: 'flex-end',
  center: 'center',
} as const satisfies { [key in TextAlign]: Alignment }

const fontSizesRem = [
  '.8rem',
  '1rem',
  '1.2rem',
  '1.5rem',
  '1.8rem',
  '2.5rem',
  '3rem',
  '3.5rem',
  '4rem',
] as const
const fontSizesTShirt = ['XS', 'S', 'M', 'M2', 'L', 'L2', 'L3', 'L4', 'XL'] as const
export const fontSizes = [8, 12, 16, 24, 32, 40, 48, 56, 64] as const

/**
 * @description There are a number of incidences where the font size is a string. We will accept
 *              these as valid font sizes to avoid zod errors, and convert them to a number. In the
 *              case where the font size does not parse to a number in the fontSizes array, we will
 *              set it to the closest valid font size. If its in the middle of two font sizes, we
 *              will set it to the smaller font size.
 */
const fontSizesString = [
  '8',
  '10',
  '12',
  '14',
  '16',
  '18',
  '24',
  '30',
  '32',
  '40',
  '48',
  '56',
  '64',
  '96',
] as const
type FontSizeString = (typeof fontSizesString)[number]
const isFontSizeString = (
  value: FontSizeString | (typeof fontSizes)[number]
): value is FontSizeString => {
  return fontSizesString.includes(value as FontSizeString)
}
const fontSizeStringToNumberMap = {
  '8': 8,
  '10': 8, // 10 is not a valid font size, so we're setting it to 8.
  '12': 12,
  '14': 12, // 14 is not a valid font size, so we're setting it to 12.
  '16': 16,
  '18': 16, // 18 is not a valid font size, so we're setting it to 16.
  '24': 24,
  '30': 32, // 30 is not a valid font size, so we're setting it to 32.
  '32': 32,
  '40': 40,
  '48': 48,
  '56': 56,
  '64': 64,
  '96': 64, // 96 is not a valid font size, so we're setting it to 64.
} satisfies {
  [key in FontSizeString]: (typeof fontSizes)[number]
}

export type FontSizeRem = (typeof fontSizesRem)[number]
type FontSizeTShirt = (typeof fontSizesTShirt)[number]

// Schema of all possible font sizes that FE can recieve.
const fontSizeUnionSchema = unionOfLiterals([
  ...fontSizes,
  ...fontSizesRem,
  ...fontSizesTShirt,
  ...fontSizesString,
])
type FontSizeUnion = z.infer<typeof fontSizeUnionSchema>

const fontSizeRemToNumberMap = {
  '.8rem': 8,
  '1rem': 12,
  '1.2rem': 16,
  '1.5rem': 24,
  '1.8rem': 32,
  '2.5rem': 40,
  '3rem': 48,
  '3.5rem': 56,
  '4rem': 64,
} satisfies { [key in FontSizeRem]: (typeof fontSizes)[number] }

export const fontSizeTShirtToNumberMap = {
  XS: 8,
  S: 12,
  M: 16,
  M2: 24,
  L: 32,
  L2: 40,
  L3: 48,
  L4: 56,
  XL: 64,
} satisfies { [key in FontSizeTShirt]: (typeof fontSizes)[number] }

export const fontSizeNumberToTShirtMap = {
  8: 'XS',
  12: 'S',
  16: 'M',
  24: 'M2',
  32: 'L',
  40: 'L2',
  48: 'L3',
  56: 'L4',
  64: 'XL',
} satisfies { [key in (typeof fontSizes)[number]]: FontSizeTShirt }

const isTShirtFontSize = (fontSize: FontSizeUnion): fontSize is FontSizeTShirt => {
  return fontSize in fontSizeTShirtToNumberMap
}
const isRemFontSize = (fontSize: FontSizeUnion): fontSize is FontSizeRem => {
  return fontSize in fontSizeRemToNumberMap
}

/** Transform all font sizes to a number in the fontSizes tuple. */
const fontSizeSchema = fontSizeUnionSchema.transform((val) => {
  if (isTShirtFontSize(val)) {
    return fontSizeTShirtToNumberMap[val]
  }
  if (isRemFontSize(val)) {
    return fontSizeRemToNumberMap[val]
  }

  if (isFontSizeString(val)) {
    return fontSizeStringToNumberMap[val]
  }

  return val
})
export type FontSize = z.infer<typeof fontSizeSchema>

export const isFontSize = (value: number): value is FontSize => {
  return fontSizes.includes(value as FontSize)
}

/** @description A schema for all scene module's text styles. */
export const textStyleSchema = z.object({
  color: z.string(),
  fontFamily: z.string(),
  bold: z.boolean(),
  /**
   * @description Can be `undefined` in some cases where a scene has been created with older code.
   *              So we're setting a default when parsing a text style object using a zod schema.
   * @default false
   */
  italic: z.boolean().optional().default(false),
  /**
   * @description Can be `undefined` in some cases where a scene has been created with older code.
   *              So we're setting a default when parsing a text style object using a zod schema.
   * @default false
   */
  underline: z.boolean().optional().default(false),
  /**
   * @description Can be `undefined` in some cases where a scene has been created with older code.
   *              So we're setting a default when parsing a text style object using a zod schema.
   * @default 'transparent'
   */
  backgroundColor: z.string().optional().default('transparent'),
  /**
   * @description Can be `undefined` in some cases where a scene has been created with older code.
   *              So we're setting a default when parsing a text style object using a zod schema.
   * @default 'center'
   */
  textAlign: textAlignSchema,
  /**
   * @description There are many different font types that are stored in the BE (t-shirt sizing,
   *              rems, and numbers). We're using a zod transform to convert each value to a single
   *              type of `FontSize`.
   */
  fontSize: fontSizeSchema,
})
export type TextStyle = z.infer<typeof textStyleSchema>

export const buttonWidthsMap = {
  Short: '109px',
  Medium: '218px',
  Long: '327px',
} as const
export const buttonWidthSchema = unionOfLiterals(Object.values(buttonWidthsMap))
export type EXPERIMENTAL_ButtonWidth = z.infer<typeof buttonWidthSchema>
export enum ButtonWidth {
  Short = '109px',
  Medium = '218px',
  Long = '327px',
}

export const buttonRadiusIncrements = [0, 4, 8, 16, 24] as const
export const buttonRadiusIncrementsSchema = unionOfNumericLiterals(buttonRadiusIncrements)
export type ButtonRadiusIncrement = z.infer<typeof buttonRadiusIncrementsSchema>

export const isButtonRadiusIncrement = (value: number): value is ButtonRadiusIncrement => {
  return buttonRadiusIncrements.includes(value as ButtonRadiusIncrement)
}

export const selectionBlockDisplayStyleTypes = ['block', 'radio', 'condensed'] as const
export const selectionBlockDisplayStyleTypeSchema = z.enum(selectionBlockDisplayStyleTypes)
export type SelectionBlockDisplayType = z.infer<typeof selectionBlockDisplayStyleTypeSchema>

export const selectionBlockShapes = ['circle', 'diamond'] as const
export const selectionBlockShapeSchema = z.enum(selectionBlockShapes)
export type SelectionBlockShape = z.infer<typeof selectionBlockShapeSchema>

export const spacingIncrements = [0, 0.5, 1, 1.5, 2] as const
export const spacingIncrementSchema = unionOfNumericLiterals(spacingIncrements)
export type SpacingIncrement = z.infer<typeof spacingIncrementSchema>

export const isSpacingIncrement = (value: number): value is SpacingIncrement => {
  return spacingIncrements.includes(value as SpacingIncrement)
}

export const selectionBlockStyleSchema = z.discriminatedUnion('choice_display_type', [
  z.object({
    choice_display_type: selectionBlockDisplayStyleTypeSchema.extract(['block', 'condensed']),
    choice_padding: spacingIncrementSchema,
    gap_spacing: spacingIncrementSchema,
    radio_button_shape: z.null(),
  }),
  z.object({
    choice_display_type: selectionBlockDisplayStyleTypeSchema.extract(['radio']),
    radio_button_shape: selectionBlockShapeSchema.optional().default('circle'),
    choice_padding: spacingIncrementSchema,
    gap_spacing: spacingIncrementSchema,
  }),
])

/** @description This is correctly called `display_options`. Might rename later. */
export type SelectionBlockStyle = z.infer<typeof selectionBlockStyleSchema>

export const selectionStyleSchema = z.object({
  prompt_text_style: textStyleSchema.optional().nullable(),
  selection_style: textStyleSchema,
  /** `display_options` can be `undefined`, so we're setting the default value using zod. */
  display_options: selectionBlockStyleSchema.optional().default({
    gap_spacing: 1,
    choice_padding: 1,
    choice_display_type: 'block',
    radio_button_shape: null,
  }),
})
export type SelectionStyles = z.infer<typeof selectionStyleSchema>

export const numericScaleIcons = [
  'NUMERIC_CIRCLE',
  'NUMERIC_STAR',
  'NUMERIC_FACE',
  'NUMERIC_FACE_COLOR_GRADIENT',
] as const
export const numericScaleIconSchema = z.enum(numericScaleIcons)
export type NumericScaleIcon = z.infer<typeof numericScaleIconSchema>
export type NumericReactionScaleFaces = Exclude<NumericScaleIcon, 'NUMERIC_CIRCLE' | 'NUMERIC_STAR'>

export const scaleRanges = [
  'RANGE_ONE_TO_FIVE',
  'RANGE_ZERO_TO_TEN',
  'RANGE_ONE_TO_TEN',
] as const satisfies Readonly<Exclude<ProtobufScaleRange, 'INVALID_SCALE_RANGE'>[]>
export const scaleRangeSchema = z.enum(scaleRanges)
export type ScaleRange = z.infer<typeof scaleRangeSchema>

export const numericScaleSelectionStylesSchema = z.object({
  /**
   * @description Can be `undefined` in some cases where a scene has been created with older code.
   *              So we're setting a default when parsing a text style object using a zod schema.
   * @default 'TOUCHPOINT_NAVY'
   */
  primaryColor: z.string().optional().default(COLORS.TOUCHPOINT_NAVY),
  /**
   * @description Can be `undefined` in some cases where a scene has been created with older code.
   *              So we're setting a default when parsing a text style object using a zod schema.
   * @default 'NUMERIC_SCALE_SECONDARY'
   */
  secondaryColor: z.string().optional().default(COLORS.NUMERIC_SCALE_SECONDARY),
  /**
   * @description Can be `undefined` in some cases where a scene has been created with older code.
   *              So we're setting a default when parsing a text style object using a zod schema.
   * @default 'NUMERIC_CIRCLE'
   */
  shape: numericScaleIconSchema.optional().default('NUMERIC_CIRCLE'),
})
export type NumericSelectionStyles = z.infer<typeof numericScaleSelectionStylesSchema>
export const numericScaleStyleSchema = z.object({
  result_text_style: textStyleSchema.omit({ textAlign: true }),
  selection_style: numericScaleSelectionStylesSchema,
  text_style: textStyleSchema,
})
export type NumericScaleStyle = z.infer<typeof numericScaleStyleSchema>

export const ButtonWidthPreviewSizesMap: { [key in ButtonWidth]: string } = {
  [ButtonWidth.Short]: ButtonWidth.Short,
  [ButtonWidth.Long]: ButtonWidth.Long,
  [ButtonWidth.Medium]: '184px',
}

export const formats = ['underline', 'italic', 'bold'] as const
export type Formats = 'underline' | 'italic' | 'bold'
export const buttonStyleSchema = z.object({
  fontFamily: z.string(),
  fontSize: fontSizeSchema,
  bold: z.boolean(),
  italic: z.boolean().optional().default(false),
  underline: z.boolean().optional().default(false),
  color: z.string(),
  backgroundColor: z.string(),
  maxHeight: z.string().optional(),
  buttonWidth: buttonWidthSchema.optional().default(ButtonWidth.Medium),
  buttonRadius: buttonRadiusIncrementsSchema.optional().default(buttonRadiusIncrements[1]),
  /**
   * @description Can be `undefined` in some cases where a scene has been created with older code.
   *              So we're setting a default when parsing a text style object using a zod schema.
   * @default 'center'
   */
  alignment: alignmentSchema.optional().default('center'),
})
export type ButtonStyle = z.infer<typeof buttonStyleSchema>

export const skipButtonStyleSchema = z.object({
  fontFamily: z.string(),
  fontSize: fontSizeSchema,
  bold: z.boolean(),
  italic: z.boolean().optional().default(false),
  underline: z.boolean().optional().default(false),
  color: z.string(),
  maxHeight: z.string().optional(),
  textAlign: textAlignSchema,
})
export type SkipButtonStyle = z.infer<typeof skipButtonStyleSchema>

/**
 * @description We're excluding 2 button types that backend defines: "INVALID_BUTTON_TYPE" and
 *              "BUTTON_SKIP". Those are not valid options on the FE anymore.
 */
const sceneButtonTypes = [
  'BUTTON_CONTAINED',
  'BUTTON_ANIMATED',
  'BUTTON_ARROW',
] as const satisfies Readonly<ProtobufButtonType[]>
export const sceneButtonTypeSchema = z.enum(sceneButtonTypes)
export type NextButtonType = z.infer<typeof sceneButtonTypeSchema>

export type SceneStyle = {
  progressBarStyles: CSSProperties
}

export const imageStyleSchema = z.object({
  zoom: z.number(),
  cropHeight: z.number(),
  cropWidth: z.number(),
  cropX: z.number(),
  cropY: z.number(),
  blur: z.number(),
  saturation: z.number(),
  brightness: z.number(),
  aspectRatio: z.number().optional(),
})
export type ImageStyle = z.infer<typeof imageStyleSchema>

export const imageOrientations = [
  'IMAGE_PORTRAIT',
  'IMAGE_LANDSCAPE',
  'IMAGE_GRID',
] as const satisfies Readonly<ProtobufImageOrientation[]>
export const imageOrientationSchema = z.enum(imageOrientations)
export type ImageOrientation = z.infer<typeof imageOrientationSchema>

export const backgroundTypeSchema = z.enum(['image', 'color'])
export type BackgroundType = z.infer<typeof backgroundTypeSchema>

export const backgroundStyleSchema = imageStyleSchema.extend({
  backgroundImage: z.string(),
  backgroundColor: z.string(),
  backgroundType: backgroundTypeSchema,
  objectPosition: z.string().optional(),
  aspectRatio: z.number().optional().default(BACKGROUND_IMAGE_ASPECT_RATIO),
})
export type BackgroundStyle = z.infer<typeof backgroundStyleSchema>

export type TextBackground = {
  style: {
    backgroundColor: string
  }
}

export const dateInputFormats = [
  'DATE_FORMAT_MONTH_DAY_YEAR',
  'DATE_FORMAT_DAY_MONTH_YEAR',
  'DATE_FORMAT_MONTH_YEAR',
  'DATE_FORMAT_YEAR',
] as const
export const dateInputFormatsSchema = z.enum(dateInputFormats)
export type DateInputFormat = z.infer<typeof dateInputFormatsSchema>

export const dateInputStyleSchema = z.object({
  color: z.string(),
  fontFamily: z.string(),
  bold: z.boolean(),
  italic: z.boolean(),
  underline: z.boolean(),
})
export type DateInputStyle = z.infer<typeof dateInputStyleSchema>

/**
 * Note: We have seen some cases where progress bar styles are either not defined or an empty
 * object.
 */
export const progressBarStyleSchema = z
  .object({
    color: z.string().optional().default(COLORS.TEXT_DARK),
  })
  .optional()
  .default({
    color: COLORS.TEXT_DARK,
  })
export type ProgressBarStyle = z.infer<typeof progressBarStyleSchema>
