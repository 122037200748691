import { z } from 'zod'

/**
 * @description Given a constant array of numbers, construct a zod schema that allows any of the
 *              numbers as numeric or string literals while only parsing them as numbers. Useful
 *              when a feature has been implemented with no regard to strict typing and both numeric
 *              and string values exist, while only numeric values make sense.
 *
 * @example ```
 * const spacingIncrements = [0, 0.5, 1, 1.5, 2] as const
 * const spacingIncrementSchema = unionOfNumericLiterals(spacingIncrements)
 * const parsedString = spacingIncrementSchema.parse("1") // 1
 * const parsedNumber = spacingIncrementSchema.parse(1) // 1
 * ```
 */
export function unionOfNumericLiterals<T extends number>(constants: readonly T[]) {
  /**
   * We have to resort to this type casting because `.map` obfuscates the number of items in an
   * array while `zod.union` expects at least 2 items (`[ z.ZodLiteral<T>, z.ZodLiteral<T>,
   * ...z.ZodLiteral<T>[] ]`).
   */
  const literals = constants.map((x) =>
    z
      .literal(x)
      .or(z.literal(`${x}`))
      .pipe(z.coerce.number())
  ) as unknown as readonly [z.ZodLiteral<T>, z.ZodLiteral<T>, ...z.ZodLiteral<T>[]]

  return z.union(literals)
}
