import {
  type ProtobufActivityStatus,
  type ProtobufPurgeStatus,
  type ProtobufSceneTransitionType,
  type ProtobufSceneType,
} from '@top/shared/src/api/dashboard.swagger.gen'
import { customThemeSchema } from '@top/shared/src/CustomTheme/types'
import { localeSchema, localesSchema, translationsSchema } from '@top/shared/src/Languages/types'
import { anySceneModuleSchema } from '@top/shared/src/SceneModules/types'
import COLORS from '@top/shared/src/style/colors'
import {
  backgroundStyleSchema,
  backgroundTypeSchema,
  buttonStyleSchema,
  progressBarStyleSchema,
  sceneButtonTypeSchema,
  skipButtonStyleSchema,
} from '@top/shared/src/style/types'
import { parseData } from '@top/shared/src/zod/helpers/parseData'

import { z } from 'zod'

const activityStatuses = [
  'ACTIVITY_DRAFT',
  'ACTIVITY_PUBLISHED',
  'INVALID_ACTIVITY_STATUS',
  'ACTIVITY_REPUBLISH_DRAFT',
] as const satisfies Readonly<ProtobufActivityStatus[]>
const activityStatusSchema = z.enum(activityStatuses)
export type ActivityStatus = z.infer<typeof activityStatusSchema>

const white = COLORS.WHITE
const blue = '#3916e4'
const black = '#000000'
const grey = '#CCCCCC'
export const defaultActivityColors = [white, blue, black, grey]

const activityStylesSchema = z.object({
  colors: z.array(z.string()).default(defaultActivityColors),
  progressBarStyles: progressBarStyleSchema,
})

const purgeRequestStatuses = [
  'CANCELLED',
  'COMPLETED',
  'FAILED',
  'INVALID',
  'RUNNING',
  'SCHEDULED',
] as const satisfies Readonly<ProtobufPurgeStatus[]>

const purgeRequestStatusSchema = z.enum(purgeRequestStatuses)
export type PurgeRequestStatus = z.infer<typeof purgeRequestStatusSchema>

const activityVersionSchema = z.object({
  id: z.string(),
  activity_version: z.number().gte(1),
  status: activityStatusSchema,
  campaign_count: z.number().gte(0).optional().default(0),
  total_dist_count: z.number().gte(0).optional().default(0),
  active_dist_count: z.number().gte(0).optional().default(0),
  has_sensitive_data: z.boolean().optional().default(false),
  purge_request_status: purgeRequestStatusSchema.optional(),
  can_publish: z.boolean().optional(),
})
export type ActivityVersion = z.infer<typeof activityVersionSchema>

const versionListSchema = z.array(activityVersionSchema)

export type VersionList = z.infer<typeof versionListSchema>

const activityBaseSchema = z.object({
  id: z.string().optional(),
  org_id: z.string(),
  name: z.string(),
  status: activityStatusSchema,
  created_at: z.string().optional(),
  updated_at: z.string().optional(),
  uid: z.string(),
  share_settings: z.object({}),
  type: z.string(),
  can_publish: z.boolean(),
  styles: activityStylesSchema,
  locales: localesSchema,
  default_locale: localeSchema,
  can_preview: z.boolean(),
  /**
   * Can only be present in activities with status ACTIVITY_PUBLISHED. This is the id for the linked
   * draft activity.
   */
  republish_draft_id: z.string().optional(),
  /**
   * Always present in activities that have status ACTIVITY_REPUBLISH_DRAFT. This is the id for the
   * linked live activity.
   */
  live_activity_id: z.string().optional(),
  activity_version: z.number().gte(1),
  grouping_uuid: z.string(),
  versioned_from_id: z.string().optional(),
  version_list: versionListSchema,
})

const sceneTypes = [
  'INVALID_SCENE_TYPE',
  'SCENE_GENERIC',
  'SCENE_QUESTION',
  'SCENE_CTA',
  'SCENE_NUMERIC',
  'SCENE_BUILDER',
  'SCENE_MODULE',
] as const satisfies Readonly<ProtobufSceneType[]>
const sceneTypeSchema = z.enum(sceneTypes).transform(() => 'SCENE_MODULE' as const)
export type SceneType = z.infer<typeof sceneTypeSchema>

export const sceneTransitionTypes = [
  'SLIDE',
  'SCROLL',
  'INVALID_SCENE_TRANSITION_TYPE',
] as const satisfies Readonly<ProtobufSceneTransitionType[]>
const sceneTransitionTypeSchema = z.enum(sceneTransitionTypes)
export type SceneTransitionType = z.infer<typeof sceneTransitionTypeSchema>

// TODO: Figure out why using ProtobufActionType breaks the SceneModulesScene type
const sceneActions = ['ACTION_NEXT_SCENE', 'ACTION_END_ACTIVITY', 'ACTION_REDIRECT_URL'] as const //satisfies Readonly<ProtobufActionType[]>
const sceneActionTypeSchema = z.enum(sceneActions)
export type SceneActionType = z.infer<typeof sceneActionTypeSchema>

export const isSceneActionType = (maybeActionType: string): maybeActionType is SceneActionType => {
  return maybeActionType in sceneActions
}

const selectionGroupSchema = z.array(
  z.object({
    selections: z.record(z.string()),
  })
)

const sceneBranchSchema = z.discriminatedUnion('action_type', [
  z.object({
    uuid: z.string(),
    action_type: z.literal('ACTION_NEXT_SCENE'),
    // Note: this is only optional because the legacy builder allowed for the action value to be undefined.
    action_value: z.string().optional(),
    selection_groups: selectionGroupSchema.optional().default([]),
  }),
  z.object({
    uuid: z.string(),
    action_type: z.literal('ACTION_REDIRECT_URL'),
    // Note: this is only optional because the legacy builder allowed for the action value to be undefined.
    action_value: z.string().optional(),
    // Note: this is only optional because the legacy builder allowed for the redirect links to be undefined.
    redirect_links: translationsSchema.optional(),
    selection_groups: selectionGroupSchema.optional().default([]),
  }),
  z.object({
    uuid: z.string(),
    action_type: z.literal('ACTION_END_ACTIVITY'),
    selection_groups: selectionGroupSchema.optional().default([]),
  }),
])
export type SceneBranch = z.infer<typeof sceneBranchSchema>
export type SelectionGroup = z.infer<typeof selectionGroupSchema>[number]

const sceneEtcSchema = z.object({
  position: z.number().gte(0),
  name: z.string(),
  shareback_results: z.boolean(),
  show_skip_button: z.boolean(),
  ai_assistant_enabled: z.boolean(),
  /**
   * This is necessary because the old builder allowed for the action value to be undefined or empty
   * string for the 'ACTION_NEXT_SCENE' action type. We will filter out these branches as they are
   * in a bad state.
   */
  branches: z
    .array(sceneBranchSchema)
    .optional()
    .transform((val) => {
      if (val === undefined) return undefined

      return val.filter((branch) => {
        // Filter out branches that have no action value defined and action type is next scene.
        if (branch.action_type === 'ACTION_NEXT_SCENE' && !branch.action_value) {
          return false
        }

        // Filter out branches that have no action value or redirect links defined and action type is redirect url.
        if (
          branch.action_type === 'ACTION_REDIRECT_URL' &&
          (!branch.action_value || !branch.redirect_links)
        ) {
          return false
        }

        return true
      })
    }),
})
const sceneStyleSchema = z.object({ progressBarStyles: progressBarStyleSchema })

const sceneItemsSchema = z.object({
  ITEM_BACKGROUND: z.object({
    style: backgroundStyleSchema,
    etc: z.object({}),
  }),
  ITEM_BUTTON: z.object({
    style: buttonStyleSchema,
    etc: z.object({
      text: z.string(),
      button_type: sceneButtonTypeSchema,
      translations: translationsSchema,
    }),
  }),
  ITEM_TEXT_BACKGROUND: z
    .object({
      etc: z.object({}),
      style: z.object({
        backgroundColor: z.string(),
        backgroundType: backgroundTypeSchema.extract(['color']).optional().default('color'),
      }),
    })
    .optional()
    .default({
      etc: {},
      style: {
        backgroundType: 'color',
        backgroundColor: 'transparent',
      },
    }),
  ITEM_SKIP_BUTTON: z
    .object({
      etc: z.object({
        button_type: z.literal('BUTTON_SKIP'),
        text: z.string(),
        translations: translationsSchema,
      }),
      style: skipButtonStyleSchema,
    })
    .optional(),
})
export type SceneItemType = keyof z.infer<typeof sceneItemsSchema>
export type SkipButton = z.infer<typeof sceneItemsSchema>['ITEM_SKIP_BUTTON']
export type NextButton = z.infer<typeof sceneItemsSchema>['ITEM_BUTTON']

export const isSkipButton = (
  maybeSkipButton: SceneItems[keyof SceneItems]
): maybeSkipButton is SkipButton => {
  return !!(maybeSkipButton?.style && 'textAlign' in maybeSkipButton.style)
}

export const isNextButton = (
  maybeNextButton: SceneItems[keyof SceneItems]
): maybeNextButton is NextButton => {
  return !!(maybeNextButton?.style && 'buttonWidth' in maybeNextButton.style)
}

export type SceneItems = z.infer<typeof sceneItemsSchema>

export const sceneSchemaBase = z.object({
  uuid: z.string(),
  created_at: z.string().optional(),
  updated_at: z.string().optional(),
  /** Note that we are transforming the type to 'SCENE_MODULE' for all scenes. */
  type: sceneTypeSchema,
  transition_type: sceneTransitionTypeSchema,
  etc: sceneEtcSchema,
  style: sceneStyleSchema,
  action_type: sceneActionTypeSchema.optional(),
  action_value: z.string().optional(),
  redirect_links: translationsSchema.optional(),
  has_sensitive_data: z.boolean(),
  scene_modules: z.array(anySceneModuleSchema).default([]),
  items: sceneItemsSchema,
})
const sceneSchema = sceneSchemaBase.transform((val) => {
  /**
   * This is necessary because the old builder allowed for the action value to be undefined or empty
   * string for the 'ACTION_NEXT_SCENE' action type. We will ensure that the scene is in a good
   * state by setting the action type to 'ACTION_END_ACTIVITY'. Note that this would only affect
   * legacy activities that were left in a bad state.
   */
  if (val.action_type === 'ACTION_NEXT_SCENE' && !val.action_value) {
    return {
      ...val,
      action_value: undefined,
      action_type: 'ACTION_END_ACTIVITY' as const,
    }
  }

  //  If a scene has no action value or redirect links defined, and the action type
  // is redirect url then we will have to assign the action type to 'ACTION_ND_ACTIVITY'.
  if (val.action_type === 'ACTION_REDIRECT_URL' && (!val.action_value || !val.redirect_links)) {
    return {
      ...val,
      action_value: undefined,
      redirect_links: undefined,
      action_type: 'ACTION_END_ACTIVITY' as const,
    }
  }

  return val
})

export type SceneModulesScene = z.infer<typeof sceneSchema>

export const activitySchema = activityBaseSchema.extend({
  theme: customThemeSchema,
  scenes: z.array(sceneSchema).default([]),
  etc: z.object({
    show_progress_bar: z.boolean().optional().default(false),
  }),
})
export type Activity = z.infer<typeof activitySchema>

/**
 * @description Parses an activity's data using a zod schema. If `schema.parse` doesn't error out,
 *              type is narrowed to `Activity`. In case the data cannot be parsed with the schema,
 *              an error is thrown.
 */
export function isActivity(maybeActivity: object): maybeActivity is Activity {
  try {
    activitySchema.parse(maybeActivity)
    return true
  } catch (e) {
    if (e instanceof z.ZodError) {
      console.groupCollapsed(`Couldn't parse activity!`)
      console.table(maybeActivity)
      console.log(e)
      console.groupEnd()
    }
    throw e
  }
}

/**
 * @description Parses an activity's data using a zod schema. If `schema.parse` doesn't error out,
 *              type is narrowed to `Activity` and a new activity with its default values is
 *              returned. In case the data cannot be parsed with the schema, an error is thrown.
 */
export const parseActivity = (maybeActivity: object) =>
  parseData(maybeActivity, activitySchema, `Couldn't parse activity!`)
