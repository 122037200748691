import React, { type CSSProperties, useRef, useState } from 'react'

import { Box, type Theme } from '@mui/material'
import ClickAwayListener from '@mui/material/ClickAwayListener'
import makeStyles from '@mui/styles/makeStyles'

import useIsTouchDevice from '@top/shared/src/hooks/useIsTouchDevice'
import { type DateInputStyle } from '@top/shared/src/style/types'
import { useSetInputType } from '@top/ui/src/SceneModules/DateEntry/hooks/useSetInputType'
import {
  type IncrementType,
  type InputTypes,
  type PinwheelSelectInputProps,
} from '@top/ui/src/SceneModules/DateEntry/types'

import DateNumberInput from './components/DateNumberInput'
import PinWheelSelect from './components/PinWheelSelect'

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    padding: '0px',
    margin: '0 auto',
    width: '69px',
    [theme.breakpoints.down('xs')]: {
      width: '49px',
    },
  },
}))

const getValueFromOptions = (options: number[], scrollValue: number, heightMultiplier: number) => {
  const mappedOptions = options.map((opt: number) => {
    return { scrollValueMid: opt * heightMultiplier + heightMultiplier / 2, optionValue: opt }
  })
  const adjustedScrollValue = scrollValue + 10

  const distMap = mappedOptions.map((opt: { scrollValueMid: number; optionValue: number }) => {
    return { ...opt, distVal: Math.abs(opt.scrollValueMid - adjustedScrollValue) }
  })

  const sortedArray = distMap.sort((a, b) => a.distVal - b.distVal)

  const closestValue = sortedArray[0]!

  return closestValue.optionValue
}

const getInputOptionsArray = (min: number, num: number) => {
  const options: number[] = []
  for (let i = min; i <= num; i++) {
    options.push(i)
  }
  return options
}

type Props = {
  style: DateInputStyle
  staticInputType?: InputTypes
  width?: number
  dateNumberInputElementStyle?: CSSProperties
  onInputChange: (value: number | undefined) => void
} & PinwheelSelectInputProps

const PinWheelNumberInput = (props: Props) => {
  const {
    style,
    staticInputType,
    dateNumberInputElementStyle,
    width,
    onInputChange,
    placeholder = '',
    max,
    min,
    inputValue,
    disabled = false,
    showError = false,
  } = props

  const classes = useStyles()

  const optionsRef = useRef(getInputOptionsArray(min, max))
  const isFocusedRef = useRef<boolean>(false)

  const [inputType, setInputType] = useState<InputTypes>('PIN_WHEEL_TYPE_CLICK')
  const [clickCount, setClickCount] = useState(0)

  const isTouchDevice = useIsTouchDevice()

  useSetInputType(isTouchDevice, setInputType)

  const handleInputTypeChange = (inputType: InputTypes) => {
    setInputType(inputType)
  }

  const handleIncrementChange = (incrementType: IncrementType) => {
    if (incrementType === 'INCREMENT') {
      const updatedValue = inputValue === undefined ? 1 : inputValue + 1
      onInputChange(updatedValue)
    } else {
      const updatedValue = inputValue === undefined ? 1 : inputValue - 1
      onInputChange(updatedValue)
    }
  }

  const handleInputChange = (value: number) => {
    const updatedInputValue = Number.isNaN(value) ? undefined : value

    onInputChange(updatedInputValue)
  }

  const handleScrollChange = (scrollValue: number, heightMultiplier: number) => {
    const inputValue = getValueFromOptions(optionsRef.current, scrollValue, heightMultiplier)
    onInputChange(inputValue)
  }

  const isPinWheelInput =
    inputType === 'PIN_WHEEL_TYPE_CLICK' || inputType === 'PIN_WHEEL_TYPE_TOUCH'
  const isNumberInput = inputType === 'NUMBER_INPUT_TYPE'
  const pinwheelType =
    inputType === 'PIN_WHEEL_TYPE_TOUCH' ? 'PIN_WHEEL_SCROLL' : 'PIN_WHEEL_ARROWS'

  const isStaticPinWheel =
    staticInputType === 'PIN_WHEEL_TYPE_CLICK' || staticInputType === 'PIN_WHEEL_TYPE_TOUCH'
  const isStaticNumberInput = staticInputType === 'NUMBER_INPUT_TYPE'

  const showPinWheelSelect = isStaticPinWheel || (isPinWheelInput && !isStaticNumberInput)
  const showDateNumberInput = isStaticNumberInput || (isNumberInput && !isStaticPinWheel)

  const resetInputType = () => {
    const inputType = isTouchDevice ? 'PIN_WHEEL_TYPE_TOUCH' : 'PIN_WHEEL_TYPE_CLICK'
    setInputType(inputType)
    setClickCount(0)
  }

  const handleResetInputType = () => {
    isFocusedRef.current = false
    resetInputType()
  }

  const handleClickAway = () => {
    if (isNumberInput) {
      return
    }

    isFocusedRef.current = false
    resetInputType()
  }

  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <Box className={classes.container} {...(width && { style: { width } })}>
        {showPinWheelSelect && (
          <PinWheelSelect
            onInputTypeChange={handleInputTypeChange}
            onIncrementChange={handleIncrementChange}
            onScrollChange={handleScrollChange}
            setClickCount={setClickCount}
            clickCount={clickCount}
            onResetInputType={handleResetInputType}
            isFocusedRef={isFocusedRef}
            selectOptions={optionsRef.current}
            max={max}
            min={min}
            showError={showError}
            inputValue={inputValue}
            placeholder={placeholder}
            pinWheelType={pinwheelType}
            style={style}
            disabled={disabled}
          />
        )}
        {showDateNumberInput && (
          <DateNumberInput
            onInputTypeChange={handleInputTypeChange}
            onIncrementChange={handleIncrementChange}
            onInputChange={handleInputChange}
            isFocusedRef={isFocusedRef}
            max={max}
            min={min}
            inputValue={inputValue}
            placeholder={placeholder}
            disabled={disabled}
            showError={showError}
            onResetInputType={handleResetInputType}
            isStaticInput={isStaticNumberInput}
            inputElementStyle={dateNumberInputElementStyle}
            style={style}
          />
        )}
      </Box>
    </ClickAwayListener>
  )
}

export default PinWheelNumberInput
