import React, { HTMLProps } from 'react'
import './style.scss'
import Color from 'color'

export interface ActivityProgressBarProps {
  barColor: string
  percentComplete: number
  barProps?: HTMLProps<HTMLDivElement>
  barWrapperProps?: HTMLProps<HTMLDivElement>
}

const ActivityProgressBar: React.FC<React.PropsWithChildren<ActivityProgressBarProps>> = (
  props
) => {
  const { barColor, percentComplete, barWrapperProps, barProps } = props

  const barColorRGB = Color(barColor).rgb()
  const barColorAlpha = barColorRGB.rgb().alpha()
  const backgroundColor = barColorRGB.alpha(barColorAlpha * 0.3)

  const wrapperStyles = {
    ...barWrapperProps?.style,
    backgroundColor: backgroundColor.toString(),
  }

  const barStyles = {
    ...barProps?.style,
    width: `${percentComplete}%`,
    backgroundColor: barColorRGB.toString(),
  }

  return (
    <div className="ProgressBar__Wrapper" {...barWrapperProps} style={wrapperStyles}>
      <div className="ProgressBar__Bar" {...barProps} style={barStyles}></div>
    </div>
  )
}

export default ActivityProgressBar
