export const DEFAULT_FONT_FAMILIES = [
  'Abril Fatface',
  'Archivo',
  'Comfortaa',
  'Courier Prime',
  'Dosis',
  'Lato',
  'Roboto',
  'Sacramento',
  'Times New Roman',
] as const

export type DefaultFontFamilies = (typeof DEFAULT_FONT_FAMILIES)[number]
export const DEFAULT_FONT = 'Roboto' satisfies DefaultFontFamilies

type SelfHostedDefaultFont = Exclude<DefaultFontFamilies, 'Times New Roman'>

/** Times New Roman is is not self hosted. We're assuming all OSs have it. */
export const selfHostedDefaultFonts = {
  'Abril Fatface': 'abrilFatface',
  Archivo: 'archivo',
  Comfortaa: 'comfortaa',
  'Courier Prime': 'courierPrime',
  Dosis: 'dosis',
  Lato: 'lato',
  Roboto: 'roboto',
  Sacramento: 'sacramento',
} as const satisfies Record<SelfHostedDefaultFont, string>
export type SelfHostedDefaultFontValue =
  (typeof selfHostedDefaultFonts)[keyof typeof selfHostedDefaultFonts]
