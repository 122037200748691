import { useEffect } from 'react'

import { type InputTypes } from '@top/ui/src/SceneModules/DateEntry/types'

/** @description Sets the input type based on the device type. */
export const useSetInputType = (
  isTouchDevice: boolean,
  setInputType: (inputType: InputTypes) => void
) => {
  useEffect(() => {
    if (isTouchDevice) {
      setInputType('PIN_WHEEL_TYPE_TOUCH')
      return
    }
    if (!isTouchDevice) {
      setInputType('PIN_WHEEL_TYPE_CLICK')
      return
    }
  }, [isTouchDevice, setInputType])
}
