import { Box } from '@mui/material'

type WrapperProps = {
  size: number
  children: React.ReactNode
}

export const FaceWrapper = (props: WrapperProps) => {
  const { size, children } = props

  return (
    <Box width={`${size}px`} height={`${size}px`}>
      <svg viewBox="0 0 56 56" fill="none">
        {children}
      </svg>
    </Box>
  )
}
