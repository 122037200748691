import AddPhotoAlternate from '@mui/icons-material/AddPhotoAlternate'
import Box from '@mui/material/Box'
import IconButton from '@mui/material/IconButton'

import COLORS from '@top/shared/src/style/colors'

type Props = {
  onClick: () => void
  iconWidth?: string | number
  containerWidth?: string | number
  containerHeight?: string | number
  containerBackground?: string
  iconBackground?: string
  aspectRatio?: number
  iconSize?: 'inherit' | 'large' | 'medium' | 'small'
}

export function ImagePlaceholder(props: Props) {
  const {
    onClick,
    containerWidth,
    containerHeight,
    iconWidth,
    iconSize,
    aspectRatio,
    containerBackground,
    iconBackground,
  } = props

  const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation()

    onClick()
  }

  return (
    <Box
      sx={{
        backgroundColor: containerBackground ?? COLORS.GRAY.ECECEC,
        aspectRatio: aspectRatio ?? 1,
      }}
      display="flex"
      alignItems="center"
      justifyContent="center"
      width={containerWidth ?? '100px'}
      height={containerHeight ?? '50px'}
    >
      <Box
        sx={{
          backgroundColor: iconBackground ?? COLORS.OVERLAY_DARK,
          aspectRatio: aspectRatio ?? 1,
        }}
        display="flex"
        alignItems="center"
        justifyContent="center"
        width={iconWidth ?? '50px'}
      >
        <IconButton
          onClick={handleClick}
          aria-label="Upload logo option"
          size="large"
          sx={{ color: COLORS.WHITE, height: 'fit-content' }}
        >
          <AddPhotoAlternate fontSize={iconSize ?? 'small'} />
        </IconButton>
      </Box>
    </Box>
  )
}
