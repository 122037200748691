import React, { useMemo, useRef } from 'react'

import { Box, List, ListItem, type Theme } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'

import COLORS from '@top/shared/src/style/colors'
import theme from '@top/shared/src/style/theme'
import { getDateNumberSelectStyle } from '@top/ui/src/SceneModules/DateEntry/helpers/getDateNumberSelectStyle'
import { useListSelectInputInteractions } from '@top/ui/src/SceneModules/DateEntry/hooks/useListSelectInputInteractions'
import { type ListSelectInputProps } from '@top/ui/src/SceneModules/DateEntry/types'

import { v4 as uuid } from 'uuid'

const PLACEHOLDER_ID = 'descendent_placeholder'

export const LIST_EL_HEIGHT_DEFAULT = 55

const useStyles = makeStyles((theme: Theme) => {
  return {
    inputWrapper: {
      border: `2px solid ${COLORS.DIVIDER_LIGHT}`,
      borderRadius: '4px',
      outline: 'none',
      height: '55px',
      [theme.breakpoints.down('xs')]: {
        height: '45px',
      },
    },
    listHolderOuter: {
      width: '65px',
      height: '55px',
      [theme.breakpoints.down('xs')]: {
        width: '45px',
        height: '45px',
      },
    },
    listHolderInner: {
      overflowY: 'scroll',
      overflowX: 'hidden',
      '&::-webkit-scrollbar': {
        display: 'none',
      },
      [theme.breakpoints.down('xs')]: {
        width: '55px',
        height: '45px',
      },
    },
    list: {
      listStyle: 'none',
      padding: 0,
      margin: 0,
      height: '100%',
      '&:focus': {
        outline: 'none',
      },
    },
    listItem: {
      height: '55px',
      backgroundColor: 'transparent',
      display: 'flex',
      justifyContent: 'center',
      fontWeight: 500,
      width: '65px',
      [theme.breakpoints.down('xs')]: {
        width: '45px',
        height: '45px',
      },
    },
    placeholder: {
      color: COLORS.DISABLED_DARK,
      fontWeight: 500,
    },
  }
})

const ListSelectInput = (props: ListSelectInputProps) => {
  const {
    selectOptions,
    clickCount,
    id,
    style,
    pinWheelType,
    onInputTypeChange,
    onScrollChange,
    setClickCount,
    min,
    max,
    inputValue,
    placeholder,
    disabled,
    showError = false,
    isFocusedRef,
  } = props

  const classes = useStyles()

  const isInvalid = !!(!inputValue || (inputValue && (inputValue > max || inputValue < min)))

  const listRef = useRef<HTMLLIElement>(null)
  const ulRef = useRef<HTMLUListElement>(null)
  const containerRef = useRef<HTMLDivElement>(null)

  const listElementHeight = listRef?.current?.offsetHeight ?? LIST_EL_HEIGHT_DEFAULT

  const {
    handleScroll,
    handleMouseDown,
    handleFocus,
    handleBlur,
    handleTouchEnd,
    handleTouchStart,
    handleWheelCapture,
    handleClick,
    placeholderOpacity,
    isInitialFocus,
  } = useListSelectInputInteractions(
    inputValue,
    listElementHeight,
    isInvalid,
    containerRef,
    onScrollChange,
    listElementHeight,
    clickCount,
    isFocusedRef,
    setClickCount,
    onInputTypeChange
  )

  const formattedOptions = useMemo(() => {
    return selectOptions.map((val: number) => ({ value: val, optionId: uuid() }))
  }, [selectOptions])

  const activatedDescendentId = useMemo(() => {
    const activeOption = formattedOptions.find(
      (opt: { value: number; optionId: string }) => opt.value === inputValue
    )

    return activeOption ? activeOption.optionId : PLACEHOLDER_ID
  }, [formattedOptions, inputValue])

  const dateNumberSelectStyle = getDateNumberSelectStyle(style)

  const isPinWheelScroll = pinWheelType === 'PIN_WHEEL_SCROLL'

  return (
    <Box
      className={classes.inputWrapper}
      sx={{
        ...(isPinWheelScroll && {
          transform: 'translateY(-55px)',
        }),
        ...(showError &&
          isInvalid && {
            borderColor: `${COLORS.ERROR} !important`,
          }),
        ...(isFocusedRef.current && {
          borderColor: `${COLORS.TEXT_DARK} !important`,
        }),
      }}
    >
      <Box
        className={classes.listHolderOuter}
        sx={{
          ...(isPinWheelScroll && {
            transform: 'translateY(-55px)',
          }),
        }}
      >
        <Box
          data-testid={`test__date-list-select-input_${placeholder}`}
          className={classes.listHolderInner}
          ref={containerRef}
          display="inline-block"
          width="75px"
          height="55px"
          overflow="auto"
          sx={{
            ...(isPinWheelScroll && {
              height: '150px',
              overflow: 'auto',
              [theme.breakpoints.down('xs')]: {
                height: '130px',
              },
            }),
          }}
          {...(!disabled && {
            onScroll: handleScroll,
            onTouchEnd: handleTouchEnd,
            onTouchStart: handleTouchStart,
            onWheelCapture: handleWheelCapture,
            onClick: handleClick,
            onMouseDown: handleMouseDown,
          })}
        >
          <List
            ref={ulRef}
            id={`pin_wheel_input_${id}`}
            tabIndex={0}
            role="listbox"
            aria-labelledby={`pin_wheel_label_${id}`}
            aria-activedescendant={activatedDescendentId}
            sx={{
              ...dateNumberSelectStyle,
              ...(isPinWheelScroll && {
                marginTop: '55px',
                [theme.breakpoints.down('xs')]: {
                  height: '45px',
                },
              }),
            }}
            className={classes.list}
            {...(!disabled && {
              onFocus: handleFocus,
              onBlur: handleBlur,
            })}
          >
            {!isFocusedRef && !disabled && !isInitialFocus ? (
              <ListItem role="option" className={classes.listItem}>
                <span>{inputValue}</span>
              </ListItem>
            ) : (
              <>
                <ListItem
                  ref={listRef}
                  role="option"
                  id={PLACEHOLDER_ID}
                  className={classes.listItem}
                  sx={{ opacity: placeholderOpacity, ...dateNumberSelectStyle }}
                >
                  <span style={dateNumberSelectStyle} className={classes.placeholder}>
                    {placeholder}
                  </span>
                </ListItem>
                {formattedOptions.map((option) => (
                  <ListItem
                    key={option.optionId}
                    id={option.optionId}
                    role="option"
                    className={classes.listItem}
                  >
                    <span>{option.value}</span>
                  </ListItem>
                ))}
              </>
            )}
          </List>
        </Box>
      </Box>
    </Box>
  )
}

export default ListSelectInput
