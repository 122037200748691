import LanguageIcon from '@mui/icons-material/Language'
import Box from '@mui/material/Box'
import FormControl from '@mui/material/FormControl'
import MenuItem from '@mui/material/MenuItem'
import Select, { type SelectChangeEvent } from '@mui/material/Select'
import { type Theme } from '@mui/material/styles'
import Typography from '@mui/material/Typography'
import { createStyles, makeStyles } from '@mui/styles'

import {
  isSupportedLocale,
  type Locale,
  localeToTranslatedLanguageName,
} from '@top/shared/src/Languages/types'
import COLORS from '@top/shared/src/style/colors'
import { type BackgroundType } from '@top/shared/src/style/types'

import color from 'color'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    select: {
      maxWidth: 'fit-content',
      height: '32px',
      paddingLeft: '6px',
      boxShadow: 'none',
      [theme.breakpoints.down('sm')]: {
        height: '28px',
      },
      '& .MuiOutlinedInput-notchedOutline': { border: 0 },
      '& .MuiSvgIcon-root': {
        fill: COLORS.WHITE,
        [theme.breakpoints.down('sm')]: {
          fontSize: '1.25rem',
        },
      },
    },
    paper: {
      maxHeight: '250px',
      '& .MuiList-padding': {
        padding: 0,
      },
    },
    list: {
      width: 184,
      [theme.breakpoints.down('sm')]: {
        width: 160,
      },
    },
    // We need to use !important here due to the inability to override the MUI style.
    selectText: {
      [theme.breakpoints.down('sm')]: {
        fontSize: '0.75rem !important',
        paddingTop: '4px !important',
        paddingRight: '0px !important',
      },
    },
  })
)

export type LanguageSelectorProps = {
  locales: Locale[]
  selectedLocale: Locale
  onChange?: (locale: Locale) => void
  backgroundType: BackgroundType
  backgroundColor: string
  disabled?: boolean
  parentTestId?: string
}

const RespondentLanguageSelector = (props: LanguageSelectorProps) => {
  const {
    locales,
    selectedLocale,
    onChange,
    backgroundType,
    backgroundColor,
    disabled = false,
    parentTestId,
  } = props

  const textColor = color(COLORS.WHITE)

  const testId = parentTestId ? `${parentTestId}-language-selector` : undefined

  const classes = useStyles()

  /** Determine if the background color has poor contrast compared to text color. */
  const backgroundHasPoorContrast =
    backgroundType === 'color' ? color(backgroundColor).contrast(textColor) < 2 : true

  const handleLanguageChange = (event: SelectChangeEvent<Locale>) => {
    const newLocale = event.target.value

    if (!isSupportedLocale(newLocale)) {
      throw new Error(`Unsupported locale: ${newLocale}`)
    }

    if (onChange) {
      onChange(newLocale)
    }
  }

  return (
    <Box padding="8px 0 0" position="sticky" data-testid={testId}>
      <FormControl variant="outlined" fullWidth>
        <Select
          aria-label="Select language of choice"
          value={selectedLocale}
          disabled={disabled}
          className={classes.select}
          sx={{
            backgroundColor: backgroundHasPoorContrast ? COLORS.OVERLAY_DARK : COLORS.OVERLAY_LIGHT,
          }}
          onChange={handleLanguageChange}
          startAdornment={<LanguageIcon sx={{ fontSize: { xs: '18px', sm: '24px' } }} />}
          renderValue={(locale) => (
            <Typography
              color={COLORS.WHITE}
              padding="3px 6px 0px"
              fontWeight={700}
              fontSize="0.875rem"
              className={classes.selectText}
            >
              {localeToTranslatedLanguageName[locale]}
            </Typography>
          )}
          MenuProps={{
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'left',
            },
            transformOrigin: {
              vertical: -8,
              horizontal: 0,
            },
            classes: { paper: classes.paper, list: classes.list },
          }}
        >
          {locales.map((locale) => {
            return (
              <MenuItem
                key={locale}
                value={locale}
                sx={{
                  minHeight: '36px',
                }}
              >
                <Typography fontWeight={500} paddingLeft="12px" fontSize=".875rem">
                  {localeToTranslatedLanguageName[locale]}
                </Typography>
              </MenuItem>
            )
          })}
        </Select>
      </FormControl>
    </Box>
  )
}

export default RespondentLanguageSelector
