import { useState, useEffect, RefObject } from 'react'

const useContainerWidth = (ref: RefObject<HTMLDivElement>): number => {
  const [width, setWidth] = useState(0)
  useEffect(() => {
    const handleResize = () => {
      if (ref.current) {
        setWidth(ref.current.offsetWidth)
      }
    }
    handleResize()
    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [ref])
  return width
}

export default useContainerWidth
