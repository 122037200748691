import color from 'color'

export const getFillColor = (
  value: number,
  selectedValue: number,
  primaryColor: string,
  isFaceItem: boolean,
  hoveredValue: number
) => {
  const hasSelectedValue = selectedValue > -1
  const hasHoveredValue = hoveredValue > -1

  const inactiveColor = color(primaryColor).mix(color('white'), 0.4).toString()

  if (isFaceItem) {
    // We want to fill the face item with the primary color if it is selected or hovered.
    if (
      (hasSelectedValue && selectedValue === value) ||
      (!hasSelectedValue && hasHoveredValue && hoveredValue === value)
    ) {
      return primaryColor
    }
    return inactiveColor
  }

  // If there is no selected value, then return primary color onHover if value is less than or equal to the hovered value.
  if (!hasSelectedValue && hasHoveredValue && hoveredValue >= value) {
    return primaryColor
  }

  // If the selected value is greater than or equal to the current value, then the current value should be filled
  // with the primary color
  if (!isFaceItem && hasSelectedValue && selectedValue >= value) {
    return primaryColor
  }

  return inactiveColor
}
