import {
  FontSizes,
  TextAlign,
  SceneStyle,
  IDateEntryElementStyle,
  SelectionBlockStyle,
} from './Style'
import {
  IItems,
  ButtonType,
  IStyle,
  IImageStyle,
  IFlexibleStyle,
  IFlexibleImageStyle,
} from './Items'
import { enumKeys } from '../utils/helpers'
import { DateInputFormat } from '../components/DateInput'
import { RedirectLinks, Translations } from './Languages'

export enum BuilderSceneTypes {
  BLANK = 'BLANK',
  GENERIC = 'GENERIC',
  TEXT_ENTRY = 'TEXT_ENTRY',
  NUMERIC_SCALE = 'NUMERIC_SCALE',
  REACTION_SCALE = 'REACTION_SCALE',
  EMAIL_CTA = 'EMAIL_CALL_TO_ACTION',
  CALL_TO_ACTION = 'CALL_TO_ACTION',
  CHOICE = 'CHOICE',
  NPS = 'NPS',
  DATE_ENTRY = 'DATE_ENTRY',
}

export interface IScene {
  id: string
  uuid: string
  style: SceneStyle
  template_type: TemplateType
  transition_type: TransitionType
  type: SceneType
  items: IItems
  selections?: Array<ISelection | INumericSelection | IFlexibleSelection>
  action_type: SceneAction
  action_value: string
  redirect_links: RedirectLinks
  elements: IElements
  percentage_completion: number
  etc: {
    text?: string
    name: string
    position: number
    terms_url?: string
    is_optional?: boolean
    image_orientation?: Orientation
    shareback_results?: boolean
    scale_range?: ScaleType
    range_groupings?: IRange[] | IDistRange[]
    branches?: Array<IDistBranch>
    minimum_selection?: number
    maximum_selection?: number
    exclusive_selections?: Array<string>
    show_skip_button: boolean
    date_format?: DateInputFormat
    show_prompt_text?: boolean
    ai_assistant_enabled?: boolean
    auto_advance?: boolean
  }
  has_sensitive_data: boolean
}

export enum SceneElementTypeEnum {
  ELEMENT_LOGO = 'ELEMENT_LOGO',
  ELEMENT_TEXTBOX = 'ELEMENT_TEXTBOX',
  ELEMENT_IMAGE = 'ELEMENT_IMAGE',
  ELEMENT_IMAGE_AND_TEXT = 'ELEMENT_IMAGE_AND_TEXT',
  ELEMENT_TEXT = 'ELEMENT_TEXT',
  ELEMENT_QUESTION = 'ELEMENT_QUESTION',
  TEXT_QUESTION_TEXTBOX = 'TEXT_QUESTION_TEXTBOX',
  IMAGE_QUESTION_TEXTBOX = 'IMAGE_QUESTION_TEXTBOX',
  ELEMENT_DATE = 'ELEMENT_DATE',
}

export type SceneElementTypes = keyof typeof SceneElementTypeEnum
export interface ISceneElement<ElementTypes> extends IElement {
  element_type: SceneElementTypeEnum
  text?: string
  translations?: Translations
  style?: ITextStyle | { backgroundImage: string } | IDateEntryElementStyle
}
export type ISceneElementType = ISceneElement<SceneElementTypes>
export interface IElements {
  element_list: ISceneElement<SceneElementTypes>[]
  selectionBlock?: ISelectionBlock
}

export interface ISelectionBlock {
  position: number
  style: SelectionBlockStyle
  promptText?: PromptText
}

export type TransitionType = 'SCROLL' | 'SLIDE'
export interface ISelection {
  etc: {
    text?: string
    position: number
    image_alt_text?: string
    image_url?: string
    value?: number
  }
  id: string
  style: Partial<IStyle>
}

export interface IFlexibleSelection {
  etc: {
    text?: string
    translations?: Translations
    position: number
    image_alt_text?: string
    image_url?: string
    value?: number
  }
  id: string
  style: Partial<IFlexibleStyle>
}

export interface INumericSelection {
  id: string
  style: Pick<IStyle, 'primaryColor' | 'secondaryColor'> & { shape: NumericScaleIcon }
  etc: {
    value: number
  }
}

export interface IImageSelection {
  position: number
  style: IImageStyle
  image_alt_text: string | undefined
  image_url: string
}

export interface IFlexibleImageSelection {
  text: string
  translations: Translations
  position: number
  style: IFlexibleImageStyle
  image_alt_text: string | undefined
  image_url: string
  id: string
}

export interface IImageSelections {
  isCustomLogic: boolean
  items: {
    [selectionId: string]: IImageSelection
  }
}

export enum SceneType {
  Generic = 'SCENE_GENERIC',
  Question = 'SCENE_QUESTION',
  CTA = 'SCENE_CTA',
  Numeric = 'SCENE_NUMERIC',
  SceneBuilder = 'SCENE_BUILDER',
}

export enum Orientation {
  PORTRAIT = 'IMAGE_PORTRAIT',
  LANDSCAPE = 'IMAGE_LANDSCAPE',
  GRID = 'IMAGE_GRID',
}

export enum FlexibleOrientation {
  PORTRAIT = 'IMAGE_PORTRAIT',
  LANDSCAPE = 'IMAGE_LANDSCAPE',
  SQUARE = 'IMAGE_SQUARE',
}

export enum TemplateType {
  GENERIC_01 = 'GENERIC_01',
  QUESTION_01 = 'QUESTION_01',
  QUESTION_02 = 'QUESTION_02',
  CTA_01 = 'CTA_01',
  CTA_02 = 'CTA_02',
  QUESTION_TEXT_ENTRY = 'QUESTION_TEXT_ENTRY',
  QUESTION_TEXT_MULTI = 'QUESTION_TEXT_MULTI',
  NUMERIC_RANGE = 'NUMERIC_RANGE',
  NUMERIC_NPS = 'NUMERIC_NPS',
  QUESTION_IMAGE_MULTI = 'QUESTION_IMAGE_MULTI',
  QUESTION_FLEXIBLE = 'QUESTION_FLEXIBLE',
  QUESTION_DATE_ENTRY = 'QUESTION_DATE_ENTRY',
}
export const TemplateTypeKeys = enumKeys(TemplateType)
export const SceneTypeKeys = enumKeys(SceneType)
export const OrientationKeys = enumKeys(Orientation)

export const SceneToTemplate = {
  [SceneType.Generic]: [TemplateType.GENERIC_01],
  [SceneType.Question]: [
    TemplateType.QUESTION_01,
    TemplateType.QUESTION_02,
    TemplateType.QUESTION_TEXT_ENTRY,
    TemplateType.QUESTION_TEXT_MULTI,
    TemplateType.QUESTION_IMAGE_MULTI,
    TemplateType.QUESTION_FLEXIBLE,
  ],
  [SceneType.CTA]: [TemplateType.CTA_01, TemplateType.CTA_02],
  [SceneType.Numeric]: [TemplateType.NUMERIC_NPS, TemplateType.NUMERIC_RANGE],
  [SceneType.SceneBuilder]: [
    TemplateType.QUESTION_FLEXIBLE,
    TemplateType.GENERIC_01,
    TemplateType.QUESTION_DATE_ENTRY,
  ],
}

export interface IImage {
  backgroundImage: string
  backgroundColor: string
  backgroundType: 'image' | 'color'
  zoom: string
  cropHeight: string
  cropWidth: string
  cropX: string
  cropY: string
  blur: string
  saturation: string
  brightness: string
}

export interface IRange {
  start: number
  end: number
  actionType?: SceneAction
  actionValue?: string
  redirectLinks?: RedirectLinks
}

export interface IDistRange {
  start: number
  end: number
  action_type: SceneAction
  action_value?: string
  redirect_links?: RedirectLinks
}

export enum ScaleType {
  OneToFive = 'RANGE_ONE_TO_FIVE',
  ZeroToTen = 'RANGE_ZERO_TO_TEN',
  OneToTen = 'RANGE_ONE_TO_TEN',
}

export enum NumericScaleIcon {
  Circle = 'NUMERIC_CIRCLE',
  Star = 'NUMERIC_STAR',
  Face = 'NUMERIC_FACE',
  FaceColored = 'NUMERIC_FACE_COLOR_GRADIENT',
}

export interface IButton {
  style: {
    color: string
    fontFamily: string
    fontSize: FontSizes
    bold: boolean
    backgroundColor: string
  }
  text: string
  redirect_url: string
  button_type: ButtonType
}

export interface ITitle {
  style: ITextStyle
  text: string
}

export interface IElement {
  position: number
  uuid: string
}
export interface ITextbox extends IElement {
  style: ITextStyle
  text: string
}

export interface ISubtitle {
  style: ITextStyle
  text: string
}

export type PromptText = {
  style: ITextStyle
  text: string
  translations: Translations
}

export interface ITermsText {
  style: ITextStyle
  text: string
  translations: Translations
}

export type HelperText = {
  text: string
  translations: Translations
}

export interface ITextStyle {
  color: string
  fontFamily: string
  fontSize: FontSizes
  bold: boolean
  italic: boolean
  underline: boolean
  backgroundColor: string
  textAlign: TextAlign
}

export interface IDateDisplayStyle extends IDateInputStyle {
  fontSize: FontSizes
  textAlign: TextAlign
  backgroundColor: string
}

export interface IDateInputStyle {
  color: string
  fontFamily: string
  bold: boolean
  italic: boolean
  underline: boolean
}

export interface IText {
  style: ITextStyle
  etc: {
    text: string
  }
}

export interface IBranch {
  requiredSelections: { [key: string]: {} }
  actionType: SceneAction
  actionValue?: string
  redirectLinks?: RedirectLinks
  uuid: string
}

export enum SceneAction {
  NEXT_SCENE = 'ACTION_NEXT_SCENE',
  END_ACTIVITY = 'ACTION_END_ACTIVITY',
  REDIRECT = 'ACTION_REDIRECT_URL',
  SCREEN_BRANCH = 'ACTION_SCREEN_BRANCH', // only used on dash
}

export const SceneActionKeys = enumKeys(SceneAction)
export const NUM_SCALE_ITEMS: { [type in ScaleType]: number } = {
  [ScaleType.OneToTen]: 10,
  [ScaleType.OneToFive]: 5,
  [ScaleType.ZeroToTen]: 11,
}

export const RANGES: { [type in ScaleType]: { start: number; end: number } } = {
  [ScaleType.OneToFive]: { start: 1, end: 5 },
  [ScaleType.OneToTen]: { start: 1, end: 10 },
  [ScaleType.ZeroToTen]: { start: 0, end: 10 },
}

export interface IDistBranch {
  required_selections: { [key: string]: {} }
  action_type: SceneAction
  action_value?: string
  redirect_links?: RedirectLinks
}

export interface INumericScaleSelections {
  isCustomLogic: boolean
  scaleRange: ScaleType
  logicRanges: Array<IRange>
  items: IItems
  style: {
    primaryColor: string
    secondaryColor: string
    shape: NumericScaleIcon
  }
}
