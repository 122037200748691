import React from 'react'

import { Box, Typography } from '@mui/material'

import { getTextStyle } from '@top/shared/src/style/helpers'
import { type DateInputFormat, type TextStyle } from '@top/shared/src/style/types'
import { type DateObject } from '@top/ui/src/SceneModules/DateEntry/types'

const DateDisplayPlaceHolder = {
  DATE_FORMAT_DAY_MONTH_YEAR: 'DD / MM / YYYY',
  DATE_FORMAT_MONTH_DAY_YEAR: 'MM / DD / YYYY',
  DATE_FORMAT_MONTH_YEAR: 'MM / YYYY',
  DATE_FORMAT_YEAR: 'YYYY',
} satisfies { [key in DateInputFormat]: string }

const getNumberWithPrefixZero = (number: number) => {
  return number < 10 ? `0${number}` : `${number}`
}

type Props = {
  dateInputStyle: TextStyle
  dateInputFormat: DateInputFormat
  isHidden: boolean
  date?: DateObject
  widgetWidth?: number
  parentTestId?: string
  onClick?: () => void
}

const DateInputDisplay = (props: Props) => {
  const { dateInputStyle, dateInputFormat, date, isHidden, parentTestId, onClick, widgetWidth } =
    props

  const testId = parentTestId ? `${parentTestId}-date-input-display` : undefined

  const dateDisplayStyles = {
    lineHeight: `${dateInputStyle.fontSize}px`,
    ...getTextStyle(dateInputStyle, widgetWidth),
  }

  const dd = date?.day && getNumberWithPrefixZero(date.day)
  const mm = date?.month && getNumberWithPrefixZero(date.month)
  const yyyy = date?.year && getNumberWithPrefixZero(date.year)

  const displayTextArray = {
    DATE_FORMAT_DAY_MONTH_YEAR: [dd, mm, yyyy],
    DATE_FORMAT_MONTH_DAY_YEAR: [mm, dd, yyyy],
    DATE_FORMAT_MONTH_YEAR: [mm, yyyy],
    DATE_FORMAT_YEAR: [yyyy],
  }

  const placeholderText = DateDisplayPlaceHolder[dateInputFormat]

  const displayText =
    date && !isHidden ? displayTextArray[dateInputFormat].join(' / ') : placeholderText

  return (
    <Box
      margin="5px"
      onClick={(e) => {
        e.stopPropagation()

        if (onClick) {
          onClick()
        }
      }}
    >
      <Typography
        sx={{ ...dateDisplayStyles, wordBreak: 'break-word' }}
        boxSizing="border-box"
        width="100%"
        borderRadius="5px"
        margin={0}
        padding="1rem"
        visibility={isHidden ? 'hidden' : 'visible'}
        data-testid={testId}
      >
        {displayText}
      </Typography>
    </Box>
  )
}

export default DateInputDisplay
