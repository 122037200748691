import React, { useRef } from 'react'

import { Box, Typography } from '@mui/material'

import useContainerWidth from '@top/shared/src/hooks/useContainerWidth'
import COLORS from '@top/shared/src/style/colors'
import { type DateInputFormat, type DateInputStyle } from '@top/shared/src/style/types'
import {
  MAX_MONTH,
  MAX_YEAR,
  MIN_DAY_MONTH,
  MIN_YEAR,
} from '@top/ui/src/SceneModules/DateEntry/constants'
import { getMaxDate } from '@top/ui/src/SceneModules/DateEntry/helpers/getMaxDate'
import { type DateInputValues, type DateObject } from '@top/ui/src/SceneModules/DateEntry/types'

import PinWheelNumberInput from '../PinWheelNumberInput'

const BREAKPOINT_XXS = 300
const BREAKPOINT_XXXS = 250

export const requiredDateInputValues: {
  [key in DateInputFormat]: DateInputValues[]
} = {
  DATE_FORMAT_YEAR: ['YEAR'],
  DATE_FORMAT_DAY_MONTH_YEAR: ['DAY', 'MONTH', 'YEAR'],
  DATE_FORMAT_MONTH_DAY_YEAR: ['MONTH', 'DAY', 'YEAR'],
  DATE_FORMAT_MONTH_YEAR: ['MONTH', 'YEAR'],
}

type Props = {
  dateInputStyle: DateInputStyle
  dateInputFormat: DateInputFormat
  showError?: boolean
  error?: string
  disabled?: boolean
  isBuilder?: boolean
  onChange?: (date: DateObject) => void
  date?: DateObject
  parentTestId?: string
  onClick?: () => void
}

const DateInput = (props: Props) => {
  const {
    dateInputFormat,
    dateInputStyle,
    isBuilder = false,
    onChange,
    error = '',
    showError = false,
    disabled = false,
    date,
    parentTestId,
    onClick,
  } = props

  const testId = parentTestId ? `${parentTestId}-date-input` : undefined

  const dayInputValue = date?.day
  const monthInputValue = date?.month
  const yearInputValue = date?.year

  const divRef = useRef<HTMLDivElement>(null)

  const containerWidth = useContainerWidth(divRef)

  const isXXS = containerWidth < BREAKPOINT_XXS
  const isXXXS = containerWidth < BREAKPOINT_XXXS

  const showErrorMessage = !!(showError && error)

  const handleInputChange = (inputType: DateInputValues, value: number | undefined) => {
    const day = inputType === 'DAY' ? value : dayInputValue
    const month = inputType === 'MONTH' ? value : monthInputValue
    const year = inputType === 'YEAR' ? value : yearInputValue

    const dateObject = {
      day,
      month,
      year,
    } satisfies DateObject

    if (onChange) {
      onChange(dateObject)
    }
  }

  const showDayInput =
    ['DATE_FORMAT_DAY_MONTH_YEAR', 'DATE_FORMAT_MONTH_DAY_YEAR'].indexOf(dateInputFormat) !== -1
  const showMonthInput = dateInputFormat !== 'DATE_FORMAT_YEAR'
  const isMonthFirst = dateInputFormat === 'DATE_FORMAT_MONTH_DAY_YEAR'

  const inputPropsDay = {
    placeholder: 'DD',
    min: MIN_DAY_MONTH,
    max: getMaxDate(monthInputValue, yearInputValue),
    inputValue: dayInputValue,
  }

  const inputPropsMonth = {
    placeholder: 'MM',
    min: MIN_DAY_MONTH,
    max: MAX_MONTH,
    inputValue: monthInputValue,
  }

  const isYearFormat = dateInputFormat === 'DATE_FORMAT_YEAR'
  const isMonthYearFormat = dateInputFormat === 'DATE_FORMAT_MONTH_YEAR'

  const dateNumberInputElementStyleMM = { minWidth: 35 }
  const dateNumberInputElementStyleDD = { paddingLeft: 5 }

  const yearInputWidth = isXXS ? 60 : 84

  return (
    <Box
      ref={divRef}
      width="100%"
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      data-testid={testId}
      onClick={(e) => {
        e.stopPropagation()

        if (onClick) {
          onClick()
        }
      }}
    >
      <Box
        display="flex"
        justifyContent="space-between"
        padding="5px 20px"
        width="100%"
        margin="5px"
        alignItems="center"
        flexWrap="wrap"
        minHeight="100px"
        maxWidth="400px"
        marginBottom="20px"
        {...(isXXXS && { flexDirection: 'column' })}
        {...(isYearFormat && { justifyContent: 'center' })}
        {...(isMonthYearFormat && { maxWidth: '230px' })}
        {...(showErrorMessage && { marginBottom: '0px' })}
      >
        {showDayInput && (
          <PinWheelNumberInput
            style={dateInputStyle}
            dateNumberInputElementStyle={
              isMonthFirst ? dateNumberInputElementStyleMM : dateNumberInputElementStyleDD
            }
            showError={showError}
            onInputChange={
              isMonthFirst
                ? (value) => handleInputChange('MONTH', value)
                : (value) => handleInputChange('DAY', value)
            }
            disabled={disabled || isBuilder}
            {...(isMonthFirst ? inputPropsMonth : inputPropsDay)}
            {...(isBuilder && { staticInputType: 'NUMBER_INPUT_TYPE' })}
          />
        )}
        {showMonthInput && (
          <PinWheelNumberInput
            style={dateInputStyle}
            dateNumberInputElementStyle={
              isMonthFirst ? dateNumberInputElementStyleDD : dateNumberInputElementStyleMM
            }
            showError={showError}
            disabled={disabled || isBuilder}
            onInputChange={
              isMonthFirst
                ? (value) => handleInputChange('DAY', value)
                : (value) => handleInputChange('MONTH', value)
            }
            {...(isMonthFirst ? inputPropsDay : inputPropsMonth)}
            {...(isBuilder && { staticInputType: 'NUMBER_INPUT_TYPE' })}
          />
        )}
        <PinWheelNumberInput
          placeholder="YYYY"
          min={MIN_YEAR}
          max={MAX_YEAR}
          inputValue={yearInputValue}
          disabled={disabled || isBuilder}
          showError={showError}
          onInputChange={(value) => handleInputChange('YEAR', value)}
          style={dateInputStyle}
          staticInputType="NUMBER_INPUT_TYPE"
          width={yearInputWidth}
        />
      </Box>
      {showErrorMessage && (
        <Typography
          data-testid="test__date-input_error"
          color={COLORS.ERROR}
          textAlign="center"
          margin={0}
          height="20px"
          sx={{ transform: 'translateY(-1rem)' }}
        >
          {error}
        </Typography>
      )}
    </Box>
  )
}

export default DateInput
