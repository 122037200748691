import { useEffect } from 'react'

import { getConfigs } from '@top/shared/src/configs'

import * as Sentry from '@sentry/react'

type UseSentryTagsOptions = {
  appName: 'Distributor' | 'Dashboard'
  user?: { org_id?: string; user_id?: string }
}

const { POD_NAME } = getConfigs()

export function useSentryTags(options: UseSentryTagsOptions) {
  const { appName, user } = options

  useEffect(() => {
    if (!!user) {
      Sentry.setUser({ org_id: user.org_id, user_id: user.user_id })
    }
    Sentry.setTag('App_Name', appName)
    Sentry.setTag('Pod_Name', POD_NAME || 'Unknown')
    Sentry.setTag('Version', process.env.REACT_APP_VERSION ?? 'Unknown')
  }, [appName, user])
}
