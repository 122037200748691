import { Box } from '@mui/material'

import { type IconProps, SIZES } from '@top/ui/src/Icons/types'

const CheckCircleOutline = (props: IconProps) => {
  const { fontSize = 'default', style } = props

  return (
    <Box
      sx={{
        fontSize: SIZES[fontSize],
        fill: 'currentcolor',
        width: '1em',
        height: '1em',
        display: 'inline-block',
        transition: 'fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
        flexShrink: 0,
      }}
    >
      <svg
        style={style}
        focusable="false"
        viewBox="0 0 24 24"
        aria-hidden="true"
        aria-label="Activity is complete"
      >
        <path d="M16.59 7.58L10 14.17l-3.59-3.58L5 12l5 5 8-8zM12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8z"></path>
      </svg>
    </Box>
  )
}
export default CheckCircleOutline
