import { type RefObject, useLayoutEffect, useState } from 'react'

type DimensionObject = {
  width: number
  height: number
  top: number
  left: number
  right: number
  bottom: number
}

function getDimensionObject(node: HTMLElement) {
  const rect = node.getBoundingClientRect()

  return {
    width: rect.width,
    height: rect.height,
    top: rect.x,
    left: rect.y,
    right: rect.right,
    bottom: rect.bottom,
  }
}

export function useDimensions(nodeRef: RefObject<HTMLElement>) {
  const [dimensions, setDimensions] = useState<DimensionObject>()

  useLayoutEffect(() => {
    if (!!nodeRef.current) {
      const measure = () => {
        if (!nodeRef.current) {
          return
        }
        const dimensions = getDimensionObject(nodeRef.current)
        window.requestAnimationFrame(() => setDimensions(dimensions))
      }
      measure()

      window.addEventListener('resize', measure)
      window.addEventListener('scroll', measure)

      return () => {
        window.removeEventListener('resize', measure)
        window.removeEventListener('scroll', measure)
      }
    }
    return
  }, [nodeRef])

  return dimensions
}
