import { SVGProps } from 'react'

const TouchpointProductLogo = (props: SVGProps<SVGSVGElement>) => {
  const { style } = props
  return (
    <svg
      width="375"
      height="86"
      viewBox="0 0 375 86"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={style}
    >
      <path
        d="M104.413 54.0423V31.9092H94.6422V24.3042H123.693V31.9092H113.967V54.0423H104.413Z"
        fill="white"
      />
      <path
        d="M135.799 54.5595C133.207 54.5595 130.951 54.1162 129.035 53.2409C127.12 52.3599 125.625 51.0355 124.562 49.2621C123.494 47.4888 122.965 45.2777 122.965 42.6234C122.965 39.969 123.499 37.7238 124.562 35.9675C125.625 34.2112 127.12 32.8925 129.035 32.0115C130.951 31.1362 133.207 30.6929 135.799 30.6929C138.391 30.6929 140.653 31.1305 142.586 32.0115C144.518 32.8925 146.013 34.2112 147.082 35.9675C148.145 37.7238 148.679 39.9462 148.679 42.6234C148.679 45.3005 148.145 47.4888 147.082 49.2621C146.013 51.0355 144.518 52.3599 142.586 53.2409C140.653 54.1162 138.391 54.5595 135.799 54.5595ZM135.799 48.7222C136.811 48.7222 137.63 48.5289 138.266 48.1367C138.897 47.7502 139.363 47.1932 139.647 46.4713C139.937 45.7495 140.079 44.8855 140.079 43.8795V41.4127C140.079 40.4066 139.931 39.5313 139.647 38.7981C139.357 38.0649 138.897 37.5022 138.266 37.11C137.63 36.7235 136.811 36.5245 135.799 36.5245C134.788 36.5245 133.975 36.7178 133.355 37.11C132.736 37.4965 132.281 38.0592 131.997 38.7981C131.707 39.5313 131.565 40.4066 131.565 41.4127V43.8795C131.565 44.8912 131.707 45.7552 131.997 46.4713C132.287 47.1932 132.736 47.7445 133.355 48.1367C133.975 48.5232 134.788 48.7222 135.799 48.7222Z"
        fill="white"
      />
      <path
        d="M160.695 54.5596C157.984 54.5596 155.983 53.8093 154.687 52.3087C153.391 50.8082 152.743 48.7052 152.743 45.9996V31.2158H161.348V44.9595C161.348 45.4199 161.411 45.8518 161.542 46.2554C161.672 46.6589 161.849 47.0057 162.082 47.2955C162.315 47.5854 162.616 47.8071 162.991 47.9662C163.366 48.1254 163.781 48.205 164.242 48.205C164.963 48.205 165.589 48.0401 166.123 47.7105C166.657 47.3808 167.072 46.9431 167.373 46.3918C167.675 45.8462 167.828 45.255 167.828 44.6184V31.2158H176.428V54.0423H169.38L168.948 50.5865H168.647C168.101 51.4221 167.402 52.1439 166.549 52.7464C165.697 53.3546 164.77 53.8036 163.758 54.1105C162.747 54.4175 161.724 54.5652 160.689 54.5652L160.695 54.5596Z"
        fill="white"
      />
      <path
        d="M193.462 54.5596C190.871 54.5596 188.614 54.1162 186.699 53.2409C184.783 52.3599 183.288 51.0356 182.225 49.2622C181.157 47.4888 180.628 45.2778 180.628 42.6234C180.628 39.969 181.163 37.7182 182.225 35.9448C183.294 34.1715 184.783 32.8471 186.699 31.9661C188.614 31.0851 190.871 30.6475 193.462 30.6475C195.19 30.6475 196.822 30.835 198.345 31.2102C199.874 31.5853 201.21 32.1594 202.363 32.9381C203.517 33.7168 204.415 34.7228 205.063 35.9619C205.711 37.201 206.035 38.6845 206.035 40.4124H197.691C197.691 39.52 197.526 38.7868 197.197 38.207C196.867 37.633 196.384 37.2067 195.747 36.9338C195.116 36.661 194.349 36.5246 193.457 36.5246C192.445 36.5246 191.632 36.7179 191.013 37.11C190.393 37.4966 189.938 38.0593 189.654 38.7982C189.364 39.5314 189.222 40.4067 189.222 41.4128V43.8796C189.222 44.8913 189.37 45.7552 189.677 46.4714C189.984 47.1876 190.456 47.7446 191.104 48.1368C191.752 48.5233 192.593 48.7222 193.633 48.7222C194.582 48.7222 195.384 48.5858 196.032 48.313C196.68 48.0402 197.174 47.6082 197.521 47.0171C197.867 46.4259 198.038 45.67 198.038 44.7492H206.035C206.035 46.4771 205.711 47.972 205.063 49.2224C204.415 50.4786 203.512 51.4903 202.363 52.269C201.21 53.0477 199.868 53.6217 198.345 53.9969C196.816 54.372 195.19 54.5596 193.462 54.5596Z"
        fill="white"
      />
      <path
        d="M210.497 54.0426V22.7017H219.097V33.5977H219.398C219.887 32.9611 220.506 32.4382 221.257 32.0176C222.007 31.6026 222.814 31.2787 223.678 31.0456C224.542 30.8183 225.395 30.6989 226.23 30.6989C228.043 30.6989 229.538 31.0399 230.703 31.7163C231.869 32.3927 232.744 33.3646 233.318 34.6321C233.892 35.8997 234.182 37.44 234.182 39.2588V54.0426H225.576V40.299C225.576 39.8102 225.514 39.3668 225.383 38.9803C225.252 38.5938 225.071 38.2528 224.843 37.9629C224.61 37.673 224.315 37.4514 223.957 37.2922C223.598 37.1331 223.172 37.0535 222.683 37.0535C221.961 37.0535 221.336 37.2183 220.802 37.548C220.268 37.8776 219.853 38.3096 219.552 38.8439C219.25 39.3782 219.097 39.975 219.097 40.64V54.0426H210.497Z"
        fill="white"
      />
      <path
        d="M239.371 62.5568V31.2158H246.374L247.107 34.4556H247.408C248.215 33.1028 249.21 32.1366 250.392 31.5568C251.574 30.9827 252.899 30.6929 254.371 30.6929C256.389 30.6929 258.117 31.1305 259.56 32.0115C261.004 32.8925 262.101 34.2169 262.868 35.9903C263.63 37.7636 264.011 39.9917 264.011 42.6688C264.011 45.3459 263.63 47.5229 262.868 49.2849C262.107 51.0412 261.004 52.3599 259.56 53.2409C258.117 54.1162 256.389 54.5595 254.371 54.5595C253.564 54.5595 252.779 54.4743 252.012 54.2981C251.25 54.1276 250.551 53.8434 249.915 53.4569C249.278 53.0704 248.733 52.5702 248.272 51.9677H247.971V62.5568H239.365H239.371ZM251.694 48.2049C252.586 48.2049 253.308 48.0174 253.854 47.6422C254.399 47.2671 254.797 46.7499 255.042 46.0848C255.286 45.4255 255.411 44.6468 255.411 43.7488V41.4582C255.411 40.5658 255.286 39.7871 255.042 39.1221C254.797 38.4571 254.399 37.9455 253.854 37.5874C253.308 37.2293 252.586 37.0475 251.694 37.0475C250.801 37.0475 250.074 37.2634 249.511 37.6954C248.949 38.1274 248.539 38.7128 248.278 39.4461C248.016 40.1793 247.891 40.9978 247.891 41.8901V43.3168C247.891 43.9818 247.965 44.607 248.107 45.1982C248.249 45.7893 248.477 46.3065 248.778 46.7555C249.079 47.2046 249.477 47.557 249.966 47.8128C250.455 48.0742 251.035 48.1993 251.694 48.1993V48.2049Z"
        fill="white"
      />
      <path
        d="M279.966 54.5595C277.374 54.5595 275.117 54.1162 273.202 53.2409C271.286 52.3599 269.791 51.0355 268.729 49.2621C267.66 47.4888 267.131 45.2777 267.131 42.6234C267.131 39.969 267.666 37.7238 268.729 35.9675C269.791 34.2112 271.286 32.8925 273.202 32.0115C275.117 31.1362 277.374 30.6929 279.966 30.6929C282.557 30.6929 284.82 31.1305 286.752 32.0115C288.685 32.8925 290.18 34.2112 291.248 35.9675C292.311 37.7238 292.845 39.9462 292.845 42.6234C292.845 45.3005 292.311 47.4888 291.248 49.2621C290.18 51.0355 288.685 52.3599 286.752 53.2409C284.82 54.1162 282.557 54.5595 279.966 54.5595ZM279.966 48.7222C280.977 48.7222 281.796 48.5289 282.432 48.1367C283.063 47.7502 283.529 47.1932 283.814 46.4713C284.103 45.7495 284.246 44.8855 284.246 43.8795V41.4127C284.246 40.4066 284.098 39.5313 283.814 38.7981C283.524 38.0649 283.063 37.5022 282.432 37.11C281.796 36.7235 280.977 36.5245 279.966 36.5245C278.954 36.5245 278.141 36.7178 277.521 37.11C276.902 37.4965 276.447 38.0592 276.163 38.7981C275.873 39.5313 275.731 40.4066 275.731 41.4127V43.8795C275.731 44.8912 275.873 45.7552 276.163 46.4713C276.453 47.1932 276.902 47.7445 277.521 48.1367C278.141 48.5232 278.954 48.7222 279.966 48.7222Z"
        fill="white"
      />
      <path
        d="M296.995 28.539V22.7017H305.594V28.539H296.995ZM296.995 54.0426V31.2161H305.594V54.0426H296.995Z"
        fill="white"
      />
      <path
        d="M310.784 54.0423V31.2158H317.832L318.394 34.6716H318.696C319.304 33.7792 320.009 33.0346 320.816 32.4435C321.623 31.8524 322.515 31.4147 323.493 31.1248C324.47 30.835 325.482 30.6929 326.517 30.6929C328.33 30.6929 329.825 31.0339 330.99 31.7103C332.155 32.3867 333.03 33.3586 333.604 34.6261C334.178 35.8936 334.468 37.434 334.468 39.2528V54.0366H325.863V40.293C325.863 39.8041 325.8 39.3608 325.67 38.9743C325.539 38.5878 325.357 38.2468 325.13 37.9569C324.897 37.667 324.601 37.4453 324.243 37.2862C323.885 37.127 323.459 37.0475 322.97 37.0475C322.248 37.0475 321.623 37.2123 321.088 37.542C320.554 37.8716 320.139 38.3036 319.838 38.8379C319.537 39.3722 319.383 39.969 319.383 40.634V54.0366H310.784V54.0423Z"
        fill="white"
      />
      <path
        d="M349.303 54.5599C347.746 54.5599 346.37 54.378 345.177 54.0199C343.983 53.6618 343.051 53.0309 342.392 52.1385C341.727 51.2461 341.397 50.0184 341.397 48.4667V37.0535H338.197V31.2161H341.744L343.602 24.2988H350.002V31.2161H354.845V37.0535H350.002V45.7441C350.002 46.7275 350.162 47.4664 350.474 47.9722C350.792 48.4781 351.44 48.7282 352.418 48.7282H354.839V53.7868C354.379 53.9289 353.811 54.0654 353.134 54.1961C352.458 54.3268 351.776 54.4178 351.099 54.4746C350.423 54.5314 349.826 54.5599 349.303 54.5599Z"
        fill="white"
      />
      <g opacity="0.3">
        <path
          d="M80.944 69.15C80.3245 67.9678 79.4719 66.3536 78.5 64.0743L56.7193 13.3911C56.2362 12.2316 55.7303 11.271 54.4401 11.271C53.1498 11.271 52.1949 11.9133 51.6607 13.0785L27.1688 66.7742C26.572 68.0758 25.9354 69.1159 25.4636 69.8719C24.9293 70.7415 24.611 71.2645 24.611 71.7078C24.611 73.7028 26.1627 73.9302 27.3279 73.9302C27.902 73.9302 29.3571 73.879 31.3749 73.8051C36.2005 73.6233 44.2716 73.3277 51.8198 73.3277C59.368 73.3277 66.9617 73.5664 72.5774 73.7426C75.9365 73.8506 78.5909 73.9302 80.1199 73.9302C81.2908 73.9302 82.041 73.1401 82.041 71.9124C82.041 71.2701 81.6432 70.5199 80.927 69.1557L80.944 69.15ZM60.1297 68.5817H36.5983C36.0129 68.5817 35.507 68.5248 35.0864 68.4168C35.0523 68.4055 35.0239 68.3998 34.9898 68.3884C34.3305 68.1554 33.745 67.6665 33.6882 66.6719C33.6882 66.5923 33.6996 66.5127 33.7166 66.4331C33.8928 65.3248 34.734 63.3752 35.1831 62.2953C35.9163 60.5617 38.5763 54.2867 41.208 48.0742L41.2193 48.0458C43.8453 41.8446 46.4428 35.7117 47.0624 34.251C47.5853 32.9266 47.8468 32.4321 48.4152 32.4605C48.6652 32.4833 48.8358 32.6652 49.029 33.0289C49.2052 33.3359 49.3928 33.7849 49.6599 34.3533L50.2624 35.689L51.7402 38.9515C52.8543 41.9299 62.6419 63.8924 63.0966 66.4331C63.3354 67.9735 62.2043 68.593 60.141 68.5873L60.1297 68.5817Z"
          fill="white"
        />
      </g>
      <path
        d="M73.5835 69.2182C72.9639 68.036 72.1113 66.4218 71.1394 64.1425L49.3644 13.4536C48.8813 12.2941 48.3754 11.3335 47.0852 11.3335C45.7949 11.3335 44.84 11.9758 44.3057 13.141L19.8139 66.8367C19.217 68.1383 18.5804 69.1784 18.1087 69.9344C17.5744 70.804 17.2561 71.327 17.2561 71.7703C17.2561 73.7653 18.8078 73.9927 19.973 73.9927C20.5471 73.9927 22.0022 73.9415 24.0199 73.8677C28.8456 73.6858 36.9167 73.3902 44.4649 73.3902C52.0131 73.3902 59.6068 73.6289 65.2224 73.8051C68.5816 73.9131 71.236 73.9927 72.765 73.9927C73.9359 73.9927 74.6861 73.2026 74.6861 71.9749C74.6861 71.3326 74.2882 70.5824 73.5721 69.2182H73.5835ZM52.769 68.6498H29.2377C28.6523 68.6498 28.1464 68.593 27.7258 68.485C27.6917 68.4736 27.6633 68.468 27.6292 68.4566C26.9699 68.2236 26.3844 67.7347 26.3276 66.7401C26.3276 66.6605 26.339 66.5809 26.356 66.5013C26.5322 65.393 27.3734 63.4434 27.8225 62.3635C28.5557 60.6299 31.2157 54.3549 33.8474 48.1424L33.8587 48.1139C36.4847 41.9128 39.0822 35.7799 39.7018 34.3191C40.2247 32.9948 40.4862 32.5003 41.0545 32.5287C41.3046 32.5515 41.4752 32.7333 41.6684 33.0971C41.8446 33.404 42.0322 33.8531 42.2993 34.4215L42.9018 35.7572L44.3796 39.0197C45.4937 41.9981 55.2813 63.9606 55.736 66.5013C55.9748 68.0417 54.8437 68.6612 52.7804 68.6555L52.769 68.6498Z"
        fill="white"
      />
    </svg>
  )
}

export default TouchpointProductLogo
