import React, { useRef } from 'react'
import ContentEditable, { type ContentEditableEvent } from 'react-contenteditable'

import { Box, Button } from '@mui/material'
import { makeStyles } from '@mui/styles'

import { selectElementTextContent } from '@top/shared/src/helpers/selectElementTextContent'
import { convertFontSizeToNewFormat, convertFormatsToCSS } from '@top/shared/src/style/helpers'
import { type ButtonStyle } from '@top/shared/src/style/types'
import TransitionArrow from '@top/ui/src/Icons/TransitionArrow'
import { FocusedBorder } from '@top/ui/src/SceneModules/components/FocusedBorder'

import DOMPurify from 'dompurify'

const useStyles = makeStyles({
  buttonText: {
    wordBreak: 'break-word',
    overflow: 'hidden',
    marginTop: '0.625rem',
    width: '100%',
    whiteSpace: 'pre-wrap',
    outline: '0px',
  },
})

type Props = {
  onClick?: () => void
  disabled: boolean
  style: ButtonStyle
  text: string
  onChange?: (text: string) => void
  isBuilder?: boolean
  widgetWidth?: number
  parentTestId?: string
  onFocus?: () => void
  onBlur?: () => void
  focused?: boolean
  showError?: boolean
}

export function TransitionButton(props: Props) {
  const {
    text,
    onClick,
    disabled,
    style,
    onChange,
    isBuilder = false,
    widgetWidth,
    parentTestId,
    onFocus,
    onBlur,
    focused = false,
    showError = false,
  } = props

  const contentEditableRef = useRef<HTMLElement>(null)

  const { maxHeight, fontFamily, color, bold, underline, italic } = style

  const classes = useStyles()

  const testId = parentTestId ? `${parentTestId}-transition` : undefined

  const fontSize = convertFontSizeToNewFormat(style.fontSize, widgetWidth)
  const { fontWeight, textDecoration, fontStyle } = convertFormatsToCSS({
    italic,
    bold,
    underline,
  })

  const handleChange = (event: ContentEditableEvent) => {
    const sanitized = DOMPurify.sanitize(event.target.value)
    if (onChange) {
      onChange(sanitized)
    }
  }

  const handleKeyDown = (e: React.KeyboardEvent<HTMLElement>) => {
    if (e.key === 'Escape') {
      if (contentEditableRef.current) {
        contentEditableRef.current.blur()
      }

      if (onBlur) {
        onBlur()
      }
    }
  }

  const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation()

    if (onClick) {
      onClick()
    }
  }

  const handleFocus = (e: React.FocusEvent<HTMLDivElement, Element>) => {
    selectElementTextContent(e.target)

    if (onFocus) {
      onFocus()
    }
  }

  return (
    <FocusedBorder
      isFocused={isBuilder && focused}
      hover={isBuilder && !focused}
      hasError={showError}
      disabled={!isBuilder || (isBuilder && disabled)}
    >
      <Button
        data-testid={testId}
        onClick={handleClick}
        disabled={disabled}
        sx={{
          fontSize,
          fontFamily,
          color,
          fontWeight,
          textDecoration,
          fontStyle,
          overflow: 'hidden',
          lineHeight: '130%',

          paddingTop: '0.5rem',
          border: 'none',
          background: 'none',
          wordBreak: 'break-word',
          whiteSpace: 'pre-wrap',
          maxHeight,
          '&:hover': {
            background: 'none',
          },
        }}
        disableRipple
      >
        <Box
          display="flex"
          flexDirection="column"
          width="100%"
          alignItems="center"
          justifyContent="center"
        >
          <TransitionArrow fill={style.backgroundColor} />
          {isBuilder ? (
            <ContentEditable
              html={text}
              tagName="span"
              spellCheck={false}
              disabled={disabled}
              onFocus={handleFocus}
              onChange={handleChange}
              className={classes.buttonText}
              innerRef={contentEditableRef}
              onKeyDown={handleKeyDown}
              style={{
                cursor: focused ? 'text' : 'pointer',
              }}
            />
          ) : (
            <span className={classes.buttonText}>{text}</span>
          )}
        </Box>
      </Button>
    </FocusedBorder>
  )
}
