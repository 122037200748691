import { Api, Schema } from '@top/shared/src/api/client'
import {
  DistributorServeReq,
  DistributorServeResp,
} from '@top/shared/src/api/distributor.swagger.gen'
import { TrackerUpdateClientReq } from '@top/shared/src/api/tracker.swagger.gen'

import { CustomFetch } from 'hooks/useFetch'
import { serveResponseSchema } from 'types/Activity'
import { TrackerEventReq } from 'types/Tracker'
import { z } from 'zod'

const tracker = (customFetch: CustomFetch) =>
  ({
    trackerEvent: {
      fn: async (args: TrackerEventReq) => {
        const res = await customFetch('/tracker/tracker.Tracker/Event', args)
        return res as {}
      },
    },
    updateClientEvent: {
      fn: async (args: TrackerUpdateClientReq) => {
        const res = await customFetch('/tracker/tracker.Tracker/UpdateClient', args)
        return res as {}
      },
    },
  } as const satisfies Schema)

const distributor = (customFetch: CustomFetch) =>
  ({
    serveGet: {
      fn: async (args: DistributorServeReq) => {
        const res = await customFetch('/distributor/distributor.Distributor/ServeV2', args)
        return res as DistributorServeResp
      },
      outputSchema: z.object({ data: serveResponseSchema }),
    },
  } as const satisfies Schema)

export const distributorApiConfig = (customFetch: CustomFetch) =>
  ({
    ...tracker(customFetch),
    ...distributor(customFetch),
  } as const satisfies Schema)

export type DistributorApiSchema = ReturnType<typeof distributorApiConfig>

export type DistributorApiClient = Api<DistributorApiSchema>
