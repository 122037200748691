import { type ImageSelection } from '@top/shared/src/SceneModules/types'
import { type ImageOrientation, type TextStyle } from '@top/shared/src/style/types'
import {
  type BuilderSelectionsErrors,
  type QuestionSceneModuleProps,
} from '@top/ui/src/SceneModules/types'

export const ImageOrientationAspectRatioMap = {
  IMAGE_PORTRAIT: 9 / 16,
  IMAGE_LANDSCAPE: 21 / 9,
  IMAGE_GRID: 13 / 12,
} as const satisfies { [key in ImageOrientation]: number }

export type ImagePresetProps = QuestionSceneModuleProps<string[], string> & {
  promptText?: string
  /** @default false */
  showPromptText?: boolean
  /** @default false */
  promptTextStyle?: TextStyle | null
  onPromptTextChange?: (text: string) => void
  imageOrientation: ImageOrientation
  isMultiChoice?: boolean
  selections: ImageSelection[]
  /** @default false */
  randomizeSelections?: boolean
  /**
   * This is an array of selection `uuid`s.
   *
   * @default undefined
   */
  pinnedSelections?: string[]
  /**
   * This is an array of selection `uuid`s.
   *
   * @default undefined
   */
  exclusiveSelections?: string[]
  widgetWidth?: number
  onFocusPromptText?: () => void
  /**
   * This is a boolean that determines whether the selection label is always visible. If it is
   * false, the selection label will only be visible when the selection is pinned or exclusive.
   */
  isSelectionLabelAlwaysShown: boolean
  onOpenModal?: () => void
  onRemoveImage?: () => void
  onOpenEditorModal?: () => void
  focusedChild?: { type: 'selection'; uuid: string } | { type: 'promptText' }
  onFocusSelection: (selectionUuid: string) => void
  builderSelectionsErrors?: BuilderSelectionsErrors
}
