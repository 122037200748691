import React from 'react'

import Button from '@mui/material/Button'

import { type SpacingIncrement } from '@top/shared/src/style/types'

import Color from 'color'

type BlockSelectionProps = {
  children: React.ReactNode
  testId?: string
  disabled: boolean
  isBuilder: boolean
  onClick: () => void
  onFocus: () => void
  selected: boolean

  choicePadding: SpacingIncrement
  backgroundColor: string
}

export function BlockSelection(props: BlockSelectionProps) {
  const {
    children,
    testId,
    choicePadding,
    disabled,
    isBuilder,
    onClick,
    backgroundColor,
    selected,
    onFocus,
  } = props

  const selectedBackgroundColor = Color(backgroundColor).darken(0.4).toString()
  const paddingY = `calc(${choicePadding ?? 1} * 1rem)`
  const sx = {
    width: '100%',
    paddingY,
    position: 'relative',
    backgroundColor: selected ? selectedBackgroundColor : backgroundColor,
  }

  return (
    <Button
      data-testid={testId}
      sx={sx}
      disabled={disabled}
      disableRipple={isBuilder}
      onClick={(e) => {
        e.stopPropagation()

        if (onClick) {
          onClick()
        }
      }}
      onFocus={onFocus}
    >
      {children}
    </Button>
  )
}
