import { Box, Button } from '@mui/material'

import { CloudinaryImage } from '@top/shared/src/components/CloudinaryImage'
import { convertIndexToLetterString } from '@top/shared/src/helpers/convertIndexToLetterString'
import { type ImageSelectionEtc } from '@top/shared/src/SceneModules/types'
import COLORS from '@top/shared/src/style/colors'
import { type ImageStyle } from '@top/shared/src/style/types'
import { PinnedOrExclusiveSelectionLabel } from '@top/ui/src/SceneModules/components/PinnedOrExclusiveSelectionLabel'
import SelectedOverlay from '@top/ui/src/SceneModules/ImagePreset/components/SelectedOverlay'
import { EditImageMenu } from '@top/ui/src/SceneModules/Logo/components/EditImageMenu'

import { ImagePlaceholder } from '../../Logo/components/ImagePlaceholder'

export type Props = Omit<ImageSelectionEtc, 'position'> & {
  parentTestId?: string
  disabled?: boolean
  selected?: boolean
  isBuilder?: boolean
  isPinned: boolean
  isExclusive: boolean
  position: number
  imageStyles: ImageStyle
  isSelectionLabelAlwaysShown: boolean
  aspectRatio?: number
  onClick?: () => void
  onOpenModal: () => void
  onRemoveImage: () => void
  onOpenEditorModal: () => void
  onFocus?: () => void
}

export function ImageSelection(props: Props) {
  const {
    image_alt_text,
    image_url,
    parentTestId,
    isBuilder = false,
    selected = false,
    disabled = false,
    imageStyles,
    onClick,
    isExclusive,
    isPinned,
    position,
    isSelectionLabelAlwaysShown,
    aspectRatio,
    onOpenModal,
    onRemoveImage,
    onOpenEditorModal,
    onFocus,
  } = props

  const testId = parentTestId ? `${parentTestId}-image-preset-${image_url}` : undefined

  const isLabelShown = isBuilder && (isPinned || isExclusive || isSelectionLabelAlwaysShown)

  const isSelected = selected && !isBuilder

  return (
    <Box position="relative">
      {isSelected && <SelectedOverlay />}
      {isLabelShown && (
        <PinnedOrExclusiveSelectionLabel
          isExclusive={isExclusive}
          isPinned={isPinned}
          label={convertIndexToLetterString(position)}
        />
      )}
      <Box
        display="flex"
        alignItems="center"
        flexDirection="column"
        overflow="auto"
        position="relative"
        onFocus={onFocus}
        borderRadius="14px"
      >
        {image_url ? (
          <>
            <Button
              disabled={disabled || isBuilder}
              data-testid={testId}
              disableRipple={isBuilder}
              fullWidth
              onClick={(e) => {
                e.stopPropagation()

                if (onClick) {
                  onClick()
                }
              }}
              sx={{
                padding: 0,
                '&:hover': {
                  backgroundColor: 'transparent',
                },
                opacity: disabled ? 0.2 : 1,
              }}
            >
              <CloudinaryImage
                publicId={image_url}
                imgAlt={image_alt_text}
                imageStyles={imageStyles}
              />
            </Button>
            {isBuilder && (
              <EditImageMenu
                onRemove={onRemoveImage}
                onReplace={onOpenModal}
                onEdit={onOpenEditorModal}
              />
            )}
          </>
        ) : (
          <Box
            justifyContent="center"
            width="100%"
            height="100%"
            sx={{ background: COLORS.OVERLAY_DARK }}
          >
            <ImagePlaceholder
              onClick={onOpenModal}
              containerBackground="transparent"
              containerHeight="100%"
              containerWidth="100%"
              iconWidth="100%"
              iconSize="large"
              aspectRatio={aspectRatio}
              iconBackground="transparent"
            />
          </Box>
        )}
      </Box>
    </Box>
  )
}
