import { CSSProperties, lazy } from 'react'
import { SceneStyle } from '../types/Style'
import { getConfigs, getEnv, ProdEnv, ProdEnvs } from './configs'
import { IFlexibleStyle } from '../types/Items'
import { ITextStyle } from '../types/Scene'

export const ensureHTTPS: (url: string) => string = (url) => {
  if (url.match('^https://')) {
    return url
  } else if (url.match('^http://')) {
    return url.replace('http://', 'https://')
  } else if (url.match('^https:/')) {
    return url.replace('https:/', 'https://')
  } else {
    return `https://${url}`
  }
}

export const removeHTTPS = (url: string) => {
  if (/^(?:ht)tps?:\/\//.test(url)) {
    return url.replaceAll(/https?:\/\//g, '')
  }
  return url
}

export const isTouchDevice: () => boolean = () => {
  return 'ontouchstart' in window || navigator.maxTouchPoints > 0 || navigator.maxTouchPoints > 0
}

export const truncateText: (props: {
  maxLength: number
  text: string
  overflowPlaceholder?: string
}) => string = (props) => {
  const { maxLength, overflowPlaceholder, text } = props
  return text.length > maxLength
    ? `${text.substring(0, maxLength).trim()}${overflowPlaceholder}`
    : text
}

export const sentryBeforeSend = (event: any, hint: any) => {
  const { POD_NAME } = getConfigs()
  if (
    hint.originalException.name === 'ChunkLoadError' &&
    ['dev1', 'dev2', 'sit1'].includes(POD_NAME as string)
  ) {
    return null
  }
  return event
}

export const isDevEnvironment = (): boolean => {
  const env = getEnv()
  return process.env.NODE_ENV === 'development' || !ProdEnvs.includes(env as ProdEnv)
}

export const VCTraceID: {
  header: string
  sentryKey: string
} = {
  header: 'X-VCTraceId',
  sentryKey: 'vctraceid',
}

/**
 * @param {Response} resp
 * @returns  {Promise<any>}
 * @description Returns response of a request, if it's JSON returns the json and if it's text it returns text
 */
export const getResponseBody = (resp: Response): Promise<any> => {
  const contentType = resp.headers.get('content-type')
  if (contentType && contentType.indexOf('application/json') !== -1) {
    return resp.json()
  } else {
    return resp.text()
  }
}

export const removeFromObjectByKey = (obj: { [key: string]: any }, key: string): any => {
  delete obj[key]
  return obj
}

export function enumKeys<T extends Object>(enumVar: T) {
  return Object.keys(enumVar) as (keyof typeof enumVar)[]
}

export function lazyRetry(
  fn: Function,
  retriesLeft = 3,
  interval = 150
): React.LazyExoticComponent<
  React.ComponentType<React.PropsWithChildren<React.PropsWithChildren<any>>>
> {
  return lazy(() => retry(fn, retriesLeft, interval))
}

export function retry(
  fn: Function,
  retriesLeft: number,
  interval: number
): Promise<{
  default: React.ComponentType<React.PropsWithChildren<React.PropsWithChildren<any>>>
}> {
  return new Promise((resolve, reject) => {
    fn()
      .then(resolve)
      .catch((error: Error) => {
        setTimeout(() => {
          if (retriesLeft === 1) {
            // reject('maximum retries exceeded');
            reject(error)
            return
          }

          // Passing on "reject" is the important part
          retry(fn, retriesLeft - 1, interval).then(resolve, reject)
        }, interval)
      })
  })
}

export const toOneDecimal = (val?: number) => {
  if (!val) return 0
  return Math.round(val * 10) / 10
}

export function numberWithCommas(x: string | number) {
  var parts = x.toString().split('.')
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',')
  return parts.join('.')
}

export const filterHTML = (value: string) => {
  if (value === '<br>') return ''
  return (
    value
      .replace(/(<|&lt;)br\s*\/*(>|&gt;)/g, '\n')
      .replace(/(<|&lt;)div\s*\/*(>|&gt;)/g, '')
      // We receive innerHTML from content editable area, we don't want to render char entities
      .replace(/((&gt;))/g, '>')
      .replace(/((&lt;))/g, '<')
      .replace(/((&amp;))/g, '&')
  )
}

export const getPixelValueAsNumber = (pixelValue: string): number => {
  return pixelValue.indexOf('px') === -1 ? NaN : parseInt(pixelValue.split('px')[0])
}

export const getDefaultSceneStyles = (): SceneStyle => {
  return {
    progressBarStyles: getDefaultProgressBarStyles(),
  }
}

export const getDefaultProgressBarStyles = (): CSSProperties => ({ color: 'rgb(29, 53, 75,1)' })

/**
 * Converts html links to markdown links
 */
export const htmlToMarkdownURL = (html: string) => {
  return html.replace(/<a.*? href="(.*?)".*?>(.*?)<\/a>/g, '[$2]($1)').replace(/&nbsp;/g, ' ')
}

/**
 * Converts markdown links to html links for builder editor and distribution contexts
 */
export const markdownToHtmlURL = (markdown: string, isBuilder: boolean) => {
  return markdown.replace(
    /[[]{1}([^\]]+)[\]]{1}[(]{1}([^)"]+)("(.+)")?[)]{1}/g,
    isBuilder
      ? '<a href="$2" style="pointer-events: none;" contenteditable="false">$1</a>'
      : '<a href="$2" target="_blank" rel="noopener noreferrer">$1</a>'
  )
}

export const getTextSelectionStyles = (style: IFlexibleStyle | ITextStyle) => {
  return {
    backgroundColor: style.backgroundColor,
    bold: style.bold,
    color: style.color,
    fontFamily: style.fontFamily,
    fontSize: style.fontSize,
    italic: style.italic,
    textAlign: style.textAlign,
    underline: style.underline,
  }
}
