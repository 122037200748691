import { FALLBACK_DEFAULT_LOCALE, Locale, localeToLanguageName } from '../src/types/Languages'

/**
 * useGetPreferredLanguage returns the user's first preferred & supported language.
 * Assumes we don't support regional locale codes (e.g. 'en-US', 'fr-CA', etc.)
 * If the user's preferred language is not supported, it returns the defaultLocale.
 * defaultLocale is optional, and if not provided, it will default to FALLBACK_DEFAULT_LOCALE ('EN')
 *
 * @deprecated Use `@top/shared/src/Languages/helpers/getPreferredLanguage` instead
 */
export const useGetPreferredLanguage = (
  support: Locale[],
  defaultLocale: Locale = FALLBACK_DEFAULT_LOCALE
) => {
  const browserPreferences =
    navigator.languages.map((locale) => locale.split('-')[0].toUpperCase()) || defaultLocale
  const locale =
    (browserPreferences.find((locale) => support.includes(locale as Locale)) as Locale) ??
    defaultLocale

  return {
    localeCode: locale,
    languageName: localeToLanguageName[locale],
  }
}
