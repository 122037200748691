import Five from '@top/ui/src/SceneModules/NumericScale/components/NumericScaleItem/ItemTypes/Face/components/Five'
import Four from '@top/ui/src/SceneModules/NumericScale/components/NumericScaleItem/ItemTypes/Face/components/Four'
import One from '@top/ui/src/SceneModules/NumericScale/components/NumericScaleItem/ItemTypes/Face/components/One'
import Three from '@top/ui/src/SceneModules/NumericScale/components/NumericScaleItem/ItemTypes/Face/components/Three'
import Two from '@top/ui/src/SceneModules/NumericScale/components/NumericScaleItem/ItemTypes/Face/components/Two'

export type FaceItemProps = {
  size: number
  secondaryColor: string
  fill: string
  value: number
}

const Face = (props: FaceItemProps) => {
  const { value } = props

  switch (value) {
    case 1:
      return <One {...props} />
    case 2:
      return <Two {...props} />
    case 3:
      return <Three {...props} />
    case 4:
      return <Four {...props} />
    case 5:
      return <Five {...props} />
    default:
      throw new Error(`Unsupported numeric scale value (${value}) for shape type: NUMERIC_FACE`)
  }
}

export default Face
