import { FaceWrapper } from '@top/ui/src/SceneModules/NumericScale/components/NumericScaleItem/ItemTypes/Face/components/FaceWrapper'
import { type FaceItemProps } from '@top/ui/src/SceneModules/NumericScale/components/NumericScaleItem/ItemTypes/Face/Face'

const One = (props: FaceItemProps) => {
  const { size, fill, secondaryColor } = props
  return (
    <FaceWrapper size={size}>
      <path
        d="M4.66675 27.9998C4.66675 15.1198 15.0967 4.6665 27.9767 4.6665C40.8801 4.6665 51.3334 15.1198 51.3334 27.9998C51.3334 40.8798 40.8801 51.3332 27.9767 51.3332C15.0967 51.3332 4.66675 40.8798 4.66675 27.9998Z"
        style={{ fill, transition: 'opacity 0.2s' }}
      />
      <path
        d="M6.66663 27.9998C6.66663 16.2218 16.2038 6.6665 27.9766 6.6665C39.7767 6.6665 49.3333 16.2257 49.3333 27.9998C49.3333 39.774 39.7767 49.3332 27.9766 49.3332C16.2038 49.3332 6.66663 39.7779 6.66663 27.9998Z"
        stroke={secondaryColor}
        strokeWidth="4"
      />

      <path
        d="M28.0001 32.6665C22.5635 32.6665 17.9201 36.0498 16.0535 40.8332H19.9501C21.5601 38.0565 24.5468 36.1665 28.0001 36.1665C31.4535 36.1665 34.4168 38.0565 36.0501 40.8332H39.9468C38.0801 36.0498 33.4368 32.6665 28.0001 32.6665Z"
        fill={secondaryColor}
      />
      <path
        d="M36.1667 25.6665C38.0997 25.6665 39.6667 24.0995 39.6667 22.1665C39.6667 20.2335 38.0997 18.6665 36.1667 18.6665C34.2338 18.6665 32.6667 20.2335 32.6667 22.1665C32.6667 24.0995 34.2338 25.6665 36.1667 25.6665Z"
        fill={secondaryColor}
      />
      <path
        d="M19.8333 25.6665C21.7662 25.6665 23.3333 24.0995 23.3333 22.1665C23.3333 20.2335 21.7662 18.6665 19.8333 18.6665C17.9003 18.6665 16.3333 20.2335 16.3333 22.1665C16.3333 24.0995 17.9003 25.6665 19.8333 25.6665Z"
        fill={secondaryColor}
      />
    </FaceWrapper>
  )
}

export default One
