/**
 * System UI
 * https://www.figma.com/file/t3HWd0BfWS5eFGC1uaTa5p/Alida-Design-System?type=design&node-id=4670-42893&mode=design&t=fGsCjs62Uzb33YCB-4
 */
const SYSTEM_UI = {
  PRIMARY: '#E55940',
  HIGHLIGHT_SEARCH: '#FFB04D',
  HIGHLIGHT_ROW: '#FFF3E4',
  LINK: '#1556B1',
  BACKGROUND: '#F6F6F6',
  DIVIDER_DARK: '#BDC3D4',
  DIVIDER_LIGHT: '#D5D6E0',
  PRIMARY_HOVER: '#BF4A35',
  PRIMARY_PRESSED: '#993B2A',
  SECONDARY_HOVER: '#B1B1BA',
  SECONDARY_PRESSED: '#8C8D94',
  WHITE: '#FFFFFF',
  BLACK: '#000000',
  TEXT_DARK: '#1D354B',
  TEXT_LIGHT: '#4A5D6F',
  DISABLED_DARK: '#727272',
  DISABLED_LIGHT: '#F0F2F3',
} as const

/**
 * Overlay Colors
 * https://www.figma.com/file/t3HWd0BfWS5eFGC1uaTa5p/Alida-Design-System?type=design&node-id=4670-42681&mode=design&t=fGsCjs62Uzb33YCB-4
 */
const OVERLAY = {
  OVERLAY_DARK: 'rgba(0,0,0,0.6)',
  OVERLAY_LIGHT: 'rgba(0,0,0,0.1)',
} as const

/**
 * Status
 * https://www.figma.com/file/t3HWd0BfWS5eFGC1uaTa5p/Alida-Design-System?type=design&node-id=4670-42715&mode=design&t=fGsCjs62Uzb33YCB-4
 */
const STATUS = {
  ERROR: '#E52B20',
  WARNING: '#FF8A1E',
  LIVE: '#00AB5D',
  INFO: '#5066E1',
  SUCCESS: '#6DD400',
} as const

/**
 * Alida Brand (Marketing)
 * https://www.figma.com/file/t3HWd0BfWS5eFGC1uaTa5p/Alida-Design-System?type=design&node-id=4670-42780&mode=design&t=fGsCjs62Uzb33YCB-4
 */
const ALIDA = {
  ORANGE: SYSTEM_UI.PRIMARY,
  GOLD: SYSTEM_UI.HIGHLIGHT_SEARCH,
  HUBS_PURPLE: '#B391C3',
  SPARQ_BLUE: '#64BAFF',
  BLUE: '#5A7387',
  SURVEYS_BLUE: '#3E84AC',
  INDUSTRY_SOLUTIONS: '#AFC1CA',
  GREEN: '#8A8C57',
} as const

/**
 * Chart Colors
 * https://www.figma.com/file/t3HWd0BfWS5eFGC1uaTa5p/Alida-Design-System?type=design&node-id=4670-42529&mode=design&t=fGsCjs62Uzb33YCB-4
 */
const CHART_COLORS = {
  ONE: SYSTEM_UI.HIGHLIGHT_SEARCH,
  TWO: STATUS.WARNING,
  THREE: SYSTEM_UI.PRIMARY,
  FOUR: '#B00020',
  FIVE: ALIDA.SURVEYS_BLUE,
  SIX: ALIDA.SPARQ_BLUE,
  SEVEN: '#96A7DD',
  EIGHT: '#765AB1',
  NINE: '#483374',
  TEN: '#CE74A5',
} as const

/** Chart colors used in activity/campaign report pages. */
export const CHARTS = {
  DARK_GRAY: ALIDA.BLUE,
  LIGHT_GRAY: ALIDA.INDUSTRY_SOLUTIONS,
  AREA_FILL: 'rgba(175, 193, 202, 0.08)',
  PURPLE: ALIDA.HUBS_PURPLE,
  BLUE: ALIDA.SPARQ_BLUE,
  CAMO: ALIDA.GREEN,
  CAMO_AREA_FILL: 'rgba(138, 140, 87, 0.08)',
} as const

export const DIST_CARDS = {
  COLUMBIAN_BLUE: 'rgba(100, 186, 255, 0.2)',
  CLAY_CREEK: 'rgba(138, 140, 87, 0.2)',
  PURPLE: 'rgba(179, 145, 195, 0.2)',
  BOSTON_BLUE: 'rgba(62, 132, 172, 0.2)',
} as const

export const AI_ASSISTANT = {
  PROMPT_BACKGROUND: '#F8F8F8',
  LOADER_BACKGROUND: '#ECEBEC',
  LOADER_DOTS: '#949294',
} as const

const COLORS = {
  CHIP_BACKGROUND: '#C3EBF5',
  DISABLED_BACKGROUND: '#F2F2F2',
  BORDER_GRAY: '#C4C4C4',
  SURFACE_OVERLAY: 'rgba(33, 33, 33, 0.08)',
  OUTLINE: 'rgba(0, 0, 0, 0.12)',
  BOX_SHADOW: 'rgba(0, 0, 0, 0.14)',
  ERROR_BACKGROUND: '#FEF9F8',
  WARNING_BACKGROUND: '#FFFAE7',
  INFO_BACKGROUND: '#DFECFE',
  NAVY_OVERLAY: 'rgba(0, 37, 71, 0.2)',

  SUBTITLE_TEXT: '#979797',
  TOUCHPOINT_NAVY: '#001834',
  NUMERIC_SCALE_SECONDARY: '#EFEFEF',
  INPUT_CONTROL_OUTLINE: '#CDCFD0',
  RESPONSE_TOOLTIP_BACKGROUND: '#EDEFF1',
  UPLOAD_MODAL_TEXT: '#626262',

  GRAY: {
    ECECEC: '#ECECEC',
    EBEBEB: '#EBEBEB',
    CCCCCC: '#CCCCCC',
    C7C7C7: '#C7C7C7',
  },

  // add these at end to make sure we don't accidentally add duplicate colors
  ...SYSTEM_UI,
  ...OVERLAY,
  ...STATUS,
  ...ALIDA,
  ...CHART_COLORS,
} as const

export default COLORS
