import FontFaceObserver from 'fontfaceobserver'

/** Preloads all the custom fonts before rendering the app. */
export async function preloadFonts(fonts: string[], onFontsLoaded?: () => void) {
  const fontPromises = fonts.map((font) => {
    const fontObserver = new FontFaceObserver(font)
    return fontObserver.load(null, 6000).catch((e) => {
      console.groupCollapsed(`An error occurred trying to preload "${font}" font`)
      console.error(e)
      console.groupEnd()
    })
  })
  await Promise.all(fontPromises).finally(() => {
    if (onFontsLoaded) {
      onFontsLoaded()
    }
  })
}
