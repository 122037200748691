import { type ComponentProps } from 'react'

import { Locale } from '@top/shared/src/Languages/types'
import { SceneWrapper } from '@top/ui/src/SceneWrapper'
import { NextButtonProps } from '@top/ui/src/SceneWrapper/NextButton/types'
import { SkipButtonProps } from '@top/ui/src/SceneWrapper/SkipButton'

import { SceneModule } from './SceneModule'

import { DistributorScene } from 'types/Activity'

type Props = {
  scene: DistributorScene
  locales: Locale[]
  responseLocale: Locale
  isLastScene: boolean
  isDashboardPreview?: boolean
  hasLanguageSelector: boolean
  showProgressBar: boolean
  progressBarColor: string
  width: number
  percentComplete: number
  onChangeSelectedLocale: (locale: Locale) => void
  onNextScene: () => void
}

export const SceneModulesScene = (props: Props) => {
  const {
    scene,
    width,
    hasLanguageSelector,
    onChangeSelectedLocale,
    responseLocale,
    locales,
    showProgressBar,
    progressBarColor,
    percentComplete,
    onNextScene,
    isLastScene,
  } = props

  const {
    etc: { show_skip_button },
  } = scene
  const { ITEM_BUTTON, ITEM_TEXT_BACKGROUND, ITEM_BACKGROUND, ITEM_SKIP_BUTTON } = scene.items

  const outerBackground = ITEM_BACKGROUND.style
  const innerBackground = {
    backgroundType: ITEM_TEXT_BACKGROUND.style.backgroundType,
    backgroundColor: ITEM_TEXT_BACKGROUND.style.backgroundColor,
  }

  const nextButtonProps = {
    style: ITEM_BUTTON.style,
    text: ITEM_BUTTON.etc.translations[responseLocale] ?? ITEM_BUTTON.etc.text,
    type: ITEM_BUTTON.etc.button_type,
    onClick: onNextScene,
  } satisfies NextButtonProps

  const skipButtonProps =
    ITEM_SKIP_BUTTON && show_skip_button
      ? ({
          style: ITEM_SKIP_BUTTON.style,
          text: ITEM_SKIP_BUTTON.etc.translations[responseLocale] ?? ITEM_SKIP_BUTTON.etc.text,
          onClick: () => {
            console.log('skip clicked')
          },
        } satisfies SkipButtonProps)
      : undefined

  const handleOnSceneModuleClick = ((value, clickedSceneModule) => {
    console.log(`scene ${scene.uuid} > clicked `, { value, clickedSceneModule })
  }) satisfies ComponentProps<typeof SceneModule>['onChange']

  return (
    <SceneWrapper
      testId={`distributor-scene-${scene.uuid}`}
      innerBackgroundStyle={innerBackground}
      outerBackgroundStyle={outerBackground}
      skipButtonProps={skipButtonProps}
      nextButtonProps={nextButtonProps}
      isNextButtonHidden={isLastScene}
      progressBarProps={
        showProgressBar
          ? {
              percentComplete,
              barColor: progressBarColor,
            }
          : undefined
      }
      languageSelectorProps={
        hasLanguageSelector
          ? {
              locales,
              selectedLocale: responseLocale,
              onChange: onChangeSelectedLocale,
            }
          : undefined
      }
    >
      {scene.scene_modules.map((sceneModule) => {
        return (
          <SceneModule
            key={sceneModule.uuid}
            sceneModule={sceneModule}
            onChange={handleOnSceneModuleClick}
            containerWidth={width}
            responseLocale={responseLocale}
          />
        )
      })}
    </SceneWrapper>
  )
}
