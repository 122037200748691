import { useRef } from 'react'
import ContentEditable, { type ContentEditableEvent } from 'react-contenteditable'

import { Typography } from '@mui/material'

import { selectElementTextContent } from '@top/shared/src/helpers/selectElementTextContent'
import { getTextStyle } from '@top/shared/src/style/helpers'
import { type TextStyle } from '@top/shared/src/style/types'

import DOMPurify from 'dompurify'

type SelectionLabelProps = {
  style: TextStyle
  text: string
  isBuilder: boolean
  widgetWidth?: number
  disabled: boolean
  onChange: (
    newText: string,
    options?: {
      clearSelectedElement: boolean
    }
  ) => void
}

export const SelectionLabel = (props: SelectionLabelProps) => {
  const { style, text, isBuilder, widgetWidth, disabled, onChange } = props

  const contentEditableRef = useRef<HTMLElement>(null)

  const selectionText = useRef(text)

  const textStyles = getTextStyle(style, widgetWidth)

  const { fontSize, color, fontWeight, fontStyle, fontFamily, textDecoration, textAlign } =
    textStyles

  const handleOnChange = (e: ContentEditableEvent) => {
    const sanitized = DOMPurify.sanitize(e.target.value)

    selectionText.current = sanitized
  }

  const handleOnBlur = () => {
    if (onChange) {
      onChange(selectionText.current, {
        clearSelectedElement: false,
      })
    }
  }

  const handleOnFocus = (e: React.FocusEvent<HTMLDivElement>) => {
    selectElementTextContent(e.target)
  }

  const handleKeydown = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.key === 'Escape') {
      e.currentTarget.blur()

      if (onChange) {
        onChange(selectionText.current, {
          clearSelectedElement: true,
        })
      }
    }
  }

  if (isBuilder) {
    return (
      <ContentEditable
        html={text}
        tagName="h3"
        onChange={handleOnChange}
        onKeyDown={handleKeydown}
        onBlur={handleOnBlur}
        style={{
          whiteSpace: 'pre-wrap',
          outline: '0px',
          minHeight: '1lh',
          lineHeight: '1.5em',
          minWidth: '3ch',
          display: 'block',
          wordBreak: 'break-word',

          overflowX: 'hidden',
          transition: 'all 0.2s ease',

          fontSize,
          color,
          fontWeight,
          fontStyle,
          fontFamily,
          textDecoration,
          textAlign,
          margin: 0,
          padding: 0,
        }}
        disabled={disabled}
        onFocus={handleOnFocus}
        innerRef={contentEditableRef}
      />
    )
  }

  return (
    <Typography
      fontSize={fontSize}
      color={color}
      fontWeight={fontWeight}
      fontStyle={fontStyle}
      fontFamily={fontFamily}
      sx={{ textDecoration, opacity: disabled ? 0.2 : 1 }}
    >
      {text}
    </Typography>
  )
}
