import { useEffect, useRef, useState } from 'react'

import { type InitialFocusType, type InputTypes } from '@top/ui/src/SceneModules/DateEntry/types'

export const useListSelectInputInteractions = (
  inputValue: number | undefined,
  listElementHeight: number,
  isInvalid: boolean,
  containerRef: React.RefObject<HTMLDivElement>,
  onScrollChange: (scrollTop: number, heightMultiplier: number) => void,
  heightMultiplier: number,
  clickCount: number,
  isFocusedRef: React.MutableRefObject<boolean>,
  setClickCount: (count: number) => void,
  onInputTypeChange: (inputState: InputTypes) => void
) => {
  const scrollHeight = inputValue ? inputValue * listElementHeight : 0

  const isScrollingRef = useRef(false)
  const currentScrollTop = useRef<number | undefined>()

  const [placeholderOpacity, setPlaceholderOpacity] = useState(1)

  const [isTouchEnd, setIsTouchEnd] = useState(true)

  const [isInitialMouseDown, setIsInitialMouseDown] = useState(false)
  const [isInitialFocus, setIsInitialFocus] = useState(false)
  const [initialFocusType, setInitialFocusType] = useState<InitialFocusType>()

  useEffect(() => {
    if (initialFocusType) return
    if (isInitialMouseDown) {
      setInitialFocusType('KEY_DOWN')
    }
    if (isInitialFocus) {
      setInitialFocusType('TAB')
    }
  }, [isInitialFocus, isInitialMouseDown, initialFocusType, setInitialFocusType])

  useEffect(() => {
    const snapToElement = (scrollValue: number) => {
      if (isInvalid) return
      containerRef.current?.scrollTo({
        top: scrollValue,
        behavior: isFocusedRef.current ? 'smooth' : 'auto',
      })
    }

    if (scrollHeight !== null && isTouchEnd) {
      snapToElement(scrollHeight)
    }
  }, [scrollHeight, isTouchEnd, isInvalid, containerRef, isInitialFocus, isFocusedRef])

  useEffect(() => {
    if (!currentScrollTop) return

    const timeOut = setTimeout(() => {
      const scrollTop = containerRef?.current?.scrollTop
      if (scrollTop !== undefined && isScrollingRef.current) {
        onScrollChange(scrollTop, heightMultiplier)
        isScrollingRef.current = false
      }
    }, 300)

    return () => {
      clearTimeout(timeOut)
    }
  }, [currentScrollTop, containerRef, onScrollChange, heightMultiplier, isScrollingRef])

  const handleScroll = (event: React.UIEvent<HTMLElement>) => {
    const scrollTop = (event.target as HTMLElement).scrollTop
    const scrollDif = (listElementHeight - scrollTop) / listElementHeight
    const opacity = scrollDif <= 0 ? 0 : scrollDif
    setPlaceholderOpacity(opacity)
    currentScrollTop.current = scrollTop
  }

  const handleMouseDown = () => {
    setIsInitialMouseDown(true)
    isFocusedRef.current = true
  }

  const handleFocus = () => {
    setIsInitialFocus(true)
    isFocusedRef.current = true
    console.log('focus happened')
  }

  const handleBlur = () => {
    setIsInitialFocus(false)
    setInitialFocusType(undefined)
  }

  const handleTouchEnd = () => {
    const scrollTop = containerRef?.current?.scrollTop

    if (scrollTop !== undefined && scrollTop > 5) {
      onScrollChange(scrollTop, listElementHeight)
    }

    setIsTouchEnd(true)
  }

  const handleTouchStart = () => {
    setIsTouchEnd(false)
  }

  const handleWheelCapture = () => {
    isScrollingRef.current = true
  }

  const handleClick = () => {
    setClickCount(clickCount + 1)

    const clickThreshold = initialFocusType === 'TAB' ? 0 : 1

    if (clickCount < clickThreshold) {
      return
    }

    onInputTypeChange('NUMBER_INPUT_TYPE')
  }

  return {
    handleScroll,
    handleMouseDown,
    handleFocus,
    handleBlur,
    handleTouchEnd,
    handleTouchStart,
    handleWheelCapture,
    handleClick,
    placeholderOpacity,
    isInitialFocus,
  }
}
