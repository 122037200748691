import { FaceWrapper } from '@top/ui/src/SceneModules/NumericScale/components/NumericScaleItem/ItemTypes/Face/components/FaceWrapper'
import { type FaceItemProps } from '@top/ui/src/SceneModules/NumericScale/components/NumericScaleItem/ItemTypes/Face/Face'

const Three = (props: FaceItemProps) => {
  const { size, fill, secondaryColor } = props
  return (
    <FaceWrapper size={size}>
      <path
        d="M27.9766 4.6665C15.0966 4.6665 4.66663 15.1198 4.66663 27.9998C4.66663 40.8798 15.0966 51.3332 27.9766 51.3332C40.88 51.3332 51.3333 40.8798 51.3333 27.9998C51.3333 15.1198 40.88 4.6665 27.9766 4.6665Z"
        style={{ fill, transition: 'opacity 0.2s' }}
      />
      <path
        d="M6.66675 27.9998C6.66675 16.2218 16.2039 6.6665 27.9767 6.6665C39.7768 6.6665 49.3334 16.2257 49.3334 27.9998C49.3334 39.774 39.7768 49.3332 27.9767 49.3332C16.2039 49.3332 6.66675 39.7779 6.66675 27.9998Z"
        stroke={secondaryColor}
        strokeWidth="4"
      />

      <path
        d="M28.0001 36C26.2967 36 22.5001 36 21.0001 36C21.0001 36.3332 21.0001 38.5 21.0001 39C23.4268 39 25.4334 39 28.0001 39C30.5901 39 33.0001 39 35.0001 39C35.0001 37.5 35.0001 38.5 35.0001 36C33.5001 36 29.7034 36 28.0001 36Z"
        fill={secondaryColor}
      />
      <path
        d="M36.1667 25.6665C38.0997 25.6665 39.6667 24.0995 39.6667 22.1665C39.6667 20.2335 38.0997 18.6665 36.1667 18.6665C34.2338 18.6665 32.6667 20.2335 32.6667 22.1665C32.6667 24.0995 34.2338 25.6665 36.1667 25.6665Z"
        fill={secondaryColor}
      />
      <path
        d="M19.8333 25.6665C21.7662 25.6665 23.3333 24.0995 23.3333 22.1665C23.3333 20.2335 21.7662 18.6665 19.8333 18.6665C17.9003 18.6665 16.3333 20.2335 16.3333 22.1665C16.3333 24.0995 17.9003 25.6665 19.8333 25.6665Z"
        fill={secondaryColor}
      />
    </FaceWrapper>
  )
}

export default Three
