import { useRef } from 'react'
import ContentEditable, { type ContentEditableEvent } from 'react-contenteditable'

import { Button } from '@mui/material'

import { selectElementTextContent } from '@top/shared/src/helpers/selectElementTextContent'
import { convertFontSizeToNewFormat, convertFormatsToCSS } from '@top/shared/src/style/helpers'
import { type ButtonStyle } from '@top/shared/src/style/types'
import { FocusedBorder } from '@top/ui/src/SceneModules/components/FocusedBorder'

import DOMPurify from 'dompurify'

type Props = {
  onClick?: () => void
  disabled: boolean
  style: ButtonStyle
  text: string
  parentTestId?: string
  onChange?: (text: string) => void
  isBuilder?: boolean
  widgetWidth?: number
  onFocus?: () => void
  onBlur?: () => void
  focused?: boolean
  showError?: boolean
}

export function ContainedButton(props: Props) {
  const {
    text,
    onClick,
    disabled,
    style,
    parentTestId,
    onChange,
    isBuilder,
    widgetWidth,
    onFocus,
    onBlur,
    focused = false,
    showError = false,
  } = props

  const contentEditableRef = useRef<HTMLElement>(null)

  const {
    maxHeight,
    buttonWidth: width,
    backgroundColor,
    fontSize,
    fontFamily,
    color,
    italic,
    bold,
    underline,
  } = style

  const testId = parentTestId ? `${parentTestId}-contained` : undefined

  const hasSelectedWidth = width !== undefined

  const fontSizeValue = convertFontSizeToNewFormat(fontSize, widgetWidth)
  const { textDecoration, fontStyle, fontWeight } = convertFormatsToCSS({
    italic,
    bold,
    underline,
  })

  const handleChange = (event: ContentEditableEvent) => {
    const sanitized = DOMPurify.sanitize(event.target.value)
    if (onChange) {
      onChange(sanitized)
    }
  }

  const handleKeyDown = (e: React.KeyboardEvent<HTMLElement>) => {
    if (e.key === 'Escape') {
      if (contentEditableRef.current) {
        contentEditableRef.current.blur()
      }

      if (onBlur) {
        onBlur()
      }
    }
  }

  const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation()

    if (onClick) {
      onClick()
    }
  }

  const handleFocus = (e: React.FocusEvent<HTMLDivElement, Element>) => {
    selectElementTextContent(e.target)

    if (onFocus) {
      onFocus()
    }
  }

  return (
    <FocusedBorder
      isFocused={isBuilder && focused}
      hover={isBuilder && !focused}
      hasError={showError}
      disabled={!isBuilder || (isBuilder && disabled)}
    >
      <Button
        data-testid={testId}
        sx={{
          fontSize: fontSizeValue,
          fontFamily,
          color,
          width,
          textDecoration,
          fontStyle,
          fontWeight,
          maxHeight,
          maxWidth: '80%',
          wordWrap: 'break-word',
          whiteSpace: 'pre-wrap',
          border: 'none',
          background: 'none',
          overflow: 'hidden',
          lineHeight: 1.3,
          margin: '0.5rem 0',
          ...(hasSelectedWidth && {
            padding: '0.3rem',
            justifyContent: 'center',
            maxWidth: 'none',
          }),
          '&:hover': {
            backgroundColor,
          },
          '&:disabled': {
            color,
          },
        }}
        style={{ backgroundColor }}
        onClick={handleClick}
        disabled={disabled}
        disableRipple
      >
        {isBuilder ? (
          <ContentEditable
            html={text}
            tagName="span"
            onChange={handleChange}
            disabled={disabled}
            onFocus={handleFocus}
            spellCheck={false}
            style={{
              whiteSpace: 'pre-wrap',
              outline: '0px',
              minHeight: '100%',
              minWidth: '5px',
              cursor: focused ? 'text' : 'pointer',
            }}
            innerRef={contentEditableRef}
            onKeyDown={handleKeyDown}
          />
        ) : (
          <span style={{ outline: '0px' }}>{text}</span>
        )}
      </Button>
    </FocusedBorder>
  )
}
