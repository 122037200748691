import { getResponseBody, VCTraceID } from './helpers'
import { getConfigs, isDash } from './configs'

var fetchWithRetry = require('fetch-retry')(fetch)
const API_RETRIES = 3

const fetchHelper = async ({
  url,
  body,
  headers,
  traceId,
  locationPath,
  isForm,
  withRetry,
}: {
  url: string
  body: any
  traceId: string
  headers?: any
  locationPath?: string
  isForm?: boolean
  withRetry?: boolean
}): Promise<{ data: any; error: any; statusCode: any }> => {
  const { API_URL } = getConfigs()
  const req = {
    method: 'POST',
    body: !isForm ? JSON.stringify({ ...body, ts: Date.now() }) : body,
    ...(isDash() && { mode: 'cors' as RequestMode, credentials: 'include' as RequestCredentials }),
    headers: {
      ...headers,
      ...(!isForm && { 'Content-Type': 'application/json' }),
      ...(!!locationPath && { 'X-FE-Route': locationPath }),
      [VCTraceID.header]: traceId,
    },
  }

  const api = `${API_URL}${url}`
  let resp: Response
  if (withRetry) {
    const retryOn = (attempt: any, error: any, response: any): boolean => {
      // Retry on any network error, or 5xx status codes
      return (error !== null || response.status >= 500) && attempt < API_RETRIES - 1
    }
    resp = await fetchWithRetry(api, { ...req, retryOn })
  } else {
    resp = await fetch(api, req)
  }

  const response: { error: any; data: unknown; statusCode: any } = {
    error: { data: undefined, shouldReport: false },
    data: undefined,
    statusCode: resp.status,
  }
  if (resp.status !== 200) {
    //we handle all errors on client side
    const error = await getResponseBody(resp)
    if (typeof error !== 'string') {
      response.error.data = { ...error }
    } else {
      response.error.data = error
    }
    if (isDash()) {
      if (resp.status >= 500 || resp.status === 404) {
        // we only report errors to sentry that their status is >=500
        response.error.shouldReport = true
      }
    } else {
      // For dist we should report all 5XX Errors
      if (resp.status >= 500) {
        response.error.shouldReport = true
      }
    }
  } else {
    const data = await resp.json()
    if (data['x-redirect-url']) {
      // BE needs to redirect the FE App
      window.location.replace(data['x-redirect-url'])
      return response
    }
    response.data = { ...data }
  }
  return response
}

export default fetchHelper
