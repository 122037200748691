import { useEffect } from 'react'

import Box from '@mui/material/Box'
import { ThemeProvider } from '@mui/material/styles'

import theme from '@top/shared/src/style/theme'
import { useGetPreferredLanguage } from '@top/shared_deprecated/hooks/useGetPreferredLanguage'
import ActivityProgressBar from '@top/shared_deprecated/src/components/ActivityProgressBar'
import { Locale } from '@top/shared_deprecated/src/types/Languages'

import SceneAnimator from '../shared/SceneTransitions'

import Scene from './Scene'

import '../shared/ScenesSharedStyle.css'
import './styles.scss'

import RespondentLanguageSelector from 'components/RespondentLanguageSelector'
import SceneFooter_DEPRECATED from 'components/shared/SceneFooter_DEPRECATED'
import { useDistribution } from 'contexts/Distribution'
import { useTracker_DEPRECATED } from 'contexts/Tracker_DEPRECATED'
import useCustomFonts from 'hooks/useCustomFonts'

const Scenes = () => {
  const {
    hasProgressBar,
    progressBarRef,
    progressBarPayload: { percent, style },
    isIframe,
    hash,
    orgUid,
    locales,
    localeOverride,
    isDashboardPreview,
    scenes,
    handleSelectedLocale,
    allowLocaleSelection,
    defaultLocale,
  } = useDistribution()
  const { currentSceneUuid, onResetTrackerEvents } = useTracker_DEPRECATED()
  useCustomFonts(hash, orgUid)

  const { localeCode } = useGetPreferredLanguage(locales, defaultLocale)
  const userLocaleOrOverride = localeOverride ?? localeCode

  const isFirstScene = (scenes && scenes[0].uuid === currentSceneUuid) ?? false
  const backgroundItem = scenes && scenes[0].items.ITEM_BACKGROUND

  const hasLanguageSelector =
    allowLocaleSelection && locales.length > 1 && isFirstScene && !!backgroundItem

  const progressBarClasses = `ActivityProgressBarContainer ${isIframe ? 'isIframe' : ''} ${
    hasLanguageSelector ? 'hasLanguageSelector' : ''
  }`

  const onChangeSelectedLocale = (locale: Locale) => {
    onResetTrackerEvents()
    handleSelectedLocale(locale)
  }

  useEffect(() => {
    document.documentElement.lang = userLocaleOrOverride.toLocaleLowerCase()
  }, [userLocaleOrOverride])

  return (
    <>
      {hasLanguageSelector && (
        <ThemeProvider theme={theme}>
          <Box className="LanguageSelectorContainer">
            <RespondentLanguageSelector
              locales={locales}
              selectedLocale={userLocaleOrOverride}
              onChange={onChangeSelectedLocale}
              backgroundColor={backgroundItem.style.backgroundColor}
              backgroundType={backgroundItem.style.backgroundType}
            />
          </Box>
        </ThemeProvider>
      )}
      {hasProgressBar && (
        <Box className={progressBarClasses} ref={progressBarRef}>
          <ActivityProgressBar barColor={style?.color as string} percentComplete={percent} />
        </Box>
      )}
      <SceneAnimator>
        {({ scene, ...rest }) => (
          <Scene
            locale={userLocaleOrOverride}
            scene={scene}
            isDashboardPreview={isDashboardPreview}
            hasLanguageSelector={hasLanguageSelector}
            {...rest}
          />
        )}
      </SceneAnimator>
      <SceneFooter_DEPRECATED />
    </>
  )
}

export default Scenes
