import React, { useMemo } from 'react'

import { Box } from '@mui/material'

import {
  type PinwheelNumberInputProps,
  type PinWheelTypes,
} from '@top/ui/src/SceneModules/DateEntry/types'

import ArrowButton from './ArrowButton'
import ListSelectInput from './ListSelectInput'

import { v4 as uuid } from 'uuid'

export type PinWheelSelectProps = {
  selectOptions: number[]
  onScrollChange: (scrollValue: number, heightMultiplier: number) => void
  pinWheelType: PinWheelTypes
  clickCount: number
  setClickCount: (count: number) => void
  onResetInputType: () => void
  hasError?: boolean
  labelText?: string
} & PinwheelNumberInputProps

const PinWheelSelect = (props: PinWheelSelectProps) => {
  const {
    pinWheelType,
    isFocusedRef,
    onIncrementChange,
    onResetInputType,
    placeholder = '',
    max,
    min,
    inputValue,
  } = props

  const id = useMemo(() => {
    return uuid().slice(0, 3)
  }, [])

  const isPinWheelArrows = pinWheelType === 'PIN_WHEEL_ARROWS'

  const showArrows = pinWheelType === 'PIN_WHEEL_ARROWS' && isFocusedRef.current

  const disableUpArrow = (inputValue !== undefined && inputValue >= max) || !showArrows
  const disableDownArrow = inputValue === undefined || inputValue <= min || !showArrows

  const handleKeyDown = (event: React.KeyboardEvent) => {
    /**
     * We want to disable the default behavior of the arrow keys, in favor of our own custom
     * behavior.
     */
    if (event.key === 'ArrowDown' || event.key === 'ArrowUp') {
      event.preventDefault()
    }

    if (event.key === 'ArrowUp' && !disableUpArrow) {
      onIncrementChange('INCREMENT')
    }

    if (event.key === 'ArrowDown' && !disableDownArrow) {
      onIncrementChange('DECREMENT')
    }

    if (event.key === 'Tab') {
      onResetInputType()
    }
  }

  return (
    <Box onKeyDown={handleKeyDown} overflow="hidden">
      {isPinWheelArrows && (
        <ArrowButton
          onClick={() => onIncrementChange('INCREMENT')}
          disabled={disableUpArrow}
          isHidden={!isFocusedRef.current}
          type="INCREMENT"
        />
      )}
      <ListSelectInput id={id} placeholder={placeholder} {...props} />
      {isPinWheelArrows && (
        <ArrowButton
          onClick={() => onIncrementChange('DECREMENT')}
          disabled={disableDownArrow}
          isHidden={!isFocusedRef.current}
          type="DECREMENT"
        />
      )}
    </Box>
  )
}

export default PinWheelSelect
