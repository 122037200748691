import React from 'react'

import { Box } from '@mui/material'

import COLORS from '@top/shared/src/style/colors'

/** Overlay that appears on top of selected image preset. */
const SelectedOverlay: React.FC<React.PropsWithChildren<unknown>> = () => {
  return (
    <Box
      height="30%"
      width="30%"
      maxHeight="50px"
      maxWidth="50px"
      zIndex={1000}
      position="absolute"
      top="0"
      borderRadius="14px 0"
      sx={{
        backgroundColor: COLORS.OVERLAY_DARK,
        clipPath: 'polygon(0 0, 100% 0%, 50% 50%, 0% 100%)',
      }}
    >
      <svg
        width="43%"
        height="43%"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        style={{
          position: 'absolute',
          top: '8%',
          left: '8%',
        }}
      >
        <path
          d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM10 17L5 12L6.41 10.59L10 14.17L17.59 6.58L19 8L10 17Z"
          fill="white"
        />
      </svg>
    </Box>
  )
}

export default SelectedOverlay
