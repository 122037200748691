import { createTheme, darken } from '@mui/material/styles'
import createBreakpoints from '@mui/system/createTheme/createBreakpoints'

import COLORS from '@top/shared/src/style/colors'

const breakpoints = createBreakpoints({})
const border = `2px solid ${COLORS.DIVIDER_LIGHT}`

const theme = createTheme({
  breakpoints: {
    values: {
      xs: 480,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1536,
    },
  },
  typography: {
    fontFamily: `'Roboto','Helvetica','BlinkMacSystemFont','Segoe UI','Oxygen'`,
    fontSize: 16,
    h1: {
      fontSize: '2rem',
      fontWeight: 700,
    },
    h2: {
      fontSize: '1.5rem',
      fontWeight: 700,
    },
    h3: {
      fontSize: '1.4rem',
      fontWeight: 700,
    },
    h4: {
      fontWeight: 700,
    },
    h5: {
      fontWeight: 700,
    },
    h6: {
      fontWeight: 700,
    },
    allVariants: {
      color: COLORS.TEXT_DARK,
    },
  },
  palette: {
    primary: {
      main: COLORS.TOUCHPOINT_NAVY,
      contrastText: '#fff',
    },
    secondary: {
      main: '#614C4E',
    },
    background: {
      default: COLORS.BACKGROUND,
    },
    error: {
      main: '#FF2424',
    },
    text: {
      primary: COLORS.TEXT_DARK,
    },
  },
  zIndex: {
    modal: 2000,
  },
  components: {
    MuiMenu: {
      defaultProps: {
        PaperProps: {
          elevation: 2,
          variant: 'elevation',
        },
      },
    },
    MuiAppBar: {
      styleOverrides: {
        root: {
          padding: '.5%',
          elevation: 0,
          border: 'none',
        },
        colorPrimary: {
          backgroundColor: '#FFFFF',
          color: COLORS.TEXT_DARK,
        },
        colorSecondary: {
          backgroundColor: COLORS.TOUCHPOINT_NAVY,
          color: '#fff',
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          '&:hover': {
            backgroundColor: COLORS.HIGHLIGHT_ROW,
          },
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        indicator: {
          height: 3,
          backgroundColor: COLORS.PRIMARY,
        },
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          color: COLORS.INPUT_CONTROL_OUTLINE,
        },
        colorPrimary: {
          '&.Mui-checked': {
            color: COLORS.PRIMARY,
            '&.Mui-disabled ': {
              color: COLORS.DISABLED_DARK,
            },
          },
        },
      },
    },
    MuiRadio: {
      styleOverrides: {
        root: {
          color: COLORS.INPUT_CONTROL_OUTLINE,
        },
        colorPrimary: {
          '&.Mui-checked': {
            color: COLORS.PRIMARY,
            '&.Mui-disabled': {
              color: COLORS.DISABLED_DARK,
            },
          },
        },
      },
    },
    MuiDivider: {
      styleOverrides: {
        root: {
          backgroundColor: '#8e8e8e',
          marginTop: '.5rem',
          marginBottom: '.5rem',
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        notchedOutline: {
          border: border,
          borderColor: COLORS.DIVIDER_LIGHT,
        },
      },
    },
    MuiInputAdornment: {
      styleOverrides: {
        root: {
          [breakpoints.down('sm')]: {
            maxWidth: '60%',
          },
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'none',
        },
        containedPrimary: {
          color: '#FFFFFF',
          backgroundColor: COLORS.PRIMARY,
          '&:hover': {
            backgroundColor: COLORS.PRIMARY_HOVER,
          },
          boxShadow: `0px 1px 1px ${COLORS.BOX_SHADOW}`,
          '&:disabled': {
            background: '#787878',
            color: '#FFFFFF',
          },
        },
        outlinedPrimary: {
          color: COLORS.TEXT_LIGHT,
          backgroundColor: '#FFFFFF',
          border: `2px solid ${COLORS.DIVIDER_LIGHT}`,
          '&:hover': {
            backgroundColor: '#FFFFFF',
            border: `2px solid ${COLORS.SECONDARY_HOVER}`,
          },
          '&:focused': {
            backgroundColor: '#FFFFFF',
            border: `2px solid ${COLORS.SECONDARY_PRESSED}`,
          },
          '&:disabled': {
            border: `2px solid ${COLORS.DIVIDER_LIGHT}`,
          },
          boxShadow: 'none',
        },
        outlinedSecondary: {
          color: '#FFF',
          backgroundColor: COLORS.PRIMARY,
          border: `2px solid ${COLORS.PRIMARY}`,
          '&:hover': {
            backgroundColor: darken(COLORS.PRIMARY, 0.2),
            border: `2px solid ${darken(COLORS.PRIMARY, 0.2)}`,
          },
          '&:focused': {
            backgroundColor: COLORS.PRIMARY,
            border: `2px solid ${COLORS.SECONDARY_PRESSED}`,
          },
          '&:disabled': {
            border: `2px solid ${COLORS.DIVIDER_LIGHT}`,
          },
          boxShadow: 'none',
        },
        outlined: {
          backgroundColor: '#FFFFFF',
          border: `2px solid ${COLORS.DIVIDER_LIGHT}`,
          '&:hover': {
            backgroundColor: COLORS.SECONDARY_HOVER,
            border: `2px solid ${COLORS.SECONDARY_HOVER}`,
          },
          '&:focused': {
            backgroundColor: COLORS.SECONDARY_HOVER,
            border: `2px solid ${COLORS.SECONDARY_PRESSED}`,
          },
          '&:disabled': {
            backgroundColor: COLORS.DISABLED_BACKGROUND,
            border: `2px solid ${COLORS.DIVIDER_LIGHT}`,
          },
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        sizeSmall: {
          paddingRight: '5px',
          paddingLeft: '5px',
        },
      },
    },
    MuiDialog: {
      styleOverrides: {
        paper: {
          borderRadius: '8px',
          borderWidth: '1px',
        },
      },
    },
    MuiPaper: {
      defaultProps: { variant: 'outlined' },
      styleOverrides: {
        outlined: {
          border: border,
        },
        root: {
          '& .top-MuiPickersBasePicker-pickerView': {
            '& .top-MuiPickersDay-day .top-MuiTypography-root': {
              fontWeight: 700,
            },
            '& .top-MuiPickersCalendarHeader-switchHeader': {
              padding: 20,
              '& .top-MuiTypography-root': {
                fontWeight: 700,
              },
            },
            '& .top-MuiPickersCalendarHeader-daysHeader': {
              '& .top-MuiPickersCalendarHeader-dayLabel': {
                fontWeight: 700,
              },
            },
            '& .top-MuiPickersCalendarHeader-iconButton': {
              borderRadius: 4,
              padding: 5,
              color: COLORS.TEXT_DARK,
              backgroundColor: COLORS.DISABLED_BACKGROUND,
              border: `2px solid ${COLORS.BORDER_GRAY}`,
            },
            '& .top-MuiPickersDay-daySelected': {
              backgroundColor: COLORS.PRIMARY,
            },
            '& .top-MuiPickersDay-hidden': {
              opacity: 0.3,
            },
          },
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          border: `1px solid ${COLORS.DIVIDER_LIGHT}`,
          borderRadius: '8px',
        },
      },
    },
    MuiFab: {
      styleOverrides: {
        primary: {
          backgroundColor: COLORS.PRIMARY,
        },
      },
    },
    MuiCssBaseline: {
      styleOverrides: {
        a: {
          textDecoration: 'none',
          color: 'inherit',
          '&:hover': {
            textDecoration: 'underline',
          },
          '&:visited,&:focus&:link': {
            textDecoration: 'none',
            color: 'inherit',
          },
        },
        '.btn-with-icon svg': {
          marginRight: '.5rem',
        },
      },
    },
  },
})

export default theme
