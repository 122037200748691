import { useEffect, useRef } from 'react'

/** A custom React hook that returns the previous value of a given variable during re-renders. */
export const usePrevious = <T>(value: T): T | undefined => {
  const ref = useRef<T>()
  useEffect(() => {
    ref.current = value
  })
  return ref.current
}
