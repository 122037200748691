export enum Swipe {
  UP = 'UP',
  LEFT = 'LEFT',
}

export enum Transition {
  SLIDE_UP = 'slide-up',
  SLIDE_LEFT = 'slide-left',
  FlIP = 'flip',
  SLIDE_UP_OVER = 'slide-up-over',
  ZOOM = 'zoom',
}
