import { useRef } from 'react'

import CancelIcon from '@mui/icons-material/Cancel'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import { Box, TextField, Typography } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'

import COLORS from '@top/shared/src/style/colors'
import { Fonts } from '@top/shared/src/style/types'
import { type CommonSceneModuleProps } from '@top/ui/src/SceneModules/types'

import DOMPurify from 'dompurify'

const useStyles = makeStyles({
  input: {
    height: '0.75rem',
    padding: '16px 8px',
    borderRadius: '4px',
    fontSize: '0.75rem',
    '&::placeholder': {
      color: COLORS.DISABLED_DARK,
      opacity: 1,
    },
  },
  error: {
    backgroundColor: COLORS.ERROR_BACKGROUND,
  },
  disabled: { backgroundColor: COLORS.DISABLED_LIGHT },
})

export type EmailInputProps = {
  /**
   * True if the email input value is valid.
   *
   * @default false
   */
  isValid?: boolean
  showEmailError?: boolean
  /**
   * True if the email imput is readOnly.
   *
   * @default false
   */
  readOnly?: boolean
  placeholder: string
  /** Function triggered when the user clicks inside the email input. */
  onFocus?: () => void
  /** Function triggered when the user clicks outside of the email input. */
  onBlur?: (
    newValue: string,
    options?: {
      clearSelectedElement: boolean
    }
  ) => void
}

type Props = Omit<CommonSceneModuleProps<string>, 'testId' | 'showError'> &
  EmailInputProps & {
    placeholder: string
    /** If true, the TextField will be styled as if its enabled, but will not be editable. */
    readOnly?: boolean
    isValid?: boolean
    value: string
    onChange?: (text: string) => void
    parentTestId?: string
    isParentFocused?: boolean
  }

/**
 * When `isBuilder` is `true`, the TextField's value font color will imitate that of the
 * placeholder.
 */
export function EmailInput(props: Props) {
  const {
    placeholder,
    disabled = false,
    showEmailError: showError = false,
    readOnly = false,
    value = '',
    onFocus,
    onBlur,
    error = '',
    isBuilder = false,
    isValid = false,
    onChange,
    parentTestId,
    isParentFocused = false,
    isFocused = false,
  } = props

  const inputRef = useRef<HTMLInputElement>(null)

  const classes = useStyles()

  const testId = parentTestId ? `${parentTestId}-email-input` : undefined

  const builderStyles = {
    color: isBuilder ? COLORS.DISABLED_DARK : undefined,
    cursor: isBuilder && !isParentFocused && !disabled ? 'pointer' : undefined,
  }

  const inputType = isBuilder ? 'text' : 'email'

  const hasEndAdornment = !isBuilder && (isValid || showError)
  const adornmentType = hasEndAdornment && isValid ? 'check' : 'error'

  const EndAdornment = () => {
    switch (adornmentType) {
      case 'check':
        return <CheckCircleIcon sx={{ color: COLORS.LIVE }} />
      case 'error':
        return <CancelIcon sx={{ color: COLORS.ERROR }} />
      default:
        return null
    }
  }

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const sanitized = DOMPurify.sanitize(event.target.value)
    if (onChange) {
      onChange(sanitized)
    }
  }

  const handleBlur = (e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    if (onBlur) {
      onBlur(e.currentTarget.value)
    }
  }

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Escape') {
      if (inputRef.current) {
        inputRef.current.blur()
      }

      if (onBlur && inputRef.current?.value) {
        onBlur(inputRef.current.value, {
          clearSelectedElement: true,
        })
      }
    }
  }

  const handleOnClick = (e: React.MouseEvent<HTMLDivElement>) => {
    if (isBuilder) {
      e.stopPropagation()

      if (onFocus) {
        onFocus()
      }
    }
  }

  // If the parent is not focused, it should not be possible to focus child.
  const handleOnFocus = () => {
    if (isBuilder && !isParentFocused && inputRef.current) {
      inputRef.current.blur()
    }
  }

  /** This is required so the behavior in the builder is consistent with the other scene modules. */
  const inputSx = {
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: COLORS.BORDER_GRAY,
      },
      '&:hover fieldset': {
        borderColor: isBuilder && !isParentFocused ? COLORS.BORDER_GRAY : COLORS.TOUCHPOINT_NAVY,
      },
      '&.Mui-focused fieldset': {
        borderColor: isBuilder && !isFocused ? COLORS.BORDER_GRAY : COLORS.TOUCHPOINT_NAVY,
      },
      // Workaround due to the fact that the first click on the input will always add the `Mui-focused` class,
      // but we don't actually focus the input until the second click. Only relevant in builder.
      ...(isBuilder &&
        isParentFocused && {
          '&:hover fieldset': {
            borderColor: `${COLORS.TOUCHPOINT_NAVY} !important`,
          },
        }),
    },
  }

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      width="100%"
    >
      <TextField
        inputRef={inputRef}
        type={inputType}
        disabled={disabled}
        onKeyDown={handleKeyDown}
        data-testid={testId}
        placeholder={placeholder}
        aria-label="Email Input"
        variant="outlined"
        onClick={handleOnClick}
        onFocus={handleOnFocus}
        fullWidth
        classes={{ root: `${showError && classes.error} ${disabled && classes.disabled}` }}
        inputProps={{
          readOnly,
          className: classes.input,
          sx: builderStyles,
        }}
        InputProps={
          hasEndAdornment
            ? {
                endAdornment: <EndAdornment />,
              }
            : undefined
        }
        sx={{
          backgroundColor: COLORS.WHITE,
          ...inputSx,
        }}
        value={value}
        onChange={handleChange}
        onBlur={handleBlur}
        error={showError}
      />
      {showError && error && (
        <Typography
          color={COLORS.ERROR}
          fontWeight="normal"
          fontSize="0.875rem"
          fontFamily={Fonts.Roboto}
          variant="h3"
          padding="8px 0"
        >
          {error}
        </Typography>
      )}
    </Box>
  )
}

export default EmailInput
