import * as Sentry from '@sentry/react'
import { useCallback } from 'react'

interface IErrorReport {
  mainError?: unknown
  message: string
  severity: Sentry.SeverityLevel
  extras?: { [key: string]: any }
  tags?: { [key: string]: any }
}
const useSentry = () => {
  const report = useCallback((errorReport: IErrorReport) => {
    if (!errorReport) {
      return
    }
    const { message, severity, extras, tags, mainError } = errorReport
    if (!message || message.length === 0) {
      return
    }
    Sentry.withScope((scope) => {
      scope.setLevel(severity)
      tags &&
        Object.keys(tags).forEach((tagKey) => {
          scope.setExtra(tagKey, tags[tagKey])
        })
      scope.setTag('API_Error', 'true')
      if (mainError) {
        scope.setExtra('main_error', mainError)
      }
      extras &&
        Object.keys(extras).forEach((extraKey) => {
          scope.setExtra(extraKey, extras[extraKey])
        })
      if (process.env.NODE_ENV === 'development') {
        console.log(scope)
        return
      }
      Sentry.captureException(new Error(message))
    })
  }, [])

  return report
}

export default useSentry
