import { Box, type Theme } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'

import { FocusedBorder } from '@top/ui/src/SceneModules/components/FocusedBorder'
import { NumericScaleItems } from '@top/ui/src/SceneModules/NumericScale/components/NumericScaleItems'
import ResultText from '@top/ui/src/SceneModules/NumericScale/components/ResultText'
import { useNumericScaleLayout } from '@top/ui/src/SceneModules/NumericScale/hooks/useNumericScaleLayout'
import { type NumericScaleProps } from '@top/ui/src/SceneModules/NumericScale/types'
import { Textbox as PromptText } from '@top/ui/src/SceneModules/Textbox'
import { COMMON_DEFAULTS } from '@top/ui/src/SceneModules/types'

const getSelectedValue = (
  selections: NumericScaleProps['selections'],
  selectedUuid: string | undefined,
  isBuilder: boolean
) => {
  if (isBuilder) {
    // Return the first value if we are in the builder.
    return selections[0]?.etc.value ?? -1
  }
  return selections.find((selection) => selection.uuid === selectedUuid)?.etc.value ?? -1
}

const useStyles = makeStyles((theme: Theme) => {
  return {
    container: {
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
      maxHeight: '350px',
      alignItems: 'center',
      justifyContent: 'center',
      [theme.breakpoints.down('md')]: {
        marginTop: 0,
        maxHeight: '350px',
      },
    },
    promptTextCircular: {
      position: 'absolute',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      overflowY: 'auto',
      overflowX: 'hidden',
      top: '50%',
      transform: 'translateY(-20%)',
    },
    promptTextLinear: {
      margin: '1.5rem 0',
      display: 'flex',
      justifyContent: 'center',
    },
  }
})

export function NumericScale(props: NumericScaleProps) {
  const {
    disabled,
    isBuilder,
    testId,
    value: selectedUuid,
    onChange,
    promptTextStyle,
    selectionStyles,
    resultTextStyle,
    scaleRange,
    text: promptText = '',
    onPromptTextChange,
    selections,
    widgetWidth,
    onFocusPromptText,
    onFocusResultText,
    onPromptTextBlur,
    onFocus,
    isFocused,
    focusedChild,
    showPromptTextError = false,
  } = { ...COMMON_DEFAULTS, ...props }

  const classes = useStyles()

  const isShortScale = scaleRange === 'RANGE_ONE_TO_FIVE'
  const selectedValue = getSelectedValue(selections, selectedUuid, isBuilder)
  const isItemSelected = selectedValue > -1

  const isResultTextRendered = selectionStyles.shape !== 'NUMERIC_FACE'
  const isPromptTextRendered = isBuilder || !!promptText

  const {
    isCircularLayout,
    NUMERIC_SCALE_WIDTH,
    ITEM_WIDTH,
    MAX_PROMPT_TEXT_HEIGHT,
    PROMP_TEXT_WIDTH,
  } = useNumericScaleLayout(widgetWidth, isShortScale)

  return (
    <Box
      className={classes.container}
      data-testid={testId}
      {...(isShortScale && { height: 'auto', maxHeight: 'none' })}
      {...(isCircularLayout && {
        height: NUMERIC_SCALE_WIDTH,
        width: NUMERIC_SCALE_WIDTH,
        position: 'relative',
      })}
      marginBottom={isCircularLayout ? '2rem' : 0}
      onClick={(e) => {
        e.stopPropagation()

        if (onFocus) {
          onFocus()
        }
      }}
    >
      <Box
        {...(isCircularLayout && {
          height: '100%',
          width: '100%',
          justifyContent: 'center',
        })}
        {...(!isCircularLayout && {
          display: 'flex',
          alignItems: 'center',
          flexDirection: 'row',
          justifyContent: 'space-between',
          paddingTop: '2rem',
        })}
      >
        <NumericScaleItems
          selectedValue={selectedValue}
          disabled={disabled || isBuilder}
          selections={selections}
          selectionStyles={selectionStyles}
          onChange={onChange}
          isCircularLayout={isCircularLayout}
          containerWidth={NUMERIC_SCALE_WIDTH}
          parentTestId={testId}
          itemWidth={ITEM_WIDTH}
        />
      </Box>
      {isPromptTextRendered && (
        <Box
          className={isCircularLayout ? classes.promptTextCircular : classes.promptTextLinear}
          maxHeight={MAX_PROMPT_TEXT_HEIGHT}
          width={isCircularLayout ? PROMP_TEXT_WIDTH : '100%'}
        >
          <FocusedBorder
            isFocused={isBuilder && focusedChild === 'promptText'}
            hover={isBuilder && isFocused}
            hasError={isBuilder && showPromptTextError}
            disabled={!isBuilder || (isBuilder && disabled)}
          >
            <PromptText
              text={promptText}
              style={promptTextStyle}
              onChange={onPromptTextChange}
              onBlur={onPromptTextBlur}
              disabled={disabled || focusedChild !== 'promptText'}
              isBuilder={isBuilder}
              widgetWidth={widgetWidth}
              onFocus={onFocusPromptText}
              {...(testId && { testId: `${testId}-prompt-text` })}
            />
          </FocusedBorder>
        </Box>
      )}
      {isResultTextRendered && (
        <Box
          {...(isCircularLayout && {
            sx: {
              position: 'relative',
              bottom: '-5%',
            },
          })}
        >
          <FocusedBorder
            isFocused={isBuilder && focusedChild === 'resultText'}
            hover={isBuilder && isFocused}
            disabled={!isBuilder || (isBuilder && disabled)}
          >
            <ResultText
              result={`${selectedValue}`}
              style={resultTextStyle}
              isHidden={!isBuilder && !isItemSelected}
              parentTestId={testId}
              widgetWidth={widgetWidth}
              onFocus={onFocusResultText}
            />
          </FocusedBorder>
        </Box>
      )}
    </Box>
  )
}
