import { Box } from '@mui/material'

import { FocusedBorder } from '@top/ui/src/SceneModules/components/FocusedBorder'
import { getSelectedValues } from '@top/ui/src/SceneModules/helpers/getSelectedValues'
import { hasBuilderSelectionError } from '@top/ui/src/SceneModules/helpers/hasSelectionError'
import { ImageSelection } from '@top/ui/src/SceneModules/ImagePreset/components/ImageSelection'
import { SelectionsLayoutManager } from '@top/ui/src/SceneModules/ImagePreset/components/SelectionsLayoutManager'
import {
  ImageOrientationAspectRatioMap,
  type ImagePresetProps,
} from '@top/ui/src/SceneModules/ImagePreset/types'
import { COMMON_DEFAULTS } from '@top/ui/src/SceneModules/types'

import { PromptText } from '../components/PromptText'

export function ImagePreset(props: ImagePresetProps) {
  const {
    selections,
    value,
    onChange,
    isBuilder,
    disabled,
    testId,
    promptText,
    showPromptText,
    promptTextStyle,
    onPromptTextChange,
    isMultiChoice = false,
    imageOrientation,
    widgetWidth,
    onFocusPromptText,
    pinnedSelections = [],
    exclusiveSelections = [],
    isSelectionLabelAlwaysShown,
    onOpenModal,
    onRemoveImage,
    onOpenEditorModal,
    onFocusSelection,
    builderSelectionsErrors,
    onFocus,
    focusedChild,
  } = { ...COMMON_DEFAULTS, ...props }

  const shouldRenderPromptText = promptText && showPromptText && promptTextStyle
  const aspectRatio = ImageOrientationAspectRatioMap[imageOrientation]

  const handleOnSelect = (selectionId: string) => {
    if (!onChange) {
      return
    }

    const selectedValues = getSelectedValues(selectionId, value, isMultiChoice)

    onChange(selectedValues)
  }

  const handlePromptTextChange = (newPromptText: string) => {
    if (!isBuilder) {
      return
    }
    if (onPromptTextChange) {
      onPromptTextChange(newPromptText)
    }
  }

  const handlePromptTextFocus = () => {
    if (onFocusPromptText) {
      onFocusPromptText()
    }
  }

  const handleOpenModal = (selectionUuid: string) => {
    if (!isBuilder) {
      return
    }
    if (onOpenModal) {
      onOpenModal()
      onFocusSelection(selectionUuid)
    }
  }

  const handleOnFocus = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.stopPropagation()
    if (onFocus) {
      onFocus()
    }
  }

  const handleOnFocusSelection = (selectionUuid: string) => {
    if (!isBuilder) {
      return
    }
    if (onFocusSelection) {
      onFocusSelection(selectionUuid)
    }
  }

  const handleRemoveImage = () => {
    if (!isBuilder) {
      return
    }
    if (onRemoveImage) {
      onRemoveImage()
    }
  }

  const handleOpenEditorModal = () => {
    if (!isBuilder) {
      return
    }
    if (onOpenEditorModal) {
      onOpenEditorModal()
    }
  }

  const renderedSelections = selections.map((selection) => {
    const {
      uuid,
      etc: { image_url, image_alt_text, position },
      style,
    } = selection

    const imageStyles = { ...style, aspectRatio }

    const selectionKey = `selection-${uuid}`
    const isSelected = value.includes(uuid)

    const isPinned = pinnedSelections.includes(uuid)
    const isExclusive = exclusiveSelections.includes(uuid)

    const handleSelectionClick = () => {
      if (isBuilder) {
        return
      }

      handleOnSelect(uuid)
    }

    const hasBuilderError = builderSelectionsErrors
      ? hasBuilderSelectionError(builderSelectionsErrors, uuid, {
          isImageSelection: true,
          hasTextProperty: false,
        })
      : false

    return (
      <FocusedBorder key={selectionKey} hasError={hasBuilderError}>
        <ImageSelection
          parentTestId={testId}
          disabled={disabled}
          selected={isSelected}
          isBuilder={isBuilder}
          onClick={handleSelectionClick}
          image_url={image_url}
          image_alt_text={image_alt_text}
          imageStyles={imageStyles}
          position={position}
          isPinned={isPinned}
          isExclusive={isExclusive}
          aspectRatio={aspectRatio}
          isSelectionLabelAlwaysShown={isSelectionLabelAlwaysShown}
          onOpenModal={() => handleOpenModal(uuid)}
          onRemoveImage={handleRemoveImage}
          onOpenEditorModal={handleOpenEditorModal}
          onFocus={() => handleOnFocusSelection(uuid)}
        />
      </FocusedBorder>
    )
  })

  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      gap={2}
      width="100%"
      onClick={handleOnFocus}
    >
      {shouldRenderPromptText && (
        <PromptText
          onFocus={handlePromptTextFocus}
          text={promptText}
          styles={promptTextStyle}
          parentTestId={testId}
          isBuilder={isBuilder}
          disabled={disabled}
          onChange={handlePromptTextChange}
          widgetWidth={widgetWidth}
          isFocused={focusedChild?.type === 'promptText'}
          fullWidth
        />
      )}
      <SelectionsLayoutManager
        imageSelections={renderedSelections}
        imageOrientation={imageOrientation}
        aspectRatio={aspectRatio}
      />
    </Box>
  )
}
