import LanguageIcon from '@mui/icons-material/Language'
import Box from '@mui/material/Box'
import FormControl from '@mui/material/FormControl'
import MenuItem from '@mui/material/MenuItem'
import Select, { SelectChangeEvent } from '@mui/material/Select'
import { Theme } from '@mui/material/styles'
import Typography from '@mui/material/Typography'
import { createStyles, makeStyles } from '@mui/styles'

import COLORS from '@top/shared/src/style/colors'
import { BackgroundType } from '@top/shared_deprecated/src/types/Items'
import { Locale, localeToTranslatedLanguageName } from '@top/shared_deprecated/src/types/Languages'

import color from 'color'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    select: {
      maxWidth: 'fit-content',
      height: '32px',
      paddingLeft: '6px',
      boxShadow: 'none',
      [theme.breakpoints.down('sm')]: {
        height: '28px',
      },
      '& .MuiOutlinedInput-notchedOutline': { border: 0 },
      '& .MuiSvgIcon-root': {
        fill: COLORS.WHITE,
        [theme.breakpoints.down('sm')]: {
          fontSize: '1.25rem',
        },
      },
    },
    paper: {
      maxHeight: '250px',
      '& .MuiList-padding': {
        padding: 0,
      },
    },
    list: {
      width: 184,
      [theme.breakpoints.down('sm')]: {
        width: 160,
      },
    },
    icon: {
      color: COLORS.WHITE,
      fontSize: '24px !important',
      [theme.breakpoints.down('sm')]: {
        fontSize: '18px !important',
      },
    },
    selectText: {
      [theme.breakpoints.down('sm')]: {
        fontSize: '0.75rem !important',
        paddingTop: '4px !important',
        paddingRight: '0px !important',
      },
    },
  })
)

type Props = {
  locales: Locale[]
  selectedLocale: Locale
  onChange: (locale: Locale) => void
  backgroundType: BackgroundType
  backgroundColor: string
}

const RespondentLanguageSelector = (props: Props) => {
  const { locales, selectedLocale, onChange, backgroundType, backgroundColor } = props

  const textColor = color(COLORS.WHITE)

  const classes = useStyles()

  /**
   * Determine if the background color has poor contrast compared to text color
   */
  const backgroundHasPoorContrast =
    backgroundType === 'color' ? color(backgroundColor).contrast(textColor) < 2 : true

  const handleLanguageChange = (event: SelectChangeEvent<Locale>) => {
    const newLocale = event.target.value as Locale
    onChange(newLocale)
  }

  return (
    <Box padding="8px 0 0 16px" position="sticky">
      <FormControl variant="outlined" fullWidth>
        <Select
          aria-label="Select language of choice"
          value={selectedLocale}
          className={classes.select}
          sx={{
            backgroundColor: backgroundHasPoorContrast ? COLORS.OVERLAY_DARK : COLORS.OVERLAY_LIGHT,
          }}
          onChange={handleLanguageChange}
          startAdornment={<LanguageIcon className={classes.icon} />}
          renderValue={(locale) => (
            <Typography
              color={COLORS.WHITE}
              padding="3px 6px 0px"
              fontWeight={700}
              fontSize="0.875rem"
              className={classes.selectText}
            >
              {localeToTranslatedLanguageName[locale]}
            </Typography>
          )}
          MenuProps={{
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'left',
            },
            transformOrigin: {
              vertical: -8,
              horizontal: 0,
            },
            classes: { paper: classes.paper, list: classes.list },
          }}
        >
          {locales.map((locale) => {
            return (
              <MenuItem
                key={locale}
                value={locale}
                sx={{
                  minHeight: '36px',
                }}
              >
                <Typography fontWeight={500} paddingLeft="12px" fontSize=".875rem">
                  {localeToTranslatedLanguageName[locale]}
                </Typography>
              </MenuItem>
            )
          })}
        </Select>
      </FormControl>
    </Box>
  )
}

export default RespondentLanguageSelector
