import React, { useRef } from 'react'
import ContentEditable, { type ContentEditableEvent } from 'react-contenteditable'

import { selectElementTextContent } from '@top/shared/src/helpers/selectElementTextContent'
import COLORS from '@top/shared/src/style/colors'
import { getTextStyle } from '@top/shared/src/style/helpers'
import { Fonts, type TextStyle } from '@top/shared/src/style/types'
import { type CommonSceneModuleProps } from '@top/ui/src/SceneModules/types'

import DOMPurify from 'dompurify'

export const defaultTitleStyle = {
  color: COLORS.BLACK,
  fontFamily: Fonts.Roboto,
  fontSize: 16,
  bold: false,
  italic: false,
  underline: false,
  backgroundColor: 'transparent',
  textAlign: 'center',
} satisfies TextStyle

export const defaultSubtitleStyle = {
  ...defaultTitleStyle,
  fontSize: 12,
} satisfies TextStyle

export type TextboxSceneModuleProps = Omit<
  CommonSceneModuleProps<string>,
  'error' | 'showError'
> & {
  /** The text displayed in the textbox. */
  text: string
  style: TextStyle
  widgetWidth?: number
  onChange?: (value: string) => void
  onFocus?: () => void
  onBlur?: (value: string, options?: { clearSelectedElement: boolean }) => void
}

export function Textbox(props: TextboxSceneModuleProps) {
  const {
    testId,
    onBlur,
    onFocus,
    onChange,
    disabled = false,
    text,
    isBuilder = false,
    style,
    widgetWidth,
  } = props

  const textStyle = getTextStyle(style, widgetWidth)

  const textboxStyles = {
    fontWeight: 'normal',
    minHeight: `calc(${textStyle.fontSize} + 15px)`,
    display: 'block',
    outline: '0px',
    whiteSpace: 'pre-wrap',
    ...textStyle,
  }

  const inputRef = useRef<HTMLDivElement>(null)

  const handleChange = (event: ContentEditableEvent) => {
    const sanitized = DOMPurify.sanitize(event.target.value)

    if (onChange) {
      onChange(sanitized)
    }
  }

  const handleOnFocus = (e: React.FocusEvent<HTMLDivElement, Element>) => {
    selectElementTextContent(e.target)
  }

  const handleClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.stopPropagation()

    if (onFocus) {
      onFocus()
    }
  }

  const handleOnBlur = (e: React.FocusEvent<HTMLDivElement, Element>) => {
    const sanitized = DOMPurify.sanitize(e.currentTarget.textContent ?? '')

    if (onBlur) {
      onBlur(sanitized)
    }
  }

  /** The escape key will blur the input. This is not the default behavior for ContentEditable. */
  const handleKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.key === 'Escape') {
      if (inputRef.current) {
        inputRef.current.blur()
      }

      const sanitized = DOMPurify.sanitize(e.currentTarget.textContent ?? '')

      // If the text has changed, call the onBlur event.
      if (onBlur) {
        onBlur(sanitized, {
          clearSelectedElement: true,
        })
      }
    }
  }

  // Do not render in the distributor if there is no text.
  if (!isBuilder && !text) return null

  return (
    <ContentEditable
      innerRef={inputRef}
      data-testid={testId}
      html={text}
      tagName="h1"
      onKeyDown={handleKeyDown}
      onFocus={handleOnFocus}
      onClick={handleClick}
      onChange={handleChange}
      onBlur={handleOnBlur}
      disabled={disabled || !isBuilder}
      spellCheck="false"
      style={{ ...textboxStyles, padding: '0px', minWidth: isBuilder ? '3rem' : 'auto' }}
    />
  )
}
