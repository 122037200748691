import { Translations } from './Languages'
import { FlexibleOrientation, Orientation } from './Scene'
import { FontSizes, Alignments, TextAlign, ButtonWidth, ISkipButtonStyle } from './Style'

export interface IItem {
  etc: {
    text?: string
    translations?: Translations
    button_shown?: boolean
    redirect_url?: string
    button_type?: ButtonType
  }
  style: Partial<IStyle>
}

export enum ButtonType {
  contained = 'BUTTON_CONTAINED',
  arrow = 'BUTTON_ARROW',
  animated = 'BUTTON_ANIMATED',
  skip = 'BUTTON_SKIP',
}

interface IItemEtc {
  text: string
  translations: Translations
  button_shown?: boolean
  redirect_url?: string
  button_type: ButtonType
  position?: number
}

export interface IStyle extends IImageStyle {
  fontFamily: string
  fontSize: FontSizes
  buttonWidth: ButtonWidth
  bold: boolean
  italic: boolean
  underline: boolean
  color: string
  textAlign: TextAlign
  alignment: Alignments
  backgroundImage: string
  backgroundColor: string
  backgroundType: BackgroundType
  primaryColor: string
  secondaryColor: string
}

export interface IFlexibleStyle extends IFlexibleImageStyle {
  fontFamily: string
  fontSize: FontSizes
  bold: boolean
  italic: boolean
  underline: boolean
  color: string
  textAlign: TextAlign
  alignment: Alignments
  backgroundImage: string
  backgroundColor: string
  backgroundType: BackgroundType
  primaryColor: string
  secondaryColor: string
  imageOrientation: FlexibleOrientation
}

export interface IItems {
  ITEM_BUTTON: ItemButton
  ITEM_LOGO: ItemLogo
  ITEM_SUBTITLE: ItemSubtitle
  ITEM_SKIP_BUTTON: ItemSkipButton
  ITEM_TEXT_BACKGROUND: ItemTextBackground
  ITEM_TITLE: ItemTitle
  ITEM_BACKGROUND: ItemBackground
  ITEM_TOC: ItemTOC
  ITEM_HELPER_TEXT: IItem
  ITEM_RESULT_TEXT: ItemResultText
}

export interface ItemButton extends IItem {
  etc: Pick<IItemEtc, 'redirect_url' | 'text' | 'translations' | 'button_type'>
  style: Pick<IStyle, buttonStyle>
}

export interface ItemSkipButton extends IItem {
  etc: Pick<IItemEtc, 'redirect_url' | 'text' | 'translations' | 'button_type'>
  style: ISkipButtonStyle
}

type buttonStyle =
  | 'fontFamily'
  | 'fontSize'
  | 'bold'
  | 'italic'
  | 'underline'
  | 'color'
  | 'backgroundColor'
  | 'alignment'
  | 'buttonWidth'

interface ItemLogo extends IItem {
  style: Pick<IStyle, 'backgroundImage'>
}

type titleStyle =
  | 'fontFamily'
  | 'fontSize'
  | 'bold'
  | 'italic'
  | 'underline'
  | 'color'
  | 'textAlign'
  | 'backgroundColor'

interface ItemSubtitle extends IItem {
  etc: Pick<IItemEtc, 'text' | 'translations'>
  style: Pick<IStyle, titleStyle>
}
interface ItemTitle extends IItem {
  etc: Pick<IItemEtc, 'text' | 'translations'>
  style: Pick<IStyle, titleStyle>
}

interface ItemTextBackground extends IItem {
  style: Pick<IStyle, 'backgroundColor'>
}

interface ItemTOC extends IItem {
  style: Pick<IStyle, titleStyle>
  etc: Pick<IItemEtc, 'redirect_url' | 'text' | 'translations'>
}

interface ItemResultText extends IItem {
  style: Pick<IStyle, Exclude<titleStyle, 'textAlign'>>
}

interface ItemBackground extends IItem {
  style: Pick<IStyle, keyof IImageStyle | 'backgroundColor' | 'backgroundImage' | 'backgroundType'>
}

export interface IImageStyle {
  zoom: number
  cropHeight: number
  cropWidth: number
  cropX: number
  cropY: number
  blur: number
  saturation: number
  brightness: number
}

export interface IFlexibleImageStyle {
  zoom: number
  cropHeight: number
  cropWidth: number
  cropX: number
  cropY: number
  blur: number
  saturation: number
  brightness: number
  imageOrientation: FlexibleOrientation
  aspectRatio: number
  backgroundColor?: string
  accessibilityText?: string
}

export interface IBackground {
  style: {
    backgroundImage: string
    backgroundColor: string
    backgroundType: BackgroundType
  } & IImageStyle
}

export type BackgroundType = 'color' | 'image'
