import Box from '@mui/material/Box'

import COLORS from '@top/shared/src/style/colors'
import {
  type DateInputFormat,
  type DateInputStyle,
  Fonts,
  type TextStyle,
} from '@top/shared/src/style/types'
import { FocusedBorder } from '@top/ui/src/SceneModules/components/FocusedBorder'
import DateInput from '@top/ui/src/SceneModules/DateEntry/components/DateInput'
import DateInputDisplay from '@top/ui/src/SceneModules/DateEntry/components/DateInputDisplay'
import { type DateObject } from '@top/ui/src/SceneModules/DateEntry/types'
import { type CommonSceneModuleProps } from '@top/ui/src/SceneModules/types'

export const defaultDateDisplayStyle = {
  color: COLORS.BLACK,
  fontFamily: Fonts.Roboto,
  fontSize: 16,
  bold: false,
  italic: false,
  underline: false,
  backgroundColor: COLORS.WHITE,
  textAlign: 'center',
} as const satisfies TextStyle

export const defaultDateInputStyle = {
  fontFamily: Fonts.Roboto,
  color: COLORS.BLACK,
  bold: false,
  italic: false,
  underline: false,
} as const satisfies DateInputStyle

export type DateEntrySceneModuleProps = {
  /** @default undefined */
  date?: DateObject
  dateInputStyle: DateInputStyle
  dateDisplayStyle: TextStyle
  dateInputFormat: DateInputFormat
  isDateDisplayShown: boolean
  onChange?: (date: DateObject) => void
  onClickDateInput?: () => void
  widgetWidth?: number
  onClickDateDisplay?: () => void
  /**
   * @description When in the builder, this is the focused child element of the date entry scene
   *              module.
   */
  focusedChild?: 'dateDisplay' | 'dateInput'
} & CommonSceneModuleProps<string>

export function DateEntry(props: DateEntrySceneModuleProps) {
  const {
    date,
    dateInputFormat,
    showError = false,
    error = '',
    isBuilder = false,
    dateInputStyle,
    dateDisplayStyle,
    isDateDisplayShown,
    onChange,
    disabled = false,
    testId,
    onClickDateDisplay,
    onClickDateInput,
    isFocused = false,
    focusedChild,
    widgetWidth,
  } = props

  return (
    <Box
      width="100%"
      display="flex"
      flexDirection="column"
      alignContent="center"
      justifyContent="center"
      paddingTop="2rem"
      paddingBottom="2rem"
      data-testid={testId}
    >
      <FocusedBorder
        isFocused={isBuilder && focusedChild === 'dateInput'}
        hover={isBuilder && isFocused}
        disabled={!isBuilder || (isBuilder && disabled)}
      >
        <DateInput
          dateInputStyle={dateInputStyle}
          dateInputFormat={dateInputFormat}
          onChange={onChange}
          showError={showError && !isBuilder}
          error={!isBuilder ? error : ''}
          disabled={disabled}
          isBuilder={isBuilder}
          date={date}
          parentTestId={testId}
          onClick={onClickDateInput}
        />
      </FocusedBorder>
      <FocusedBorder
        isFocused={isBuilder && focusedChild === 'dateDisplay'}
        hover={isBuilder && isFocused}
        disabled={!isBuilder || (isBuilder && disabled)}
      >
        <DateInputDisplay
          date={!isBuilder ? date : undefined}
          dateInputFormat={dateInputFormat}
          dateInputStyle={dateDisplayStyle}
          isHidden={!isDateDisplayShown && !isBuilder}
          parentTestId={testId}
          widgetWidth={widgetWidth}
          onClick={onClickDateDisplay}
        />
      </FocusedBorder>
    </Box>
  )
}
