import { useCloudinary } from '@top/shared/src/contexts/Cloudinary'
import { transformCloudinaryImage } from '@top/shared/src/helpers/transformCloudinaryImage'
import COLORS from '@top/shared/src/style/colors'
import { type ImageStyle } from '@top/shared/src/style/types'

import { AdvancedImage } from '@cloudinary/react'

export type Props = {
  publicId: string
  testId?: string
  imgAlt?: string
  imageStyles?: ImageStyle
  height?: number
  disableTransformations?: boolean
  /**
   * @default false
   *
   * If true, the image will be have rounded edges with a box shadow.
   */
  isRoundedWithBoxShadow?: boolean
}

export function CloudinaryImage(props: Props) {
  const {
    publicId,
    testId,
    imgAlt,
    imageStyles,
    height,
    disableTransformations = false,
    isRoundedWithBoxShadow = false,
  } = props

  const { cloudinary } = useCloudinary()

  const cloudinaryImage = cloudinary.image(publicId)

  const filter = `brightness(${imageStyles?.brightness ?? 100}%) blur(${
    imageStyles?.blur ?? 0
  }px) saturate(${imageStyles?.saturation ?? 100}%)`

  if (!disableTransformations) {
    transformCloudinaryImage(cloudinaryImage, {
      height,
      imageStyles,
    })
  }

  const style = {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
    display: 'block',

    filter,
    ...(isRoundedWithBoxShadow && {
      boxShadow: `2px 5px 6px ${COLORS.OVERLAY_LIGHT}`,
      borderRadius: '14px',
    }),
  }

  return (
    <AdvancedImage
      cldImg={cloudinaryImage}
      data-testid={testId}
      alt={imgAlt}
      style={style}
      draggable={false}
    />
  )
}
