import { useEffect, useState } from 'react'

import { isTouchDevice as isTouch } from '@top/shared/src/helpers/isTouchDevice'

type Point = {
  x: number
  y: number
}

type CustomMouseEvent = MouseEvent & {
  triggeredByTouch?: boolean
}

const useIsTouchDevice = () => {
  const [isTouchDevice, setIsTouchDevice] = useState(isTouch())
  const [touchPoints, setTouchPoints] = useState<Point[]>([])

  useEffect(() => {
    // How long to keep the touchevents.
    const KEEP_MS = 1000

    let timeOut: NodeJS.Timeout

    const registerTouch = (event: TouchEvent) => {
      const touch = event.touches[0] ?? event.changedTouches[0]

      if (!touch) return

      const point = { x: touch.pageX, y: touch.pageY }

      const updatedTouchpoints = [...touchPoints, point]

      setTouchPoints(updatedTouchpoints)

      timeOut = setTimeout(() => setTouchPoints([]), KEEP_MS)
    }

    const handleMouseEvent = (event: CustomMouseEvent) => {
      touchPoints.forEach((touchpoint) => {
        if (
          Math.abs(touchpoint.x - event.pageX) < 60 &&
          Math.abs(touchpoint.y - event.pageY) < 60
        ) {
          event.triggeredByTouch = true
          return
        }
      })

      if (!event.triggeredByTouch) {
        setIsTouchDevice(false)
      }
    }

    window.addEventListener('touchstart', registerTouch, true)
    window.addEventListener('touchend', registerTouch, true)
    window.addEventListener('mouseover', handleMouseEvent, true)

    return () => {
      window.removeEventListener('touchstart', registerTouch, true)
      window.removeEventListener('touchend', registerTouch, true)
      window.removeEventListener('mouseover', handleMouseEvent, true)
      clearTimeout(timeOut)
    }
  }, [isTouchDevice, touchPoints, setIsTouchDevice, setTouchPoints])

  return isTouchDevice
}

export default useIsTouchDevice
