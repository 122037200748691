import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'

import { useGetPreferredLanguage } from '@top/shared_deprecated/hooks/useGetPreferredLanguage'
import { SceneType, TemplateType } from '@top/shared_deprecated/src/types/Scene'
import useSentry from '@top/shared_deprecated/src/utils/useSentry'

import { DistSource, useDistribution } from './Distribution'

import fjs from '@fingerprintjs/fingerprintjs'
import { handleRedirectUrl } from 'components/helpers'
import useAPI_DEPRECATED from 'hooks/useAPI_DEPRECATED'
import { v4 as uuid } from 'uuid'

export enum EventType {
  ACTIVITY_RENDER = 'ACTIVITY_RENDER',
  ACTIVITY_FINISH = 'ACTIVITY_FINISH',
  ACTIVITY_START = 'ACTIVITY_START',
  // sent to BE any time an activity is closed
  ACTIVITY_COLLAPSED = 'ACTIVITY_COLLAPSED',
  // sent to BE when an activity is closed before it's started
  ACTIVITY_DISMISSED = 'ACTIVITY_DISMISSED',
  SCENE_VIEW = 'SCENE_VIEW',
  // for when a selection(Q01 Q02 Multi) triggers a end/redirect
  SCENE_SELECTION_END = 'SCENE_SELECTION_END',
  SCENE_SELECTION = 'SCENE_SELECTION',
  SCENE_TEXT_ENTRY = 'SCENE_TEXT_ENTRY',
  SCENE_MULTI_SELECTION = 'SCENE_MULTI_SELECTION',
  CTA_EMAIL = 'CTA_EMAIL',
  CTA_LINK = 'CTA_LINK',
  SCENE_DATE_ENTRY = 'SCENE_DATE_ENTRY',
}

interface ITrackerProps {
  ignoreTracker: boolean
  children: React.ReactNode
}

export enum PostMessageCommands {
  SetVisitor = 'SET_VISITOR',
  ActivityCollapsed = 'ACTIVITY_COLLAPSED',
  ActivityCompleted = 'ActivityCompleted',
  SetOrigin = 'SET_ORIGIN',
  TopIsReady = 'TOPIsReady',
}

interface ITrackerContext {
  responses: IResponse | undefined
  onActivityStart: () => void
  onActivityFinish: () => void
  onSceneView: (sceneId: string) => void
  onSceneSelectionEnd: () => void
  onSceneSelection: (sceneId: string, selectionId: string, hasSensitiveData: boolean) => void
  onMultiSelection: (
    sceneId: string,
    multiSelections: Array<string>,
    hasSensitiveData: boolean
  ) => void
  onTextEntrySubmit: (sceneId: string, response: string, hasSensitiveData: boolean) => void
  onCTALinkClick: (sceneId: string) => void
  onRedirectLink: (sceneId: string, url: string) => void
  onCTAEmailSubmit: (email: string, sceneId: string, hasSensitiveData: boolean) => void
  onDateSubmit: (date: string, sceneId: string, hasSensitiveData: boolean) => void
  onInteraction: (currentPage: number, isEndScene?: boolean) => void
  onQuestionStartInteraction: () => void
  closeWidget: () => void
  setCurrentSceneId: React.Dispatch<React.SetStateAction<string | undefined>>
  currentSceneUuid: string | undefined
  setCurrentSceneUiid: React.Dispatch<React.SetStateAction<string | undefined>>
  onResetTrackerEvents: () => void
}
const TrackerContext = createContext<ITrackerContext | undefined>(undefined)

/**
 * @deprecated
 */
export const useTracker_DEPRECATED = () => {
  const context = useContext(TrackerContext)
  if (context === undefined) {
    throw new Error('useTracker_DEPRECATED must be used within a TrackerProvider')
  }
  return context
}

interface IResponse {
  [sceneId: string]: string | Array<string>
  source: DistSource
}

/**
 * @deprecated
 */
export const TrackerProvider_DEPRECATED = (props: ITrackerProps) => {
  const { ignoreTracker, children } = props

  const {
    hash,
    sessionId,
    isCompleted,
    scenes = [],
    source,
    locales,
    localeOverride,
    defaultLocale,
    activityUid,
  } = useDistribution()
  const localeCode = useGetPreferredLanguage(locales, defaultLocale).localeCode
  const userLocaleOrOverride = localeOverride ?? localeCode

  const [responses, setResponses] = useState<IResponse>()
  const [fingerPrintId, setFingerPrintId] = useState<string | undefined>()
  const [currentSceneId, setCurrentSceneId] = useState<string | undefined>()
  const [currentSceneUuid, setCurrentSceneUiid] = useState<string | undefined>()
  const [visitor, setVisitor] = useState<{ [key: string]: any }>({})
  const [, trackEventAPI] = useAPI_DEPRECATED()
  const [, updateClientAPI] = useAPI_DEPRECATED()
  const report = useSentry()

  const triggeredStart = useRef(false)
  const triggeredFinish = useRef(false)
  const triggeredCollapse = useRef(false)
  const shouldTrack = useRef(false)
  // BE cannot parse number from empty string - we need to send dummy value when no prev scene
  const prevSceneId = useRef('0')

  shouldTrack.current = useMemo(() => {
    // We don't want to send events before we get a sessionID from Serve
    return !isCompleted && !ignoreTracker && !!sessionId
  }, [isCompleted, ignoreTracker, sessionId])

  const trackEvent = useCallback(
    (type: EventType, params?: any) => {
      if (shouldTrack.current) {
        // We need to set timeout on endpoints that will be fired sequentially so state updates are not batched by React
        trackEventAPI({
          url: '/tracker/tracker.Tracker/Event',
          body: {
            hash,
            activity_uid: activityUid,
            session_id: sessionId,
            insert_id: uuid(),
            event_type: type,
            response_locale: userLocaleOrOverride,
            ...params,
          },
        })
      }
    },
    [hash, sessionId, userLocaleOrOverride, trackEventAPI, activityUid]
  )

  useEffect(() => {
    // We want to send this event after Serve resolves and before the loading widget is collapsed
    // We're not cancelling the Serve request on collapse to avoid context cancelled alerts on BE
    if (sessionId && !triggeredCollapse.current) {
      trackEvent(EventType.ACTIVITY_RENDER)
    }
  }, [trackEvent, sessionId])

  const updateClient = useCallback(
    ({
      hash,
      session_id,
      fingerprint_js_id,
      visitor,
    }: {
      hash: string | undefined
      session_id: string
      fingerprint_js_id?: string
      visitor?: { [key: string]: any }
    }) => {
      if (ignoreTracker) return
      updateClientAPI({
        url: '/tracker/tracker.Tracker/UpdateClient',
        body: {
          hash,
          activity_uid: activityUid,
          session_id,
          ...(fingerprint_js_id && { fingerprint_js_id }),
          ...(visitor && { visitor }),
        },
        sentryExtras: { fingerprint_js_id: fingerprint_js_id },
      })
    },
    [updateClientAPI, ignoreTracker, activityUid]
  )

  useEffect(() => {
    if (ignoreTracker) return
    ;(async () => {
      try {
        const fp = await fjs.load({ delayFallback: 5000 })

        // The FingerprintJS agent is ready.
        const result = await fp.get()
        // This is the fjs identifier:
        const fpId = result.visitorId
        setFingerPrintId(fpId)
      } catch (error) {
        report({
          severity: 'warning',
          tags: { source },
          extras: { session_id: sessionId, hash, main_error: error },
          message: "Couldn't generate fpjs id",
        })
      }
    })()
  }, [hash, sessionId, source, report, ignoreTracker])

  useEffect(() => {
    if (fingerPrintId && sessionId) {
      updateClient({ hash, session_id: sessionId, fingerprint_js_id: fingerPrintId })
    }
  }, [hash, sessionId, fingerPrintId, updateClient])

  useEffect(() => {
    if (visitor && sessionId) {
      updateClient({ hash, session_id: sessionId, visitor })
    }
  }, [hash, sessionId, visitor, trackEventAPI, updateClient])

  const onActivityStart = useCallback(() => {
    trackEvent(EventType.ACTIVITY_START)
  }, [trackEvent])

  const onActivityFinish = useCallback(() => {
    trackEvent(EventType.ACTIVITY_FINISH)
  }, [trackEvent])

  const onSceneSelectionEnd = useCallback(() => {
    currentSceneId &&
      trackEvent(EventType.SCENE_SELECTION_END, {
        scene_id: currentSceneId,
        prev_scene_id: prevSceneId.current,
      })
  }, [trackEvent, currentSceneId])

  const onActivityCollapse = useCallback(() => {
    triggeredCollapse.current = true
    trackEvent(EventType.ACTIVITY_COLLAPSED)
  }, [trackEvent])

  const onActivityDismiss = useCallback(() => {
    trackEvent(EventType.ACTIVITY_DISMISSED)
  }, [trackEvent])

  const onSceneView = useCallback(
    (scene_id: string) => {
      // We want to track the scene they came from for metrics (ie. drop-off rate)
      trackEvent(EventType.SCENE_VIEW, { scene_id, prev_scene_id: prevSceneId.current })
      // This scene is now the prev scene for the next one
      prevSceneId.current = scene_id
    },
    // Note: Adding `prevSceneId.current` as dependency causes scene view effect to fire twice - maybe there's a better way to refactor in the future
    [trackEvent]
  )

  const onSceneSelection = useCallback(
    (scene_id: string, selection_id: string, hasSensitiveData: boolean) => {
      if (shouldTrack.current) {
        setResponses((res: IResponse | undefined) => ({
          ...res,
          [scene_id]: selection_id,
          source,
        }))
        trackEvent(EventType.SCENE_SELECTION, {
          scene_id,
          selection_id,
          has_sensitive_data: hasSensitiveData,
        })
      }
    },
    [source, trackEvent]
  )

  const onMultiSelection = useCallback(
    (sceneId: string, multiSelections: Array<string>, hasSensitiveData: boolean) => {
      if (shouldTrack.current) {
        setResponses((res: IResponse | undefined) => ({
          ...res,
          sceneId: multiSelections,
          source,
        }))
        trackEvent(EventType.SCENE_MULTI_SELECTION, {
          scene_id: sceneId,
          multi_selections: multiSelections,
          has_sensitive_data: hasSensitiveData,
        })
      }
    },

    [source, trackEvent]
  )

  const onTextEntrySubmit = useCallback(
    (sceneId: string, response: string, hasSensitiveData: boolean) => {
      trackEvent(EventType.SCENE_TEXT_ENTRY, {
        scene_id: sceneId,
        response,
        has_sensitive_data: hasSensitiveData,
      })
    },
    [trackEvent]
  )

  const closeWidget = useCallback(() => {
    // We aren't sending anything sensitive here, can have source as wildcard
    window.parent.postMessage({ type: PostMessageCommands.ActivityCompleted }, '*')
  }, [])

  const onCTALinkClick = useCallback(
    (sceneId: string) => {
      trackEvent(EventType.CTA_LINK, { scene_id: sceneId })
    },
    [trackEvent]
  )

  // a replacement for onCTALinkClick, handle generic workflow
  const onRedirectLink = useCallback(
    (sceneId: string, url: string) => {
      onCTALinkClick(sceneId)
      handleRedirectUrl(url)
      closeWidget()
    },
    [onCTALinkClick, closeWidget]
  )

  const onCTAEmailSubmit = useCallback(
    (email: string, sceneId: string, hasSensitiveData: boolean) => {
      trackEvent(EventType.CTA_EMAIL, {
        email,
        scene_id: sceneId,
        has_sensitive_data: hasSensitiveData,
      })
    },
    [trackEvent]
  )

  const onDateSubmit = useCallback(
    (response: string, sceneId: string, hasSensitiveData: boolean) => {
      trackEvent(EventType.SCENE_DATE_ENTRY, {
        response,
        scene_id: sceneId,
        has_sensitive_data: hasSensitiveData,
      })
    },
    [trackEvent]
  )

  const postActivityCompleteToMobileSDK = useCallback((shouldAutoClose: boolean) => {
    const _window: any = window
    _window?.webkit?.messageHandlers?.mobileSDKCommunication?.postMessage?.({
      type: PostMessageCommands.ActivityCompleted,
      shouldAutoClose,
    })
    _window?.mobileSDKCommunication?.postMessage?.(
      JSON.stringify({
        type: PostMessageCommands.ActivityCompleted,
        shouldAutoClose,
      })
    )
  }, [])
  const onInteraction = useCallback(
    (currentPage: number, isEndScene?: boolean) => {
      if (shouldTrack.current) {
        if (!triggeredStart.current) {
          triggeredStart.current = true
          onActivityStart()
        }
        if (!triggeredFinish.current) {
          if (currentPage === scenes.length - 1 || isEndScene) {
            triggeredFinish.current = true
            onActivityFinish()
            if (
              source === DistSource.TopSDK &&
              // For these scene types, close widget manually based on activity
              scenes[currentPage].type !== SceneType.CTA &&
              scenes[currentPage].template_type !== TemplateType.QUESTION_TEXT_ENTRY &&
              scenes[currentPage].template_type !== TemplateType.GENERIC_01
            ) {
              closeWidget()
            }
            if (source === DistSource.MobileSDK) {
              postActivityCompleteToMobileSDK(
                scenes[currentPage].type !== SceneType.CTA &&
                  scenes[currentPage].template_type !== TemplateType.QUESTION_TEXT_ENTRY &&
                  scenes[currentPage].template_type !== TemplateType.GENERIC_01
              )
            }
          }
        }
      }
    },
    [
      onActivityFinish,
      onActivityStart,
      scenes,
      closeWidget,
      source,
      postActivityCompleteToMobileSDK,
    ]
  )

  const onQuestionStartInteraction = useCallback(() => {
    if (!triggeredStart.current) {
      triggeredStart.current = true
      onActivityStart()
    }
  }, [onActivityStart])

  const onResetTrackerEvents = useCallback(() => {
    triggeredStart.current = false
    triggeredFinish.current = false
  }, [triggeredFinish, triggeredStart])

  useEffect(() => {
    const receiveMessage = (e: MessageEvent) => {
      if (!e.origin || !e.data) {
        return
      }
      const { type, visitor } = e.data
      if (type === PostMessageCommands.SetVisitor && visitor) {
        setVisitor(visitor)
      }
      if (type === PostMessageCommands.ActivityCollapsed && !triggeredStart.current) {
        onActivityDismiss()
      }
      if (type === PostMessageCommands.ActivityCollapsed && !triggeredFinish.current) {
        onActivityCollapse()
      }
    }
    window.addEventListener('message', receiveMessage, false)

    return () => window.removeEventListener('message', receiveMessage)
  }, [onActivityCollapse, onActivityDismiss])
  return (
    <TrackerContext.Provider
      value={{
        responses,
        onActivityStart,
        onActivityFinish,
        onSceneSelection,
        onMultiSelection,
        onTextEntrySubmit,
        onSceneView,
        onCTALinkClick,
        onRedirectLink,
        onCTAEmailSubmit,
        onDateSubmit,
        onInteraction,
        onQuestionStartInteraction,
        closeWidget,
        setCurrentSceneId,
        currentSceneUuid,
        setCurrentSceneUiid,
        onSceneSelectionEnd,
        onResetTrackerEvents,
      }}
    >
      {children}
    </TrackerContext.Provider>
  )
}
