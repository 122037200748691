import { useGetPreferredLanguage } from '@top/shared_deprecated/hooks/useGetPreferredLanguage'
import { Locale, SUPPORTED_LOCALES } from '@top/shared_deprecated/src/types/Languages'
import EN from './Translations/en.json'
import DE from './Translations/de.json'
import ES from './Translations/es.json'
import FR from './Translations/fr.json'
import IT from './Translations/it.json'
import NL from './Translations/nl.json'

const SYSTEM_MESSAGE_TRANSLATIONS = {
  EN,
  AR: EN,
  ZH: EN,
  NL,
  FR,
  DE,
  ID: EN,
  IT,
  JA: EN,
  KO: EN,
  PL: EN,
  PT: EN,
  RU: EN,
  ES,
  TH: EN,
  TR: EN,
  VI: EN,
  BG: EN,
  DA: EN,
  RO: EN,
  ET: EN,
  HY: EN,
  KA: EN,
} as const satisfies Record<Locale, any>

type SystemMessageKey = keyof (typeof SYSTEM_MESSAGE_TRANSLATIONS)[Locale]

/**
 * @deprecated Use `@top/shared/src/Languages/helpers/getSystemMessage` instead
 */
const getSystemMessage = (key: SystemMessageKey, locale?: Locale) => {
  if (!locale) {
    const mutableSupportedLocales = SUPPORTED_LOCALES.map((l) => l)
    locale = useGetPreferredLanguage(mutableSupportedLocales).localeCode
  }
  return SYSTEM_MESSAGE_TRANSLATIONS[locale][key]
}

export default getSystemMessage
