import {
  FALLBACK_DEFAULT_LOCALE,
  type Locale,
  localeToLanguageName,
} from '@top/shared/src/Languages/types'

/**
 * useGetPreferredLanguage returns the user's first preferred & supported language. Assumes we don't
 * support regional locale codes (e.g. 'en-US', 'fr-CA', etc.) If the user's preferred language is
 * not supported, it returns the defaultLocale. defaultLocale is optional, and if not provided, it
 * will default to FALLBACK_DEFAULT_LOCALE ('EN')
 */
export const useGetPreferredLanguage = (
  supportedLocales: Locale[],
  defaultLocale: Locale = FALLBACK_DEFAULT_LOCALE
) => {
  const browserPreferences = navigator.languages.map((locale) => {
    const parsedLocale = locale.split('-')[0]
    return parsedLocale ? parsedLocale.toUpperCase() : defaultLocale
  })

  const locale =
    (browserPreferences.find((locale) => supportedLocales.includes(locale as Locale)) as Locale) ??
    defaultLocale

  return {
    localeCode: locale,
    languageName: localeToLanguageName[locale],
  }
}
