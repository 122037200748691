/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  type InvalidateOptions,
  type InvalidateQueryFilters,
  type MutationFunction,
  QueryClient,
  type QueryClientConfig,
  type QueryFunction,
  type QueryKey,
  useMutation as __useMutation,
  type UseMutationOptions,
  useQuery as __useQuery,
  type UseQueryOptions,
} from '@tanstack/react-query'
import { type z, type ZodTypeAny } from 'zod'

/** Recursively extract the inner type of a `Promise`. */
type Awaited<T> = T extends PromiseLike<infer U> ? Awaited<U> : T

const querySuffixes = ['Breakdown', 'Get', 'List', 'Metrics', 'Preview', 'Results'] as const
type QuerySuffix = (typeof querySuffixes)[number]

/** @description This is the structure a single endpoint's configuration needs to satisfy. */
export type EndpointConfig = {
  /** @description The function that will be called by either `useQuery` or `useMutation`. */
  fn: (...args: any[]) => Promise<unknown>
  /**
   * A zod schema, that will be used to validate the response of the corresponding `fn`, when the BE
   * response is a success.
   */
  outputSchema?: z.ZodSchema
  /**
   * A zod schema, that will be used to validate the response of the corresponding `fn`, when the BE
   * response is an error.
   */
  errorSchema?: z.ZodSchema
}

export type Schema = Record<string, EndpointConfig>

type ExtractKeys<TSchema extends Schema> = {
  [MethodName in keyof TSchema]-?: TSchema[MethodName] extends EndpointConfig ? MethodName : never
}[keyof TSchema]

type QueryPaths<TSchema extends Schema> = {
  [QueryName in keyof TSchema]-?: QueryName extends `${string}${QuerySuffix}`
    ? TSchema[QueryName] extends EndpointConfig
      ? QueryName
      : never
    : never
}[keyof TSchema]

/** @description Infers the output of the given query as configured in the passed schema. */
type inferQueryOutput<
  TSchema extends Schema,
  TProcedure extends QueryPaths<TSchema>,
  TOutputSchema = TSchema[TProcedure]['outputSchema'],
  TFunctionOutput = ReturnType<TSchema[TProcedure]['fn']>
> = TOutputSchema extends ZodTypeAny
  ? z.infer<TOutputSchema>
  : TFunctionOutput extends Promise<any>
  ? Awaited<TFunctionOutput>
  : undefined

type MutationPaths<TSchema extends Schema> = Exclude<
  ExtractKeys<TSchema>,
  QueryPaths<TSchema> | number | symbol
>

/** @description Infers the output of the given mutation as configured in the passed schema. */
type inferMutationOutput<
  TSchema extends Schema,
  TProcedure extends MutationPaths<TSchema>,
  TOutputSchema = TSchema[TProcedure]['outputSchema'],
  TFunctionOutput = ReturnType<TSchema[TProcedure]['fn']>
> = TOutputSchema extends ZodTypeAny
  ? z.infer<TOutputSchema>
  : TFunctionOutput extends Promise<any>
  ? Awaited<TFunctionOutput>
  : undefined

/**
 * @description Infers the output of the given procedure as configured in the passed schema. Uses
 *              either `inferQueryOutput` or `inferMutationOutput` accordingly.
 *
 * @see {@link inferQueryOutput}
 * @see {@link inferMutationOutput}
 */
export type inferProcedureOutput<
  TSchema extends Schema,
  TProcedure extends keyof TSchema
> = TProcedure extends QueryPaths<TSchema>
  ? inferQueryOutput<TSchema, TProcedure>
  : TProcedure extends MutationPaths<TSchema>
  ? inferMutationOutput<TSchema, TProcedure>
  : never

type inferProcedureInput<TProcedure extends Schema[string]> = TProcedure['fn'] extends (
  args: infer TInput,
  headers?: any
) => Promise<any>
  ? undefined extends TInput // ? is input optional
    ? unknown extends TInput // ? is input unset
      ? null | undefined // -> there is no input
      : TInput | null | undefined // -> there is optional input
    : TInput // -> input is required
  : undefined | null // -> there is no input

type ApiOrSchemaError<TError = unknown> =
  | {
      apiError: TError
      schemaError: undefined
    }
  | {
      apiError: Partial<TError>
      schemaError: z.ZodType<any, any, any>
    }

type inferApiError<
  Config extends Schema,
  TPath extends MutationPaths<Config> | QueryPaths<Config>,
  ErrorSchema = Config[TPath]['errorSchema']
> = ErrorSchema extends z.ZodType<any, any, any> ? ApiOrSchemaError<z.infer<ErrorSchema>> : unknown

export type QueryOptions<
  TSchema extends Schema,
  TQueryPath extends QueryPaths<TSchema>,
  TQueryOutput = inferQueryOutput<TSchema, TQueryPath>,
  TError = inferApiError<TSchema, TQueryPath>
> = UseQueryOptions<TQueryOutput, TError>

export type MutationOptions<
  TSchema extends Schema,
  TPath extends MutationPaths<TSchema>,
  TMutationOutput = inferMutationOutput<TSchema, TPath>,
  TMutationInput = inferProcedureInput<TSchema[TPath]>,
  TError = inferApiError<TSchema, TPath>
> = UseMutationOptions<TMutationOutput, TError, TMutationInput>

export class Api<const ApiSchema extends Schema> {
  public queryClient: QueryClient

  private contract: ApiSchema

  constructor(contract: ApiSchema, queryClientConfig?: QueryClientConfig) {
    this.queryClient = new QueryClient(queryClientConfig)
    this.contract = contract
  }

  private parseResponse<TResponseSchema extends z.ZodTypeAny>(
    response: unknown,
    responseSchema: TResponseSchema
  ) {
    try {
      // We are OK with `any` here as the schema is defined by the user, and it's up to them to make sure it is specific enough.
      // eslint-disable-next-line @typescript-eslint/no-unsafe-return
      return responseSchema.parse(response) as z.infer<TResponseSchema>
    } catch (e) {
      throw e
    }
  }

  public useQuery<
    TPath extends QueryPaths<ApiSchema> & string,
    TQueryOutput extends inferQueryOutput<ApiSchema, TPath>,
    TQueryInput extends inferProcedureInput<ApiSchema[TPath]>
  >(pathAndInput: [path: TPath, args: TQueryInput], opts?: QueryOptions<ApiSchema, TPath>) {
    const [path, args] = pathAndInput
    const endpoint = this.contract[path]

    if (!endpoint) {
      throw new Error(`Couldn't find an endpoint for "path: ${path}" in the schema`)
    }

    const queryFn = (async () => {
      try {
        const response = await endpoint.fn(args)
        const responseSchema = this.contract[path]?.outputSchema

        if (responseSchema) {
          // We are OK with `any` here as the schema is defined by the user, and it's up to them to make sure it is specific enough.
          // eslint-disable-next-line @typescript-eslint/no-unsafe-return
          return this.parseResponse(response, responseSchema)
        }

        return response
      } catch (e) {
        const errorSchema = this.contract[path]?.errorSchema
        if (errorSchema) {
          try {
            errorSchema.parse(e)
          } catch (schemaError) {
            throw { schemaError, apiError: e }
          }
        }

        throw { apiError: e }
      }
    }) satisfies QueryFunction<TQueryOutput, QueryKey>

    return __useQuery(pathAndInput as QueryKey, queryFn, opts)
  }

  public useMutation<
    TPath extends MutationPaths<ApiSchema> & string,
    TMutationOutput extends inferMutationOutput<ApiSchema, TPath>,
    TMutationInput extends inferProcedureInput<ApiSchema[TPath]>
  >(path: [TPath] | TPath, opts?: MutationOptions<ApiSchema, TPath>) {
    const actualPath = Array.isArray(path) ? path[0] : path
    const endpoint = this.contract[actualPath]

    if (!endpoint) {
      throw new Error(`Couldn't find an endpoint for "path: ${path}" in the schema`)
    }

    const mutationFn = (async (input: TMutationInput) => {
      try {
        const response = await endpoint.fn(input)
        const responseSchema = this.contract[actualPath]?.outputSchema

        if (responseSchema) {
          // We are OK with `any` here as the schema is defined by the user, and it's up to them to make sure it is specific enough.
          // eslint-disable-next-line @typescript-eslint/no-unsafe-return
          return this.parseResponse(response, responseSchema)
        }

        return response
      } catch (e) {
        const errorSchema = this.contract[actualPath]?.errorSchema
        if (errorSchema) {
          try {
            errorSchema.parse(e)
          } catch (schemaError) {
            throw { schemaError, apiError: e }
          }
        }

        throw { apiError: e }
      }
    }) satisfies MutationFunction<TMutationOutput, TMutationInput>

    return __useMutation(mutationFn, opts)
  }

  public invalidateQueries<
    TPath extends QueryPaths<ApiSchema> & string,
    TQueryInput extends inferProcedureInput<ApiSchema[TPath]>
  >(
    filters: InvalidateQueryFilters & { queryKey: [path: TPath, args: Partial<TQueryInput>] },
    options?: InvalidateOptions
  ) {
    return this.queryClient.invalidateQueries(filters, options)
  }
}
