import { Component, lazy, Suspense } from 'react'

import * as Sentry from '@sentry/react'

const GlobalErrorPage = lazy(() => import('./pages/GlobalError'))

interface IState {
  hasError: boolean
}
class ErrorBoundary extends Component<{ children: React.ReactNode }, IState> {
  state = {
    hasError: false,
  }

  static getDerivedStateFromError() {
    return { hasError: true }
  }

  componentDidCatch(err: Error) {
    Sentry.withScope((scope) => {
      scope.setLevel('error')
      Sentry.captureException(err)
    })
    this.setState({ hasError: true })
  }

  render() {
    return this.state.hasError ? (
      <Suspense fallback={null}>
        <GlobalErrorPage />
      </Suspense>
    ) : (
      this.props.children
    )
  }
}

export default ErrorBoundary
