import COLORS from '@top/shared/src/style/colors'
import { type TextStyle } from '@top/shared/src/style/types'

export const defaultPromptTextStyles = {
  backgroundColor: 'transparent',
  bold: false,
  color: COLORS.TEXT_DARK,
  fontFamily: 'Roboto',
  fontSize: 8,
  italic: false,
  textAlign: 'center',
  underline: false,
} satisfies TextStyle
