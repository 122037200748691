import { type CSSProperties } from 'react'

import {
  type FontSize,
  type FontSizeRem,
  type SelectionBlockDisplayType,
  type TextAlign,
} from '@top/shared/src/style/types'
import { type TextStyle } from '@top/shared/src/style/types'

import type color from 'color'

// REM to VW by a constant 4.2625
const fontSizeNumberToPercentMap = {
  8: 3.41,
  12: 4.27,
  16: 5.12,
  24: 6.39,
  32: 7.67,
  40: 10.66,
  48: 12.79,
  56: 14.92,
  64: 17.05,
} satisfies { [key in FontSize]: number }

const fontSizeNumberToRemMap = {
  8: '.8rem',
  12: '1rem',
  16: '1.2rem',
  24: '1.5rem',
  32: '1.8rem',
  40: '2.5rem',
  48: '3rem',
  56: '3.5rem',
  64: '4rem',
} satisfies { [key in FontSize]: FontSizeRem }

const FONTSIZE_BREAK_POINT = 400

export const convertFormatsToCSS: (formats: {
  italic?: boolean
  bold?: boolean
  underline?: boolean
}) => CSSProperties = (formats) => {
  const { italic, bold, underline } = formats

  return {
    fontStyle: italic ? 'italic' : 'normal',
    fontWeight: bold ? 'bold' : 'normal',
    textDecoration: underline ? 'underline' : 'none',
  }
}

// TODO: We need to revisit sizing based on the widget width.
export function convertFontSizeToNewFormat(fontSize: FontSize, widgetWidth = 400) {
  // Ideally we just have `return fontSizeToRemMap[fontSize]` in here,
  // and use global media queries to adjust the font size based on breakpooints.
  let fontSizeValue = `${fontSizeNumberToPercentMap[fontSize]}vw` || ''

  if (widgetWidth !== undefined && widgetWidth !== 999) {
    fontSizeValue = `${widgetWidth * (fontSizeNumberToPercentMap[fontSize] / 100)}px`
  }

  if (widgetWidth && widgetWidth > FONTSIZE_BREAK_POINT) {
    fontSizeValue = fontSizeNumberToRemMap[fontSize]
  }

  return fontSizeValue
}

export function getTextStyle(
  style: Omit<TextStyle, 'textAlign'> & { textAlign?: TextAlign },
  widgetWidth?: number
) {
  const fontSize = convertFontSizeToNewFormat(style.fontSize, widgetWidth)
  return {
    textAlign: style.textAlign ?? 'center',
    fontFamily: style.fontFamily,
    fontSize,
    ...convertFormatsToCSS({
      italic: style.italic,
      bold: style.bold,
      underline: style.underline,
    }),
    color: style.color,
    backgroundColor: style.backgroundColor,
    overflowY: 'auto',
    overflowX: 'hidden',
    width: '100%',
    wordBreak: 'break-word',
    ...(style.backgroundColor && { borderRadius: '5px', padding: '5px' }),
  } satisfies CSSProperties
}

export function getOverlayColor(
  textColor: color,
  backgroundColor: color,
  choiceDisplayType: SelectionBlockDisplayType,
  showShareback: boolean
) {
  switch (choiceDisplayType) {
    case 'radio':
      // no overlay when selecting a radio option
      if (!showShareback) {
        return backgroundColor.toString()
      }
      // feedback overlay conditions
      // change background if there is little contrast
      if (textColor.contrast(backgroundColor) < 2 && textColor.isDark()) {
        return backgroundColor.fade(0.9).toString()
      }
      return backgroundColor.darken(0.3).toString()

    default: // case 'condensed', 'block' (may be undefined in old activities before TOP-3124)
      //need to highlight selected option, when the answer options don't have a background color
      if (backgroundColor.alpha() === 0) {
        return textColor.fade(0.9).toString()
      }
      if (backgroundColor.isDark()) {
        return backgroundColor.fade(0.3).toString()
      }
      return backgroundColor.darken(0.3).toString()
  }
}
