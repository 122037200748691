import { type BuilderSelectionsErrors } from '@top/ui/src/SceneModules/types'

export function hasBuilderSelectionError(
  errors: BuilderSelectionsErrors,
  selectionUuid: string,
  options: {
    isImageSelection: boolean
    hasTextProperty: boolean
  }
) {
  if (options.isImageSelection) {
    if (!errors.images) {
      throw new Error(
        'Expected selections image errors to be defined because there is a selection with an image in this scene module'
      )
    }

    const imageErrors = errors.images[selectionUuid]

    if (!imageErrors) {
      throw new Error('Expected imageErrors to be defined because selection is an image')
    }

    if (!options.hasTextProperty) {
      return !!imageErrors?.hasError
    } else {
      if (!errors.translations) {
        throw new Error(
          'Expected selections translations errors to be defined because there is a selection with text in this scene module'
        )
      }

      const translations = errors.translations[selectionUuid]

      if (!translations) {
        throw new Error('Expected translations to be defined because selection has text')
      }

      return !!imageErrors?.hasError || !!translations?.hasError
    }
  } else {
    // This the the case where the selection is a text selection
    if (!errors.translations) {
      throw new Error(
        'Expected selections translations errors to be defined because there is a selection with text in this scene module'
      )
    }

    const translations = errors.translations[selectionUuid]

    if (!translations) {
      throw new Error('Expected translations to be defined because selection has text')
    }

    return !!translations?.hasError
  }
}
