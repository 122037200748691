import { useState } from 'react'

import { Box } from '@mui/material'

import COLORS from '@top/shared/src/style/colors'
import { type NumericSelectionStyles } from '@top/shared/src/style/types'
import { NumericScaleItem } from '@top/ui/src/SceneModules/NumericScale/components/NumericScaleItem/NumericScaleItem'
import { getFillColor } from '@top/ui/src/SceneModules/NumericScale/helpers/getFillColor'
import { getTransformationsForCircularLayout } from '@top/ui/src/SceneModules/NumericScale/helpers/getTransformationsForCircularLayout'
import { type NumericSelection } from '@top/ui/src/SceneModules/NumericScale/types'

export const defaultNumericSelectionStyles = {
  primaryColor: COLORS.TOUCHPOINT_NAVY,
  secondaryColor: COLORS.NUMERIC_SCALE_SECONDARY,
  shape: 'NUMERIC_CIRCLE',
} as const satisfies NumericSelectionStyles

type Props = {
  selections: NumericSelection[]
  selectionStyles: NumericSelectionStyles
  disabled?: boolean
  onChange?: (id: string) => void
  selectedValue: number
  itemWidth: number
  /** Used to calculate the position of the items in a circular layout. */
  containerWidth: number
  parentTestId?: string
  isCircularLayout: boolean
}

export function NumericScaleItems(props: Props) {
  const {
    selectedValue,
    disabled = false,
    isCircularLayout,
    selectionStyles,
    selections,
    parentTestId,
    onChange,
    itemWidth,
    containerWidth,
  } = props

  const [hoveredValue, setHoveredValue] = useState<number>(-1)

  const testId = parentTestId ? `${parentTestId}-numeric-scale-items` : undefined

  const { primaryColor, secondaryColor, shape } = selectionStyles

  return selections.map((selection, index) => {
    const { uuid, etc } = selection
    const { value } = etc

    const isFaceShape = shape === 'NUMERIC_FACE'

    const fillColor = getFillColor(value, selectedValue, primaryColor, isFaceShape, hoveredValue)

    const numberOfItems = selections.length

    const { ITEM_ROTATION, ITEM_TRANSLATION } = getTransformationsForCircularLayout(
      numberOfItems,
      index,
      itemWidth,
      containerWidth
    )

    const handleMouseEnter = () => {
      if (disabled) {
        return
      }
      setHoveredValue(value)
    }

    const handleMouseLeave = () => {
      if (disabled) {
        return
      }
      setHoveredValue(-1)
    }

    const handleItemClick = () => {
      if (disabled) {
        return
      }

      if (onChange) {
        onChange(uuid)
      }
    }

    return (
      <Box
        key={uuid}
        onClick={handleItemClick}
        sx={{
          ...(isCircularLayout && {
            transform: `rotate(${ITEM_ROTATION}deg) translate(${ITEM_TRANSLATION}px)`,
            position: 'absolute',
            left: '50%',
            top: '48%',
            marginLeft: `-${itemWidth / 2}px`,
          }),
          cursor: disabled ? 'default' : 'pointer',
        }}
        data-testid={testId}
        aria-label={`select rank ${value}`}
      >
        <Box
          sx={{
            ...(isCircularLayout && {
              transform: `rotate(${ITEM_ROTATION * -1}deg)`,
            }),
          }}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
          data-testid={parentTestId ? `${parentTestId}-numeric-scale-item-${value}` : undefined}
        >
          <NumericScaleItem
            value={value}
            width={itemWidth}
            secondaryColor={secondaryColor}
            shape={shape}
            selectedValue={selectedValue}
            fillColor={fillColor}
          />
        </Box>
      </Box>
    )
  })
}
