import { type ImageStyle } from '@top/shared/src/style/types'

import { type CloudinaryImage } from '@cloudinary/url-gen'
import { quality } from '@cloudinary/url-gen/actions/delivery'
import { crop, fit, scale } from '@cloudinary/url-gen/actions/resize'

function applyZoom(value: number, zoom: number) {
  return Math.round(value * zoom)
}

/** This function transforms the cloudinaryImage based on the given styles. */
export const transformCloudinaryImage = (
  cloudinaryImage: CloudinaryImage,
  options?: {
    imageStyles?: ImageStyle
    height?: number
  }
) => {
  const { imageStyles, height } = options ?? {}

  if (!imageStyles) {
    cloudinaryImage.delivery(quality('auto')).resize(fit())
    return cloudinaryImage
  }

  const { cropX, cropY, cropWidth, cropHeight, zoom, aspectRatio } = imageStyles

  if (zoom && zoom !== 1) {
    cloudinaryImage.resize(scale().width(zoom.toFixed(1)))
  }

  if (cropHeight > 0) {
    cloudinaryImage.resize(
      crop()
        .x(applyZoom(cropX, zoom))
        .y(applyZoom(cropY, zoom))
        .width(applyZoom(cropWidth, zoom))
        .height(applyZoom(cropHeight, zoom))
        .gravity('north_west')
    )
  }

  if (!!aspectRatio) {
    cloudinaryImage.resize(crop().aspectRatio(aspectRatio).gravity('north_west'))
  }

  if (!!height) {
    cloudinaryImage.resize(scale().height(height))
  } else {
    cloudinaryImage.delivery(quality('auto')).resize(fit())
  }

  return cloudinaryImage
}
