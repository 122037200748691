import { type SVGProps } from 'react'

const DiamondRadioButtonChecked = (props: SVGProps<SVGSVGElement>) => {
  const { fill = 'white', ...rest } = props
  return (
    <svg {...rest} width="24" height="24" viewBox="0 0 24 24">
      <path
        d="M1.54285 11.3425L11.3429 1.54246C11.7063 1.17901 12.2945 1.17901 12.6579 1.54246L22.4579 11.3425C22.8214 11.7059 22.8214 12.2941 22.4579 12.6575L12.6579 22.4575C12.2945 22.821 11.7063 22.821 11.3429 22.4575L1.54285 12.6575C1.1794 12.2941 1.1794 11.7059 1.54285 11.3425Z"
        fill="transparent"
        stroke={fill}
        strokeWidth="2.1"
      />
      <path
        d="M11.3594 7L6.98437 11.375C6.63906 11.7203 6.63906 12.2797 6.98437 12.625L11.3594 17C11.7047 17.3453 12.2641 17.3453 12.6094 17L16.9844 12.625C17.3297 12.2797 17.3297 11.7203 16.9844 11.375L12.6094 7C12.2641 6.65469 11.7047 6.65469 11.3594 7Z"
        fill={fill}
      />
    </svg>
  )
}

export default DiamondRadioButtonChecked
