import CheckBoxIcon from '@mui/icons-material/CheckBox'
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank'
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked'
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'

import { type SelectionBlockShape, type SpacingIncrement } from '@top/shared/src/style/types'
import DiamondRadioButtonChecked from '@top/ui/src/Icons/DiamondRadioButtonChecked'

const getRadioButtonElement = (
  shape: SelectionBlockShape,
  isMultiChoice: boolean,
  isFilled: boolean,
  color: string,
  marginBottom?: number,
  marginLeft?: number
) => {
  const style = {
    fill: color,
    alignSelf: 'center',
    minWidth: 24,
    marginBottom,
    marginLeft,
    fontSize: 24,
  }

  switch (shape) {
    case 'diamond': {
      if (isFilled) {
        return <DiamondRadioButtonChecked fill={color} style={style} />
      }
      return (
        <CheckBoxOutlineBlankIcon
          style={{
            ...style,
            transform: 'rotate(45deg)',
          }}
        />
      )
    }

    case 'circle': {
      if (isMultiChoice) {
        if (isFilled) {
          return <CheckBoxIcon style={style} />
        }
        return <CheckBoxOutlineBlankIcon style={style} />
      }

      if (isFilled) {
        return <RadioButtonCheckedIcon style={style} />
      }
      return <RadioButtonUncheckedIcon style={style} />
    }

    default:
      throw new Error(`Invalid shape "${shape}"`)
  }
}

type RadioSelectionProps = {
  children: React.ReactNode
  choicePadding: SpacingIncrement
  shape: SelectionBlockShape
  testId?: string
  disabled: boolean
  isBuilder: boolean
  onClick: () => void
  onFocus: () => void
  selected: boolean
  isMultiChoice: boolean
  backgroundColor: string
}

export function RadioSelection(props: RadioSelectionProps) {
  const {
    children,
    disabled,
    isBuilder,
    onClick,
    choicePadding,
    selected,
    backgroundColor,
    testId,
    shape,
    isMultiChoice,
    onFocus,
  } = props

  const radioButton = getRadioButtonElement(
    shape,
    isMultiChoice,
    selected || isBuilder,
    backgroundColor
  )

  const padding = `calc(0.25rem * ${choicePadding} + 0.25rem) calc(1rem * ${choicePadding} + 0.25rem)`

  const handleOnClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation()

    if (!isBuilder) {
      onClick()
    }
  }

  return (
    <Button
      onClick={handleOnClick}
      onFocus={onFocus}
      disabled={disabled}
      disableRipple={isBuilder}
      data-testid={testId}
      sx={{
        padding,
        borderRadius: '0.25rem',
        width: '100%',
        display: 'flex',
        backgroundColor: 'transparent',
        position: 'relative',
      }}
    >
      <Box display="flex" width="100%">
        <Box paddingRight="8px" display="flex">
          {radioButton}
        </Box>
        <Box width="100%">{children}</Box>
      </Box>
    </Button>
  )
}
