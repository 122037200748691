import { useEffect, useRef } from 'react'

import { usePrevious } from '@top/shared/src/hooks/usePrevious'

/** Returns the transition string for the numeric scale item animation. */
export const useNumericScaleAnimation = (value: number, selectedValue: number) => {
  const transitionRef = useRef<string>('')

  const prevValue = usePrevious(selectedValue)

  useEffect(() => {
    if (prevValue === undefined || prevValue < selectedValue) {
      // Animate filling from left to right
      transitionRef.current = `fill ${value * 0.05}s`
    } else {
      // Animate filling from right to left
      if (value <= selectedValue) {
        transitionRef.current = `fill ${(prevValue - value) * 0.05}s `
      }
    }
  }, [selectedValue, prevValue, value])

  return transitionRef.current
}
