import CircularProgress from '@mui/material/CircularProgress'
import Grid from '@mui/material/Grid'

type Props = {
  size?: number
}

export function FullContainerSpinner(props: Props) {
  const { size = 40 } = props

  return (
    <Grid
      container
      alignItems="center"
      justifyContent="center"
      direction="column"
      style={{ paddingTop: '10%' }}
    >
      <CircularProgress size={size} />
    </Grid>
  )
}
