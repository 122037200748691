import { useApi } from 'api'
import { useServeParams } from 'hooks/useServeParams'

/**
 * @description Hook that returns the response of the serveGet API call
 * Note that we set the staleTime to Infinity to prevent the query from being invalidated unless
 * done so manually.
 */
export const useServeGet = () => {
  const { hash, source, external_id, collection_id, local_storage_id, preview, app_id } =
    useServeParams()

  return useApi().apiClient.useQuery(
    [
      'serveGet',
      {
        hash,
        source,
        external_id,
        collection_id,
        local_storage_id,
        preview,
        app_id,
      },
    ],
    {
      staleTime: Infinity,
    }
  )
}
