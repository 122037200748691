import { createContext, useCallback, useContext, useRef } from 'react'

import { TrackerUpdateClientReq } from '@top/shared/src/api/tracker.swagger.gen'
import { Locale } from '@top/shared/src/Languages/types'

import { useTrackerEvent } from 'hooks/useTrackerEvent'
import { useUpdateClient } from 'hooks/useUpdateClient'
import {
  CtaEmailTrackerEventArgs,
  CtaLinkTrackerEventArgs,
  SceneDateEntryTrackerEventArgs,
  SceneMultiSelectionTrackerEventArgs,
  SceneSelectionEndTrackerEventArgs,
  SceneSelectionTrackerEventArgs,
  SceneTextEntryTrackerEventArgs,
  SceneViewTrackerEventArgs,
  TrackerEventReq,
  TrackerEventReqBase,
} from 'types/Tracker'
import { v4 as uuid } from 'uuid'

type TrackerProps = {
  hash: string
  session_id: string
  activity_uid: string
  ignoreTracker: boolean
  children: React.ReactNode
}

type TrackerContext = {
  onUpdateClient: (body: TrackerUpdateClientReq) => void
  onActivityStart: (response_locale: Locale) => void
  onActivityFinish: (response_locale: Locale) => void
  onActivityCollapse: (response_locale: Locale) => void
  onActivityDismiss: (response_locale: Locale) => void
  onSceneView: (args: SceneViewTrackerEventArgs) => void
  onSceneSelectionEnd: (args: SceneSelectionEndTrackerEventArgs) => void
  onSceneSelection: (args: SceneSelectionTrackerEventArgs) => void
  onMultiSelection: (args: SceneMultiSelectionTrackerEventArgs) => void
  onTextEntrySubmit: (args: SceneTextEntryTrackerEventArgs) => void
  onCTALinkClick: (args: CtaLinkTrackerEventArgs) => void
  onCTAEmailSubmit: (args: CtaEmailTrackerEventArgs) => void
  onDateSubmit: (args: SceneDateEntryTrackerEventArgs) => void
  onResetTrackerEvents: () => void
  triggeredStart: React.MutableRefObject<boolean>
  triggeredFinish: React.MutableRefObject<boolean>
  triggeredCollapse: React.MutableRefObject<boolean>
  isSendingTrackerEvent: boolean
}
const TrackerContext = createContext<TrackerContext | undefined>(undefined)

export const useTracker = () => {
  const context = useContext(TrackerContext)
  if (context === undefined) {
    throw new Error('useTracker must be used within a TrackerProvider')
  }
  return context
}

export const TrackerProvider = (props: TrackerProps) => {
  const { hash, session_id, activity_uid, ignoreTracker, children } = props

  const { mutate: updateClientMutation, isLoading: isSendingTrackerEvent } = useUpdateClient()
  const { mutate: trackerEventMutation } = useTrackerEvent()

  const triggeredStart = useRef(false)
  const triggeredFinish = useRef(false)
  const triggeredCollapse = useRef(false)

  const commonReqArgs = {
    hash,
    session_id,
    activity_uid,
    insert_id: uuid(),
  } satisfies Omit<TrackerEventReqBase, 'response_locale'>

  const onTrackEvent = useCallback(
    (args: TrackerEventReq) => {
      if (!ignoreTracker) {
        trackerEventMutation(args)
      }
    },
    [trackerEventMutation, ignoreTracker]
  )

  const onActivityStart = useCallback(
    (response_locale: Locale) => {
      onTrackEvent({
        event_type: 'ACTIVITY_START',
        response_locale,
        ...commonReqArgs,
      })
    },
    [onTrackEvent, commonReqArgs]
  )

  const onActivityFinish = useCallback(
    (response_locale: Locale) => {
      onTrackEvent({
        event_type: 'ACTIVITY_FINISH',
        response_locale,
        ...commonReqArgs,
      })
    },
    [onTrackEvent, commonReqArgs]
  )

  const onActivityCollapse = useCallback(
    (response_locale: Locale) => {
      onTrackEvent({
        event_type: 'ACTIVITY_COLLAPSED',
        response_locale,
        ...commonReqArgs,
      })
    },
    [onTrackEvent, commonReqArgs]
  )

  const onActivityDismiss = useCallback(
    (response_locale: Locale) => {
      onTrackEvent({
        event_type: 'ACTIVITY_DISMISSED',
        response_locale,
        ...commonReqArgs,
      })
    },
    [onTrackEvent, commonReqArgs]
  )

  const onSceneSelectionEnd = useCallback(
    (args: SceneSelectionEndTrackerEventArgs) => {
      onTrackEvent({
        event_type: 'SCENE_SELECTION_END',
        ...commonReqArgs,
        ...args,
      })
    },
    [onTrackEvent, commonReqArgs]
  )

  const onSceneView = useCallback(
    (args: SceneViewTrackerEventArgs) => {
      onTrackEvent({
        event_type: 'SCENE_VIEW',
        ...commonReqArgs,
        ...args,
      })
    },
    [onTrackEvent, commonReqArgs]
  )

  const onSceneSelection = useCallback(
    (args: SceneSelectionTrackerEventArgs) => {
      onTrackEvent({
        event_type: 'SCENE_SELECTION',
        ...commonReqArgs,
        ...args,
      })
    },
    [onTrackEvent, commonReqArgs]
  )

  const onMultiSelection = useCallback(
    (args: SceneMultiSelectionTrackerEventArgs) => {
      onTrackEvent({
        event_type: 'SCENE_MULTI_SELECTION',
        ...commonReqArgs,
        ...args,
      })
    },
    [onTrackEvent, commonReqArgs]
  )

  const onTextEntrySubmit = useCallback(
    (args: SceneTextEntryTrackerEventArgs) => {
      onTrackEvent({
        event_type: 'SCENE_TEXT_ENTRY',
        ...commonReqArgs,
        ...args,
      })
    },
    [onTrackEvent, commonReqArgs]
  )

  const onCTALinkClick = useCallback(
    (args: CtaLinkTrackerEventArgs) => {
      onTrackEvent({
        event_type: 'CTA_LINK',
        ...commonReqArgs,
        ...args,
      })
    },
    [onTrackEvent, commonReqArgs]
  )

  const onCTAEmailSubmit = useCallback(
    (args: CtaEmailTrackerEventArgs) => {
      onTrackEvent({
        event_type: 'CTA_EMAIL',
        ...commonReqArgs,
        ...args,
      })
    },
    [onTrackEvent, commonReqArgs]
  )

  const onDateSubmit = useCallback(
    (args: SceneDateEntryTrackerEventArgs) =>
      onTrackEvent({
        event_type: 'SCENE_DATE_ENTRY',
        ...commonReqArgs,
        ...args,
      }),
    [onTrackEvent, commonReqArgs]
  )

  const onUpdateClient = useCallback(
    (args: Omit<TrackerUpdateClientReq, 'hash' | 'session_id' | 'activity_uid'>) => {
      if (ignoreTracker) return
      updateClientMutation({ ...args, session_id, hash, activity_uid })
    },
    [updateClientMutation, ignoreTracker, hash, activity_uid, session_id]
  )

  const onResetTrackerEvents = useCallback(() => {
    triggeredStart.current = false
    triggeredFinish.current = false
  }, [triggeredFinish, triggeredStart])

  return (
    <TrackerContext.Provider
      value={{
        onUpdateClient,
        onActivityStart,
        onActivityFinish,
        onActivityCollapse,
        onActivityDismiss,
        onSceneSelection,
        onMultiSelection,
        onTextEntrySubmit,
        onSceneView,
        onCTALinkClick,
        onCTAEmailSubmit,
        onDateSubmit,
        onSceneSelectionEnd,
        onResetTrackerEvents,
        triggeredStart,
        triggeredFinish,
        triggeredCollapse,
        isSendingTrackerEvent,
      }}
    >
      {children}
    </TrackerContext.Provider>
  )
}
