import { type CSSProperties } from 'react'

export const SIZES = {
  inherit: 'inherit',
  default: '2.0rem',
  small: '1.5rem',
  large: '2.5rem',
} as const

export type IconProps = {
  fontSize?: 'inherit' | 'default' | 'small' | 'large'
  style?: CSSProperties
}
