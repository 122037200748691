import Box, { type BoxProps } from '@mui/material/Box'

import { FocusedBorder } from '@top/ui/src/SceneModules/components/FocusedBorder'
import { getSelectedValues } from '@top/ui/src/SceneModules/helpers/getSelectedValues'
import { hasBuilderSelectionError } from '@top/ui/src/SceneModules/helpers/hasSelectionError'
import { COMMON_DEFAULTS } from '@top/ui/src/SceneModules/types'

import { PromptText } from '../components/PromptText'

import { Selection } from './components/Selection'
import { type ChoiceProps, isImageSelection } from './types'

export function Choice(props: ChoiceProps) {
  const {
    showPromptText = false,
    promptText,
    prompt_text_style,
    testId,
    selections,
    selection_style,
    isBuilder,
    onChange,
    disabled,
    value,
    isMultiChoice = false,
    onPromptTextChange,
    onSelectionTextChange,
    display_options,
    maximumSelection,
    onFocusSelection,
    onFocusPromptText,
    pinnedSelections = [],
    exclusiveSelections = [],
    isSelectionLabelAlwaysShown,
    isFocused,
    focusedChild,
    onFocus,
    onOpenModal,
    onRemoveImage,
    onOpenEditorModal,
    builderSelectionsErrors,
    widgetWidth,
    locale,
  } = { ...COMMON_DEFAULTS, ...props }

  const shouldRenderPromptText = promptText && showPromptText && prompt_text_style

  // Disable unselected choices if maximumSelection is set and reached.
  const areUnselectedDisabled = !!(
    isMultiChoice &&
    maximumSelection &&
    value.length >= maximumSelection
  )

  const handleOnSelect = (selectionUuid: string) => {
    if (!onChange) {
      return
    }

    const selectedValues = getSelectedValues(selectionUuid, value, isMultiChoice, maximumSelection)

    onChange(selectedValues, selectionUuid)
  }

  const handlePromptTextChange = (newPromptText: string) => {
    if (!isBuilder) {
      return
    }
    if (onPromptTextChange) {
      onPromptTextChange(newPromptText)
    }
  }

  const handlePromptTextFocus = () => {
    if (onFocusPromptText) {
      onFocusPromptText()
    }
  }

  const renderedSelections = selections.map((selection) => {
    const {
      uuid,
      etc: { position },
    } = selection
    const isSelected = value.includes(uuid)
    const isDisabled =
      disabled || (areUnselectedDisabled && !value.includes(uuid)) || (isBuilder && !isFocused)
    const isSelectionFocused = focusedChild?.type === 'selection' && focusedChild.uuid === uuid

    const isPinned = pinnedSelections.includes(uuid)
    const isExclusive = exclusiveSelections.includes(uuid)

    const handleSelectionClick = () => {
      if (isBuilder) {
        return
      }
      handleOnSelect(uuid)
    }

    const handleSelectionTextChange = (
      newText: string,
      options?: {
        clearSelectedElement: boolean
      }
    ) => {
      if (!isBuilder) {
        return
      }
      if (onSelectionTextChange) {
        onSelectionTextChange(uuid, newText, options)
      }
    }

    const handleOpenModal = () => {
      if (!isBuilder) {
        return
      }
      if (onOpenModal) {
        handleOnSelectionFocus()
        onOpenModal()
      }
    }

    const handleRemoveImage = () => {
      if (!isBuilder) {
        return
      }
      if (onRemoveImage) {
        onRemoveImage()
      }
    }

    const handleOpenEditorModal = () => {
      if (!isBuilder) {
        return
      }
      if (onOpenEditorModal) {
        onOpenEditorModal()
      }
    }

    const handleOnSelectionFocus = () => {
      if (onFocusSelection) {
        onFocusSelection(uuid)
      }
    }

    if (isImageSelection(selection)) {
      const {
        etc: { image_alt_text, image_url, text, translations },
        style: imageStyles,
      } = selection

      const hasText = text !== undefined

      const hasBuilderError = builderSelectionsErrors
        ? hasBuilderSelectionError(builderSelectionsErrors, uuid, {
            hasTextProperty: hasText,
            isImageSelection: true,
          })
        : false

      const selectionText = locale && translations ? translations[locale] ?? text : text

      return (
        <FocusedBorder
          hover={isBuilder && isFocused}
          isFocused={isBuilder && isSelectionFocused}
          hasError={hasBuilderError}
          disabled={!isBuilder || (isBuilder && disabled)}
          key={`selection-${uuid}-${image_alt_text ?? image_url}`}
        >
          <Selection
            disabled={isDisabled}
            isBuilder={isBuilder}
            onSelect={handleSelectionClick}
            parentTestId={testId}
            text={selectionText}
            image_alt_text={image_alt_text}
            image_url={image_url}
            selected={isSelected}
            isMultiChoice={isMultiChoice}
            onTextChange={handleSelectionTextChange}
            selection_style={selection_style}
            display_options={display_options}
            imageStyles={imageStyles}
            onFocus={handleOnSelectionFocus}
            position={position}
            isPinned={isPinned}
            isExclusive={isExclusive}
            isSelectionLabelAlwaysShown={isSelectionLabelAlwaysShown}
            onOpenModal={handleOpenModal}
            onRemoveImage={handleRemoveImage}
            onOpenEditorModal={handleOpenEditorModal}
            widgetWidth={widgetWidth}
          />
        </FocusedBorder>
      )
    }

    const {
      etc: { text, translations },
    } = selection

    const hasBuilderError = builderSelectionsErrors
      ? hasBuilderSelectionError(builderSelectionsErrors, uuid, {
          hasTextProperty: true,
          isImageSelection: false,
        })
      : false

    const selectionText = locale ? translations[locale] ?? text : text

    return (
      <FocusedBorder
        isFocused={isBuilder && isSelectionFocused}
        hover={isBuilder && isFocused}
        hasError={hasBuilderError}
        disabled={!isBuilder || (isBuilder && disabled)}
        key={`selection-${uuid}-${text}`}
      >
        <Selection
          disabled={isDisabled}
          isBuilder={isBuilder}
          onSelect={handleSelectionClick}
          parentTestId={testId}
          text={selectionText}
          selected={isSelected}
          isMultiChoice={isMultiChoice}
          onTextChange={handleSelectionTextChange}
          selection_style={selection_style}
          display_options={display_options}
          onFocus={handleOnSelectionFocus}
          position={position}
          isPinned={isPinned}
          isExclusive={isExclusive}
          isSelectionLabelAlwaysShown={isSelectionLabelAlwaysShown}
          onOpenModal={handleOpenModal}
          onRemoveImage={handleRemoveImage}
          onOpenEditorModal={handleOpenEditorModal}
          widgetWidth={widgetWidth}
        />
      </FocusedBorder>
    )
  })

  const isCondensed = display_options?.choice_display_type === 'condensed'

  const handleOnFocus = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.stopPropagation()
    if (!isFocused && onFocus) {
      onFocus()
    }
  }
  const sx = {
    display: 'flex',
    gap: `calc(${display_options?.gap_spacing ?? 1} * 1rem + 0.2rem)`,
    flexDirection: isCondensed ? undefined : 'column',
    flexFlow: isCondensed ? 'wrap' : undefined,
    justifyContent: isCondensed ? 'center' : undefined,
    width: '100%',
  } satisfies BoxProps['sx']

  const isPromptTextDisabled = disabled || (isBuilder && !isFocused)

  return (
    <Box data-testid={testId} sx={sx} onClick={handleOnFocus}>
      {shouldRenderPromptText && (
        <PromptText
          text={promptText}
          styles={prompt_text_style}
          parentTestId={testId}
          isBuilder={isBuilder}
          disabled={isPromptTextDisabled}
          onChange={handlePromptTextChange}
          onFocus={handlePromptTextFocus}
          showHover={isBuilder && isFocused}
          isFocused={focusedChild?.type === 'promptText'}
          widgetWidth={widgetWidth}
          fullWidth
        />
      )}
      {renderedSelections}
    </Box>
  )
}
