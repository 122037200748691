import { useEffect, useRef, useState } from 'react'
import { useHistory, useLocation, useParams } from 'react-router-dom'

import useContainerWidth from '@top/shared/src/hooks/useContainerWidth'
import { useGetPreferredLanguage } from '@top/shared/src/hooks/useGetPreferredLanguage'
import { Locale } from '@top/shared/src/Languages/types'
import { FullContainerSpinner } from '@top/ui/src/FullContainerSpinner'

import '../../shared/ScenesSharedStyle.css'
import '../styles.scss'

import { useApi } from 'api'
import NotFoundPage from 'components/ErrorBoundry/pages/NotFound'
import { SceneFooter } from 'components/scenes/SceneModules/components/SceneFooter'
import { SceneModulesScene } from 'components/scenes/SceneModules/components/SceneModulesScene'
import { TrackerProvider, useTracker } from 'contexts/Tracker'
import useCustomFonts from 'hooks/useCustomFonts'
import { useServeGet } from 'hooks/useServeGet'
import { useServeParams } from 'hooks/useServeParams'
import { ServeResponse } from 'types/Activity'
import { getPercentComplete } from 'utils/getPercentComplete'

type Props = {
  serveResponse: ServeResponse
}

export const SceneModules = (props: Props) => {
  const {
    serveResponse: {
      scenes,
      default_locale,
      allow_locale_selection,
      locales,
      hash,
      etc,
      privacy_policies,
    },
  } = props

  const { source, external_id, collection_id, local_storage_id, preview, app_id } = useServeParams()
  const { onResetTrackerEvents } = useTracker()

  const hasPrivacyPolicies = privacy_policies && privacy_policies.length > 0

  const api = useApi().apiClient

  const containerRef = useRef<HTMLDivElement>(null)
  const width = useContainerWidth(containerRef)

  const history = useHistory()
  const location = useLocation()
  const { search } = location

  const { orgUid, localeOverride } = useParams<{
    orgUid?: string
    localeOverride?: string
  }>()

  useCustomFonts(hash, orgUid)

  const [currentSceneUuid, setCurrentSceneUuid] = useState(scenes[0].uuid)

  const currentScene = scenes.find((scene) => scene.uuid === currentSceneUuid)

  if (!currentScene) {
    throw new Error(`Scene with uuid ${currentSceneUuid} not found`)
  }

  const isFirstScene = (scenes && scenes[0].uuid === currentSceneUuid) ?? false
  const isLastScene = (scenes && scenes[scenes.length - 1].uuid === currentSceneUuid) ?? false

  const backgroundItem = scenes && scenes[0].items.ITEM_BACKGROUND

  const showProgressBar = etc.show_progress_bar

  const { localeCode } = useGetPreferredLanguage(locales, default_locale)
  const userLocaleOrOverride = (localeOverride as Locale) ?? localeCode

  const hasLanguageSelector =
    allow_locale_selection && locales.length > 1 && isFirstScene && !!backgroundItem

  const onChangeSelectedLocale = (locale: Locale) => {
    history.push(`/${hash}/${locale}${search}`)

    onResetTrackerEvents()

    // Invalidating the serve query will trigger another request to serve and give us a new session_id
    api.queryClient.invalidateQueries([
      'serveGet',
      {
        hash,
        source,
        external_id,
        collection_id,
        local_storage_id,
        preview,
        app_id,
      },
    ])
  }

  const handleNextScene = () => {
    const currentSceneIndex = scenes.findIndex((scene) => scene.uuid === currentSceneUuid)
    const nextScene = scenes[currentSceneIndex + 1]
    const nextSceneUuid = nextScene.uuid

    setCurrentSceneUuid(nextSceneUuid)
  }

  useEffect(() => {
    document.documentElement.lang = userLocaleOrOverride.toLocaleLowerCase()
  }, [userLocaleOrOverride])

  return (
    <div style={{ width: '100%', height: '100%' }} ref={containerRef}>
      <SceneModulesScene
        locales={locales}
        responseLocale={userLocaleOrOverride}
        scene={currentScene}
        isDashboardPreview={false} // TODO: Handle the preview case later
        hasLanguageSelector={hasLanguageSelector}
        isLastScene={isLastScene}
        width={width}
        onChangeSelectedLocale={onChangeSelectedLocale}
        showProgressBar={showProgressBar}
        progressBarColor={currentScene.style.progressBarStyles.color}
        percentComplete={getPercentComplete(currentSceneUuid, scenes)}
        onNextScene={handleNextScene}
      />
      {hasPrivacyPolicies && <SceneFooter privacyPolicies={privacy_policies} />}
    </div>
  )
}

const SceneModulesLoader = (props: { shouldIgnoreTracker: boolean }) => {
  const { shouldIgnoreTracker } = props

  const serveGetQuery = useServeGet()

  if (serveGetQuery.isLoading) {
    return <FullContainerSpinner />
  }

  if (serveGetQuery.isError) {
    return <NotFoundPage />
  }

  const {
    data: {
      data: { session_id, hash, activity_uid },
    },
  } = serveGetQuery

  return (
    <TrackerProvider
      session_id={session_id}
      hash={hash}
      activity_uid={activity_uid}
      ignoreTracker={shouldIgnoreTracker}
    >
      <SceneModules serveResponse={serveGetQuery.data.data} />
    </TrackerProvider>
  )
}

export default SceneModulesLoader
