export const getMaxDate = (month: number | undefined, year: number | undefined) => {
  const isLeapYear = year && year % 4 === 0
  const februaryDays = isLeapYear ? 29 : 28
  const monthDayMaxArray = [31, februaryDays, 31, 30, 30, 31, 31, 31, 30, 31, 30, 31] as const

  if (month !== undefined) {
    return monthDayMaxArray[month - 1] ?? 31
  }
  return 31
}
