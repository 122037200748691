import { RefObject, useEffect, useState } from 'react'

import { getConfigs, getEnv, ProdEnv, ProdEnvs } from '@top/shared/src/configs'
import { ButtonType, ItemSkipButton } from '@top/shared_deprecated/src/types/Items'
import { Locale } from '@top/shared_deprecated/src/types/Languages'
import { IDistBranch, SceneType } from '@top/shared_deprecated/src/types/Scene'
import { ISkipButtonStyle } from '@top/shared_deprecated/src/types/Style'

import { debounce } from 'lodash'
import isURL from 'validator/lib/isURL'

export const getBtnWrapperHeights = (
  btnType: ButtonType,
  showSkipButton: boolean,
  sceneType?: SceneType
) => {
  const sceneSpecificHeights: { [key: string]: number } = {
    [SceneType.Numeric]: 88,
    [SceneType.SceneBuilder]: 70,
  }
  const animatedSkipHeight = (sceneType && sceneSpecificHeights[sceneType]) || 48
  const nextOnlySkipHeight = (sceneType && sceneSpecificHeights[sceneType]) || 'auto'

  const nextBtnOnly: { [key: string]: number | string } = {
    [ButtonType.contained]: 68,
    [ButtonType.arrow]: 76,
    [ButtonType.animated]: nextOnlySkipHeight,
  }
  const nextAndSkipBtn: { [key: string]: number | string } = {
    [ButtonType.contained]: 113,
    [ButtonType.arrow]: 122,
    [ButtonType.animated]: animatedSkipHeight,
  }
  return showSkipButton ? nextAndSkipBtn[btnType] : nextBtnOnly[btnType]
}

export const getIframeStyling = (isIframe: boolean) => {
  return isIframe ? { width: '100%', height: '100%' } : {}
}

export const getFromETC = (etc: any, key: string) => {
  return etc && etc[key] ? etc[key] : ''
}

export const handleRedirectUrl = (url: string = '') => {
  if (isURL(url)) {
    setTimeout(() => {
      window.open(url, '_blank')
    }, 400)
  } else {
    throw new Error(`Invalid url ${url}`)
  }
}

export const getCloudinaryUrl = ({
  cloudinaryId,
  zoom,
  cropHeight,
  cropWidth,
  cropX,
  cropY,
  width,
  height,
}: {
  cloudinaryId: string
  zoom?: number
  cropHeight?: number
  cropWidth?: number
  cropX?: number
  cropY?: number
  width?: number
  height?: number
}) => {
  const { CLOUDINARY_URL } = getConfigs()
  if (cloudinaryId === '') return ''
  let url = `${CLOUDINARY_URL}`
  if (zoom) url += `/w_${zoom.toFixed(1)},c_scale`
  if (
    isNumber(cropX) &&
    isNumber(cropY) &&
    isNumber(cropWidth) &&
    isNumber(cropHeight) &&
    (cropHeight as number) > 0 &&
    isNumber(zoom)
  ) {
    url += `/x_${applyZoom(cropX as number, zoom as number)},y_${applyZoom(
      cropY as number,
      zoom as number
    )},w_${applyZoom(cropWidth as number, zoom as number)},h_${applyZoom(
      cropHeight as number,
      zoom as number
    )},c_crop`
  }

  // On distributor, if we don't pass an explicit width we should assume it should fill screen
  url +=
    width && height ? `/w_${width},h_${height}` : `/w_${window.innerWidth},h_${window.innerHeight}`
  url += `,c_fit/${cloudinaryId}`
  return url
}

function applyZoom(value: number, zoom: number) {
  return Math.round(value * zoom)
}

function isNumber(x: number | undefined) {
  return typeof x === 'number'
}

/**
 * @deprecated
 */
export const isDevEnvironment = (): boolean => {
  const env = getEnv()
  return process.env.NODE_ENV === 'development' || !ProdEnvs.includes(env as ProdEnv)
}

export function alphaToHexValue(color: string, alphaValue?: number) {
  const hex = color.charAt(0) === '#' ? color.substring(1, 7) : color
  let alpha = alphaValue && Math.round(alphaValue * 255).toString(16)
  if (!alpha || (alpha && alpha.length === 1)) {
    alpha = `0${alpha}`
  }
  return `#${hex}${alpha}`
}

// Returns as value out of 100
export function getOpacityValue(color: string) {
  // If no opacity value in hex, return 100
  if (color && color.length && color.length < 8) {
    return 100
  }
  const hex = color.charAt(0) === '#' ? color.substring(1, 9) : color
  const alphaValue = hex.substring(6, 8)
  const opacity = (parseInt(alphaValue, 16) / 255) * 100
  return Math.round(opacity)
}

export const useIsWindowResizing = (ref: RefObject<HTMLDivElement>): boolean => {
  const [isResizing, setIsResizing] = useState<boolean>(false)
  useEffect(() => {
    const setResizingToFalse = debounce<() => void>(() => {
      setIsResizing(false)
    }, 100)
    const handleResize = () => {
      if (ref.current) {
        setIsResizing(true)
        setResizingToFalse()
      }
    }
    handleResize()
    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
      setResizingToFalse.cancel()
    }
  }, [ref])
  return isResizing
}

export const getBranch = (branches: Array<IDistBranch>, selId: string): IDistBranch | undefined => {
  return branches.find((b) => b.required_selections[selId] !== undefined)
}

export const getSelectedBranch = (branches: Array<IDistBranch>, selected: Array<string>) => {
  for (const branch of branches) {
    let match = false
    const requiredSelections = branch.required_selections
    if (requiredSelections && Object.keys(requiredSelections).length === selected.length) {
      match = true
      for (const selection of selected) {
        if (!branch.required_selections[selection]) {
          match = false
        }
      }
    }
    if (match) {
      return branch
    }
  }
  return null
}

export const getSkipButtonData = (
  ITEM_SKIP_BUTTON: ItemSkipButton | undefined,
  locale?: Locale
): Partial<{
  skip_text: string
  skip_style: ISkipButtonStyle
}> => {
  if (ITEM_SKIP_BUTTON === undefined) {
    return { skip_text: undefined, skip_style: undefined }
  }

  const {
    etc: { text: skip_text, translations },
    style: skip_style,
  } = ITEM_SKIP_BUTTON as ItemSkipButton

  // Use text from etc text property for legacy scenes
  return { skip_text: (locale && translations?.[locale]) ?? skip_text, skip_style }
}
