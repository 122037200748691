import React, { type MouseEvent, useState } from 'react'

import MoreVertIcon from '@mui/icons-material/MoreVert'
import IconButton from '@mui/material/IconButton'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'

import COLORS from '@top/shared/src/style/colors'

type Props = {
  onRemove: () => void
  onReplace: () => void
  onEdit?: () => void
}

export function EditImageMenu(props: Props) {
  const { onReplace, onRemove, onEdit } = props
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)

  const handleClick = (event: MouseEvent<HTMLButtonElement | null>) => {
    event.stopPropagation()

    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleReplace = (e: React.MouseEvent<HTMLLIElement>) => {
    e.stopPropagation()

    handleClose()
    onReplace()
  }

  const handleRemove = (e: React.MouseEvent<HTMLLIElement>) => {
    e.stopPropagation()

    onRemove()
  }

  const handleEdit = (e: React.MouseEvent<HTMLLIElement>) => {
    e.stopPropagation()

    handleClose()
    if (onEdit) {
      onEdit()
    }
  }

  return (
    <>
      <IconButton
        size="small"
        aria-label="image-options"
        aria-haspopup="true"
        onClick={handleClick}
        sx={{
          position: 'absolute',
          opacity: 0.72,
          backgroundColor: COLORS.WHITE,
          borderRadius: '400px',
          top: '5px',
          right: '5px',
          '&:hover': {
            opacity: 0.72,
            backgroundColor: COLORS.WHITE,
          },
        }}
      >
        <MoreVertIcon fontSize="small" />
      </IconButton>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        keepMounted={true}
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {onEdit && (
          <MenuItem dense={true} onClick={handleEdit}>
            Edit Image
          </MenuItem>
        )}
        <MenuItem dense={true} onClick={handleReplace}>
          {onEdit ? 'Change Image' : 'Replace Image'}
        </MenuItem>
        <MenuItem dense={true} onClick={handleRemove}>
          Remove Image
        </MenuItem>
      </Menu>
    </>
  )
}
