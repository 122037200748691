import React, { useEffect } from 'react'

import { PrivacyPolicy } from 'types/Activity'

type Props = {
  privacyPolicies: PrivacyPolicy[]
}

export const SceneFooter = (props: Props) => {
  const { privacyPolicies } = props

  const linksEl = React.createRef<HTMLDivElement>()

  useEffect(() => {
    if (!linksEl.current) {
      return
    }
    const { offsetWidth, scrollWidth } = linksEl.current
    if (scrollWidth - offsetWidth > 0) {
    }
  }, [linksEl])

  return (
    <div
      style={{
        display: 'flex',
        fontSize: '0.75rem',
        width: '100vw',
        backgroundColor: '#ffff',
        opacity: 0.9,
        overflowX: 'auto',
        position: 'fixed',
        whiteSpace: 'nowrap',
        bottom: 0,
        justifyContent: 'center',
      }}
      ref={linksEl}
    >
      {privacyPolicies.map(({ link, title }, idx: number) => (
        <a
          key={`link-${idx}`}
          href={link}
          style={{
            textDecoration: 'underline',
            color: '#000000',
            padding: '0.5rem',
            minWidth: '80px',
            textAlign: 'center',
          }}
          target="_blank"
          rel="noopener noreferrer"
        >
          {title}
        </a>
      ))}
    </div>
  )
}
