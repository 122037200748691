import React from 'react'

import SvgIcon, { type SvgIconProps } from '@mui/material/SvgIcon'

type Props = {
  fill?: string
} & SvgIconProps

const Pin = (props: Props) => {
  const { fill = 'white', ...rest } = props
  return (
    <SvgIcon {...rest} fill="none" viewBox="0 0 12 12" style={{ marginLeft: '-2px' }}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 4.5V2H8.5C8.775 2 9 1.775 9 1.5C9 1.225 8.775 1 8.5 1H3.5C3.225 1 3 1.225 3 1.5C3 1.775 3.225 2 3.5 2H4V4.5C4 5.33 3.33 6 2.5 6V7H5.485V10.5L5.985 11L6.485 10.5V7H9.5V6C8.67 6 8 5.33 8 4.5Z"
        fill={fill}
      />
    </SvgIcon>
  )
}

export default Pin
