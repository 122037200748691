import React, { useCallback, useEffect, useState } from 'react'
import Div100vh from 'react-div-100vh'

import { FALLBACK_DEFAULT_LOCALE, Locale } from '@top/shared_deprecated/src/types/Languages'
import { IScene, ISelection } from '@top/shared_deprecated/src/types/Scene'

import Scene from 'components/scenes/Scene'

interface Props {
  scene: IScene
  onSceneReady: (num: number) => void
}

const mockHandlers = {
  goToSkipPage: () =>
    new Error('goToSkipPage should not have been triggered on render scene components'),
  enableScroll: () =>
    new Error('enableScroll should not have been triggered on render scene components'),
  goToNextPage: () =>
    new Error('goToNextPage should not have been triggered on render scene components'),
  isLastScene: false,
  locale: FALLBACK_DEFAULT_LOCALE as Locale,
}

// Responsible for pre-fetching images
const ReadyNextScene: React.FC<React.PropsWithChildren<Props>> = (props) => {
  const [readyCalls, setReadyCalls] = useState(0)
  const { onSceneReady, scene } = props

  useEffect(() => {
    const id = setTimeout(() => {
      onSceneReady(scene.etc.position)
    }, 5000)

    return () => clearTimeout(id)
  }, [onSceneReady, scene])

  const getImageCount = useCallback((scene: IScene): number => {
    let imageCount = 0
    if (
      scene.items.ITEM_BACKGROUND.style?.backgroundImage &&
      scene.items.ITEM_BACKGROUND.style?.backgroundType === 'image'
    )
      imageCount++
    if (scene.items.ITEM_LOGO?.style?.backgroundImage) imageCount++

    scene?.selections?.forEach((selection, index) => {
      if (scene.etc?.image_orientation !== 'IMAGE_GRID' && index > 1) return
      const selectionImg = (selection as ISelection)?.etc?.image_url
      if (selectionImg) {
        imageCount++
      }
    })

    return imageCount
  }, [])

  useEffect(() => {
    const numOfRequiredCalls = getImageCount(scene)
    if (readyCalls >= numOfRequiredCalls) {
      onSceneReady(scene.etc.position)
    }
  }, [readyCalls, onSceneReady, getImageCount, scene])

  return (
    <Div100vh style={{ position: 'absolute', width: '100%', top: 0, visibility: 'hidden' }}>
      <Scene
        onImageReady={() => setReadyCalls((prev) => prev + 1)}
        scene={scene}
        isPresentational={true}
        isSwiped={false}
        {...mockHandlers}
      />
    </Div100vh>
  )
}

export default ReadyNextScene
