import React, { lazy, Suspense, useEffect } from 'react'
import { BrowserRouter, Route, Switch } from 'react-router-dom'

import { useSelfHostedFonts } from '@top/shared/src/Fonts/hooks/useSelfHostedFonts'
import { initSentry } from '@top/shared/src/Sentry/helpers/initSentry'
import { useSentryTags } from '@top/shared/src/Sentry/hooks/useSentryTags'

import App from '../App'

import ErrorBoundry from 'components/ErrorBoundry'
import OnlyDevRoute from 'components/OnlyDevRoute'
import FullPageCircularProgress from 'components/shared/FullPageCircularProgress'

const DevSettings = lazy(() => import('components/Dev'))
const Health = lazy(() => import('@top/shared_deprecated/src/pages/health'))
const ActivityNotFoundPage = lazy(() => import('components/ErrorBoundry/pages/ActivityNotFound'))
const HubsErrorPage = lazy(() => import('components/ErrorBoundry/pages/HubsError'))
const NotFoundPage = lazy(() => import('components/ErrorBoundry/pages/NotFound'))
const GlobalErrorPage = lazy(() => import('components/ErrorBoundry/pages/GlobalError'))
const Version = lazy(() => import('components/Version'))

const Router: React.FC<React.PropsWithChildren<unknown>> = () => {
  useSentryTags({ appName: 'Distributor' })
  useSelfHostedFonts()
  useEffect(() => {
    initSentry({
      // Ignore errors will match strings partially
      ignoreErrors: ['Object Not Found Matching Id'],
    })
  }, [])

  return (
    <ErrorBoundry>
      <Suspense fallback={<FullPageCircularProgress />}>
        <BrowserRouter>
          <Switch>
            <Route exact path="/_version" component={Version} />
            <Route exact path="/_health" component={Health} />
            <Route exact path="/hubs-error" component={HubsErrorPage} />
            <Route exact path="/activity-not-found" component={ActivityNotFoundPage} />
            <Route exact path="/error" component={GlobalErrorPage} />
            <Route exact path="/preview/:orgUid/:uid">
              <App ignoreTracker isDashboardPreview />
            </Route>
            <Route exact path="/preview/:orgUid/:uid/:localeOverride">
              <App ignoreTracker isDashboardPreview />
            </Route>
            <OnlyDevRoute exact path="/_dev">
              <DevSettings />
            </OnlyDevRoute>
            <Route exact path="/:hash">
              <App />
            </Route>
            <Route exact path="/:hash/:localeOverride">
              <App />
            </Route>
            <Route component={NotFoundPage} />
          </Switch>
        </BrowserRouter>
      </Suspense>
    </ErrorBoundry>
  )
}

export default Router
