import { type Locale } from '@top/shared/src/Languages/types'
import { type ImageSelection, type Selection } from '@top/shared/src/SceneModules/types'
import {
  type SelectionBlockStyle,
  type SelectionStyles,
  type TextStyle,
} from '@top/shared/src/style/types'
import {
  type BuilderSelectionsErrors,
  type QuestionSceneModuleProps,
} from '@top/ui/src/SceneModules/types'

export type SelectionStyle = TextStyle & {
  selectionBlockStyles: SelectionBlockStyle
}

export type ChoiceProps = Omit<QuestionSceneModuleProps<string[], string>, 'onChange'> &
  SelectionStyles & {
    promptText?: string
    /** @default false */
    isMultiChoice?: boolean
    /** @default false */
    showPromptText?: boolean
    /** @default undefined */
    minimumSelection?: number
    /** @default undefined */
    maximumSelection?: number
    /** @default false */
    randomizeSelections?: boolean
    /**
     * This is an array of selection `uuid`s.
     *
     * @default undefined
     */
    pinnedSelections?: string[]
    /**
     * This is an array of selection `uuid`s.
     *
     * @default undefined
     */
    exclusiveSelections?: string[]
    /** @default false */
    sharebackResults?: boolean
    selections: Selection[]
    onPromptTextChange?: (newPomptText: string) => void
    onSelectionTextChange?: (
      selectionUuid: string,
      newText: string,
      options?: {
        clearSelectedElement: boolean
      }
    ) => void
    onChange?: (value: string[], selectionUuid: string) => void
    widgetWidth?: number
    onFocusSelection?: (selectionUuid: string) => void
    onFocusPromptText?: () => void
    /**
     * This is a boolean that determines whether the selection label is always visible. If it is
     * false, the selection label will only be visible when the selection is pinned or exclusive.
     */
    isSelectionLabelAlwaysShown: boolean
    /**
     * @description When in the builder, this is the focused child - either `selection` or the
     *              `promptText`.
     */
    focusedChild?: { type: 'selection'; uuid: string } | { type: 'promptText' }
    onOpenModal?: () => void
    onRemoveImage?: () => void
    onOpenEditorModal?: () => void
    builderSelectionsErrors?: BuilderSelectionsErrors
    /**
     * @description This is the respondent locale to determine which translation to use for the
     *              selections. If there is no locale, the selections text property will be used.
     */
    locale?: Locale
  }

export const isImageSelection = (selection: Selection): selection is ImageSelection => {
  return selection.style.hasOwnProperty('zoom') && selection.etc.hasOwnProperty('image_url')
}
