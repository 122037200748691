import React, {
  CSSProperties,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import { useLocation, useParams } from 'react-router'
import { useHistory } from 'react-router-dom'

import {
  convertToLocale,
  FALLBACK_DEFAULT_LOCALE,
  Locale,
} from '@top/shared_deprecated/src/types/Languages'
import { IScene, SceneAction, SceneType } from '@top/shared_deprecated/src/types/Scene'
import { ensureHTTPS } from '@top/shared_deprecated/src/utils/helpers'

import {
  getUserIDFromLocalStorage,
  isBlankFlexScene,
  provideServeReqBody,
  shouldDisableScroll,
} from './helpers'
import { PostMessageCommands } from './Tracker_DEPRECATED'

import useAPI_DEPRECATED from 'hooks/useAPI_DEPRECATED'
import { useIsSceneModulesEnabled } from 'hooks/useIsSceneModulesEnabled'
import queryString from 'query-string'
import {
  IActivityPreviewResponse,
  ISceneResults,
  ISelfResults,
  IServeResponse,
} from 'types/ServeResponse'

export interface IPrivacyPolicy {
  title: string
  link: string
}

export enum DistSource {
  TopSDK = 'top_sdk',
  public = 'public',
  MobileSDK = 'mobile_sdk',
}

interface IDistributionProps {
  isDashboardPreview?: boolean
  children: React.ReactNode
  testHash?: any
}

type Style = 'embedded'

interface IDistributionContext {
  isLoading: boolean
  isError: boolean | undefined
  scenes: IScene[] | undefined
  results?: any
  hash?: string
  sessionId: string | undefined
  isIframe: boolean
  providersShareURLs: { facebook: string } | undefined
  privacyPolicies: Array<IPrivacyPolicy> | undefined
  isCompleted: boolean
  shouldScroll: boolean
  setNextScene: (sceneId: string) => void
  setSkipScene: (sceneId: string) => Promise<number>
  nextPage: number
  selfResults?: ISelfResults
  source: DistSource
  external_id?: string
  collection_id?: string
  local_storage_id?: string
  sdkOrigin?: string
  app_id?: string
  style: Style | undefined
  tmAppId?: string
  progressBarStyles: CSSProperties | undefined
  hasProgressBar: boolean
  progressBarRef: React.RefObject<HTMLDivElement>
  progressBarPayload: { percent: number; style: CSSProperties }
  setCurrentSceneUiid: React.Dispatch<React.SetStateAction<string | undefined>>
  showSceneShareBack: (sceneUuid: string) => void
  endActivity: boolean
  setEndActivity: React.Dispatch<React.SetStateAction<boolean>>
  distributionWidth?: number
  setDistWidth: React.Dispatch<React.SetStateAction<number | undefined>>
  isDashboardPreview?: boolean
  orgUid?: string
  locales: Locale[]
  localeOverride?: Locale
  allowLocaleSelection: boolean
  handleSelectedLocale: (locale: Locale) => void
  defaultLocale?: Locale
  activityUid: string
}

const initProgressBarPayload = { percent: 0, style: {} }
export const progressBarRefInitValue = null as unknown as React.RefObject<HTMLDivElement>

const DistributionContext = React.createContext({
  isLoading: false,
  isError: undefined,
  scenes: [],
  results: [],
  hash: '',
  activityUid: '',
  sessionId: '',
  isIframe: false,
  providersShareURLs: undefined,
  privacyPolicies: [],
  isCompleted: false,
  shouldScroll: true,
  setNextScene: () => null,
  nextPage: 0,
  selfResults: undefined,
  source: DistSource.public,
  external_id: undefined,
  collection_id: undefined,
  local_storage_id: undefined,
  sdkOrigin: '',
  app_id: '',
  style: undefined,
  progressBarStyles: undefined,
  hasProgressBar: false,
  progressBarRef: progressBarRefInitValue,
  setCurrentSceneUiid: () => null,
  showSceneShareBack: () => null,
  progressBarPayload: initProgressBarPayload,
  endActivity: false,
  setEndActivity: () => null,
  setSkipScene: () => new Promise(() => {}),
  setDistWidth: () => {},
  distributionWidth: undefined,
  locales: [],
  localeOverride: undefined,
  tmAppId: '',
  allowLocaleSelection: false,
  handleSelectedLocale: () => null,
  defaultLocale: undefined,
} as IDistributionContext)

export const useDistribution = () => useContext(DistributionContext)

export const DistributionProvider: React.FC<React.PropsWithChildren<IDistributionProps>> = ({
  isDashboardPreview,
  children,
  testHash,
}) => {
  const [distributionWidth, setDistWidth] = useState<number>()
  const [scenes, setScenes] = useState<Array<IScene>>()
  const [progressBarStyles, setProgressBarStyles] = useState<CSSProperties>()
  const [hasProgressBar, setHasProgressBar] = useState<boolean>(false)
  const progressBarRef = useRef<HTMLDivElement>(null)

  const [results, setResults] = useState<ISceneResults>()
  const [sessionId, setSessionId] = useState<string>('')
  const [isIframe, setIsIframe] = useState<boolean>(false)
  const [providersShareURLs, setProvidersShareURLs] = useState<{ facebook: any }>()
  const [privacyPolicies, setPrivacyPolicies] = useState<Array<IPrivacyPolicy>>()
  const [isCompleted, setIsCompleted] = useState<boolean>(false)
  const [shouldScroll, setShouldScroll] = useState<boolean>(true)
  const [nextPage, setNextPage] = useState<number>(0)
  const [nextSceneId, setNextSceneId] = useState<string>('')
  const [selfResults, setSelfResults] = useState<ISelfResults>()
  const [currentSceneUuid, setCurrentSceneUiid] = useState<string | undefined>()
  const [sharebackUuids, setSharebackUuids] = useState<string[]>([])
  const [endActivity, setEndActivity] = useState<boolean>(false)
  const [locales, setLocales] = useState<Locale[]>([])
  const [tmAppId, setTmAppId] = useState('')
  const [allowLocaleSelection, setAllowLocaleSelection] = useState(false)
  const [defaultLocale, setDefaultLocale] = useState<Locale>(FALLBACK_DEFAULT_LOCALE)
  const [activityUid, setActivityUId] = useState('')

  const { hash, uid, orgUid, localeOverride } = useParams<{
    hash: string
    uid?: string
    orgUid?: string
    localeOverride?: string // At this point we can't be sure this is a valid locale
  }>()
  const verifiedLocaleOverride = localeOverride ? convertToLocale(localeOverride) : undefined
  const location = useLocation()
  const history = useHistory()
  const { search } = location
  const isSceneModulesEnabled = useIsSceneModulesEnabled()
  const [{ isLoading, isError, data, statusCode, error }, fetchDistAPI] = useAPI_DEPRECATED<
    IActivityPreviewResponse | IServeResponse
  >()

  const {
    source = DistSource.public,
    external_id,
    collection_id,
    scroll,
    sdk_local_storage_id,
    pid,
    preview,
    style,
    app_id,
  }: {
    source?: DistSource
    external_id?: string
    collection_id?: string
    scroll?: string
    sdk_local_storage_id?: string
    pid?: string
    preview?: string
    style?: Style
    app_id?: string
  } = queryString.parse(search)

  const local_storage_id = sdk_local_storage_id || getUserIDFromLocalStorage()

  const sdkOrigin = useRef<string>('')

  const showSceneShareBack = (sceneUuid: string): void => {
    setSharebackUuids((uuids: string[]) => [...uuids, sceneUuid])
  }

  // Detect if app is loaded in iframe (REVISIT THIS)
  useEffect(() => {
    setIsIframe(window.location !== window.parent.location)
  }, [isIframe])

  const scenesMap = useMemo(
    () =>
      scenes &&
      scenes.reduce((acc: { [uuid: string]: number }, curr, index) => {
        return { ...acc, [curr.uuid]: index }
      }, {}),
    [scenes]
  )

  const progressBarPayload = useMemo(() => {
    const currentScene = scenes
      ? scenes.find((s: IScene) => s.uuid === currentSceneUuid)
      : undefined
    const currentScenePercent = currentScene !== undefined ? currentScene.percentage_completion : 0

    const progressBarStyles = currentScene ? currentScene.style.progressBarStyles : {}

    const isCurrentSceneShareBack =
      sharebackUuids.filter((uuid: string) => uuid === currentSceneUuid).length > 0

    const nextScene = scenes ? scenes.find((s: IScene) => s.uuid === nextSceneId) : undefined
    const nextScenePercent =
      isCurrentSceneShareBack && nextScene !== undefined ? nextScene.percentage_completion : 0

    const isLastScene =
      currentScene?.action_type === SceneAction.END_ACTIVITY ||
      currentScene?.action_type === SceneAction.REDIRECT

    const isIsBlankFlexScene = isBlankFlexScene(currentScene) && isLastScene
    const noShareBack = !currentScene?.etc.shareback_results && isLastScene

    const isDistComplete =
      endActivity ||
      isIsBlankFlexScene ||
      noShareBack ||
      (isCurrentSceneShareBack && isLastScene) ||
      (currentScene?.type === SceneType.Generic && isLastScene) ||
      (currentScene?.etc.is_optional && isLastScene) ||
      (currentScene?.type === SceneType.CTA && isLastScene)

    const maxValue = Math.max(currentScenePercent, nextScenePercent, isDistComplete ? 1 : 0)

    return { percent: Math.floor(maxValue * 100), style: progressBarStyles }
  }, [nextSceneId, scenes, currentSceneUuid, sharebackUuids, endActivity])

  const setNextScene = useCallback(
    (sceneId: string) => {
      setNextSceneId(sceneId)
      setNextPage((scenesMap && scenesMap[sceneId]) || -1)
    },
    [scenesMap]
  )

  const setSkipScene = useCallback(
    async (sceneId: string): Promise<number> => {
      return new Promise((resolve) => {
        setNextSceneId(sceneId)
        setNextPage((scenesMap && scenesMap[sceneId]) || -1)
        resolve((scenesMap && scenesMap[sceneId]) || -1)
      })
    },
    [scenesMap]
  )

  const fetchDistribution = useCallback(async () => {
    if (isSceneModulesEnabled) {
      return fetchDistAPI({
        url: isDashboardPreview
          ? '/dashboard/dashboard.Public/ActivityPreview'
          : '/distributor/distributor.Distributor/ServeV2',
        body: isDashboardPreview
          ? provideServeReqBody({ uid, isPreview: true })
          : provideServeReqBody({
              hash: hash || testHash || '',
              app_id,
              source,
              collection_id,
              external_id,
              pid,
              preview,
              local_storage_id,
            }),
        sentryExtras: { ...(isDashboardPreview ? { uid } : { hash }) },
      })
    }
    return fetchDistAPI({
      url: isDashboardPreview
        ? '/dashboard/dashboard.Public/ActivityPreview'
        : '/distributor/distributor.Distributor/Serve',
      body: isDashboardPreview
        ? provideServeReqBody({ uid, isPreview: true })
        : provideServeReqBody({
            hash: hash || testHash || '',
            app_id,
            source,
            collection_id,
            external_id,
            pid,
            preview,
            local_storage_id,
          }),
      sentryExtras: { ...(isDashboardPreview ? { uid } : { hash }) },
    })
  }, [
    isSceneModulesEnabled,
    fetchDistAPI,
    isDashboardPreview,
    uid,
    hash,
    testHash,
    app_id,
    source,
    collection_id,
    external_id,
    pid,
    preview,
    local_storage_id,
  ])

  const handleSelectedLocale = useCallback(
    (locale: Locale) => {
      if (locale !== localeOverride) {
        setSessionId('')
        setIsCompleted(false)

        /**
         * We call fetchDistribution to get a new session_id, thus triggering an ACTIVITY_RENDER event with the selected locale.
         * This is necessary to ensure the selected locale is associated with all tracker events (ACTIVITY_RENDER, ACTIVITY_START, SCENE_VIEW)
         **/
        fetchDistribution()

        history.push(`/${hash}/${locale}${search}`)
      }
    },
    [localeOverride, history, hash, search, fetchDistribution]
  )

  useEffect(() => {
    fetchDistribution()
  }, [fetchDistribution])

  useEffect(() => {
    if (!statusCode || statusCode === 200) {
      return
    }
    // check for expired distributions
    if (statusCode === 400 && error) {
      const { meta } = error
      if (meta) {
        if (meta.redirect_url) {
          window.location.href = ensureHTTPS(meta.redirect_url)
        }
      }
    }

    if (!error) return

    switch (statusCode) {
      case 500:
        history.push('/error')
        break
      case 400:
        if (error.data.meta.ui_code === 'FE003') {
          history.push('/hubs-error')
          break
        }
        history.push('/activity-not-found')
        break
      case 404:
        history.push('/activity-not-found')
        break
      default:
        history.push('/error')
    }
  }, [statusCode, error, history])

  useEffect(() => {
    ;(async () => {
      if (data) {
        // check for scroll option
        if (shouldDisableScroll(scroll)) {
          setShouldScroll(false)
        }
        if (isDashboardPreview) {
          const activityPreviewData = data as IActivityPreviewResponse
          const {
            activity: {
              scenes,
              share_settings: { providers_share_url },
              styles,
              etc,
              locales,
            },
          } = activityPreviewData
          setScenes(scenes)
          if (etc?.show_progress_bar && styles?.progressBarStyles) {
            setProgressBarStyles(styles.progressBarStyles)
            setHasProgressBar(true)
          }
          if (providers_share_url) {
            const { ACTIVITY_SHARE_PROVIDER_FACEBOOK } = providers_share_url

            ACTIVITY_SHARE_PROVIDER_FACEBOOK &&
              setProvidersShareURLs({ facebook: ACTIVITY_SHARE_PROVIDER_FACEBOOK })
          }
          setLocales(locales)
        } else {
          const serveData = data as IServeResponse
          const {
            scene_results,
            scenes,
            session_id,
            providers_share_url,
            footer_links,
            completed,
            self_scene_results,
            styles,
            etc,
            locales,
            tm_app_id,
            allow_locale_selection,
            default_locale,
            activity_uid,
          } = serveData
          setScenes(scenes)
          setResults(scene_results)
          setSessionId(session_id)
          setActivityUId(activity_uid)
          setPrivacyPolicies(footer_links)
          setIsCompleted(completed)
          setSelfResults(self_scene_results)
          setLocales(locales)
          setTmAppId(tm_app_id)
          setAllowLocaleSelection(allow_locale_selection)
          setDefaultLocale(default_locale)
          if (etc?.show_progress_bar && styles?.progressBarStyles) {
            setProgressBarStyles(styles.progressBarStyles)
            setHasProgressBar(true)
          }
          if (providers_share_url) {
            const { ACTIVITY_SHARE_PROVIDER_FACEBOOK } = providers_share_url
            ACTIVITY_SHARE_PROVIDER_FACEBOOK &&
              setProvidersShareURLs({ facebook: ACTIVITY_SHARE_PROVIDER_FACEBOOK })
          }
        }
      }
    })()
  }, [data, isDashboardPreview, scroll])

  useEffect(() => {
    const receiveMessage = (e: MessageEvent) => {
      if (!e.origin || !e.data) {
        return
      }
      const { type } = e.data
      if (type === PostMessageCommands.SetOrigin) {
        sdkOrigin.current = e.data.origin
      }
    }
    window.addEventListener('message', receiveMessage, false)

    return () => window.removeEventListener('message', receiveMessage)
  }, [])

  useEffect(() => {
    if (sdkOrigin.current) {
      const imgs = document.images
      const len = imgs.length
      let counter = 0
      ;[].forEach.call(imgs, function (img: HTMLImageElement) {
        if (img.complete) incrementCounter()
        else img.addEventListener('load', incrementCounter, false)
      })
      const incrementCounter = () => {
        counter++
        if (counter === len) {
          window.parent.postMessage({ type: PostMessageCommands.TopIsReady }, sdkOrigin.current)
        }
      }
    }
  }, [])
  return (
    <DistributionContext.Provider
      value={{
        isLoading,
        isError,
        scenes,
        results,
        hash,
        sessionId,
        isIframe,
        providersShareURLs,
        privacyPolicies,
        isCompleted,
        shouldScroll,
        setNextScene,
        setSkipScene,
        nextPage,
        selfResults,
        source,
        local_storage_id,
        external_id,
        collection_id,
        app_id,
        sdkOrigin: sdkOrigin.current,
        style,
        progressBarStyles,
        hasProgressBar,
        progressBarRef,
        setCurrentSceneUiid,
        showSceneShareBack,
        progressBarPayload,
        setEndActivity,
        endActivity,
        setDistWidth,
        distributionWidth,
        orgUid,
        locales,
        localeOverride: verifiedLocaleOverride,
        isDashboardPreview,
        tmAppId,
        allowLocaleSelection,
        handleSelectedLocale,
        defaultLocale: defaultLocale || FALLBACK_DEFAULT_LOCALE,
        activityUid,
      }}
    >
      {children}
    </DistributionContext.Provider>
  )
}
