import React, { useCallback, useEffect, useMemo, useState } from 'react'

import { enumKeys } from '@top/shared_deprecated/src/utils/helpers'

import { Swipe, Transition } from 'components/shared/SceneTransitions/types'

const InitialState: DevState = {
  isSentryOn: false,
  animation: Transition.SLIDE_UP,
  swipe: Swipe.UP,
}

interface IDevSettingsContext {
  state: DevState
  toggleSentry: () => void
  updateDevState: (key: string, value: any) => void
  defaultDevState: () => void
}

interface DevState {
  isSentryOn: boolean
  animation: Transition
  swipe: Swipe
}

interface Props {
  children: React.ReactElement
}
const DevSettingsContext = React.createContext<IDevSettingsContext | undefined>(undefined)

export const useDevSettings = () => {
  const context = React.useContext(DevSettingsContext)
  if (context === undefined) {
    throw new Error('useDevSettings must be used within a DevSettingsProvider')
  }
  return context
}

const TOP_DEV_TOOLS_STATE_KEY = 'TOP_DEV_TOOLS_STATE_KEY'

export const DevSettingsProvider: React.FC<React.PropsWithChildren<Props>> = (props) => {
  const [devState, setDevState] = useState<DevState>(InitialState)
  const urlParams = new URLSearchParams(window.location.search)
  const animation = urlParams.get('animation') as unknown as Transition
  const swipe = urlParams.get('swipe') as unknown as Swipe

  const { children } = props
  useEffect(() => {
    try {
      const stateJSON = localStorage.getItem(TOP_DEV_TOOLS_STATE_KEY)
      if (stateJSON) {
        const state: DevState = JSON.parse(stateJSON)
        setDevState({ ...InitialState, ...state })
      }
    } catch (error) {
      console.error(error)
    }
  }, [])

  // persist in local storage
  useEffect(() => {
    try {
      const stateJSON = JSON.stringify(devState)
      localStorage.setItem(TOP_DEV_TOOLS_STATE_KEY, stateJSON)
    } catch (error) {
      console.error(error)
    }
  }, [devState])

  const toggleSentry = useCallback(() => {
    setDevState((state: DevState) => ({ ...state, isSentryOn: !state.isSentryOn }))
  }, [])

  const updateDevState = useCallback((key: string, value: any) => {
    setDevState((state: DevState) => ({ ...state, [key]: value }))
  }, [])

  const defaultDevState = useCallback(() => {
    setDevState(InitialState)
  }, [])

  devState.animation = Object.values(Transition).includes(animation as any)
    ? animation
    : InitialState.animation
  devState.swipe = enumKeys(Swipe).includes(swipe as any) ? swipe : InitialState.swipe
  // since it's on Top we make sure the effect of change is minimum
  const memoizedValue = useMemo(
    () => ({ state: devState, toggleSentry, updateDevState, defaultDevState }),
    [devState, toggleSentry, updateDevState, defaultDevState]
  )

  return <DevSettingsContext.Provider value={memoizedValue}>{children}</DevSettingsContext.Provider>
}
