import { useLayoutEffect } from 'react'

import { getConfigs, getEnv } from '@top/shared/src/configs'

/**
 * @description Injects the font css into the DOM
 * use useLayoutEffect to avoid FOUC (flash of unstyled content)
 */
export default function useCustomFonts(distHash?: string, orgUid?: string) {
  useLayoutEffect(() => {
    const { CLOUDFRONT_URL, API_URL } = getConfigs()
    const env = getEnv()
    const link = document.createElement('link')
    link.rel = 'stylesheet'
    link.type = 'text/css'

    // Check if activity has a hash and is from a distribution or doesn't and is from a preview
    if (distHash) {
      link.href = `${CLOUDFRONT_URL}/vci-touchpoint/${env}/css/${distHash}.css`
    } else if (orgUid) {
      link.href = `${API_URL}/dashboard/font-css?org_uid=${orgUid}`
    }

    document.head.appendChild(link)

    return () => {
      document.head.removeChild(link)
    }
  }, [distHash, orgUid])
}
