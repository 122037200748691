import { useCallback, useRef, useState } from 'react'

type SwipeDirection = 'RIGHT' | 'LEFT' | 'UP' | 'DOWN' | undefined
type SwipeType = 'HORIZONTAL' | 'VERTICAL'
const ACCELERATION_THRESHOLD = 0.5

interface ILastWheel {
  x: {
    delta: number
    ts: number
  }
  y: {
    delta: number
    ts: number
  }
}

function useWheel() {
  const [isSwiping, setIsSwiping] = useState(false)
  const [swipeDirection, setSwipeDirection] = useState<SwipeDirection>()
  const lastWheel = useRef<ILastWheel>({
    x: { delta: 0, ts: Date.now() },
    y: { delta: 0, ts: Date.now() },
  })

  const calculateAcceleration = (
    currentDelta: number,
    currTs: number,
    prev: { delta: number; ts: number }
  ) => {
    const { delta: prevDelta, ts: prevTs } = prev
    const delta = currentDelta - prevDelta
    const ts = currTs - prevTs
    const acceleration = delta / ts

    return acceleration
  }

  const handleWheelEvent = useCallback((event: React.WheelEvent<HTMLDivElement>): void => {
    let swipe: SwipeType
    const tsCurr = Date.now()
    if (Math.abs(event.deltaX) > Math.abs(event.deltaY)) {
      swipe = 'HORIZONTAL'
    } else {
      swipe = 'VERTICAL'
    }

    let acceleration = 0
    let triggerSwipeAction
    let swipeDir: SwipeDirection
    switch (swipe) {
      case 'HORIZONTAL':
        acceleration = calculateAcceleration(event.deltaX, tsCurr, lastWheel.current.x)

        triggerSwipeAction = acceleration > ACCELERATION_THRESHOLD ? true : false
        swipeDir = event.deltaX > 0 ? 'LEFT' : 'RIGHT'
        setSwipeDirection(swipeDir)
        setIsSwiping(triggerSwipeAction)

        break
      case 'VERTICAL':
        acceleration = calculateAcceleration(event.deltaY, tsCurr, lastWheel.current.y)

        triggerSwipeAction = acceleration > ACCELERATION_THRESHOLD ? true : false
        swipeDir = event.deltaY > 0 ? 'UP' : 'DOWN'
        setSwipeDirection(swipeDir)
        setIsSwiping(triggerSwipeAction)

        break
      default:
        new Error(`unknown swipe type ${swipe}`)
    }

    lastWheel.current = {
      x: {
        delta: event.deltaX,
        ts: tsCurr,
      },
      y: {
        delta: event.deltaY,
        ts: tsCurr,
      },
    }
  }, [])

  const returnVal: [(event: React.WheelEvent<HTMLDivElement>) => void, boolean, SwipeDirection] = [
    handleWheelEvent,
    isSwiping,
    swipeDirection,
  ]
  return returnVal
}

export default useWheel
