/** Props that are common to all scene modules. */
import { type SelectionsImageErrors } from '@top/dashboard/src/pages/activities/[activityId]/SceneModulesActivity/helpers/validation/images/getSceneImageErrors'
import { type SelectionsTranslationsErrors } from '@top/dashboard/src/pages/activities/[activityId]/SceneModulesActivity/helpers/validation/translations/types'

export type CommonSceneModuleProps<TError> = {
  /** @default false */
  showError?: boolean
  error?: TError
  /**
   * An id to be used for testing purposes. Will be passed to the root element of the component as
   * the `data-testid` html attribute.
   */
  testId?: string
  /** @default false */
  disabled?: boolean
  /** @default false */
  isBuilder?: boolean
  /**
   * @description In the builder, true when the scene modules is selected.
   *
   * @default false
   */
  isFocused?: boolean
}

export const COMMON_DEFAULTS = {
  showError: false,
  disabled: false,
  isBuilder: false,
  isFocused: false,
} satisfies Omit<CommonSceneModuleProps<unknown>, 'error'>

export type QuestionSceneModuleProps<TValue, TError> = CommonSceneModuleProps<TError> & {
  value: TValue
  onChange?: (value: TValue) => void
  onFocus?: () => void
  onBlur?: () => void
}

export type BuilderSelectionsErrors = {
  images?: SelectionsImageErrors
  translations?: SelectionsTranslationsErrors
}
