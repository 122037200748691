import { Box } from '@mui/material'

import COLORS from '@top/shared/src/style/colors'
import { BACKGROUND_IMAGE_ASPECT_RATIO } from '@top/shared/src/style/constants'
import { BackgroundImage } from '@top/ui/src/SceneWrapper/BackgroundImage'
import NextButton from '@top/ui/src/SceneWrapper/NextButton'
import { ProgressBar } from '@top/ui/src/SceneWrapper/ProgressBar'
import RespondentLanguageSelector from '@top/ui/src/SceneWrapper/RespondentLanguageSelector'
import SkipButton from '@top/ui/src/SceneWrapper/SkipButton'
import { type SceneWrapperProps } from '@top/ui/src/SceneWrapper/types'

export const defaultOuterBackgroundStyle = {
  backgroundColor: '#ffff',
  backgroundImage: '',
  backgroundType: 'color',
  blur: 0,
  brightness: 100,
  cropHeight: 0,
  cropWidth: 0,
  cropX: 0,
  cropY: 0,
  saturation: 100,
  zoom: 1,
  /** @description Background image has a constant aspect ratio. This is not editable. */
  aspectRatio: BACKGROUND_IMAGE_ASPECT_RATIO,
} satisfies SceneWrapperProps['outerBackgroundStyle']

export const defaultInnerBackgroundStyle = {
  backgroundColor: '#ffff',
  backgroundType: 'color',
} satisfies SceneWrapperProps['innerBackgroundStyle']

/**
 * The default container padding. The padding top and bottom will be the largest of 2% or 1rem,
 * effectively minimizing the padding to 1rem. The padding left and right will be 5%.
 */
const DEFAULT_CONTAINER_PADDING = 'max(2%, 1rem) 5% '

export function SceneWrapper(props: SceneWrapperProps) {
  const {
    isBuilder = false,
    isNextButtonHidden,
    outerBackgroundStyle,
    innerBackgroundStyle,
    languageSelectorProps,
    progressBarProps,
    nextButtonProps,
    skipButtonProps,
    testId,
    children,
  } = props

  const { backgroundType: outerBackgroundType } = outerBackgroundStyle
  const { backgroundColor: innerBackroundColor } = innerBackgroundStyle

  const hasBackgroundImage =
    outerBackgroundType === 'image' && !!outerBackgroundStyle.backgroundImage

  const outerBackgroundColor = outerBackgroundStyle.backgroundColor || COLORS.WHITE

  const hasButton = !!(nextButtonProps ?? skipButtonProps)
  const hasProgressBar = !!progressBarProps
  const hasLanguageSelector = !!languageSelectorProps && !isBuilder

  return (
    <Box
      display="flex"
      flexGrow={1}
      data-testid={testId}
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      width="auto"
      minHeight="100%"
      padding={DEFAULT_CONTAINER_PADDING}
      sx={{ position: 'relative' }}
      {...((hasLanguageSelector || hasProgressBar) && { paddingTop: '0' })}
      {...(!hasBackgroundImage && {
        backgroundColor: outerBackgroundColor,
      })}
      {...(isBuilder && { border: `1px solid ${COLORS.BORDER_GRAY}` })}
    >
      {hasBackgroundImage && (
        <BackgroundImage backgroundStyles={outerBackgroundStyle} parentTestId={testId} />
      )}
      <Box
        width="100%"
        height="100%"
        position="sticky"
        top="0"
        {...(!isBuilder && { zIndex: 9999 })}
        align-self="flex-start"
        {...(hasLanguageSelector && !hasProgressBar && { paddingBottom: 'max(2%, 0.5rem)' })}
        {...(!hasLanguageSelector && hasProgressBar && { paddingBottom: { xs: 0, md: '2%' } })}
      >
        {hasLanguageSelector && (
          <RespondentLanguageSelector
            {...languageSelectorProps}
            backgroundColor={outerBackgroundStyle.backgroundColor}
            backgroundType={outerBackgroundType}
            parentTestId={testId}
          />
        )}
        {hasProgressBar && <ProgressBar {...progressBarProps} parentTestId={testId} />}
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexGrow: 1,
          rowGap: '1rem',
          flexDirection: 'column',
          justifyContent: 'space-between',
          alignItems: 'center',
          borderRadius: '14px',
          backgroundColor: innerBackroundColor,
          width: '100%',
          boxSizing: 'border-box',
          padding: '1.5rem',
        }}
      >
        {children}
        {hasButton && (
          <Box
            display="flex"
            flexGrow={0}
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            padding="1rem 0 0"
            width="100%"
            gap="0.5rem"
            boxSizing="border-box"
          >
            {!isNextButtonHidden && nextButtonProps && (
              <NextButton {...nextButtonProps} isBuilder={isBuilder} parentTestId={testId} />
            )}

            {skipButtonProps && (
              <SkipButton {...skipButtonProps} isBuilder={isBuilder} parentTestId={testId} />
            )}
          </Box>
        )}
      </Box>
    </Box>
  )
}
