import Box, { type BoxProps } from '@mui/material/Box'

import COLORS from '@top/shared/src/style/colors'

export type Border = {
  width: number
  stroke: 'solid' | 'dashed'
  color: string
}

const defaultBorder = {
  width: 1,
  stroke: 'solid',
  color: COLORS.BLACK,
} satisfies Border

type Props = {
  children: React.ReactNode
  /**
   * @description Enables solid border.
   *
   * @default false
   */
  isFocused?: boolean
  /**
   * @description Enables border on hover.
   *
   * @default false
   */
  hover?: boolean
  /** @description Custom focus border. */
  focusBorder?: Border

  /** @description Custom hover border. */
  hoverBorder?: Border
  errorBorder?: Border
  fullWidth?: boolean
  hasError?: boolean
  /** If true, no focus or hover border is shown. */
  disabled?: boolean
}

export function FocusedBorder(props: Props) {
  const {
    isFocused = false,
    hover = false,
    children,
    focusBorder = defaultBorder,
    hoverBorder = defaultBorder,
    errorBorder = { ...defaultBorder, color: COLORS.ERROR },
    fullWidth = false,
    hasError = false,
    disabled = false,
  } = props

  const getBorderStyle = (border: Border) => {
    return `${border.width}px ${border.stroke} ${border.color}`
  }

  const hoverSx = {
    ':hover': {
      border: !hasError
        ? getBorderStyle(hoverBorder)
        : getBorderStyle({ ...errorBorder, width: errorBorder.width + 1 }),
      cursor: 'pointer',
    },
  } satisfies BoxProps['sx']

  const focusedSx = {
    border: getBorderStyle(focusBorder),
    borderWidth: hasError ? '2px' : undefined,
  } satisfies BoxProps['sx']

  const sx = {
    ...(isFocused ? focusedSx : {}),
    ...(hover ? hoverSx : {}),
    ...(hasError ? { border: getBorderStyle(errorBorder) } : {}),
  } satisfies BoxProps['sx']

  return (
    <Box borderRadius="4px" sx={!disabled ? sx : undefined} width={fullWidth ? '100%' : undefined}>
      {children}
    </Box>
  )
}
