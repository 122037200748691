import { createContext, useContext } from 'react'

import { getConfigs } from '@top/shared/src/configs'

import { Cloudinary } from '@cloudinary/url-gen'

type Props = { cloudinary: Cloudinary }
const CloudinaryContext = createContext<Props | undefined>(undefined)

export const useCloudinary = () => {
  const context = useContext(CloudinaryContext)

  if (context === undefined) {
    throw new Error('useCloudinary must be used within CloudinaryProvider')
  }
  return context
}

const { CLOUDINARY_CLOUD } = getConfigs()

let cloudinary: Cloudinary

const getCloudinaryInstance = () => {
  if (!cloudinary) {
    cloudinary = new Cloudinary({
      cloud: { cloudName: CLOUDINARY_CLOUD },
      url: { privateCdn: true },
    })
  }
  return cloudinary
}

export const CloudinaryProvider = (props: { children: React.ReactNode }) => {
  const cloudinary = getCloudinaryInstance()

  return (
    <CloudinaryContext.Provider value={{ cloudinary }}>{props.children}</CloudinaryContext.Provider>
  )
}
