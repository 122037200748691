type Props = {
  size: number
  isSelected: boolean
  value: number
  secondaryColor: string
  fill: string
  transition?: string
}

const Star = (props: Props) => {
  const { size, isSelected, value, secondaryColor, transition, fill } = props

  return (
    <svg viewBox="0 0 58 57" fill="none" width={`${size}px`} height={`${size}px`}>
      <path
        d="M54 22.1L36.025 20.55L29 4L21.975 20.575L4 22.1L17.65 33.925L13.55 51.5L29 42.175L44.45 51.5L40.375 33.925L54 22.1Z"
        fill={secondaryColor}
      />
      <path
        d="M50.11 23.2438L34.9715 21.9384L29.055 8L23.1385 21.9595L8 23.2438L19.496 33.2028L16.043 48.0045L29.055 40.151L42.067 48.0045L38.635 33.2028L50.11 23.2438Z"
        style={{ fill, transition }}
      />
      <text
        x="50%"
        y="53%"
        dominantBaseline="central"
        textAnchor="middle"
        fill={secondaryColor}
        style={{ fontWeight: 500 }}
      >
        {value}
      </text>
      {isSelected && (
        <path
          d="M36.587 19.874L56 21.548L41.285 34.319L45.686 53.3L29 43.229L12.314 53.3L16.742 34.319L2 21.548L21.413 19.901L29 2L36.587 19.874Z"
          stroke={fill}
          strokeDasharray="7 6"
          strokeDashoffset={101}
        />
      )}
    </svg>
  )
}

export default Star
