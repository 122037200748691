import { v4 as uuid } from 'uuid'

const LOCAL_STORAGE_ID_KEY = '__MAG__LOCAL__STORAGE__ID__'

export function getUserIdFromLocalStorage(): string | undefined {
  try {
    const currentID = localStorage.getItem(LOCAL_STORAGE_ID_KEY)
    if (currentID && currentID.length > 0) {
      return currentID
    } else {
      const newID = uuid()
      localStorage.setItem(LOCAL_STORAGE_ID_KEY, newID)
      return newID
    }
  } catch (error) {
    return undefined
  }
}
