import React, { type CSSProperties, useEffect, useRef } from 'react'

import { Box, type Theme } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'

import { getDateNumberInputTestId } from '@top/dashboard/e2e/utils/testIds'
import COLORS from '@top/shared/src/style/colors'
import { getDateNumberSelectStyle } from '@top/ui/src/SceneModules/DateEntry/helpers/getDateNumberSelectStyle'
import { type PinwheelNumberInputProps } from '@top/ui/src/SceneModules/DateEntry/types'

const getCleansedValue = (inputValue: string, max: number) => {
  const maxDigits = `${max}`.length

  // Remove non-digit characters and ensure it's not longer than maxDigits
  const cleansedString = inputValue.replace(/\D/g, '').slice(-maxDigits)

  const cleansedValue = parseInt(cleansedString, 10)

  return cleansedValue
}

const useStyles = makeStyles((theme: Theme) => {
  return {
    inputContainer: {
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      fontSize: '1rem',
      textAlign: 'center',
      width: '100%',
    },
    inputWrapper: {
      border: `2px solid ${COLORS.DIVIDER_LIGHT}`,
      borderRadius: '4px',
      height: '55px',
      [theme.breakpoints.down('xs')]: {
        height: '45px',
        margin: '35px 0',
      },
      '&:focus-within': {
        borderColor: `${COLORS.TEXT_DARK}`,
      },
    },
    input: {
      '-moz-appearance': 'textfield',
      appearance: 'textfield',
      width: '65px',
      backgroundColor: 'transparent',
      border: 'none',
      fontSize: 'inherit',
      fontWeight: 500,
      display: 'block',
      boxSizing: 'border-box',
      [theme.breakpoints.down('xs')]: {
        width: '45px',
      },
      '&::-webkit-inner-spin-button, &::-webkit-outer-spin-button': {
        '-webkit-appearance': 'none',
        margin: 0,
      },
      '&:focus': {
        outline: 'none',
      },
      '&::placeholder': {
        color: 'inherit',
        fontWeight: 'inherit',
        width: 'fit-content',
      },
    },
  }
})

type Props = {
  onInputChange: (value: number) => void
  inputElementStyle?: CSSProperties
  isStaticInput: boolean
} & PinwheelNumberInputProps

const DateNumberInput = (props: Props) => {
  const {
    style,
    inputElementStyle,
    onInputTypeChange,
    onInputChange,
    onResetInputType,
    min,
    max,
    inputValue,
    placeholder,
    showError,
    disabled,
    isFocusedRef,
    isStaticInput,
  } = props

  const classes = useStyles()

  const inputRef = useRef<HTMLInputElement>(null)

  /** We use this side effect to focus into the input if its a dynamic input. */
  useEffect(() => {
    if (!isStaticInput) {
      inputRef.current?.focus()
    }
  }, [isStaticInput])

  const isInvalid =
    inputValue === undefined || (inputValue && (inputValue > max || inputValue < min))

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const cleansedValue = getCleansedValue(event.target.value, max)

    onInputChange(cleansedValue)
  }

  const handleBlur = (event: React.FocusEvent<HTMLInputElement>) => {
    event.stopPropagation()

    // If the input is invalid, we don't want to reset the input type
    if (isInvalid) return

    if (!isFocusedRef.current) {
      onInputTypeChange('PIN_WHEEL_TYPE_CLICK')
    }

    onResetInputType()
  }

  const handleKeyDown = (event: React.KeyboardEvent) => {
    const key = event.key

    if (key === 'Enter' || key === 'Escape') {
      inputRef.current?.blur()
    }
  }

  const dateNumberSelectStyle = getDateNumberSelectStyle(style, inputElementStyle)

  return (
    <Box className={classes.inputContainer}>
      <Box
        className={classes.inputWrapper}
        sx={{
          ...(isInvalid && showError && { borderColor: `${COLORS.ERROR} !important` }),
        }}
      >
        <Box height="100%" width="100%" display="flex" justifyContent="center">
          <input
            data-testid={getDateNumberInputTestId(placeholder)}
            placeholder={placeholder}
            aria-label={`Enter ${placeholder}`}
            type="number"
            inputMode="numeric"
            className={classes.input}
            ref={inputRef}
            value={inputValue ?? ''}
            max={max}
            min={min}
            onBlur={handleBlur}
            onChange={handleInputChange}
            onKeyDown={handleKeyDown}
            style={{ ...dateNumberSelectStyle, textAlign: 'center', width: 'fit-content' }}
            disabled={disabled}
          />
        </Box>
      </Box>
    </Box>
  )
}

export default DateNumberInput
