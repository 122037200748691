import { type CSSProperties } from 'react'

import { type DateInputStyle } from '@top/shared/src/style/types'

export const getDateNumberSelectStyle = (
  dateInputStyle: DateInputStyle,
  elementStyle?: CSSProperties
) => {
  return {
    ...dateInputStyle,
    ...(dateInputStyle.bold && { fontWeight: 'bold' }),
    ...(dateInputStyle.italic && { fontStyle: 'italic' }),
    ...(dateInputStyle.underline && { textDecoration: 'underline' }),
    fontSize: 16,
    ...(elementStyle && { ...elementStyle }),
  }
}
