import React from 'react'

import { Box } from '@mui/material'

import COLORS from '@top/shared/src/style/colors'
import { getTextStyle } from '@top/shared/src/style/helpers'
import { Fonts } from '@top/shared/src/style/types'
import { type ResultTextStyle } from '@top/ui/src/SceneModules/NumericScale/types'

export const defaultResultTextStyles = {
  backgroundColor: 'transparent',
  bold: true,
  color: COLORS.BLACK,
  fontFamily: Fonts.Roboto,
  fontSize: 64,
  italic: false,
  underline: false,
} as const satisfies ResultTextStyle

export type Props = {
  result: string
  style: ResultTextStyle
  onFocus?: () => void
  onBlur?: () => void
  isHidden?: boolean
  parentTestId?: string
  widgetWidth?: number
}

const ResultText = (props: Props) => {
  const { result, style, isHidden = false, onFocus, onBlur, parentTestId, widgetWidth } = props

  const {
    fontSize,
    color,
    fontWeight,
    fontStyle,
    fontFamily,
    textDecoration,
    textAlign,
    backgroundColor,
  } = getTextStyle(style, widgetWidth)

  const testId = parentTestId ? `${parentTestId}-result-text` : undefined

  const handleOnClick = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation()

    if (onFocus) {
      onFocus()
    }
  }

  return (
    <Box
      data-testid={testId}
      sx={{
        padding: '0rem 1rem',
        opacity: isHidden ? 0 : 1,
        borderRadius: 4,

        textAlign,
        fontSize,
        color,
        fontWeight,
        fontStyle,
        fontFamily,
        textDecoration,
        backgroundColor,
      }}
      onBlur={onBlur}
      onClick={handleOnClick}
    >
      {result}
    </Box>
  )
}

export default ResultText
