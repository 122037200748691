export const ProdEnvs = ['na1', 'na2', 'ap2', 'ap3', 'eu1', 'eu2'] as const
export const DevEnvs = ['sit1', 'localhost'] as const
const OtherEnvs = ['local', 'testing'] as const

export type ProdEnv = (typeof ProdEnvs)[number]
export type DevEnv = (typeof DevEnvs)[number]
type OtherEnv = (typeof OtherEnvs)[number]
export type Env = ProdEnv | DevEnv | OtherEnv

const EnvList = [...ProdEnvs, ...DevEnvs, ...OtherEnvs] satisfies Env[]

export function isEnv(env: string): env is Env {
  return EnvList.includes(env as Env)
}

export type App = 'Touchpoint' | 'CC'

type EnvConfig = {
  API_URL: string
  CLOUDINARY_URL: string
  SENTRY_DSN: string
  CLOUDINARY_CLOUD: string
  POD_NAME: Env
  DIST_URL: string
  SDK_CDN: string
  SOCIAL_DEFAULT_IMAGE: string
  DOMAIN: string
  STATIC_URL: string
  CLOUDFRONT_URL: string
  POD_FQDN: string
}

export const MUI_X_LICENSE_KEY =
  'cd07ab25b04f233666a1a8e6fb930473Tz01NDQzNixFPTE3MDAxNzM4NjE1MTksUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI='

export const useNewUrl = true
export const DEV_URL = useNewUrl ? 'alidalabs.com' : 'vcilabs.com'
export const PROD_URL = useNewUrl ? 'alida.com' : 'visioncritical.com'
export const FONT_URL = '/cc/fonts/' as const

export const SENTRY = {
  SIT1: 'https://a4ca813e7fae42d29bc2d55b4cfd66a9@o457413.ingest.sentry.io/5978061',
  DEV: 'https://d3e6efbfc8fe4d179d6b04deb9ae47fb@o457413.ingest.sentry.io/5977978',
  PROD: 'https://fe12c27412e345adab67596ef59eac44@o457413.ingest.sentry.io/5978064',
  FEEDBACK: 'https://8f6174882e104f38b3a77d19cb965f78@o457413.ingest.sentry.io/5995444',
}

const CLOUDINARY = {
  DEV_NAME: 'vcdevelopment',
  DEV_URL: 'https://vcdevelopment-res.cloudinary.com/image/upload',
  PROD_NAME: 'vcprodit',
  PROD_URL: 'https://vcprodit-res.cloudinary.com/image/upload',
}

const STATIC_URL = {
  DEV: `https://static.sit1.${DEV_URL}`,
  PROD: `https://static.global.${PROD_URL}`,
}

const SOCIAL_IMAGE = {
  DEV: 'https://vcdevelopment-res.cloudinary.com/image/upload/touchpoint/local/22-ff4e264e-7c76-4f81-880d-dbb698000c62/wrdmuwariywfd1iftkcw',
  PROD: 'https://vcprodit-res.cloudinary.com/image/upload/v1600275277/touchpoint/assets/bf43b8aa9aa6aa7e68c1353d1dbde944.jpg',
}

const SDK_CDN = {
  DEV: `https://touchpoint-sdk.${DEV_URL}/main.js`,
  PROD: `https://touchpoint-sdk.${PROD_URL}/main.js`,
}

const CONFIGS: { [key in Env]: EnvConfig } = {
  sit1: {
    API_URL: `https://api-touchpoint.sit1.${DEV_URL}`,
    CLOUDINARY_URL: CLOUDINARY.DEV_URL,
    SENTRY_DSN: SENTRY.SIT1,
    CLOUDINARY_CLOUD: CLOUDINARY.DEV_NAME,
    POD_NAME: 'sit1',
    DIST_URL: `https://dist-touchpoint.sit1.${DEV_URL}`,
    SDK_CDN: SDK_CDN.DEV,
    SOCIAL_DEFAULT_IMAGE: SOCIAL_IMAGE.DEV,
    DOMAIN: `touchpoint.sit1.${DEV_URL}`,
    STATIC_URL: STATIC_URL.DEV,
    CLOUDFRONT_URL: `https://client-uploads.sit1.${DEV_URL}`,
    POD_FQDN: `https://static.sit1.${DEV_URL}`,
  },
  na1: {
    API_URL: `https://api-touchpoint.na1.${PROD_URL}`,
    CLOUDINARY_URL: CLOUDINARY.PROD_URL,
    SENTRY_DSN: SENTRY.PROD,
    CLOUDINARY_CLOUD: CLOUDINARY.PROD_NAME,
    POD_NAME: 'na1',
    DIST_URL: `https://dist-touchpoint.na1.${PROD_URL}`,
    SDK_CDN: SDK_CDN.PROD,
    SOCIAL_DEFAULT_IMAGE: SOCIAL_IMAGE.PROD,
    DOMAIN: `touchpoint.na1.${PROD_URL}`,
    STATIC_URL: STATIC_URL.PROD,
    CLOUDFRONT_URL: `https://client-uploads.na1.${PROD_URL}`,
    POD_FQDN: `https://static.na1.${PROD_URL}`,
  },
  na2: {
    API_URL: `https://api-touchpoint.na2.${PROD_URL}`,
    CLOUDINARY_URL: CLOUDINARY.PROD_URL,
    SENTRY_DSN: SENTRY.PROD,
    CLOUDINARY_CLOUD: CLOUDINARY.PROD_NAME,
    POD_NAME: 'na2',
    DIST_URL: `https://dist-touchpoint.na2.${PROD_URL}`,
    SDK_CDN: SDK_CDN.PROD,
    SOCIAL_DEFAULT_IMAGE: SOCIAL_IMAGE.PROD,
    DOMAIN: `touchpoint.na2.${PROD_URL}`,
    STATIC_URL: STATIC_URL.PROD,
    CLOUDFRONT_URL: `https://client-uploads.na2.${PROD_URL}`,
    POD_FQDN: `https://static.na2.${PROD_URL}`,
  },
  eu1: {
    API_URL: `https://api-touchpoint.eu1.${PROD_URL}`,
    CLOUDINARY_URL: CLOUDINARY.PROD_URL,
    SENTRY_DSN: SENTRY.PROD,
    CLOUDINARY_CLOUD: CLOUDINARY.PROD_NAME,
    POD_NAME: 'eu1',
    DIST_URL: `https://dist-touchpoint.eu1.${PROD_URL}`,
    SDK_CDN: SDK_CDN.PROD,
    SOCIAL_DEFAULT_IMAGE: SOCIAL_IMAGE.PROD,
    DOMAIN: `touchpoint.eu1.${PROD_URL}`,
    STATIC_URL: STATIC_URL.PROD,
    CLOUDFRONT_URL: `https://client-uploads.eu1.${PROD_URL}`,
    POD_FQDN: `https://static.eu1.${PROD_URL}`,
  },
  eu2: {
    API_URL: `https://api-touchpoint.eu2.${PROD_URL}`,
    CLOUDINARY_URL: CLOUDINARY.PROD_URL,
    SENTRY_DSN: SENTRY.PROD,
    CLOUDINARY_CLOUD: CLOUDINARY.PROD_NAME,
    POD_NAME: 'eu2',
    DIST_URL: `https://dist-touchpoint.eu2.${PROD_URL}`,
    SDK_CDN: SDK_CDN.PROD,
    SOCIAL_DEFAULT_IMAGE: SOCIAL_IMAGE.PROD,
    DOMAIN: `touchpoint.eu2.${PROD_URL}`,
    STATIC_URL: STATIC_URL.PROD,
    CLOUDFRONT_URL: `https://client-uploads.eu2.${PROD_URL}`,
    POD_FQDN: `https://static.eu2.${PROD_URL}`,
  },
  ap2: {
    API_URL: `https://api-touchpoint.ap2.${PROD_URL}`,
    CLOUDINARY_URL: CLOUDINARY.PROD_URL,
    SENTRY_DSN: SENTRY.PROD,
    CLOUDINARY_CLOUD: CLOUDINARY.PROD_NAME,
    POD_NAME: 'ap2',
    DIST_URL: `https://dist-touchpoint.ap2.${PROD_URL}`,
    SDK_CDN: SDK_CDN.PROD,
    SOCIAL_DEFAULT_IMAGE: SOCIAL_IMAGE.PROD,
    DOMAIN: `touchpoint.ap2.${PROD_URL}`,
    STATIC_URL: STATIC_URL.PROD,
    CLOUDFRONT_URL: `https://client-uploads.ap2.${PROD_URL}`,
    POD_FQDN: `https://static.ap2.${PROD_URL}`,
  },
  ap3: {
    API_URL: `https://api-touchpoint.ap3.${PROD_URL}`,
    CLOUDINARY_URL: CLOUDINARY.PROD_URL,
    SENTRY_DSN: SENTRY.PROD,
    CLOUDINARY_CLOUD: CLOUDINARY.PROD_NAME,
    POD_NAME: 'ap3',
    DIST_URL: `https://dist-touchpoint.ap3.${PROD_URL}`,
    SDK_CDN: SDK_CDN.PROD,
    SOCIAL_DEFAULT_IMAGE: SOCIAL_IMAGE.PROD,
    DOMAIN: `touchpoint.ap3.${PROD_URL}`,
    STATIC_URL: STATIC_URL.PROD,
    CLOUDFRONT_URL: `https://client-uploads.ap3.${PROD_URL}`,
    POD_FQDN: `https://static.ap3.${PROD_URL}`,
  },
  local: {
    API_URL: `https://api-touchpoint.local.${DEV_URL}`,
    CLOUDINARY_URL: CLOUDINARY.DEV_URL,
    SENTRY_DSN: SENTRY.DEV,
    CLOUDINARY_CLOUD: CLOUDINARY.DEV_NAME,
    POD_NAME: 'local',
    DIST_URL: `https://dist-touchpoint.local.${DEV_URL}`,
    SDK_CDN: SDK_CDN.DEV,
    SOCIAL_DEFAULT_IMAGE: SOCIAL_IMAGE.DEV,
    DOMAIN: `touchpoint.local.${DEV_URL}`,
    STATIC_URL: STATIC_URL.DEV,
    CLOUDFRONT_URL: `https://client-uploads.sit1.${DEV_URL}`,
    POD_FQDN: `https://static.sit1.${DEV_URL}`,
  },
  testing: {
    API_URL: '', // let it resolve itself
    // API_URL: `https://api-touchpoint.sit1.${DEV_URL}`, // CC Configs
    CLOUDINARY_URL: CLOUDINARY.DEV_URL,
    SENTRY_DSN: SENTRY.DEV,
    CLOUDINARY_CLOUD: CLOUDINARY.DEV_NAME,
    POD_NAME: 'testing',
    DIST_URL: '',
    SDK_CDN: '',
    SOCIAL_DEFAULT_IMAGE: SOCIAL_IMAGE.DEV,
    DOMAIN: `top.testing.${DEV_URL}`,
    STATIC_URL: STATIC_URL.DEV,
    CLOUDFRONT_URL: `https://client-uploads.sit1.${DEV_URL}`,
    POD_FQDN: `https://static.sit1.${DEV_URL}`,
  },
  localhost: {
    API_URL: `https://api-touchpoint.sit1.${DEV_URL}`,
    CLOUDINARY_URL: CLOUDINARY.DEV_URL,
    SENTRY_DSN: SENTRY.SIT1,
    CLOUDINARY_CLOUD: CLOUDINARY.DEV_NAME,
    POD_NAME: 'sit1',
    DIST_URL: '',
    SDK_CDN: '',
    SOCIAL_DEFAULT_IMAGE: SOCIAL_IMAGE.DEV,
    DOMAIN: `localhost`,
    STATIC_URL: STATIC_URL.DEV,
    CLOUDFRONT_URL: `https://client-uploads.sit1.${DEV_URL}`,
    POD_FQDN: `https://static.sit1.${DEV_URL}`,
  },
}

export const getConfigs: () => EnvConfig = () => {
  const env = getEnv()
  return CONFIGS[env]
}

export function getEnv(): Env {
  let env: Env = 'testing'
  const hostname = window.location.hostname
  if (hostname === 'localhost' && process.env.NODE_ENV !== 'test') {
    return 'localhost'
  }
  const _env: Env = hostname.split('.')[1] as Env
  if (EnvList.includes(_env)) {
    env = _env
  }
  return env
}

export function getApp(): App {
  const hostname = window.location.hostname

  if (hostname === 'localhost') {
    return 'Touchpoint'
  }

  const hostnameFragments = hostname.split('.')

  if (!hostnameFragments[0]) {
    throw new Error(`Invalid hostname ${hostname}`)
  }

  const app = hostnameFragments[0].toLowerCase()
  if (!app.includes('top') && !app.includes('touchpoint')) {
    return 'CC'
  }
  return 'Touchpoint'
}

export function isDist(): boolean {
  const hostname = window.location.hostname.toLowerCase()
  if (hostname.indexOf('dist') !== -1) {
    return true
  }
  return false
}

export function isDash(): boolean {
  const hostname = window.location.hostname.toLowerCase()
  if (hostname.indexOf('dist') === -1) {
    return true
  }
  return false
}

export function getFontUrl() {
  const config = getConfigs()

  return new URL(FONT_URL, config.POD_FQDN)
}

export const isDevEnvironment = () => {
  const env = getEnv()
  return process.env.NODE_ENV === 'development' || !ProdEnvs.includes(env as ProdEnv)
}
