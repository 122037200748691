import React from 'react'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'

import COLORS from '@top/shared/src/style/colors'

import TouchpointProductLogo from './icons/TouchpointProductLogo'

type Props = {
  title: string
  description: string
  statusCode?: number
}

const TouchpointErrorPage = (props: Props) => {
  const { title, description, statusCode } = props

  return (
    <Grid
      container
      justifyContent="center"
      alignItems="center"
      bgcolor={COLORS.BLUE}
      minHeight="100vh"
      p={{ xs: '1.5rem 0.75rem', md: '3rem 1.5rem' }}
      style={{ overflowY: 'scroll', height: '100vh' }}
    >
      <Grid
        container
        borderRadius="2rem"
        padding="2rem 1rem"
        width="min(1200px, 100%)"
        minHeight={{ md: '500px' }}
        bgcolor="rgba(61, 56, 53, 0.73)"
        style={{
          opacity: '0.8',
        }}
      >
        <Grid
          container
          flexDirection="column"
          alignItems="center"
          rowGap={{ xs: 5, md: 10 }}
          textAlign="center"
          whiteSpace="pre-line"
        >
          <TouchpointProductLogo style={{ width: 'min(360px, 100%)' }} />
          <Typography
            color={COLORS.WHITE}
            fontSize={{ xs: '1rem', md: '2rem' }}
            fontWeight="900"
            lineHeight="normal"
            fontFamily="Archivo"
          >
            {title}
          </Typography>
          <Grid container flexDirection="column" rowGap={2}>
            <Typography
              color={COLORS.WHITE}
              fontSize={{ xs: '0.75rem', md: '1.25rem' }}
              fontWeight="600"
              lineHeight="normal"
              fontFamily="Archivo"
            >
              {description}
            </Typography>
            {statusCode && (
              <Typography
                color={COLORS.BLACK}
                fontSize={{ xs: '1rem', md: '1.5rem' }}
                fontWeight="900"
                lineHeight="normal"
                fontFamily="Archivo"
              >
                {statusCode}
              </Typography>
            )}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default TouchpointErrorPage
