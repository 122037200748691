import { type NumericScaleIcon } from '@top/shared/src/style/types'
import Circle from '@top/ui/src/SceneModules/NumericScale/components/NumericScaleItem/ItemTypes/Circle'
import Face from '@top/ui/src/SceneModules/NumericScale/components/NumericScaleItem/ItemTypes/Face/Face'
import Star from '@top/ui/src/SceneModules/NumericScale/components/NumericScaleItem/ItemTypes/Star'
import { useNumericScaleAnimation } from '@top/ui/src/SceneModules/NumericScale/hooks/useNumericScaleAnimation'

type Props = {
  width: number
  value: number
  secondaryColor: string
  shape: NumericScaleIcon
  selectedValue: number
  /**
   * The fill color of the item. It may be different from the primary color if the item is not
   * selected.
   */
  fillColor: string
}

export function NumericScaleItem(props: Props) {
  const { value, width, secondaryColor, fillColor, shape, selectedValue } = props

  const transition = useNumericScaleAnimation(value, selectedValue)

  const isSelected = selectedValue === value

  switch (shape) {
    case 'NUMERIC_CIRCLE':
      return (
        <Circle
          value={value}
          secondaryColor={secondaryColor}
          diameter={width}
          fill={fillColor}
          isSelected={isSelected}
          transition={transition}
        />
      )
    case 'NUMERIC_STAR':
      return (
        <Star
          value={value}
          secondaryColor={secondaryColor}
          size={width}
          fill={fillColor}
          isSelected={isSelected}
          transition={transition}
        />
      )
    case 'NUMERIC_FACE':
      return <Face value={value} secondaryColor={secondaryColor} size={width} fill={fillColor} />
    default:
      throw new Error(`Unsupported numeric scale shape: ${shape}`)
  }
}
