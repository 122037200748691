import { Box } from '@mui/material'

import { CloudinaryImage } from '@top/shared/src/components/CloudinaryImage'
import { type ImageStyle, type TextAlign, type TextStyle } from '@top/shared/src/style/types'
import { ImagePlaceholder } from '@top/ui/src/SceneModules//Logo/components/ImagePlaceholder'
import { SelectionLabel } from '@top/ui/src/SceneModules/Choice/components/SelectionDisplayTypes/SelectionContent/SelectionLabel'
import { EditImageMenu } from '@top/ui/src/SceneModules/Logo/components/EditImageMenu'

type SelectionContentProps = {
  imageUrl?: string
  imageAlt?: string
  text?: string
  style: TextStyle
  isBuilder: boolean
  disabled: boolean
  onChange: (
    newText: string,
    options?: {
      clearSelectedElement: boolean
    }
  ) => void
  onOpenModal: () => void
  onRemoveImage: () => void
  onOpenEditorModal: () => void
  widgetWidth?: number
  imageStyles?: ImageStyle
}

export function SelectionContent(props: SelectionContentProps) {
  const {
    text,
    imageUrl,
    imageAlt,
    style,
    isBuilder,
    disabled,
    onChange,
    widgetWidth,
    imageStyles,
    onOpenModal,
    onRemoveImage,
    onOpenEditorModal,
  } = props

  const { textAlign } = style

  const image = imageUrl ? (
    <>
      <Box
        display="flex"
        alignItems="center"
        border="1px solid"
        flexDirection="column"
        overflow="auto"
        position="relative"
        height="150px"
        maxWidth={imageStyles?.aspectRatio === 1 ? '150px' : '250px'}
      >
        <CloudinaryImage
          publicId={imageUrl}
          imgAlt={imageAlt}
          // TODO: This was how choice image sizing was done pre-foundations. We may want to revisit this.
          height={widgetWidth && widgetWidth > 700 ? 321 : 150}
          imageStyles={imageStyles}
        />
      </Box>
      {isBuilder && (
        <EditImageMenu
          onRemove={onRemoveImage}
          onReplace={onOpenModal}
          onEdit={onOpenEditorModal}
        />
      )}
    </>
  ) : (
    <ImagePlaceholder
      onClick={onOpenModal}
      containerBackground="transparent"
      containerHeight="150px"
      containerWidth="150px"
      iconWidth="150px"
      iconSize="large"
    />
  )

  const getAlignment = (textAlign: TextAlign) => {
    switch (textAlign) {
      case 'center':
        return 'center'
      case 'right':
        return 'flex-end'
      case 'left':
      default:
        return 'flex-start'
    }
  }

  return (
    <Box
      display="flex"
      flexDirection="column"
      sx={{ alignItems: getAlignment(textAlign), width: '100%' }}
    >
      {imageUrl !== undefined && image}
      {text !== undefined && (
        <SelectionLabel
          style={style}
          text={text}
          isBuilder={isBuilder}
          widgetWidth={widgetWidth}
          disabled={disabled}
          onChange={onChange}
        />
      )}
    </Box>
  )
}
