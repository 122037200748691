import { useLocation } from 'react-router-dom'

import { ThemeProvider } from '@mui/material/styles'

import { getConfigs } from '@top/shared/src/configs'
import { CloudinaryProvider } from '@top/shared/src/contexts/Cloudinary'
import theme from '@top/shared/src/style/theme'

import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { ApiProvider } from 'api'
import { CloudinaryContext } from 'cloudinary-react'
import Scenes from 'components/scenes'
import SceneModules from 'components/scenes/SceneModules'
import { DistributionProvider } from 'contexts/Distribution'
import { TrackerProvider_DEPRECATED } from 'contexts/Tracker_DEPRECATED'
import { useIsSceneModulesEnabled } from 'hooks/useIsSceneModulesEnabled'
import queryString from 'query-string'

type Props = {
  ignoreTracker?: boolean
  isDashboardPreview?: boolean
}

const App = (props: Props) => {
  const { ignoreTracker, isDashboardPreview } = props
  const { search } = useLocation()
  const { CLOUDINARY_CLOUD } = getConfigs()
  const { preview }: { preview?: string } = queryString.parse(search)

  const isSceneModulesEnabled = useIsSceneModulesEnabled()

  const shouldIgnoreTracker = ignoreTracker || preview === 'true'

  if (isSceneModulesEnabled) {
    return (
      <ApiProvider>
        <ThemeProvider theme={theme}>
          <SceneModules shouldIgnoreTracker={shouldIgnoreTracker} />
          <ReactQueryDevtools initialIsOpen={false} />
        </ThemeProvider>
      </ApiProvider>
    )
  }

  return (
    <DistributionProvider isDashboardPreview={isDashboardPreview}>
      <TrackerProvider_DEPRECATED ignoreTracker={shouldIgnoreTracker}>
        <CloudinaryProvider>
          <CloudinaryContext cloudName={CLOUDINARY_CLOUD} privateCdn>
            <Scenes />
          </CloudinaryContext>
        </CloudinaryProvider>
      </TrackerProvider_DEPRECATED>
    </DistributionProvider>
  )
}

export default App
